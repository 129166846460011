const initialState = { isLocationVerified: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SUCCESS_IS_VERIFIED':
      return { ...state, ...action.data };
    case 'FAIL_IS_VERIFIED':
      return { ...state, isLocationVerified: false };
    default:
      return state;
  }
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { createAnswer } from 'components/organisms/question';
import Question from './14_SliderQuestion';
import Feedback from './14_Feedback';
import Result from './14_Result';
import { SUBMIT_DELAY } from '../../../constants';

const STEP1_QUESTION = 1;
const STEP2_FEEDBACK = 2;
const STEP3_RESULT = 3;

export default function SliderFeedbackQuestion14(props) {
  const {
    question,
    onSubmitQuestion,
    communityAnswer,
    showCoin,
    questionButtonClicked,
    disableForm,
    slugId,
    setHideSkipAfterAnswer,
    skipResults = false,
    setIsMediaZoomed,
  } = props;
  const [sliderValue, setSliderValue] = useState(50);
  const [step, setStep] = useState(STEP1_QUESTION);

  const sliderChange = (e) => {
    if (step !== STEP1_QUESTION) return;

    const newSliderValue = +e.target.value;
    setSliderValue(newSliderValue);
  };

  const questionConfig = question && question.questionConfiguration;
  const feedbackConfig = questionConfig && questionConfig.feedbackConfiguration;

  const createChoiceArray = (feedback, email, postalCode, image = {}) => [
    {
      choiceId: question.choices[0]._id,
      amount: sliderValue,
      email,
      postalCode,
      feedback,
      attachment: Object.keys(image).length > 0 ? [image] : undefined,
    },
  ];

  const onSubmitSlider = () => {
    onSubmitQuestion(createAnswer(createChoiceArray()), false);
    setTimeout(() => setStep(STEP2_FEEDBACK), SUBMIT_DELAY);
    setHideSkipAfterAnswer(false);
  };

  const onSubmitFeedback = ({ feedback, email, postalCode, image }) => {
    // console.log('FEEDBACK', feedback);
    onSubmitQuestion(createAnswer(createChoiceArray(feedback, email, postalCode, image)));
    if (!skipResults) {
      setTimeout(() => setStep(STEP3_RESULT), SUBMIT_DELAY);
    }
  };

  // console.log('QUESTION 14 SLIDER', this.props.question);
  return (
    <React.Fragment>
      {STEP1_QUESTION === step && (
        <Question
          question={question}
          sliderValue={sliderValue}
          sliderChange={sliderChange}
          onSubmitQuestion={onSubmitSlider}
          showCoin={showCoin}
          disableForm={disableForm}
          setIsMediaZoomed={setIsMediaZoomed}
        />
      )}

      {STEP2_FEEDBACK === step && (
        <Feedback
          question={question}
          sliderValue={sliderValue}
          onSubmitFeedback={onSubmitFeedback}
          showCoin={showCoin}
          disableForm={disableForm}
          slugId={slugId}
          allowImageUpload={
            questionConfig && questionConfig.allowUserContentUpload && questionConfig.allowUserContentUpload.image
          }
          isPostCodeOptional={feedbackConfig && feedbackConfig.isPostCodeOptional}
          isEmailOptional={feedbackConfig && feedbackConfig.isEmailOptional}
        />
      )}

      {STEP3_RESULT === step && (
        <Result
          questionButtonClicked={questionButtonClicked}
          question={question}
          sliderValue={sliderValue}
          communityAnswer={communityAnswer}
          setIsMediaZoomed={setIsMediaZoomed}
        />
      )}
    </React.Fragment>
  );
}

SliderFeedbackQuestion14.propTypes = {
  question: PropTypes.object.isRequired,
  slugId: PropTypes.string.isRequired,
  questionButtonClicked: PropTypes.func.isRequired,
};

import React from 'react';
import { Img, SQUARE } from '../images/Img';

export default function ChoiceImage(props) {
  const { isResult, resource, choiceName, communityAnswer } = props;

  return (
    <span className='c-choice-image__media'>
      {/* SAX! <img src={resource} alt={choiceName} /> */}
      <Img imageType={SQUARE} imageId={resource} alt={choiceName} />
      <span className='c-choice-image__state'>
        {isResult && <span className='c-choice-image__value'>{communityAnswer}%</span>}

        <svg viewBox='0 0 36 36' className='c-choice-image__svg'>
          <polyline className='c-choice-image__check' points='12 18 16 22 29 9' />

          {isResult && (
            <path
              className='c-choice-image__chart'
              strokeDasharray={`${communityAnswer}, 100`}
              d='M18 2 a 16 16 0 0 1 0 32 a 16 16 0 0 1 0 -32'
            />
          )}

          <path
            className='c-choice-image__chart-full'
            strokeDasharray='100, 100'
            d='M18 2 a 16 16 0 0 1 0 32 a 16 16 0 0 1 0 -32'
          />
        </svg>
      </span>
    </span>
  );
}

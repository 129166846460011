import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { hideTooltip } from 'actions';
import { bindActionCreators } from 'redux';

const TOOLTIP_PADDING = 10;
function AnnotateTooltip(props) {
  const { surveyData, actions } = props;

  const tooltipDescriptionElement = useRef();
  const [currentDescriptionElement, setCurrentDescriptionElement] = useState(tooltipDescriptionElement.current);

  useEffect(() => {
    setCurrentDescriptionElement(tooltipDescriptionElement.current);
  }, [surveyData.isTooltipVisible]);

  function getNewPosition(newX, newY) {
    const descOffsetWidth = currentDescriptionElement?.offsetWidth || 0;
    const descOffsetHeight = currentDescriptionElement?.offsetHeight || 0;
    const left = Math.min(Math.max(newX + TOOLTIP_PADDING, 0), window.innerWidth - descOffsetWidth);
    const top = Math.min(Math.max(newY + TOOLTIP_PADDING, window.scrollY), window.innerHeight - descOffsetHeight);

    return { left: `${left}px`, top: `${top}px` };
  }

  return surveyData.isTooltipVisible ? (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className='o-tooltip-container' onClick={actions.hideTooltip}>
      <span
        className='o-tooltip__description'
        role='tooltip'
        style={getNewPosition(surveyData.x, surveyData.y)}
        ref={tooltipDescriptionElement}
      >
        {surveyData.tooltipText}
      </span>
    </div>
  ) : null;
}

AnnotateTooltip.propTypes = {
  surveyData: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  surveyData: state.survey,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      hideTooltip,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnnotateTooltip);

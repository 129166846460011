import { Answer } from 'components/organisms/question';
import { Condition } from 'components/organisms/questions/types';

export const logicOps = {
  $AND: (left: boolean, right: boolean): boolean => left && right,
  $OR: (left: boolean, right: boolean): boolean => left || right,
};

export const criteriaOps = {
  $IS: (prevValue: boolean, left: string, right: string): boolean => prevValue || left === right,
  $IS_NOT: (prevValue: boolean, left: string, right: string): boolean => prevValue && left !== right,
};

export const comparisonOps = {
  $LT: (left: number, right: number): boolean => left < right,
  $LTE: (left: number, right: number): boolean => left <= right,
  $GT: (left: number, right: number): boolean => left > right,
  $GTE: (left: number, right: number): boolean => left >= right,
  $EQ: (left: number, right: number): boolean => left === right,
};

export const getBooleanFromAnswer = (answers: Answer[], condition: Condition): boolean => {
  if (condition.value === null || condition.value === undefined) {
    // get last answer and go through choiceIds, return boolean based on whether a required condition.choiceId $IS / $IS_NOT in the answer
    // different logic for $IS and $IS_NOT, for $IS, at least one MUST be the correct choiceId, for $IS_NOT, all must not be choiceId
    return answers[answers.length - 1]?.choice.reduce<boolean>(
      (accumulator, nextValue) =>
        criteriaOps[condition.criteria as keyof typeof criteriaOps](
          accumulator,
          nextValue.choiceId,
          condition.choiceId,
        ),
      condition.criteria === '$IS_NOT',
    );
  }
  // get last answer and go trough values, return boolean based on condition.value and condition.logicOp in relation to answer.amount
  const answerAmount = answers[answers.length - 1]?.choice[0].amount;
  if (answerAmount !== undefined) {
    return comparisonOps[condition.criteria as keyof typeof comparisonOps](
      // answer returns string, but we are comparing numbers
      Number.parseFloat(answerAmount.toString()) ?? 0,
      condition.value,
    );
  }
  return false;
};

export const getBooleanFromConditions = (conditions: Condition[], answers: Answer[]): boolean =>
  // loop through all conditions and return true if all are satisfied
  conditions.reduce<boolean>((accumulator, nextValue): boolean => {
    // logicOps are
    if (nextValue.logicOp === null) {
      return getBooleanFromAnswer(answers, nextValue);
    }
    return logicOps[nextValue.logicOp](accumulator, getBooleanFromAnswer(answers, nextValue));
  }, false);

import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../i18n/helper';
import SvgIcon from '../SvgIcon';

export default function PollsTitleBar(props) {
  const { openExplainer, showExplainer, explainerVisible } = props;
  return (
    <div className='c-section-intro'>
      <h1 className='c-section-intro__title'>{i18n.t('pollsScreen.title')}</h1>
      <div className='c-section-intro__action'>
        {explainerVisible && (
          <button
            className='c-button c-button--pill c-button--small c-button--alternate'
            aria-controls='explainer'
            aria-expanded='false'
            onClick={openExplainer}
          >
            <span className='c-button__label'>{i18n.t('gamification.explainer')}</span>{' '}
            {<SvgIcon icon={showExplainer ? 'times' : 'question-circle'} />}
          </button>
        )}
      </div>
    </div>
  );
}

PollsTitleBar.propTypes = {
  openExplainer: PropTypes.func.isRequired,
  showExplainer: PropTypes.bool,
  explainerVisible: PropTypes.bool,
};

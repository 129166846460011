const initialState = {
  isNewsDataPresent: false,
  renderWayPoint: false,
  page: 0,
  newsData: [],
  isLoading: true,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case 'SUCCESS_ADD_NEWS':
      return {
        ...state,
        newsData: action.newsData.data ? action.newsData.data.data : [],
        panel: action.newsData.data.panel,
        isNewsDataPresent: !!(action.newsData.data.data.length > 0 && action.newsData.data.data !== undefined),
        renderWayPoint: !!(action.newsData.data.data.length > 0 && action.newsData.data.data !== undefined),
        isLoading: false,
        page: action.page,
      };

    case 'FAIL_ADD_NEWS':
      return { ...state, failState: action.data };
    case 'CLEAR_NEWS':
      return { ...action.data };

    default:
      return state;
  }
};

import React from 'react';
import i18n from '../../i18n/helper';
import PillButton from '../button/PillButton';

export default function FeedbackOnPolls() {
  return (
    <article className='c-card-feedback'>
      <div className='c-card-feedback__header'>{i18n.t('pollsScreen.feedbackTitle')}</div>
      <div className='c-card-feedback__content'>
        <p className='c-card-feedback__description'>{i18n.t('pollsScreen.feedbackSubtitle')}</p>
        <PillButton
          url='feedback'
          title={i18n.t('pollsScreen.sendFeedback')}
          classModifier='c-button--primary c-button--small c-card-feedback__action'
        />
      </div>
    </article>
  );
}

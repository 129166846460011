import * as api from '../api';

export const getUpdatesFromServer = (slug_id, sorting, pageNo) => {
  const endpoint = `/projects/${slug_id}/notifications`;
  const data = {
    params: {
      q: {
        filter: {},
        paging: {
          size: 100, // IMPORTANT: all news are now displayed on the first page
          page: pageNo,
        },
        sort: sorting,
      },
    },
  };
  return api.get(endpoint, data);
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ImageWithZoomOrCarousel } from 'components/molecules/imageWithZoomOrCarousel/ImageWithZoomOrCarousel';
import i18n from '../../../i18n/helper';
import QuestionUtils from '../QuestionUtils/QuestionUtils';
import { handleScroll } from '../QuestionUtils/QuestionFormUtils';
import thumbup from '../../../assets-v2/images/illustration/thumbs-up.png';
import InputRange from '../../inputRange/InputRange';

function Result14(props) {
  const { communityAnswer, sliderValue, question, setIsMediaZoomed } = props;
  const communityAnswerAmount =
    communityAnswer.length > 0 && communityAnswer[0] ? communityAnswer[0].amount : sliderValue;
  const [isScrollingClass, setScrollingClass] = useState('');

  return (
    <article className={`c-question c-question--side-header ${isScrollingClass || ''}`}>
      <div className='c-question__header'>
        <h2 className='c-question__title'>
          <React.Fragment>
            <img src={thumbup} alt='thumbimage' />
            {i18n.t('questions.emojiSliderQuestion.result')}
          </React.Fragment>
        </h2>
      </div>
      <div className='c-question__content' onScroll={handleScroll(setScrollingClass)}>
        {question?.resource.length > 0 && (
          <ImageWithZoomOrCarousel
            resource={question?.resource}
            altImage='slider image'
            leadImageType={question?.questionConfiguration?.leadImageType}
            setIsMediaZoomed={setIsMediaZoomed}
          />
        )}
        <div className='c-slider c-slider--emoji u-a-slider-move-cue is-result is-disabled '>
          <label className='c-slider__label' htmlFor='emoji-1'>
            {i18n.t('questions.emojiSliderQuestion.sliderInfoText')}
          </label>
          <div className='c-slider__group'>
            <div className='c-slider__control'>
              <InputRange
                min={question.choices[0].range[0]}
                max={question.choices[0].range[1]}
                step='1'
                value={sliderValue}
                id='emoji-1'
                onChange={() => {}}
                onInput={() => {}}
              />
              <div className='c-slider__indicator'>
                {/* eslint-disable-next-line */}
                <label aria-hidden htmlFor='emoji-1' style={{ width: `${sliderValue}%` }}>
                  <span
                    className={`c-slider__handle c-slider__handle--emoji-${QuestionUtils.getCommunityEmoji(
                      sliderValue,
                    )}`}
                  />
                </label>
                <div
                  className='c-slider__community'
                  style={{ width: `${communityAnswerAmount}%` }}
                  data-title={i18n.t('questions.emojiSliderQuestion.rating')}
                >
                  <span className='c-slider__community-handle' />
                </div>
              </div>
            </div>
            <span className='c-slider__value'>{`${sliderValue}`}</span>
          </div>
        </div>
      </div>
      <div className='c-question__footer'>
        <button className='c-button c-button--primary c-button--large' onClick={props.questionButtonClicked}>
          <span className='c-button__label'>{i18n.t('questions.nextQuestion')}</span>
        </button>
      </div>
    </article>
  );
}

Result14.propTypes = {
  question: PropTypes.object.isRequired,
  questionButtonClicked: PropTypes.func.isRequired,
};

export default Result14;

import React from 'react';
import PropTypes from 'prop-types';

import { Img, WIDE } from '../images/Img';
import MarkdownField, { MARKDOWN_MID } from '../markdown/MarkdownField';
import PillButton from '../button/PillButton';

export default function Panel({ panel }) {
  const { name, description, resource, actionName, actionUrl } = panel;

  return (
    <article className={`c-section-panel ${resource ? 'c-section-panel--figure' : ''}`}>
      {resource && (
        <figure className='c-section-panel__figure'>
          <Img imageType={WIDE} imageId={resource} alt='' />
        </figure>
      )}
      <div className='c-section-panel__content'>
        <h2 className='c-section-panel__title'>{name}</h2>
        <div className='c-section-panel__description u-rich-text'>
          <MarkdownField markdown={description} markdownConfig={MARKDOWN_MID} />
        </div>
        {actionUrl && (
          <PillButton
            classModifier='c-button--small c-button--primary c-section-panel__action'
            url={actionUrl}
            title={actionName}
          />
        )}
      </div>
    </article>
  );
}

Panel.propTypes = {
  panel: PropTypes.object.isRequired,
};

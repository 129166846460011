import * as languages from '../../services/languages/languages';

export const getLanguages = (data) => (dispatch) =>
  languages
    .getLanguagesFromServer(data)
    .then((response) => {
      dispatch({
        type: 'SUCCESS_ADD_LANGUAGES',
        data: response.data,
      });
      return response;
    })
    .catch((response) => {
      dispatch({
        type: 'FAIL_ADD_LANGUAGES',
        failState: response,
      });
      return response;
    });

import React from 'react';
import PropTypes from 'prop-types';

import { createAnswer } from 'components/organisms/question';
import { SUBMIT_DELAY } from '../../../constants';
import FeedbackFormQuestion from '../FeedbackFormQuestion';

export default function FeedbackQuestion13(props) {
  const { question, onSubmitQuestion, showCoin, disableForm, slugId, questionButtonClicked } = props;

  const createChoiceArray = (feedback, email, postalCode, image) => [
    {
      choiceId: question.choices[0]._id,
      email,
      postalCode,
      feedback,
      attachment: Object.keys(image).length > 0 ? [image] : undefined,
    },
  ];

  const onSubmitFeedback = ({ feedback, email, postalCode, image }) => {
    // console.log('FEEDBACK', feedback);
    onSubmitQuestion(createAnswer(createChoiceArray(feedback, email, postalCode, image)));
    setTimeout(() => questionButtonClicked(), SUBMIT_DELAY);
  };

  const questionConfig = question && question.questionConfiguration;
  const feedbackConfig = questionConfig && questionConfig.feedbackConfiguration;

  return (
    <FeedbackFormQuestion
      questionText={question.questionText}
      question={question}
      onSubmitFeedback={onSubmitFeedback}
      showCoin={showCoin}
      disableForm={disableForm}
      slugId={slugId}
      allowImageUpload={
        questionConfig && questionConfig.allowUserContentUpload && questionConfig.allowUserContentUpload.image
      }
      isPostCodeOptional={feedbackConfig && feedbackConfig.isPostCodeOptional}
      isEmailOptional={feedbackConfig && feedbackConfig.isEmailOptional}
      collectEmailOnly={questionConfig?.collectEmailOnly}
    />
  );
}

FeedbackQuestion13.propTypes = {
  question: PropTypes.object.isRequired,
  slugId: PropTypes.string.isRequired,
};

import { SvgIcon } from 'components/atoms/svgIcon';
import MarkdownField, { MARKDOWN_MAX } from 'components/markdown/MarkdownField';
import i18n from 'i18n/helper';
import { GeneralModalProps } from './types';

export const GeneralModal = ({ description, closeCallback }: GeneralModalProps): JSX.Element => (
  <div className='c-modal-global-container'>
    <div className='c-modal-global' tabIndex={-1} role='dialog'>
      <div className='c-modal-global__header'>
        <button className='c-button c-modal-global__close' onClick={closeCallback} type='button'>
          <span className='c-button__label'>{i18n.t('close')}</span>
          <SvgIcon icon='times' />
        </button>
      </div>
      <div className='c-modal-global__content u-rich-text'>
        <MarkdownField markdown={description} markdownConfig={MARKDOWN_MAX} />
      </div>
    </div>
  </div>
);

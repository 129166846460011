import React from 'react';
import thumbup from '../../../assets-v2/images/illustration/thumbs-up.png';
import i18n from '../../../i18n/helper';
import Image from '../Image';
import PollButton from '../../button/PollButton';
import { OTHER_CHOICE_IMAGE } from '../../../constants';
import QuestionUtils from '../QuestionUtils/QuestionUtils';
import { WIDE } from '../../images/Img';

export default class Result15 extends React.Component {
  // console.log('RESULT', props);
  constructor(props) {
    super(props);
    this.state = {};
    this.choices = this.props.question.choices.filter((item) => item.resource);

    const fixedChoice = this.props.question.choices.find((item) => !item.resource);
    if (fixedChoice) {
      this.choices.push(fixedChoice);
    } else {
      // console.error('Fixed OTHER choice not found');
    }
    // const myAnswer = props.communityAnswer.find(item => item._id === props.selectedChoiceId);
    // console.error('Community answer', myAnswer);
    // this.percent = myAnswer ? myAnswer.amount : 0;
  }

  handleScroll = QuestionUtils.handleScroll(this);

  getPercent = () => {
    const myAnswer = this.props.communityAnswer.find((item) => item._id === this.props.selectedChoiceId);
    // console.error('Community answer', myAnswer);
    // this.percent = myAnswer ? myAnswer.amount : 0;
    return myAnswer ? myAnswer.amount : 0;
  };

  render() {
    return (
      <article className={`c-question ${this.state.isScrollingClass}`}>
        <div className='c-question__header'>
          <h2 className='c-question__title'>
            {/* {i18n.t('questions.pollType.aggrement', { percent: this.state.percentage })} */}
            <img src={thumbup} alt='imagethumb' />
            {i18n.t('questions.imagesOther.result.agreement', { percent: this.getPercent() })}
          </h2>
        </div>
        <div className='c-question__content' onScroll={this.handleScroll}>
          <div className='l-choice-grid l-choice-grid--wide-three'>
            {this.choices.map((data, index) => {
              // ### IMPORTANT 3rd choice/image is fixed one "Other"
              // console.log('Community answer', this.props.communityAnswer);
              const communityAnswerItem = this.props.communityAnswer.find((item) => item._id === data._id);
              // console.log('Community answer ITEM', communityAnswerItem);
              return (
                <Image
                  key={index}
                  isFixedImageURL={index === 2}
                  idx={index}
                  resource={index === 2 ? OTHER_CHOICE_IMAGE : data.resource}
                  imageText={index === 2 ? i18n.t('questions.imagesOther.question.other') : data.choice}
                  communityAnswerAmount={communityAnswerItem ? communityAnswerItem.amount : 0}
                  imageType={WIDE}
                  isResult
                  isSelected={this.props.selectedChoiceId === data._id}
                  isDisabled
                  questionId={this.props.question._id}
                  showCoin={false}
                />
              );
            })}
          </div>
        </div>
        <div className='c-question__footer'>
          <PollButton text={i18n.t('questions.nextQuestion')} onClick={this.props.questionButtonClicked} />
        </div>
      </article>
    );
  }
}

const initialState = {
  isTooltipVisible: false,
  x: 0,
  y: 0,
  tooltipText: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_TOOLTIP':
      return { ...state, isTooltipVisible: true, tooltipText: action.tooltipText, x: action.x, y: action.y };
    case 'HIDE_TOOLTIP':
      return { ...state, isTooltipVisible: false };
    default:
      return state;
  }
};

import React from 'react';
import PropTypes from 'prop-types';

import { Img, SQUARE } from '../../images/Img';
import SvgIcon from '../../SvgIcon';
import i18n from '../../../i18n/helper';

export default function ExplainerCharityItem(props) {
  const { description, title, coins, icon, isOpen, setIsOpen, id } = props;

  const onClickTab = () => {
    isOpen ? setIsOpen(undefined) : setIsOpen(id);
  };

  return (
    <div className={`c-charity c-charity--switchable ${isOpen ? 'is-opened' : 'is-closed'}`}>
      {icon ? (
        <figure className='c-charity__logo'>
          <Img imageType={SQUARE} imageId={icon} alt='charity-icon' />
        </figure>
      ) : null}
      <button className='c-button c-charity__toggle' tabIndex='0' onClick={onClickTab}>
        <span className='c-button__label'>{i18n.t('gamification.details')}</span>
        <SvgIcon icon='angle-down' />
      </button>
      <div className='c-charity__content'>
        <h3 className='c-charity__title'>{title}</h3>
        {coins > 50 && (
          <p className='c-charity__points'>
            <SvgIcon icon='coin' />
            {`${coins} ${i18n('questions.pointsEarned')}`}
          </p>
        )}
        <p className='c-charity__description'>{description}</p>
      </div>
    </div>
  );
}

ExplainerCharityItem.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  coins: PropTypes.number,
  icon: PropTypes.string,
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

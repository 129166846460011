import { CSSProperties } from 'react';
import { XYCoord, useDragLayer } from 'react-dnd';
import { Img, REACTION } from 'components/images/Img';
import { DragItem } from './types';
import { DRAG_FORMAT, REDRAG_FORMAT } from '../utils';

const layerStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 1000,
  left: 0,
  top: 0,
  width: '0%',
  height: '100%',
};

function getItemStyles(initialOffset: XYCoord | null, currentOffset: XYCoord | null) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  const { x, y } = currentOffset;

  const transform = `translate(${x - 32}px, ${y - 32}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

export const AnnotateDragPreview = (): JSX.Element | null => {
  const { item, initialClientOffset, currentClientOffset, isDragging, itemType } = useDragLayer((monitor) => ({
    item: monitor.getItem() as DragItem,
    itemType: monitor.getItemType(),
    initialSourceClientOffset: monitor.getInitialSourceClientOffset(),
    initialClientOffset: monitor.getInitialClientOffset(),
    currentClientOffset: monitor.getClientOffset(),
    currentSourceClientOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (!isDragging || (itemType !== DRAG_FORMAT && itemType !== REDRAG_FORMAT)) {
    return null;
  }

  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialClientOffset, currentClientOffset)}>
        <span className='c-choice-icon__media'>
          <Img imageId={item?.resource} imageType={REACTION} alt='' />
        </span>
      </div>
    </div>
  );
};

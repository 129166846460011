import React, { useState } from 'react';

import SpanTextCount from '../../basicComponents/inputs/SpanTextCount';
import { CHAR_LIMIT_WARNING, MAX_QUICK_TEXT_LENGTH } from '../../../constants';
import i18n from '../../../i18n/helper';

export default function QuickTextInput(props) {
  const { choiceLabel, choiceIndex, feedback, setFeedback, hasPrefixLabel = true, labelIndex, isResult } = props;
  const [inFocus, setInFocus] = useState(false);
  const hideTextCount = () => inFocus && MAX_QUICK_TEXT_LENGTH - feedback[choiceIndex].length < CHAR_LIMIT_WARNING;
  return (
    <div className='c-field c-field--label-hide'>
      <label className='c-field__label' htmlFor={`question-text-${labelIndex}`}>
        {labelIndex}
      </label>
      <div className='c-field__group'>
        <div className={`o-input o-input--prefix ${isResult ? 'is-disabled' : ''}`}>
          {hasPrefixLabel && (
            <label className='o-input__prefix' htmlFor={`question-text-${labelIndex}`}>
              {labelIndex}
            </label>
          )}
          <input
            onFocus={() => setInFocus(true)}
            onBlur={() => setInFocus(false)}
            type='text'
            placeholder={choiceLabel}
            id={`question-text-${labelIndex}`}
            value={feedback[choiceIndex]}
            maxLength={MAX_QUICK_TEXT_LENGTH}
            onChange={(e) => {
              const newFeedback = feedback.slice(0);
              newFeedback[choiceIndex] = e.target.value;
              setFeedback(newFeedback);
            }}
            disabled={isResult}
          />
        </div>
        {hideTextCount() ? (
          <SpanTextCount
            currentCount={feedback[choiceIndex].length}
            classModifier='c-field__count'
            title={i18n.t('questions.feedbackForm.countTitle')}
            maxLength={MAX_QUICK_TEXT_LENGTH}
            charLimitWarning={CHAR_LIMIT_WARNING}
          />
        ) : null}
      </div>
    </div>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { getQueries } from '../link/LinkHelper';

export default function LanguageItem(props) {
  const { languageTitle, languageCode, onLanguageSelect, selectedLanguage } = props;
  const location = useLocation();

  return (
    <React.Fragment>
      <li className={`c-nav-lang__item ${languageCode === selectedLanguage ? `is-active` : ``}`}>
        <a
          className='c-nav-lang__link'
          value={languageCode}
          onClick={() => onLanguageSelect(languageCode)}
          href={`${location.pathname}${getQueries(languageCode)}`}
        >
          {languageTitle}
        </a>
      </li>
    </React.Fragment>
  );
}

LanguageItem.propTypes = {
  languageTitle: PropTypes.string,
  languageCode: PropTypes.string,
  onLanguageSelect: PropTypes.func,
  selectedLanguage: PropTypes.string,
};

import { setTranslations, setLocale, t, Translate } from 'react-i18nify';
import _ from 'lodash';

const DEFAULT_LANGUAGE = 'en';

// const translations = require('./translations');
const translations = {
  /* eslint-disable global-require */
  en: require('./en.json'),
  // cy: require('./cy.json'),

  ar: require('./ar.json'),
  bn: require('./bn.json'),
  de: require('./de.json'),
  es: require('./es.json'),
  fa: require('./fa.json'),
  gu: require('./gu.json'),
  hi: require('./hi.json'),
  lt: require('./lt.json'),
  pa: require('./pa.json'),
  pl: require('./pl.json'),
  so: require('./so.json'),
  ur: require('./ur.json'),
  tr: require('./tr.json'),
  hr: require('./hr.json'),
  ta: require('./ta.json'),
  fr: require('./fr.json'),
  cy: require('./cy.json'),
  /* eslint-enable global-require */
};

setTranslations(translations);
// const lang = "en";
setLocale(DEFAULT_LANGUAGE);

export default { t, setLocale, Translate };

/**
 * Customizes static strings on the client
 * Strings are provided by the API in the format that is identical to the
 * translations format that I get from Loco (compile time)
 * So I just have to merge the 2 objects and re-init the i18n
 * @param customStrings
 */
export function addCustomStrings(customStrings, initialLanguage) {
  // console.log('addCustomStrings called', customStrings);
  if (!customStrings) {
    return;
  }

  // const customTranslations = Object.assign({}, translations, customStrings);
  // const customTranslations = mergeDeep({}, translations, customStrings);
  const customTranslations = _.merge({}, translations, customStrings);
  // console.log('TRANSLATIONS', customTranslations);

  setTranslations(customTranslations, true);
  // const lang = "en";
  setLocale(initialLanguage || DEFAULT_LANGUAGE);
}

/**
 * Deep merging of objects
 * taken from https://stackoverflow.com/a/48218209/362246
 * @param objects
 * @returns {*}
 */
// function mergeDeep(...objects) {
//   const isObject = obj => obj && typeof obj === 'object';
//
//   return objects.reduce((prev, obj) => {
//     Object.keys(obj).forEach(key => {
//       const pVal = prev[key];
//       const oVal = obj[key];
//
//       if (Array.isArray(pVal) && Array.isArray(oVal)) {
//         prev[key] = pVal.concat(...oVal);
//       }
//       else if (isObject(pVal) && isObject(oVal)) {
//         prev[key] = mergeDeep(pVal, oVal);
//       }
//       else {
//         prev[key] = oVal;
//       }
//     });
//
//     return prev;
//   }, {});
// }

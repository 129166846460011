import React from 'react';

/* eslint-disable react/no-unescaped-entities */
export default function PrivacyPolicy() {
  return (
    <div className='c-m-block'>
      <div className='l-container'>
        <div className='c-m-text'>
          <h1>Privacy policy</h1>

          <p>
            This privacy policy contains important information about who we are and how and why we collect personal
            information from you. It explains what personal information we collect about you via the Give My View
            platform (givemyview.com) (the "Site"), why we collect it, how we use it and your rights in relation to it.
          </p>
          <p>
            When we refer to personal information, we mean any information which could directly identify you (such as
            your name or e-mail address) as well as information which could indirectly identify you. Information which
            could indirectly identify you means information which could identify you when combined with other
            information we hold about you.
          </p>
          <p>
            When we refer to "<strong>us</strong>", "<strong>we</strong>", or "<strong>our</strong>", we mean Built-ID
            Limited, and with a registered address of 9 Appold Street, London, England, EC2A 2AP. Built-ID Limited is a
            wholly owned subsidiary of Land Technologies Limited, a company registered in England and Wales
            (registration number 08845300).
          </p>
          <p>
            Please read this privacy policy carefully. If you have any questions, comments or concerns about any aspect
            of this policy, please contact us at 
            <a href='mailto:info@givemyview.com'>info@givemyview.com</a> or call 020 3086 7855.
          </p>

          <h2>1. WHAT BASIS DO WE HAVE FOR PROCESSING YOUR PERSONAL INFORMATION?</h2>
          <p>
            We will only process personal information where we have a lawful reason for doing so. The lawful basis for
            processing personal information by us will be one of the following, as set out in section 2 below:
          </p>
          <ul>
            <li>
              the processing is necessary for the pursuit of our legitimate business interests (including that of the
              delivery and the promotion of our Site or services) so long as this is not overridden by your own rights
              and interests; or
            </li>
            <li>processing is necessary for compliance with a legal obligation; or</li>
            <li>where you have given your consent to the processing.</li>
          </ul>

          <h2>2. WHAT PERSONAL INFORMATION DO WE COLLECT AND PROCESS?</h2>
          <p>
            We collect personal information from you when you access our Site, participate in Give My View polls and
            surveys or if you contact us. We either collect this personal information from you directly (such as when
            you participate in a poll or answer a free-text question on our Site) or indirectly (such as your browsing
            activity on our Site).
          </p>
          <p>This section sets out the different ways in which we collect and use personal data from you.</p>

          <h3>2.1. Information we automatically collect</h3>
          <h4>Collection:</h4>
          <p>
            We collect certain information relating to all visitors to our Site (whether they choose to participate in a
            poll or not). Indirect personal information consists of:{' '}
          </p>
          <ul>
            <li>
              information about the device you used to access the Site, such as the internal protocol (IP) address of
              the device, the browser software, operating system, date and time of access; and
            </li>
            <li>information on how you use our Site (for example, what pages you looked at).</li>
          </ul>

          <h4>Use:</h4>
          <p>We use the personal information we collect when you visit the Site to:</p>
          <ul>
            <li>Use: enable you to use the Site (and all of its features);</li>
            <li>Analytics: analyse how our Site is being used so that we can constantly improve it; and</li>
            <li>
              Statistical purposes: collate anonymous, aggregate statistics on the performance of parts of our Site or
              member profiles (for example, the number of people answering a poll over a defined period of time).
            </li>
          </ul>
          <p>
            <strong>Our reason for using your data in this way:</strong>
          </p>
          <p>
            <strong>Legitimate interests (for essential cookies):</strong> where our use of your personal information is
            necessary for our legitimate interests or the legitimate interests of a third party (unless there is a good
            reason to protect your personal information which overrides our legitimate interests).
          </p>
          <p>
            <strong>Consent (for non-essential cookies):</strong> we collect and use your personal information in this
            way where you have given us clear consent to do so. Please see our Cookies policy below for more information
            about how we use Cookies.
          </p>

          <h3>2.2. When you participate in a Poll on our Site</h3>
          <h4>Collection:</h4>

          <p>
            We run a software business that engages with individuals to collate information and opinions about
            particular projects through targeted campaigns. You are able to give your views on projects that relate to
            or may affect you through our Site.{' '}
          </p>
          <p>
            When you participate in a poll or survey through our Site, we may ask you for personal information (such as
            your name, email address and/or postcode) depending on the nature of the project or campaign. We may also
            ask you other questions that relate specifically to the poll that you are participating in. For some polls,
            you may be able to submit answers without providing your contact information, but for others, we will
            require you to enter your contact information if you wish to participate. You will have control and
            oversight of the personal information that you choose to submit to us at the point you are submitting your
            responses.{' '}
          </p>
          <p>
            The information you provide as part of the Give My View polls and surveys will be shared with our clients.
            Our clients are organisations that have engaged us to create the poll or survey and collate and/or analyse
            the results. Such organisations may include property developers, local councils and other organisations
            looking to gain insight into public opinion on a project or campaign ("<strong>Clients</strong>").{' '}
          </p>
          <p>
            As part of the services we provide to our Clients, we may also be required to share your personal
            information with their communications agencies. For more information on how we share your personal data,
            please see the "How will we share your personal data?" at section 3 below.{' '}
          </p>
          <p>
            Please note that you can still vote anonymously by choosing not to provide this information, but it is only
            by creating a profile and providing your name and email address that you can donate points earned by voting
            to a local charity.
          </p>
          <p>
            If you choose to sign up via Google or another third-party network, we will collect certain additional
            personal information from you (such as an overview of your profile with that third party). If you choose to
            use a third party to sign up, please consult the relevant third party’s privacy policy for information on
            how they use and share your data.
          </p>
          <p>
            In some instances, our Clients may request additional information from you as part of their project. Such
            information is collected in accordance with their own privacy policy.
          </p>

          <h4>Use:</h4>
          <p>
            We use the personal information we collect from you when you participate in a poll or survey as follows:
          </p>
          <ul>
            <li>
              <strong>Access:</strong> to give you access to information about the project relating to the poll;
            </li>
            <li>
              <strong>Submit responses:</strong> to allow you to participate in the poll by responding to our questions
              or requests;
            </li>
            <li>
              <strong>Communication:</strong> to communicate with you about the poll or project or send you information
              about us or our services that we believe may be of interest to you; and
            </li>
            <li>
              <strong>Analytics:</strong> perform analytics (such as trends, sales intelligence, marketing effectiveness
              uptake and progress) to improve our Site or services;
            </li>
            <li>
              <strong>Provide services to our Clients:</strong> we will pass your information and poll or survey results
              to our Clients and other third parties as part of the services we provide to them.{' '}
            </li>
          </ul>

          <p>
            We may use your contact information to send you relevant updates by email, text message or phone that we may
            circulate from time to time, news about events we are organising or participating in, and/or other
            information about us and the services that we believe may be of interest to you. You can specify your
            contact preferences by using the options provided on our emails or by emailing 
            <a href='mailto:info@givemyview.com'>info@givemyview.com</a>.{' '}
          </p>
          <p>
            We like to keep you posted on everything you need to know about our Site. We usually do this by email. You
            can choose to stop receiving communications from us at any time by unsubscribing from our e-mails or by
            contacting us on 
            <a href='mailto:info@givemyview.com'>info@givemyview.com</a> or 020 3086 7855. We may ask you to confirm
            your marketing preferences if you instruct us to provide further services in the future or if we’re required
            to do so by law or a restructuring of our business.{' '}
          </p>

          <p>
            <strong>Our reason for using your data in this way:</strong>
          </p>
          <p>
            <strong>Legitimate interests:</strong> where our use of your personal information is necessary for our
            legitimate interests or the legitimate interests of a third party (unless there is a good reason to protect
            your personal information which overrides our legitimate interests).
          </p>

          <h3>2.3. Other uses for your personal information</h3>

          <p>We may also use personal information to:</p>
          <ul>
            <li>assist us in any disputes, claims or investigations; and</li>
            <li>comply with our own legal obligations.</li>
          </ul>
          <p>
            We will only use your personal information for those purposes where it is necessary to do so, and (in any
            case) our use of your personal information will continue to be governed by this privacy policy (as well as
            certain laws and regulations which govern our use of your personal information).
          </p>

          <h3>2.4. Children</h3>

          <p>
            Our services are not designed for, or intentionally targeted at, children under the age of 16 years old. We
            do not intentionally collect or maintain data about anyone under the age of 16.
          </p>

          <h2>3. HOW WILL WE SHARE YOUR PERSONAL INFORMATION?</h2>

          <h3>3.1. Sharing your personal data with our Clients</h3>

          <p>
            The Site allows you as an individual to participate in and give your opinions about particular projects
            through targeted campaigns. You are able to give your views on projects that relate to or may affect you
            through our Site.
          </p>
          <p>
            When we collect your personal data through polls or surveys, we will share this information with our Clients
            and their communications agencies as part of the services we provide to them. We may analyse and aggregate
            the information before sharing this with our Clients and their communications agencies, and our Clients may
            also analyse your information to help shape their projects.{' '}
          </p>
          <p>
            Our Clients or their communications agency may contact you to invite you to events or exhibitions relating
            to the project or to ask you for further information relating to the poll (e.g. further details about your
            opinions or they may ask you to provide a letter of support or otherwise, as determined by them).{' '}
          </p>
          <p>
            Our Client engaging in the poll will be specified on the Site and linked to the relevant poll. For a list of
            the Clients we have worked with and may share your personal information with, please contact us at
            info@built-id.com
          </p>

          <h3>3.2. Sharing your personal data with our suppliers</h3>

          <p>
            We use third party data processors who provide services to us to enable us to run our Site and operate our
            business. Such third parties cannot do anything with your personal information unless we have instructed
            them to do so.{' '}
          </p>
          <p>
            We may share your personal data with third parties who provide IT services and cloud hosting solutions, such
            as: 
          </p>
          <ul>
            <li>Amazon Web Services (cloud services), including (but not limited to) Lambda, CloudFront; </li>
            <li>Heroku (cloud services); </li>
            <li>Papertrail (log management); </li>
            <li>Mandrill (email services); and </li>
            <li>Mailchimp (marketing automation and email service).</li>
          </ul>

          <h3>3.3. Sharing your personal data with other third parties</h3>

          <p>We may also share your personal information with other third parties, including:</p>
          <ul>
            <li>
              <strong>Communication agencies:</strong> in connection with the management of an event, in which case the
              details will only be used by the third party for that specific purpose;
            </li>
            <li>
              <strong>Professional advisors:</strong> to enable us to provide our services to you, we may need to share
              your personal information with our business partners (including professional advisers such as accountants
              or auditors), external service providers (for support services including accounting, events management,
              data entry) and/or overseas counsel;{' '}
            </li>
            <li>
              <strong>Social media sites:</strong> third parties approved by you, e.g. social media sites (such as
              Facebook) where we’ve obtained your consent to share your data in this way; or
            </li>
            <li>
              <strong>Legal and regulatory compliance:</strong> disclosures required by law or regulation.
            </li>
          </ul>

          <p>
            If a third party is considering whether to invest in our business, or to acquire all (or any part of) of our
            business and/or assets, we may need to share your personal information with that third party. We will only
            do so to the extent that it is necessary, and (in any case) our use of your personal information will
            continue to be governed by this privacy policy (as well as certain laws and regulations which govern our use
            of your personal information).
          </p>
          <p>
            Some of those third-party recipients may be based outside the European Economic Area — for further
            information including on how we safeguard your personal data when this occurs, see ‘"International
            Transfers".
          </p>

          <h2>4. THIRD PARTY WEBSITES AND APPLICATIONS</h2>

          <p>
            Our Site contains links to other websites and applications which are operated by third parties. These other
            third-party websites may also gather information about you in accordance with their own privacy policies,
            which are entirely separate. This privacy policy applies only to the personal information that we collect
            through our Site. For privacy information relating to these other third-party websites, please visit their
            respective privacy policies as applicable.{' '}
          </p>

          <h2>5. INTERNATIONAL TRANSFERS</h2>
          <p>
            From time to time, we may need to transfer your personal information to our business partners, who are
            located in territories outside of the European Economic Area ("EEA"), including in the USA, in order to
            provide you with the services required.
          </p>
          <p>
            Such countries do not have the same data protection laws as the UK and EEA. We will, however, ensure the
            transfer complies with data protection law, and all personal information will be transferred securely. Our
            standard practice is to use standard data protection contract clauses that have been approved by the
            European Commission or, for transfers to the USA, Privacy Shield (as applicable).
          </p>

          <h2>6. RETENTION</h2>
          <p>
            Your personal information is retained by us for only as long as it is necessary for the purposes set out
            above. We will keep your personal information for as long as required in order to:{' '}
          </p>
          <ul>
            <li>deliver our services to our Client;</li>
            <li>respond to any queries, complaints or claims made by you or on your behalf; </li>
            <li>resolve disputes;</li>
            <li>to fulfil our legal obligations or meet regulatory requirements.</li>
          </ul>

          <p>
            In general, we will retain your information after the completion of a project you provided your view on to
            allow the relevant third party to analyse project feedback and make improvements to the project. Anonymised
            survey data will be retained in perpetuity.{' '}
          </p>
          <p>
            If you wish to delete your personal data from the Site prior to the end of this retention period, you may do
            so at any point by contacting us at <a href='mailto:info@givemyview.com'>info@givemyview.com</a>.
          </p>

          <h2>7. YOUR RIGHTS IN RELATION TO YOUR PERSONAL INFORMATION</h2>

          <p>Under data protection laws, you have the right to:</p>

          <table>
            <tbody>
              <tr>
                <th>Access</th>
                <td>The right to be provided with a copy of your personal information (the right of access)</td>
              </tr>
              <tr>
                <th>Rectification</th>
                <td>The right to require us to correct any mistakes in your personal information</td>
              </tr>
              <tr>
                <th>To be forgotten</th>
                <td>The right to require us to delete your personal information—in certain situations</td>
              </tr>
              <tr>
                <th>Restriction of processing</th>
                <td>
                  The right to require us to restrict the processing of your personal information—in certain
                  circumstances, e.g. if you contest the accuracy of the data
                </td>
              </tr>
              <tr>
                <th>Data portability</th>
                <td>
                  The right to receive the personal information you provided to us in a structured, commonly used and
                  machine-readable format and/or transmit that data to a third party—in certain situations
                </td>
              </tr>
              <tr>
                <th>To object</th>
                <td>
                  The right to object:
                  <ul>
                    <li>
                      at any time to your personal information being processed for direct marketing (including
                      profiling)
                    </li>
                    <li>
                      in certain other situations to our continued processing of your personal information, e.g.
                      processing carried out for the purpose of our legitimate interests.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <th>Not to be subject to automated individual decision-making</th>
                <td>
                  The right not to be subject to a decision based solely on automated processing (including profiling)
                  that produces legal effects concerning you or similarly significantly affects you
                </td>
              </tr>
            </tbody>
          </table>

          <p>
            For further information on each of those rights, including the circumstances in which they apply, please
            contact us or see the Guidance from the UK Information Commissioner’s Office (ICO) on individuals’ rights
            under the General Data Protection Regulation, which is available at:
            https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/.
          </p>

          <p>If you would like to exercise any of those rights, please:</p>

          <ul>
            <li>
              contact us at <a href='mailto:info@givemyview.com'>info@givemyview.com</a> or 020 3086 7855;
            </li>
            <li>let us have enough information to identify you; </li>
            <li>let us have proof of your identity; and</li>
            <li>let us know what right you want to exercise and the information to which your request relates.</li>
          </ul>

          <h2>8. SECURITY</h2>

          <p>
            We use a variety of security measures, including encryption and authentication tools, to maintain the safety
            and security of your personal information. Your personal information is protected behind secured networks
            and is only accessible by a limited number of people who have special access rights to such systems and who
            have a genuine business need to access it. Those processing your personal information will do so only in an
            authorised manner and are subject to a duty of confidentiality.
          </p>
          <p>
            We also have procedures in place to deal with any suspected data security breach. We will notify you and any
            applicable regulator of a suspected data security breach where we are legally required to do so.
          </p>
          <p>
            If you want detailed information from Get Safe Online on how to protect your information and your computers
            and devices against fraud, identity theft, viruses and many other online problems, please
            visit https://www.getsafeonline.org/. Get Safe Online is supported by HM Government and leading businesses.
          </p>

          <h2>9. COOKIES</h2>

          <h3>9.1. What are Cookies?</h3>

          <p>
            A cookie is a small file which is placed on your device when you use our Site. Cookies contain information
            that is transferred to your computer's hard drive (or the hard drive of another relevant device).{' '}
          </p>
          <p>
            We use cookies to distinguish you from other users on our website, to tailor your experience to your
            preferences, and to help us improve the Site.
          </p>
          <p>
            Some of the cookies we use are essential for the Site to operate. If you use your browser settings to block
            all cookies (including essential cookies), you may not be able to access all or parts of our Site.
          </p>
          <p>
            You can manage any non-essential cookies by clicking on our cookie pop-up. By default, most internet
            browsers accept cookies, but you can choose to enable or disable some or all cookies via the settings on
            your internet browser. Most internet browsers also enable you to choose whether you wish to disable all
            cookies or only third-party cookies. For further details, please consult the help menu in your internet
            browser.
          </p>
          <p>
            You can withdraw your consent for the use of non-essential cookies or manage any other cookie preferences by
            clicking on <strong>Manage cookies</strong> at the bottom of any page on our Site. It may be necessary to
            refresh the page for the updated settings to take effect.
          </p>

          <h3>9.2. What Cookies do we use?</h3>

          <p>We use the following cookies:</p>
          <ul>
            <li>
              <strong>Strictly necessary cookies.</strong> These cookies are required to save your session and to carry
              out activities that are strictly necessary for the operation of the Site. These cookies are session
              cookies, which means they’re temporary and will expire when you close your browser.
            </li>
            <li>
              <strong>Functionality cookies.</strong> These cookies are used to recognise you when you return to the
              Site. They enable us to personalise our content for you, greet you by name and remember your preferences.
            </li>
            <li>
              <strong>Third Party cookies.</strong> We use third-party cookies on the Site to perform other functions,
              such as analytics and advertising (as set out below). You have the right to opt out of third-party cookies
              by removing your consent through our Site.
            </li>
          </ul>

          <table>
            <tbody>
              <tr>
                <th style={{ width: '20%' }}>Cookie</th>
                <th style={{ width: '20%' }}>Name</th>
                <th style={{ width: '30%' }}>Purpose</th>
                <th style={{ width: '30%' }}>Essential or Non-Essential</th>
              </tr>
              <tr>
                <th>BID-Authorization</th>
                <td>Application session cookie</td>
                <td>Allows you to securely access the Site, including the polls, and submit responses.</td>
                <td>
                  This is an essential cookie, so we are not required to request your consent before placing this
                  cookie.
                </td>
              </tr>
              <tr>
                <th>Google Analytics</th>
                <td>_ga, _gid</td>
                <td>
                  This cookie collects the following information: traffic source, page visits and duration, repeat
                  visitors, browser type and version, browser language, operating system, device type, service provider
                  and screen resolution (if on mobile). If you are logged into a Google account, Google Analytics also
                  provides us with your age range, gender and interests.
                </td>
                <td>
                  Non-essential third-party cookie. We will therefore request your consent before placing this cookie.{' '}
                </td>
              </tr>
              <tr>
                <th>Facebook Tracking</th>
                <td>_fr, _fbp</td>
                <td>This is an advertising cookie used to deliver advertising content through Facebook.</td>
                <td>
                  Non-essential third-party cookie. We will therefore request your consent before placing this cookie.
                </td>
              </tr>
            </tbody>
          </table>

          <h3>9.3 Third party access to Cookies</h3>
          <p>
            The cookies we use will only be accessed by us and those third parties named in the table above for the
            purposes referred to in this cookie policy. Those cookies will not be accessed by any other third party.
          </p>
          <p>
            If you 'share' content from our Site through social media websites (such as Facebook, Google and Twitter),
            you may be sent cookies from these third-party websites. We don't control the setting of these cookies, and
            we recommend you check the third-party websites for more information about their cookies and how to manage
            them.
          </p>
          <h3>9.4 How to turn off Cookies </h3>
          <p>
            If you do not want to accept any cookies, you may be able to change your browser settings so that cookies
            (including those which are essential to the services requested) are not accepted. If you do this, please be
            aware that you may lose some of the functionality of our Site.
          </p>
          <p>
            For further information about cookies and how to disable them, please go to the guidance on
            cookies published by the UK Information Commissioner’s Office available at:{' '}
            <a href='https://www.aboutcookies.org/'>www.aboutcookies.org</a> or 
            <a href='https://www.allaboutcookies.org/'>www.allaboutcookies.org</a>.
          </p>

          <h2>10. UPDATES TO THIS PRIVACY POLICY</h2>
          <p>
            This privacy policy was last updated in February 2024. We keep our privacy notice under regular review to
            make sure it is up-to-date and accurate. If we change our privacy policy from time to time, we will post the
            details of any changes here. We may also take reasonable steps to notify you if such changes affect how your
            personal data is processed.
          </p>
        </div>
      </div>
    </div>
  );
}

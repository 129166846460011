import { Fragment } from 'react';
import ThumbsUp from '../../../assets-v2/images/illustration/thumbs-up.png';
import { QuestionTextHeaderProps } from './types';
import { QuestionTooltip } from './QuestionTooltip';

export function QuestionTextHeader({
  questionText,
  isResult,
  resultText,
  hasTooltip,
  tooltipText,
}: QuestionTextHeaderProps): JSX.Element {
  // Your code here
  return (
    <div className='c-question__header'>
      <h2 className='c-question__title'>
        {!isResult ? (
          questionText
        ) : (
          <Fragment>
            <img src={ThumbsUp} alt='' />
            {resultText}
          </Fragment>
        )}
      </h2>
      {/* Issue#2254 - annotate question tooltip
      Uncomment code below and add necessary inputs to the component once we decide to fully implement this feature */}
      {hasTooltip && <QuestionTooltip tooltipText={tooltipText} />}
    </div>
  );
}

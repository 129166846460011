import React from 'react';
import PropTypes from 'prop-types';
// import i18n from '../../i18n/helper';
import InfluencedDecisionWidget from './InfluencedDecisionWidget';
import NewsWidget from './NewsWidget';

export default function NewsItem(props) {
  const { news } = props;
  const { resource, title, description } = news;

  return (
    <React.Fragment>
      {news.source === 'INFLUENCED_DECISION' && (title || description || resource) && (
        <InfluencedDecisionWidget {...props} />
      )}
      {news.source === 'NEWS' && <NewsWidget {...props} date={news.timestamp} />}
    </React.Fragment>
  );
}

NewsItem.propTypes = {
  news: PropTypes.object.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

import { DemographicsTypes } from '../DemographicConstants';
import DemographicHouseholdNumberChoice from './DemographicHouseholdNumberChoice';
import DemographicTemplateChoice from './DemographicTemplateChoice';

export default function DemographicQuestionChoices(props) {
  const { questionType, choices, handleSelect, questionId } = props;

  const getChoice = () => {
    switch (questionType) {
      case DemographicsTypes.DEMOGRAPHIC_HOUSEHOLD_NUMBER:
        return (
          <DemographicHouseholdNumberChoice choices={choices} handleSelect={handleSelect} questionType={questionType} />
        );
      default:
        return choices.map((choice, index) => (
          <DemographicTemplateChoice
            key={choice._id}
            choiceKey={choice.choiceKey ? choice.choiceKey : `${questionId}_${index}`}
            choiceId={choice._id}
            choiceLabel={choice.choice}
            questionType={`${questionType}_${questionId}`}
            choiceContentType={choice.choiceContentType}
            handleSelect={handleSelect}
          />
        ));
    }
  };

  return <React.Fragment>{getChoice()}</React.Fragment>;
}

DemographicQuestionChoices.propTypes = {
  questionType: PropTypes.string.isRequired,
  choices: PropTypes.array.isRequired,
  handleSelect: PropTypes.func.isRequired,
  questionId: PropTypes.string.isRequired,
};

import { Answer } from 'components/organisms/question';
import {
  AT_1007_CHARITY,
  AT_1014_END_OF_POLL,
  AT_1013_KNOW_LOCATION,
  AT_1016_WRONG_LOCATION,
  QuestionTypes,
} from 'containers/questions/QuestionConstants';

export type CommonQuestionComponentProps = {
  communityAnswer: unknown;
  onSubmitQuestion: (answer: Answer) => void;
  onSubmitMultipleQuestion: (answer: Answer) => void;
  isResult: boolean;
  showCoin: boolean;
  questionButtonClicked: () => void;
  slugId: string;
  disableForm: boolean;
  setHideSkipAfterAnswer: (newState: boolean) => void;
  setAnnotateEdit: (newAnnotateClass: string) => void;
  skipResults: boolean;
  showCoinsInHeader: (arg0: boolean) => boolean;
  setIsMediaZoomed(isMediaZoomed: boolean): void;
  isMediaZoomed: boolean;
};

export type QuestionComponentProps = {
  question: Question;
} & CommonQuestionComponentProps;

export type MapQuestionComponentProps = {
  question: MapQuestionProps;
} & CommonQuestionComponentProps;

export type PersonalDataComponentProps = {
  question: PersonalDataQuestionProps;
} & CommonQuestionComponentProps;

export type CommonQuestionProps = {
  questionConfiguration: QuestionConfiguration;
  order: number;
  // questionType: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20;
  questionType: (typeof QuestionTypes)[keyof typeof QuestionTypes];
  awardPoints: number;
  _id: string;
  questionText: string;
  questionSubtext: string | null;
  resource: QuestionResource[];
  resourceLabels: string;
  choices: Choice[];
  maxValue: number | null;
  label: string | null;
  multiChoicesOfferedBound: number;
  multiChoicesSelectableBound: number;
  communityAgreement?: number;
  submittedAnswer: unknown;
  isSubmitted: boolean;
  communityAnswer?: [];
  skipCommunityAnswerStep?: boolean;
};

// sax
export type FieldConfiguration = {
  required: boolean;
  visible: boolean;
  label: string;
  lookupValues: [];
};

// sax
export type PersonalDataFieldConfiguration = {
  firstName: FieldConfiguration;
  lastName: FieldConfiguration;
  email: FieldConfiguration;
  address: FieldConfiguration;
  postalCode: FieldConfiguration;
  phone: FieldConfiguration;
  extra1: FieldConfiguration;
  extra2: FieldConfiguration;
};

export type SegmentationQuestion = Omit<CommonQuestionProps, 'questionConfiguration'>;

export type FormTypes =
  | typeof AT_1007_CHARITY
  | typeof AT_1014_END_OF_POLL
  | typeof AT_1013_KNOW_LOCATION
  | typeof AT_1016_WRONG_LOCATION
  | CommonQuestionProps['questionType'];

export type MapQuestionProps = {
  questionMap: QuestionMapSchema;
} & CommonQuestionProps;

export type PersonalDataQuestionProps = {
  fieldConfiguration: PersonalDataFieldConfiguration;
} & CommonQuestionProps;

export type Question = CommonQuestionProps;

// TODO: export type submittedAnswer = {

// }

export type RatingConfiguration = {
  useCustomLabels: boolean;
  resourceLabels?: {
    lowest: string;
    highest: string;
  };
};

export type QuestionMapSchema = {
  _id: string;
  shape: 'circle' | 'polygon' | 'rectangle';
  zoom: number;
  mapTypeId?: google.maps.MapTypeId;
  circle?: {
    center?: google.maps.LatLngLiteral;
    radius?: number;
  };
  rectangle: {
    bounds: {
      ne: google.maps.LatLngLiteral;
      sw: google.maps.LatLngLiteral;
    };
  };
  polygon: {
    path: google.maps.LatLngLiteral[];
  };
  customLayer: string;
};

export type ConditionSchema = {
  _id: string;
  rules: {
    condition: Condition[] | [];
    // validate that target question exists
    jumpTo: string | -1;
  }[];
  // validate that target question exists
  else: string | null;
};

export type Condition = {
  logicOp: null | '$AND' | '$OR';
  choiceId: string;
  criteria: '$IS' | '$IS_NOT' | '$LT' | '$LTE' | '$EQ' | '$GT' | '$GTE';
  value: number | null;
};

export const LEAD_IMAGE_TYPES = {
  STANDARD_SINGLE_IMAGE: 'STANDARD_SINGLE_IMAGE',
  ZOOM_SINGLE_IMAGE: 'ZOOM_SINGLE_IMAGE',
  STANDARD_CAROUSEL: 'STANDARD_CAROUSEL',
};

export type LeadImageType = keyof typeof LEAD_IMAGE_TYPES | null;

export type QuestionConfiguration = {
  allowUserContentUpload: {
    image: boolean;
  };
  feedbackConfiguration: {
    isPostCodeOptional: boolean;
    isEmailOptional: boolean;
  };
  allowComments: boolean;
  conditionalExecution?: ConditionSchema | null;
  ratingConfiguration: RatingConfiguration;
  leadImageType: LeadImageType;
  tooltip?: string;
};

type MediaType = 'image' | 'video';

export type QuestionResource = {
  mediaType: MediaType;
  _id: string;
  url: string;
};

export type Choice = {
  order: number;
  range: number[] | null;
  _id: string;
  resource: string | null;
  choice?: string;
  reactionOrigin?: string;
};

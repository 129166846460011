import React from 'react';

export default function Footer(props) {
  const { onClick } = props;
  return (
    <footer className='l-section-footer c-section-footer' role='contentinfo'>
      <div className='l-container'>
        <p className='c-footer-info'>
          <span className='c-footer-info__title'>Give My View powered by LandTech</span>
          <button className='c-footer-info__cookies' onClick={onClick}>
            Manage cookies
          </button>
          <small className='c-footer-info__note'>
            The site is protected by reCAPTCHA and the Google{' '}
            <a href='https://policies.google.com/privacy' target='_blank' rel='noopener noreferrer'>
              Privacy Policy
            </a>{' '}
            and{' '}
            <a href='https://policies.google.com/terms' target='_blank' rel='noopener noreferrer'>
              Terms of Service
            </a>{' '}
            apply
          </small>
        </p>
      </div>
    </footer>
  );
}

import React from 'react';
import PropTypes from 'prop-types';

import FacebookButtonShare from '../button/ShareButtons/FacebookButtonShare';
import TwitterButtonShare from '../button/ShareButtons/TwitterButtonShare';
import WhatsAppButtonShare from '../button/ShareButtons/WhatsAppButtonShare';
// import MessengerButtonShare from '../button/ShareButtons/MessengerButtonShare';

export default function ShareSocial(props) {
  const { slugId, shareURL, classModifier = '' } = props;
  return (
    <div className={`c-social  ${classModifier}`}>
      <ul className='c-social__items'>
        <li className='c-social__item c-social__item--facebook'>
          <FacebookButtonShare url={shareURL} slugId={slugId} />
        </li>
        <li className='c-social__item c-social__item--x'>
          <TwitterButtonShare url={shareURL} slugId={slugId} />
        </li>
        <li className='c-social__item c-social__item--whatsapp'>
          <WhatsAppButtonShare url={shareURL} slugId={slugId} />
        </li>
        {/* <li className='c-social__item c-social__item--messenger'>
          <MessengerButtonShare url={shareURL} slugId={slugId} />
        </li> */}
      </ul>
    </div>
  );
}

ShareSocial.propTypes = {
  slugId: PropTypes.string.isRequired,
  shareURL: PropTypes.string.isRequired,
};

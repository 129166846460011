import * as feedback from '../../services/feedback/feedback';

export const getFeedbakOptions = (projectId) => (dispatch) =>
  feedback
    .getFeedbackOptionFromServer(projectId)
    .then((response) => {
      dispatch({
        type: 'SUCCESS_ADD_FEEDBACK_OPTIONS',
        data: response,
      });
      return response;
    })
    .catch((response) => {
      dispatch({
        type: 'FAIL_ADD_FEEDBACK_OPTIONS',
        data: response,
      });
      return response;
    });

export const sendFeedbackData = (data) => (dispatch) =>
  feedback
    .sendFeedbackOptionToServer(data)
    .then((response) => {
      dispatch({
        type: 'SUCCESS_SEND_FEEDBACK_OPTIONS',
        data: response,
      });
      return response;
    })
    .catch((response) => {
      dispatch({
        type: 'FAIL_SEND_FEEDBACK_OPTIONS',
        response,
      });
      return response;
    });

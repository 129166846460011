import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

export default function SliderArrow(props) {
  const { className, onClick, isRight } = props;
  return (
    <button
      className={`${isRight ? 'c-carousel__next' : 'c-carousel__prev'} ${className}`}
      style={{ display: 'flex' }}
      onClick={onClick}
    >
      <span className='u-screen-reader-text'>{isRight ? 'Next' : 'Previous'}</span>
      {isRight ? <SvgIcon icon='arrow-right' /> : <SvgIcon icon='arrow-left' />}
    </button>
  );
}
SliderArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  isRight: PropTypes.bool,
};

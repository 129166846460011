import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../i18n/helper';
import Coin from '../../coin/coin';
import SvgIcon from '../../SvgIcon';

export default function SubmitFooter(props) {
  const { onSubmitQuestion, nrChoices, showCoin, isDisabled } = props;
  return (
    <div className='c-question__footer c-question__footer--alt'>
      <div className='c-question__note'>
        <p>
          {nrChoices === 1
            ? `${i18n.t('questions.multichoice.question.select')} 1`
            : i18n.t('questions.multiChoice.question.selectHint', { nrChoices })}
          <SvgIcon icon='arrow-down' />
        </p>
      </div>
      <button
        className={`c-button c-button--primary c-button--large ${isDisabled ? ' is-disabled ' : ''}`}
        onClick={onSubmitQuestion}
        disabled={isDisabled}
      >
        <span className='c-button__label'>{i18n.t('questions.submitButton')}</span>
      </button>
      {showCoin && <Coin bounceFromBottom />}
    </div>
  );
}

SubmitFooter.propTypes = {
  onSubmitQuestion: PropTypes.func,
  nrChoices: PropTypes.number,
  showCoin: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

import React from 'react';
import i18n from '../../i18n/helper';
import { isMobile } from '../../utils/utils';
import GOOGLE_MAP_ENUMS from '../../shared/engage-google-map-enums';
/* eslint-disable */
const { shapeTypes } = GOOGLE_MAP_ENUMS();
const overlayStyle = {
  ...GOOGLE_MAP_ENUMS().roadmapOverlayStyle,
  draggable: false,
  editable: false,
};
const markerStyle = { ...GOOGLE_MAP_ENUMS().markerStyle, draggable: false };
const { mapOptions } = GOOGLE_MAP_ENUMS();

const { google } = window;

class Map extends React.Component {
  componentDidMount() {
    let map;
    let shape;
    if (this.props.location) {
      map = new google.maps.Map(document.getElementById('map'), {
        zoom: (this.props.locationExtended && this.props.locationExtended.zoom) || mapOptions.zoom,
        center: { lat: this.props.location.latitude, lng: this.props.location.longitude },
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        disableDefaultUI: true,
        zoomControl: !isMobile(),
        streetViewControl: !isMobile(),
        styles: this.props.mapStyle,
        gestureHandling: 'greedy',
      });
    }
    if (this.props.locationExtended) {
      let bounds;
      if (this.props.locationExtended.shape === shapeTypes.CIRCLE) {
        shape = new google.maps.Circle({
          ...overlayStyle,
          map,
          center: { lat: this.props.location.latitude, lng: this.props.location.longitude },
          radius: this.props.locationExtended.circle.radius,
        });

        bounds = shape.getBounds();
      } else if (this.props.locationExtended.shape === shapeTypes.POLYGON) {
        const triangleCoords = [...this.props.locationExtended.polygon.path];
        const Coords = [];
        triangleCoords.map((item) => {
          const obj = {};
          obj.lat = item.lat;
          obj.lng = item.lng;
          Coords.push(obj);
          return 0;
        });
        new google.maps.Polygon({
          map,
          paths: Coords,
          ...overlayStyle,
        });

        bounds = new google.maps.LatLngBounds();
        Coords.forEach((coord) => {
          bounds.extend(coord);
        });
      } else if (this.props.locationExtended.shape === shapeTypes.RECTANGLE) {
        const north = this.props.locationExtended.rectangle.bounds.ne.lat;
        const south = this.props.locationExtended.rectangle.bounds.sw.lat;
        const east = this.props.locationExtended.rectangle.bounds.ne.lng;
        const west = this.props.locationExtended.rectangle.bounds.sw.lng;

        shape = new google.maps.Rectangle({
          ...overlayStyle,
          map,
          bounds: {
            north,
            south,
            east,
            west,
          },
        });

        bounds = shape.getBounds();
      } else if (this.props.locationExtended.shape === shapeTypes.MARKER) {
        shape = new google.maps.Marker({
          map,
          animation: google.maps.Animation.DROP,
          position: { lat: this.props.location.latitude, lng: this.props.location.longitude },
          ...markerStyle,
        });

        map.setCenter({ lat: this.props.location.latitude, lng: this.props.location.longitude });
        map.setZoom(this.props.locationExtended.zoom ? this.props.locationExtended.zoom : mapOptions.zoom);
      }

      if (bounds) {
        if (this.props.locationExtended.zoom) {
          map.setCenter(bounds.getCenter());
          map.setZoom(parseInt(this.props.locationExtended.zoom, 10));
        } else {
          map.fitBounds(bounds);
        }
      }
    }
  }

  render() {
    return (
      <div className='c-block'>
        <div className='l-container l-container--spacing'>
          <div className='c-location'>
            <h2 className='c-location__heading'>{i18n.t('body.location')}</h2>

            <div className='c-location__map'>
              <div id='map' style={{ height: '400px', position: 'relative', overflow: 'hidden' }} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Map;

import ImageOrVideo from 'components/imageOrVideo/ImageOrVideo';
import { QuestionResource } from 'components/organisms/questions';

type CarouseItemProps = {
  resource: QuestionResource;
  index: number;
  altImage: string;
};

export const CarouselItem = ({ resource, index, altImage }: CarouseItemProps): JSX.Element => (
  <div className='c-carousel__item'>
    <ImageOrVideo resource={resource} imageAlt={`${altImage}-${index}`} autostart />
  </div>
);

import { SvgIcon } from 'components/atoms/svgIcon';
import i18n from 'i18n/helper';
import { AnnotateReactionsControlProps } from './types';

export function AnnotateReactionsControl({
  onClickLeft,
  onClickRight,
  showLeftArrow,
  showRightArrow,
}: AnnotateReactionsControlProps): JSX.Element {
  return (
    <div className='c-reactions__controls'>
      {showLeftArrow ? (
        <div className='c-reactions__prev'>
          <button onClick={onClickLeft}>
            <span className='u-screen-reader-text'>{i18n.t('questions.annotate.previous')}</span>
            <SvgIcon icon='angle-left' />
          </button>
        </div>
      ) : null}
      {showRightArrow ? (
        <div className='c-reactions__next'>
          <button onClick={onClickRight}>
            <span className='u-screen-reader-text'>{i18n.t('questions.annotate.next')}</span>
            <SvgIcon icon='angle-right' />
          </button>
        </div>
      ) : null}
    </div>
  );
}

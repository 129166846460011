const initialState = {};
export default (state = initialState, action) => {
  switch (action.type) {
    case 'SUCCESS_GET_CUSTOMIZATIONS':
      return { ...state, ...action.data };

    case 'FAIL_GET_CUSTOMIZATIONS':
      return { ...state, ...action.data };

    default:
      return state;
  }
};

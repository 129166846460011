import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n/helper';
import FactIllustration from '../../../assets-v2/images/illustration/fact-book.png';
import MarkdownField, { MARKDOWN_MIN_PLUS } from '../../markdown/MarkdownField';

export default function FactText10(props) {
  const { question, questionButtonClicked } = props;

  return (
    <div className='c-modal-poll__content'>
      <article className='c-question c-question--narrow'>
        <div className='c-question__header' />
        <div className='c-question__content'>
          <div className='c-fact u-a-children-fade-in'>
            <div className='c-fact__header'>
              <figure className='c-fact__illustration'>
                <img src={FactIllustration} alt='' />
              </figure>
              <h3 className='c-fact__title'>{i18n.t('questions.quickfact')}</h3>
            </div>
            <div className='c-fact__content u-rich-text'>
              <MarkdownField markdown={question.questionText} markdownConfig={MARKDOWN_MIN_PLUS} />
            </div>
          </div>
        </div>
        <div className='c-question__footer'>
          <button className='c-button c-button--alternate c-button--large' onClick={questionButtonClicked}>
            <span className='c-button__label'>{i18n.t('questions.factType2.buttonText')}</span>
          </button>
        </div>
      </article>
    </div>
  );
}

FactText10.propTypes = {
  question: PropTypes.object.isRequired,
  questionButtonClicked: PropTypes.func.isRequired,
};

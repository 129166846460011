import React from 'react';
import Link from '../link/Link';

import LabelWithIcon from '../labels/LabelWithIcon';
import SvgIcon from '../SvgIcon';

export default function DetailsButton(props) {
  const { linkTo, isPrimary, title, subtitle, titleIcon, subtitleIcon, openInNewWindow, action, closePoll } = props;
  const modifierClass = isPrimary ? 'c-button--primary' : 'c-button--outline';
  const isNoop = linkTo === '#NO_OP#';

  /**
   * I can have different cases
   * 1) normal link
   * 2) normal link to new tab
   * 3) internal navigation - then I have to close the poll first and follow the link after
   * 4) action - open next popup (next poll or charities)
   * @param e
   */
  function onClick(e) {
    // Case we just want to show info without action ie #2010
    if (isNoop) {
      return;
    }

    // case next poll or charities
    if (action) {
      e.preventDefault();
      action();
    }

    // case internal navigation
    if (closePoll) {
      closePoll();
    }
    // else - JUST follow normal link
  }

  return (
    <>
      {isNoop ? (
        <button onClick={onClick} className={`c-button c-button--details ${modifierClass}`}>
          <span className='o-label'>
            <LabelWithIcon label={title} icon={titleIcon} />
            <small>
              <LabelWithIcon label={subtitle} icon={subtitleIcon} />
            </small>
          </span>
        </button>
      ) : (
        // eslint-disable-next-line
        <Link
          url={linkTo}
          onClick={onClick}
          openInNewWindow={openInNewWindow}
          className={`c-button c-button--details ${modifierClass}`}
        >
          <span className='o-label'>
            <LabelWithIcon label={title} icon={titleIcon} />
            <small>
              <LabelWithIcon label={subtitle} icon={subtitleIcon} />
            </small>
          </span>
          <SvgIcon icon='arrow-right' />
        </Link>
      )}
    </>
  );
}

import React from 'react';

import { getImageUrl, IMAGE_FORMATS } from './Formats';

export default function ImgUWide(props) {
  const { imageId, imageType, alt, ...rest } = props;

  return (
    <picture>
      <source media='(max-width: 799px)' srcSet={getImageUrl(imageId, IMAGE_FORMATS.GMV_IMAGE_ULTRA_WIDE_S_800x200)} />
      <source
        media='(min-width: 800px) and (max-width: 1199px)'
        srcSet={getImageUrl(imageId, IMAGE_FORMATS.GMV_IMAGE_ULTRA_WIDE_M_1200x300)}
      />
      <source
        media='(min-width: 1200px) and (max-width: 1599px)'
        srcSet={getImageUrl(imageId, IMAGE_FORMATS.GMV_IMAGE_ULTRA_WIDE_L_1600x400)}
      />
      <source
        media='(min-width: 1600px)'
        srcSet={getImageUrl(imageId, IMAGE_FORMATS.GMV_IMAGE_ULTRA_WIDE_XL_2000x500)}
      />
      <img src={getImageUrl(imageId, IMAGE_FORMATS.GMV_IMAGE_ULTRA_WIDE_L_1600x400)} alt={alt} {...rest} />
    </picture>
  );
}

import { useRef, useEffect, useState } from 'react';
import i18n from 'i18n/helper';
import { AnnotatePinReaction } from './AnnotatePinReaction';
import { AnnotateReactionsControl } from './AnnotatePinReactionsControl';
import { AnnotatePinProps } from './types';

export function AnnotatePin({
  choices,
  annotateMenu,
  multiChoicesSelectableBound,
  firstInteractionOccured,
  setAnnotateEdit,
  isDragAllowed,
  reactionsLeft,
}: AnnotatePinProps): JSX.Element {
  const reactionContainerRef = useRef<HTMLDivElement | null>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  // const [sliderOffset, setSliderOffset] = useState(0);

  useEffect(() => {
    if (reactionContainerRef.current) {
      setShowLeftArrow(reactionContainerRef.current.scrollLeft > 0);
      setShowRightArrow(
        reactionContainerRef.current.scrollLeft <=
          reactionContainerRef.current.scrollWidth - reactionContainerRef.current.offsetWidth - 1,
      );
    }
  }, []);

  const reactionCallback = (node: HTMLDivElement) => {
    if (node !== null) {
      reactionContainerRef.current = node;
      setShowLeftArrow(reactionContainerRef.current.scrollLeft > 0);
      setShowRightArrow(
        reactionContainerRef.current.scrollLeft <=
          reactionContainerRef.current.scrollWidth - reactionContainerRef.current.offsetWidth - 1,
      );
    }
  };

  const scrollBy = (scrollAmount: number) => {
    if (reactionContainerRef.current) {
      reactionContainerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const onScroll = () => {
    if (reactionContainerRef.current) {
      setShowLeftArrow(reactionContainerRef.current.scrollLeft > 0);
      setShowRightArrow(
        reactionContainerRef.current.scrollLeft <=
          reactionContainerRef.current.scrollWidth - reactionContainerRef.current.offsetWidth - 1,
      );
    }
  };

  const getDragExplainerText = () => {
    if (multiChoicesSelectableBound > 1) {
      if (reactionsLeft === multiChoicesSelectableBound) {
        return i18n.t('questions.annotate.dragExplainer', { nrOfAnnotationsLeft: reactionsLeft });
      }
      return i18n.t('questions.annotate.dragExplainerMore', { nrOfAnnotationsLeft: reactionsLeft });
    }
    return i18n.t('questions.annotate.dragExplainerSingle');
  };

  return (
    <div className='c-annotate-advanced-pin'>
      <div className='c-annotate-pin__group c-annotate-pin__group--reaction is-opened'>
        <div className='c-field'>
          <label className='c-field__label' htmlFor='annotate-reactions'>
            {getDragExplainerText()}
          </label>
          <div className='c-field__group'>
            <div className='c-reactions u-a-choice-reaction-scroll-cue c-reactions--scroll'>
              {/* <div className='c-reactions__items__container'> */}
              <div className='c-reactions__items__container' ref={reactionCallback} onScroll={onScroll} dir='ltr'>
                <div className='c-reactions__items'>
                  {choices.map((choice) => (
                    <AnnotatePinReaction
                      choice={choice}
                      key={choice._id}
                      firstInteractionOccured={firstInteractionOccured}
                      setAnnotateEdit={setAnnotateEdit}
                      isDragAllowed={isDragAllowed}
                      firstReactionDropped={reactionsLeft === multiChoicesSelectableBound}
                    />
                  ))}
                </div>
              </div>
              <AnnotateReactionsControl
                onClickLeft={() => {
                  scrollBy(-(reactionContainerRef.current?.offsetWidth ?? 0) / 4);
                }}
                onClickRight={() => {
                  scrollBy((reactionContainerRef.current?.offsetWidth ?? 0) / 4);
                }}
                showLeftArrow={showLeftArrow}
                showRightArrow={showRightArrow}
              />
            </div>
          </div>
        </div>
      </div>
      {annotateMenu}
    </div>
  );
}

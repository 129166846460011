function getSliderClass(value) {
  const array = [
    'emoji-angry-icon',
    'emoji-raisedeye-icon',
    'emoji-neutral-icon',
    'emoji-slightlysmile-icon',
    'emoji-smiley-icon',
    'emoji-happy-icon',
    'emoji-hearteyes-icon',
  ];
  if (value >= 0 && value <= 14) return array[0];
  if (value >= 15 && value <= 28) return array[1];
  if (value >= 29 && value <= 42) return array[2];
  if (value >= 43 && value <= 56) return array[3];
  if (value >= 57 && value <= 70) return array[4];
  if (value >= 71 && value <= 84) return array[5];

  return array[6];
}

/**
 * Community answer for slider decoded in class
 * @param communityAnswer
 * @returns {string}
 */
function getCommunityClass(communityAnswer) {
  if (communityAnswer <= 14) return 'average_rating_position average_rating_10';
  if (communityAnswer <= 28) return 'average_rating_position average_rating_28';
  if (communityAnswer <= 42) return 'average_rating_position average_rating_42';
  if (communityAnswer <= 56) return 'average_rating_position average_rating_56';
  if (communityAnswer <= 70) return 'average_rating_position average_rating_70';
  if (communityAnswer <= 84) return 'average_rating_position average_rating_84';
  if (communityAnswer <= 100) return 'average_rating_position average_rating_100';
  return 'average_rating_position';
}

/**
 * Community answer for slider decoded in inline style
 * @param communityAnswer
 * @returns {{left: string}}
 */
function getCommunityStyle(communityAnswer) {
  if (communityAnswer <= 14) return { left: `${communityAnswer + 1}%` };
  if (communityAnswer <= 28) return { left: `${communityAnswer - 5}%` };
  if (communityAnswer <= 42) return { left: `${communityAnswer - 11}%` };
  if (communityAnswer <= 56) return { left: `${communityAnswer - 26}%` };
  if (communityAnswer <= 70) return { left: `${communityAnswer - 36}%` };
  if (communityAnswer <= 84) return { left: `${communityAnswer - 41}%` };
  if (communityAnswer <= 100) return { left: `${communityAnswer - 53}%` };
  return {};
}

/**
 * Community answer for slider decoded in inline style
 * @param communityAnswer
 * @returns {{left: string}}
 */
function getCommunityEmoji(communityAnswer) {
  if (communityAnswer <= 14) return '1';
  if (communityAnswer <= 28) return '2';
  if (communityAnswer <= 42) return '3';
  if (communityAnswer <= 56) return '4';
  if (communityAnswer <= 70) return '5';
  if (communityAnswer <= 84) return '6';
  if (communityAnswer <= 100) return '7';
  return {};
}

/**
 * ##### OBSOLETED - REMOVE from here when all questions are switched to Hooks based and refactored
 */
function timerPromise(delay) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(true), delay);
  });
}
function handleScroll(master, clazz = 'c-question--scroll') {
  // console.log('INIT');
  return (event) => {
    // console.log('SCROLL', event);
    // console.log('SCROLL', event.currentTarget.scrollTop);
    if (event.currentTarget.scrollTop > 0) {
      master.setState({ isScrollingClass: clazz });
    } else {
      master.setState({ isScrollingClass: '' });
    }
  };
}
/**
 * ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
 * ##### OBSOLETED - REMOVE from here when all questions are switched to Hooks based and refactored
 */

export default {
  getSliderClass,
  getCommunityClass,
  getCommunityStyle,
  getCommunityEmoji,
  timerPromise,
  handleScroll,
};

import * as timeline from '../../services/timeline/timeline';

export const getNoOfPhases = (slug_id) => (dispatch) =>
  timeline
    .getNoOfPhasesFromServer(slug_id)
    .then((response) => {
      dispatch({
        type: 'SUCCESS_ADD_TIMLINE_NO_OF_PHASE',
        data: response.data,
      });
      return response;
    })
    .catch((response) => {
      dispatch({
        type: 'FAIL_ADD_TIMELINE_NO_OF_PHASE',
        failState: response,
      });
      return response;
    });

export const getCurrentPhaseData = (slug_id, currentPhase) => (dispatch) =>
  timeline
    .getCurrentPhaseDataFromServer(slug_id, currentPhase)
    .then((response) => {
      dispatch({
        type: 'SUCCESS_ADD_TIMELINE_PHASE_DATA',
        data: response.data,
      });
      return response;
    })
    .catch((response) => {
      dispatch({
        type: 'FAIL_ADD_TIMELINE_PHASE_DATA',
        failState: response,
      });
      return response;
    });

export const getMorePhaseInfo = (slug_id, currentPhase, block_id) => (dispatch) =>
  timeline
    .getMorePhaseInfoFromServer(slug_id, currentPhase, block_id)
    .then((response) => {
      dispatch({
        type: 'SUCCESS_ADD_TIMELINE_MORE_PHASE_DATA',
        data: response.data,
      });
      return response;
    })
    .catch((response) => {
      dispatch({
        type: 'FAIL_ADD_TIMELINE_MORE_PHASE_DATA',
        failState: response,
      });
    });

import React from 'react';

const Spinner = () => (
  <div id='loading-bar-spinner' className='o-loading o-loading--center'>
    <span className='o-loading__dot' />
    <span className='o-loading__dot' />
    <span className='o-loading__dot' />
    <span className='o-loading__dot' />
  </div>
);

export default Spinner;

const initialState = {
  team: [],
  influencedDecisions: [],
  phase: {},
  about: '',
  gallery: [],
  isLoading: true,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case 'SUCCESSFULL_ADD_INFO':
      return {
        ...state,
        team: action.data[0].data.team,
        influencedDecisions: action.data[0].data.influencedDecisions,
        phase: action.data[2].data,
        about: action.data[0].data.about,
        panel: action.data[0].data.panel,
        gallery: action.data[1].data,
        isLoading: false,
      };

    case 'FAILED_ADD_INFO':
      return {
        ...state,
        failedData: action.data,
      };

    default:
      return state;
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import { ImageWithZoomOrCarousel } from 'components/molecules/imageWithZoomOrCarousel/ImageWithZoomOrCarousel';
import i18n from '../../../i18n/helper';
import FactIllustration from '../../../assets-v2/images/illustration/fact-book.png';
import MarkdownField, { MARKDOWN_MIN_PLUS } from '../../markdown/MarkdownField';

export default function FactImage11(props) {
  const { question, questionButtonClicked, setIsMediaZoomed, isMediaZoomed } = props;

  return (
    <article className='c-question c-question--narrow'>
      <div className='c-question__header' />
      <div className='c-question__content'>
        <div className='c-fact u-a-children-fade-in'>
          <div className='c-fact__header'>
            <figure className='c-fact__illustration'>
              <img src={FactIllustration} alt='' />
            </figure>
            <h3 className='c-fact__title'>{i18n.t('questions.quickfact')}</h3>
          </div>
          {question?.resource.length > 0 ? (
            <div className='c-fact__media'>
              <ImageWithZoomOrCarousel
                resource={question?.resource}
                altImage='quickTextImage'
                leadImageType={question?.questionConfiguration?.leadImageType}
                setIsMediaZoomed={setIsMediaZoomed}
              />
            </div>
          ) : null}
          <div className='c-fact__content u-rich-text' style={{ display: `${isMediaZoomed ? 'none' : ''}` }}>
            <MarkdownField markdown={question.questionText} markdownConfig={MARKDOWN_MIN_PLUS} />
          </div>
        </div>
      </div>
      <div className='c-question__footer'>
        <button className='c-button c-button--alternate c-button--large' onClick={questionButtonClicked}>
          <span className='c-button__label'>{i18n.t('questions.factType1.buttonText')}</span>
        </button>
      </div>
    </article>
  );
}

FactImage11.propTypes = {
  question: PropTypes.object.isRequired,
  questionButtonClicked: PropTypes.func.isRequired,
};

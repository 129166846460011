import React from 'react';
import { FacebookShareButton } from 'react-share';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SvgIcon from '../../SvgIcon';
import { socialShare } from '../../../actions';

function FacebookButtonShare(props) {
  const { url = 'https://www.givemyview.com', quote = '', slugId = '', actions } = props;
  const { socialShare: socialShareAction } = actions;
  return (
    <FacebookShareButton
      url={url}
      quote={quote}
      className='c-social__link'
      onClick={() => {
        socialShareAction(slugId);
      }}
    >
      <span className='c-social__label'>Facebook</span>
      <SvgIcon icon='facebook' />
    </FacebookShareButton>
  );
}

FacebookButtonShare.propTypes = {
  url: PropTypes.string.isRequired,
  quote: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      socialShare,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(FacebookButtonShare);

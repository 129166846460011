import React from 'react';

import { DemographicsTypes } from './DemographicConstants';
import HouseholdNumberIllustration from '../../../../assets-v2/images/illustration/demographic-household-number.png';
import IncomeIllustration from '../../../../assets-v2/images/illustration/demographic-household-income.png';
import AccomodationTypeIllustration from '../../../../assets-v2/images/illustration/demographic-accommodation-type.png';
import GenderIllustration from '../../../../assets-v2/images/illustration/demographic-gender.png';
import AgeIllustration from '../../../../assets-v2/images/illustration/demographic-age.png';
import ReligionIllustration from '../../../../assets-v2/images/illustration/demographic-religion.png';

export const getQuestionIllustration = (demographicType) => {
  switch (demographicType) {
    case DemographicsTypes.DEMOGRAPHIC_AGE:
      return <img src={AgeIllustration} alt='' />;
    case DemographicsTypes.DEMOGRAPHIC_GENDER:
      return <img src={GenderIllustration} alt='' />;
    case DemographicsTypes.DEMOGRAPHIC_RELIGION:
      return <img src={ReligionIllustration} alt='' />;
    case DemographicsTypes.DEMOGRAPHIC_ACCOMODATION_TYPE:
      return <img src={AccomodationTypeIllustration} alt='' />;
    case DemographicsTypes.DEMOGRAPHIC_HOUSEHOLD_INCOME:
      return <img src={IncomeIllustration} alt='' />;
    case DemographicsTypes.DEMOGRAPHIC_HOUSEHOLD_NUMBER:
      return <img src={HouseholdNumberIllustration} alt='' />;
    default:
      return null;
  }
};

export const getQuestionClass = (demographicType) => {
  switch (demographicType) {
    case DemographicsTypes.DEMOGRAPHIC_HOUSEHOLD_NUMBER:
      return 'c-demographic__group--household-number';
    default:
      return '';
  }
};

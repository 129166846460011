import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createAnswer } from 'components/organisms/question';
import { QuestionTextHeader } from 'components/atoms/questionTextHeader';
import { ImageWithZoomOrCarousel } from 'components/molecules/imageWithZoomOrCarousel/ImageWithZoomOrCarousel';
import i18n from '../../../i18n/helper';
import QuickTextInput from './QuickTextInput';
import QuestionFooter from '../questionComponents/QuestionFooter';
import { handleScroll } from '../QuestionUtils/QuestionFormUtils';

export default function Question19(props) {
  const { question, showCoin, onSubmitQuestion, isResult, questionButtonClicked, disableForm, setIsMediaZoomed } =
    props;
  const { multiChoicesOfferedBound: choiceNr, questionText: questionTitle, resource, choices } = question;
  const choice = choices[0];
  const [feedback, setFeedback] = useState(['', '', '', '', '']);
  const [isScrollingClass, setIsScrollingClass] = useState('');

  const placeholderArray = [
    i18n.t('questions.quickText.firstAnswer'),
    i18n.t('questions.quickText.secondAnswer'),
    i18n.t('questions.quickText.thirdAnswer'),
    i18n.t('questions.quickText.fourthAnswer'),
    i18n.t('questions.quickText.fifthAnswer'),
  ].slice(0, choiceNr);

  const isSubmitDisabled = () => feedback.filter((item) => item.length !== 0).length === 0 || disableForm;

  const submitFeedback = (choiceId) => {
    const createChoiceArray = () =>
      feedback
        .filter((item) => item.length !== 0)
        .map((feedbackItem, index) => ({ choiceId, order: index + 1, amount: null, feedback: feedbackItem }));

    onSubmitQuestion(createAnswer(createChoiceArray()));
  };

  return (
    <article className={`c-question c-question--narrow ${isScrollingClass}`}>
      <QuestionTextHeader questionText={questionTitle} isResult={isResult} resultText={i18n.t('questions.thanks')} />
      <div className='c-question__content' onScroll={handleScroll(setIsScrollingClass)}>
        {resource && resource.length !== 0 && (
          <ImageWithZoomOrCarousel
            resource={resource}
            altImage='quickTextImage'
            leadImageType={question?.questionConfiguration?.leadImageType}
            setIsMediaZoomed={setIsMediaZoomed}
            zoomEnabled={!disableForm}
          />
        )}
        <div className='l-form c-fields u-a-children-fade-in'>
          {placeholderArray.map((placeholder, index) => (
            <QuickTextInput
              key={index}
              labelIndex={index + 1}
              choiceIndex={index}
              choiceLabel={placeholder}
              feedback={feedback}
              setFeedback={setFeedback}
              hasPrefixLabel={choiceNr > 1}
              isResult={isResult}
            />
          ))}
        </div>
      </div>
      <QuestionFooter
        buttonClassModifier={`c-button--primary c-button--large ${isSubmitDisabled() ? 'is-disabled' : ''}`}
        disabled={isSubmitDisabled()}
        showCoin={showCoin}
        onClick={isResult ? () => questionButtonClicked() : () => submitFeedback(choice._id)}
        buttonText={isResult ? i18n.t('questions.nextQuestion') : i18n.t('questions.submitButton')}
      />
    </article>
  );
}

Question19.propTypes = {
  question: PropTypes.object.isRequired,
  resource: PropTypes.array,
};

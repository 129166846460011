import ImageOrVideo from 'components/imageOrVideo/ImageOrVideo';
import { QuestionResource, LEAD_IMAGE_TYPES, LeadImageType } from 'components/organisms/questions';
import { ImageWithZoom } from './ImageWithZoom';
import { ImageCarousel } from './ImageCarousel';
import { getImageClassModifier } from './utils';

type ImageWithZoomOrCarouselProps = {
  resource: QuestionResource[];
  leadImageType: LeadImageType;
  altImage: string;
  setIsMediaZoomed(isMediaZoomed: boolean): void;
  zoomEnabled: boolean;
};

export const ImageWithZoomOrCarousel = ({
  resource,
  leadImageType,
  altImage,
  setIsMediaZoomed,
  zoomEnabled,
}: ImageWithZoomOrCarouselProps): JSX.Element => {
  const isCarousel = leadImageType === LEAD_IMAGE_TYPES.STANDARD_CAROUSEL;
  const isZoomImage = leadImageType === LEAD_IMAGE_TYPES.ZOOM_SINGLE_IMAGE;
  const classModifier = getImageClassModifier(isCarousel, isZoomImage);

  const imageSwitcher = (): JSX.Element => {
    // return if no resource array
    if (!(resource?.length > 0)) {
      return <></>;
    }
    // carousel
    if (isCarousel) {
      return <ImageCarousel resource={resource} altImage={altImage} />;
    }
    // zoom image
    if (isZoomImage) {
      return (
        <ImageWithZoom
          resource={resource[0]}
          altImage={altImage}
          setIsMediaZoomed={setIsMediaZoomed}
          zoomEnabled={zoomEnabled}
        />
      );
    }
    // single image
    return (
      <figure className='c-lead-image__media'>
        <ImageOrVideo resource={resource[0]} imageAlt={altImage} autostart />
      </figure>
    );
  };

  return <div className={`c-lead-image ${classModifier}`}>{imageSwitcher()}</div>;
};

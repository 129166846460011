const initialState = {
  data: [],
  isLoading: true,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case 'SUCCESS_ADD_FAQS':
      return {
        ...state,
        data: action.data.data,
        isLoading: false,
      };

    case 'FAIL_ADD_FAQS':
      return {
        ...state,
        failState: action.failState,
      };
    default:
      return state;
  }
};

import React from 'react';
import RangeTouch from '../../externalCode/rangetouch/js/rangetouch';

export default function RangeInput(props) {
  const inputRef = React.useRef(null);
  React.useEffect(() => {
    // eslint-disable-next-line
    new RangeTouch(inputRef.current);
  }, []);

  return <input ref={inputRef} type='range' {...props} />;
}

import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n/helper';
import ButtonWithLabelAndIcon from '../../button/ButtonWithLabelAndIcon';

export default function AnnotateMapCustomControls(props) {
  const { map, areaBounds } = props;
  return (
    <div className='c-annotate-actions'>
      <div className='c-annotate-actions__area'>
        <ButtonWithLabelAndIcon
          onClick={() => map.setZoom(map.getZoom() + 1)}
          classModifier='c-annotate-actions__zoom-in'
          label={i18n.t('questions.annotate.zoomIn')}
          icon='plus'
        />
        <ButtonWithLabelAndIcon
          onClick={() => map.setZoom(map.getZoom() - 1)}
          classModifier='c-annotate-actions__zoom-out'
          label={i18n.t('questions.annotate.zoomOut')}
          icon='minus'
        />
        {areaBounds && (
          <ButtonWithLabelAndIcon
            onClick={() => map.fitBounds(areaBounds)}
            classModifier='c-annotate-actions__center'
            label={i18n.t('questions.annotate.center')}
            icon='expand-fit'
          />
        )}
      </div>
    </div>
  );
}

AnnotateMapCustomControls.propTypes = {
  map: PropTypes.object.isRequired,
  areaBounds: PropTypes.object,
};

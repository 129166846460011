import React, { useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import i18n from '../../i18n/helper';
import { GAKEY, PRIVACY_POLICY_LINK } from '../../constants';

const CookieNotice = (props) => {
  const { onManageCookies } = props;

  const [showMe, setShowMe] = useState(() => localStorage.getItem('CookieAccepted') !== 'YES');

  useEffect(() => {
    const html = document.getElementsByTagName('html')[0];
    if (showMe) {
      html.classList.add('is-cookie-notice-opened', 'is-cookie-notice-minimal');
    }

    return () => {
      html.classList.remove('is-cookie-notice-opened', 'is-cookie-notice-minimal');
    };
  }, [showMe]);

  const acceptCookiesHandler = () => {
    localStorage.setItem('CookieAccepted', 'YES');
    localStorage.setItem('AnalyticsCookies', true);
    localStorage.setItem('MarketingCookies', true);

    window[`ga-disable-${GAKEY}`] = true;
    ReactPixel.grantConsent();

    setShowMe(false);
  };

  return (
    <React.Fragment>
      {showMe && (
        <div className='c-cookie-notice'>
          <p>
            {`${i18n.t('cookies.notice')} `}
            <a href={PRIVACY_POLICY_LINK} target='_blank' rel='noopener noreferrer'>
              {i18n.t('dashboard.settings.policy')}
            </a>
          </p>
          <button className='c-button c-button--outline' onClick={onManageCookies}>
            <span className='c-button__label'>{i18n.t('footer.manageCookies')}</span>
          </button>
          <button className='c-button c-button--primary' onClick={acceptCookiesHandler}>
            <span className='c-button__label'>{i18n.t('cookies.accept')}</span>
          </button>
        </div>
      )}
    </React.Fragment>
  );
};

export default CookieNotice;

import React from 'react';

import i18n from '../../../i18n/helper';
import Coin from '../../coin/coin';

const INITIAL_STATE = {
  choiceValue: '',
};

const MAX_VALUE_CHARS = 2000; // selectedChoiceId goNext

class OtherInput15 extends React.Component {
  constructor(props) {
    super(props);
    // console.log('Other form', props);

    this.state = INITIAL_STATE;
  }

  handleInput = (e) => {
    // limit input to MAX_VALUE_CHARS char
    if (e.target.value.length > MAX_VALUE_CHARS) {
      return;
    }
    this.setState({ choiceValue: e.target.value });
  };

  charLeft = () => MAX_VALUE_CHARS - this.state.choiceValue.length;

  isSubmitDisabled = () =>
    // disabled because submitting or empty field
    this.props.disableForm || !(this.state.choiceValue && this.state.choiceValue.trim());

  submitAnswer = () => {
    this.props.goNext(this.state.choiceValue.trim());
  };

  render() {
    return (
      <article className='c-question c-question--narrow'>
        <div className='c-question__header'>
          <h2 className='c-question__title'>{this.props.question.questionText}</h2>
        </div>

        <div className='c-question__content'>
          <div className='l-form c-fields u-a-children-fade-in'>
            <div className='c-field l-form__item'>
              <label className='c-field__label' htmlFor='write-your-own'>
                {i18n.t('questions.imagesOther.input.choiceLabel')} *
              </label>
              <div className='c-field__group'>
                <div className='o-input'>
                  <textarea
                    type='text'
                    placeholder={i18n.t('questions.imagesOther.input.choicePlaceholder')}
                    id='write-your-own'
                    value={this.state.choiceValue}
                    onChange={this.handleInput}
                    maxLength={MAX_VALUE_CHARS}
                  />
                </div>
                <span
                  className={`c-field__count ${this.charLeft() <= 10 ? 'c-field__count--limit' : ''}`}
                  title='Characters left'
                >
                  {this.charLeft()}
                </span>
              </div>
            </div>

            <div className='c-field c-field--action l-form__item'>
              <div className='c-field__group'>
                <button
                  className={`c-button c-button--primary c-button--large c-button--block ${
                    this.isSubmitDisabled() ? 'is-disabled' : ''
                  }`}
                  onClick={this.submitAnswer}
                  disabled={this.isSubmitDisabled()}
                >
                  <span className='c-button__label'>{i18n.t('questions.submitButton')}</span>
                </button>
                <button className='c-button c-button--link c-button--block js-otherBack' onClick={this.props.goBack}>
                  <span className='c-button__label'>{i18n.t('questions.backButton')}</span>
                </button>
                {this.props.showCoin && <Coin bounceFromBottom />}
              </div>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default OtherInput15;

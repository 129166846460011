import { useEffect, useRef, useState } from 'react';

export type MapMarkerProps = {
  updateReactionPosition: (position: google.maps.LatLngLiteral) => void;
  isLocationInShape: (location: google.maps.LatLngLiteral) => boolean;
} & google.maps.MarkerOptions;

export const MapMarker = ({
  updateReactionPosition,
  isLocationInShape,
  draggable,
  ...options
}: MapMarkerProps): null => {
  const [marker, setMarker] = useState<google.maps.Marker>();
  const lastPosition = useRef<google.maps.LatLngLiteral>();

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  useEffect(() => {
    if (marker) {
      marker.setOptions({ draggable });
    }
  }, [marker, draggable]);

  useEffect(() => {
    if (marker) {
      google.maps.event.addListener(marker, 'dragend', () => {
        const lat = marker.getPosition()?.lat() ?? 0;
        const lng = marker.getPosition()?.lng() ?? 0;
        if (!isLocationInShape({ lat, lng }) || !draggable) {
          marker.setPosition(lastPosition.current);
        } else {
          updateReactionPosition({ lat, lng });
        }
      });
      google.maps.event.addListener(marker, 'dragstart', () => {
        lastPosition.current = { lat: marker.getPosition()?.lat() ?? 0, lng: marker.getPosition()?.lng() ?? 0 };
      });
    }
    return () => {
      if (marker) {
        google.maps.event.clearInstanceListeners(marker);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marker, draggable]);

  return null;
};

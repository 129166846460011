import React, { useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import debounce from 'lodash.debounce';

import InfluenceDecision from './InfluenceDecision';
import SliderControls from './SliderControls';
import i18n from '../../i18n/helper';

export default function Decisions(props) {
  const { decisions } = props;
  const slider = useRef(null);
  const [slide, setSlide] = useState(0);
  const filteredDecisions = decisions.filter((item) => item.description || item.title || item.resource);

  useEffect(() => {
    slider.current.slickGoTo(slide);
  }, [slide]);

  const IDENTIFIER = 'decisions';

  // there is an issue with afterChange: https://github.com/akiran/react-slick/issues/1262
  // without debounce it can get stuck in infinite loop when clicking quickly on arrows
  // useCallback is necessary for debounce to work
  const speed = 500;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounced = useCallback(debounce((next) => setSlide(next), speed, { leading: true }));
  const sliderSettings = {
    mobileFirst: true,
    arrows: false,
    infinite: true,
    beforeChange: (current, next) => debounced(next),
    adaptiveHeight: true,
  };

  return (
    <div className='c-block c-block--light c-block--background-2'>
      <div className='l-container l-container--spacing'>
        <div className='c-influenced'>
          <h2 className='c-influenced__heading'>{i18n.t('body.decision_title')}</h2>
          <div className='c-influenced__carousel c-carousel'>
            <Slider className='c-influenced__items c-carousel__items' ref={slider} {...sliderSettings}>
              {filteredDecisions.map((item, index) => (
                <InfluenceDecision
                  key={index}
                  title={item.title}
                  description={item.description}
                  resource={item.resource}
                  type={item.type}
                  identifier={IDENTIFIER}
                  index={index}
                />
              ))}
            </Slider>
            {filteredDecisions.length > 1 && (
              <SliderControls
                setSlide={setSlide}
                classModifier='c-carousel__controls--blue'
                slide={slide}
                items={filteredDecisions}
                identifier={IDENTIFIER}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

Decisions.propTypes = {
  decisions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      resource: PropTypes.string,
      type: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

import TextInputWithIcon from './TextInputWithIcon';

export default function TextInputWithIconAndEditButton(props) {
  const { value, placeholder, onChange, disabled, id, name, icon, onClick, buttonLabel, buttonHide, maxLength } = props;
  return (
    <React.Fragment>
      <TextInputWithIcon
        value={value}
        type='text'
        placeholder={placeholder}
        id={id}
        name={name}
        onChange={onChange}
        disabled={disabled}
        icon={icon}
        maxLength={maxLength}
      />
      {buttonHide && (
        <button className='c-button' onClick={onClick}>
          <span className='c-button__label'>{buttonLabel}</span>
        </button>
      )}
    </React.Fragment>
  );
}

TextInputWithIconAndEditButton.propTypes = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonHide: PropTypes.bool.isRequired,
  maxLength: PropTypes.number,
};

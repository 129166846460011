import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

export default function ButtonWithLabelAndIcon(props) {
  const { classModifier = '', onClick, label, icon, disabled = false, value } = props;
  return (
    <button className={`c-button ${classModifier}`} onClick={onClick} disabled={disabled}>
      {label && <span className='c-button__label'>{label}</span>}
      {!!value && <span className='c-button__value'>{value}</span>}
      {icon && <SvgIcon icon={icon} />}
    </button>
  );
}

ButtonWithLabelAndIcon.propTypes = {
  classModifier: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

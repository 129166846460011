import React from 'react';
import PropTypes from 'prop-types';
import CoinAnimation from './svg/coinAnimation';

export default function Coin(props) {
  const { bounceFromBottom } = props;
  return (
    <div
      className={`c-points-bounce ${bounceFromBottom ? 'c-points-bounce--from-bottom' : ''}`}
      style={{ display: 'block' }}
    >
      <figure>
        <CoinAnimation />
      </figure>
    </div>
  );
}

Coin.propTypes = {
  bounceFromBottom: PropTypes.bool,
};

import { Answer, lastQuestionAnswered } from 'components/organisms/question';
import { Question, FormTypes } from 'components/organisms/questions/types';
import { RefObject, useState } from 'react';
import ReactDOM from 'react-dom';
import { getInitialQuestionId, getInitialFormType, getNextQuestionIndex, getNextForm } from './utils';

type UseGetNextQuestionType = readonly [number, FormTypes, () => void];

/**
 * state diagrams from:
 * https://github.com/sdesregistry/IH-Engage/blob/fix/conditional-usecases/src/utils/hooks/useConditionalQuestion/conditional-usecases.md
 * This hook handles business logic for setting initial question and forms (modal views)
 * after those are set, calling getNextForm changes the required next question Id and view
 */
export const useGetNextQuestion = (
  questions: Question[],
  lastResponse: lastQuestionAnswered | null,
  isConditionalPoll: boolean,
  initialQuestionId: string | undefined,
  initialFormType: FormTypes | undefined,
  hasActiveCharities: boolean,
  isCharityChosen: boolean,
  pollId: string,
  answers: RefObject<Answer[]>,
): UseGetNextQuestionType => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(() =>
    getInitialQuestionId(questions, lastResponse, isConditionalPoll, initialQuestionId),
  );
  const [currentForm, setCurrentForm] = useState<FormTypes>(() =>
    getInitialFormType(questions, currentQuestionIndex, hasActiveCharities, initialFormType),
  );

  const getNextQuestion = () => {
    const nextQuestionIndex = getNextQuestionIndex(
      currentQuestionIndex,
      isConditionalPoll,
      answers.current ?? [],
      questions[currentQuestionIndex],
      questions,
    );
    const nextForm = getNextForm(
      questions,
      nextQuestionIndex,
      currentForm,
      hasActiveCharities,
      isCharityChosen,
      pollId,
    );
    ReactDOM.unstable_batchedUpdates(() => {
      setCurrentQuestionIndex(nextQuestionIndex);
      setCurrentForm(nextForm);
    });
  };

  return [currentQuestionIndex, currentForm, getNextQuestion];
};

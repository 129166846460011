import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { createAnswer } from 'components/organisms/question';
import Question from './15_Question';
import Result from './15_Result';
import OtherInput from './15_OtherInput';

const STEP1_QUESTION = 1;
const STEP2_INPUT = 2;
const STEP3_RESULT = 3;

export default function ImagesOther15(props) {
  const { question, communityAnswer, onSubmitQuestion, isResult, showCoin, questionButtonClicked, disableForm } = props;

  const [step, setStep] = useState(STEP1_QUESTION);
  const [selected, setSelected] = useState('');

  useEffect(() => {
    if (isResult) {
      setStep(STEP3_RESULT);
    }
  }, [isResult]);

  const goToSelectImage = () => {
    if (!disableForm) {
      setSelected('');
      setStep(STEP1_QUESTION);
    }
  };

  const goToSubmitFeedback = (choiceId) => {
    if (!disableForm) {
      setSelected(choiceId);
      setStep(STEP2_INPUT);
    }
  };

  const submitImageChoice = (choiceId) => {
    setSelected(choiceId);
    onSubmitQuestion(createAnswer([{ choiceId }]));
  };

  const submitFeedbackChoice = (feedback) => {
    onSubmitQuestion(createAnswer([{ choiceId: selected, feedback }]));
  };

  return (
    <React.Fragment>
      {STEP1_QUESTION === step && (
        <Question
          question={question}
          submitImageChoice={submitImageChoice}
          goToSubmitFeedback={goToSubmitFeedback}
          selected={selected}
          showCoin={showCoin}
        />
      )}
      {STEP2_INPUT === step && (
        <OtherInput
          question={question}
          selectedChoiceId={selected}
          goBack={goToSelectImage}
          goNext={submitFeedbackChoice}
          showCoin={showCoin}
          disableForm={disableForm}
        />
      )}
      {STEP3_RESULT === step && (
        <Result
          question={question}
          questionButtonClicked={questionButtonClicked}
          communityAnswer={communityAnswer}
          selectedChoiceId={selected}
        />
      )}
    </React.Fragment>
  );
}

ImagesOther15.propTypes = {
  question: PropTypes.object.isRequired,
  questionButtonClicked: PropTypes.func.isRequired,
};

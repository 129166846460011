import React from 'react';

// Questions
import { AnnotateAdvanced17, AnnotateAdvancedMap18 } from 'components/organisms/questions';
import ThreeImageChoiceQuestion00 from '../../components/question/00_ThreeImageChoiceQuestion/00_Question';
import ReorderQuestion01 from '../../components/question/01_ReorderQuestion/01_Question';
import SliderQuestion02 from '../../components/question/02_SliderQuestion/02_Question';
import ThreeSliderQuestion03 from '../../components/question/03_ThreeSliderQuestion/03_Question';
import FourImageChoiceQuestion04 from '../../components/question/04_FourImageChoiceQuestion/04_Question';
import TwoImageChoiceQuestion05 from '../../components/question/05_TwoImageChoiceQuestion/05_Question';
import MultipleChoice from '../../components/question/06_MultipleChoiceQuestion/06_Question';
import FeedbackQuestion from '../../components/question/13_Feedback/13_FeedbackQuestion';
import SliderFeedback from '../../components/question/14_SliderFeedback/14_SliderFeedbackQuestion';
import ImagesOther15 from '../../components/question/15_ImagesOtherQuestion/15_ImagesOtherQuestion';
import DemographicQuestion from '../../components/question/16_DemographicQuestions/DemographicQuestion';
// import AnnotateImageQuestion17 from '../../components/question/17_AnnotateImage/17_AnnotateImageQuestion';
// import AnnotateMapQuestion18 from '../../components/question/18_AnnotateMap/18_AnnotateMapQuestion';
import QuickTextQuestion19 from '../../components/question/19_QuickTextQuestion/19_Question';
import { RatingQuestion20 } from '../../components/organisms/questions/ratingQuestion/20_RatingQuestion';
import { PersonalDataQuestion21 } from '../../components/organisms/questions/21_PersonalData/21_PersonalData';
// QQ
import ImageQualifyingQuestions from '../../components/question/qualifyingQuestions/ImageQualifyingQuestions';

// Facts
import FactText10 from '../../components/question/10_FactText/10_FactText';
import FactImage11 from '../../components/question/11_FactImage/11_FactImage';
import FactCompare12 from '../../components/question/12_FactCompare/12_FactCompare';

import { Question } from '../../components/organisms/question';

import {
  EXCLUDE_FACTS,
  INCLUDE_FACTS,
  INCLUDE_QQ,
  INCLUDE_DEMOGRAPHIC,
  isQuestion,
  QQ_102_SINGLE_2_IMAGE,
  QQ_103_SINGLE_3_IMAGE,
  QQ_104_SINGLE_4_IMAGE,
  QQ_106_MULTI_IMAGE,
  QT_0_SINGLE_3_IMAGE,
  QT_10_FACT_TEXT,
  QT_11_FACT_IMAGE_TEXT,
  QT_12_FACT_COMPARE,
  QT_13_FEEDBACK,
  QT_14_SLIDER_FEEDBACK,
  QT_15_IMAGES_OTHER,
  QT_16_DEMOGRAPHIC,
  QT_1_REORDER_QUESTION,
  QT_2_SLIDER_QUESTION,
  QT_3_MULTIPLE_SLIDER_QUESTION,
  QT_4_SINGLE_4_IMAGE,
  QT_5_SINGLE_2_IMAGE,
  QT_6_MULTI_IMAGE,
  EXCLUDE_DEMOGRAPHIC,
  QT_17_ANNOTATION_IMAGE,
  QT_18_ANNOTATION_MAP,
  QT_19_QUICK_TEXT,
  QT_20_RATING,
  QT_21_PERSONAL_DATA,
} from './QuestionConstants';

/**
 * Mapping questionType to corresponding component to render in popup
 * @param questionType
 * @param props
 * @returns {null|*}
 */
function questionSwitcher(questionType, props) {
  switch (questionType) {
    case QT_21_PERSONAL_DATA:
      return <Question QuestionComponent={PersonalDataQuestion21} {...props} />;
    // Proper questions
    case QT_0_SINGLE_3_IMAGE:
      return <Question QuestionComponent={ThreeImageChoiceQuestion00} {...props} />;
    case QT_1_REORDER_QUESTION:
      return <Question QuestionComponent={ReorderQuestion01} {...props} />;
    case QT_2_SLIDER_QUESTION:
      return <Question QuestionComponent={SliderQuestion02} {...props} />;
    case QT_3_MULTIPLE_SLIDER_QUESTION:
      return <Question QuestionComponent={ThreeSliderQuestion03} {...props} />;
    case QT_4_SINGLE_4_IMAGE:
      return <Question QuestionComponent={FourImageChoiceQuestion04} {...props} />;
    case QT_5_SINGLE_2_IMAGE:
      return <Question QuestionComponent={TwoImageChoiceQuestion05} {...props} />;
    case QT_6_MULTI_IMAGE:
      return <Question QuestionComponent={MultipleChoice} {...props} />;
    case QT_13_FEEDBACK:
      return <Question QuestionComponent={FeedbackQuestion} {...props} />;
    case QT_14_SLIDER_FEEDBACK:
      return <Question QuestionComponent={SliderFeedback} {...props} />;
    case QT_15_IMAGES_OTHER:
      return <Question QuestionComponent={ImagesOther15} {...props} />;
    case QT_16_DEMOGRAPHIC:
      return props.question.questions.length > 0 ? <DemographicQuestion {...props} /> : null;
    case QT_17_ANNOTATION_IMAGE:
      return <Question QuestionComponent={AnnotateAdvanced17} {...props} />;
    case QT_18_ANNOTATION_MAP:
      return <Question QuestionComponent={AnnotateAdvancedMap18} {...props} />;
    case QT_19_QUICK_TEXT:
      return <Question QuestionComponent={QuickTextQuestion19} {...props} />;
    case QT_20_RATING:
      return <Question QuestionComponent={RatingQuestion20} {...props} />;

    // FACTS
    case QT_10_FACT_TEXT:
      return <FactText10 {...props} />;
    case QT_11_FACT_IMAGE_TEXT:
      return <FactImage11 {...props} />;
    case QT_12_FACT_COMPARE:
      return <FactCompare12 {...props} />;

    // QUALIFYING QUESTIONS
    case QQ_106_MULTI_IMAGE:
    case QQ_102_SINGLE_2_IMAGE:
    case QQ_103_SINGLE_3_IMAGE:
    case QQ_104_SINGLE_4_IMAGE:
      return <ImageQualifyingQuestions {...props} />;

    default:
      return null;
  }
}

function doShowPollHeader(formType) {
  return isQuestion(formType, INCLUDE_QQ, INCLUDE_FACTS, INCLUDE_DEMOGRAPHIC);
}

/**
 * Calculating backwards from
 * = total steps (questions+facts) - unanswered steps + (skip) answered steps
 * @returns {number}
 */
function getCurrentStepNumber(poll, index) {
  return (
    // return a new array (slice) and calculate the length of that array without demographic questions and facts
    // so just the normal questions and segmentation
    poll.questions
      .slice(0, index + 1)
      .filter((question) => isQuestion(question.questionType, INCLUDE_QQ, EXCLUDE_FACTS, EXCLUDE_DEMOGRAPHIC)).length
  );
}

function getProgressQuestionsTotal(poll) {
  return poll.questions.filter((question) =>
    isQuestion(question.questionType, INCLUDE_QQ, EXCLUDE_FACTS, EXCLUDE_DEMOGRAPHIC),
  ).length;
}

/**
 * Calculating backwards
 * = total questions (not facts) - un-answered/remaining questions
 * @returns {number}
 */
function getCurrentQuestionNumber(poll, index) {
  const remainingQuestions = poll.questions.filter(
    (question, idx) =>
      idx >= index && isQuestion(question.questionType, INCLUDE_QQ, EXCLUDE_FACTS, EXCLUDE_DEMOGRAPHIC),
  ).length;
  // console.log('Questions progress', (this.props.poll.totalQuestions - remainingQuestions), this.props.poll.totalQuestions, remainingQuestions, this.state.currentIndex, this.props.poll.questions.length);

  // Have to +1 because in the calculation I follow the question index (starting at 0)
  // but I have to display natural number (starting at 1)
  return poll.totalQuestions - remainingQuestions + 1;
}

export default {
  questionSwitcher,
  getCurrentStepNumber,
  getCurrentQuestionNumber,
  getProgressQuestionsTotal,
  doShowPollHeader,
};

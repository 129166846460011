import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { getNews as getNewsAction, clearNewsData as clearNewsDataAction } from '../actions';
import i18n from '../i18n/helper';
import SvgIcon from '../components/SvgIcon';
import Spinner from '../components/spinner/spinner';
import NewsItem from '../components/news/NewsItem';
import SORTING from '../shared/engage-notifications-enums';
import Panel from '../components/panel/Panel';

const SORTING_ENUM = SORTING().EngageNotificationSortType;

function News(props) {
  const { actions, slugId, newsData } = props;
  const { getNews, clearNewsData } = actions;
  const [sorting, setSorting] = useState(SORTING_ENUM.TOP);
  const [news, setNews] = useState([]);
  const [isLoading, setIsLoading] = useState(newsData.isLoading);

  useEffect(() => {
    getNews(slugId, sorting);
    return () => {
      const data = {
        isNewsDataPresent: false,
        renderWayPoint: false,
        page: 0,
        newsData: [],
        isLoading: true,
      };
      clearNewsData(data);
    };
  }, [getNews, slugId, clearNewsData, sorting]);

  useEffect(() => {
    setNews(newsData.newsData);
    setIsLoading(newsData.isLoading);
  }, [newsData]);

  return (
    <main className='l-section-main c-section-main' id='appContent'>
      <div className='l-container'>
        {isLoading === true ? (
          <Spinner />
        ) : (
          <React.Fragment>
            {/* panels */}
            {newsData.panel && <Panel history={props.history} panel={newsData.panel} />}

            <div className='c-section-intro'>
              <h1 className='c-section-intro__title'>{i18n.t('header.news')}</h1>
              <div className='c-section-intro__action'>
                <div className='c-field c-field--label-hide'>
                  {/* eslint-disable-next-line */}
                  <label className='c-field__label'>Sort news by</label>
                  <div className='c-field__group'>
                    <div className='o-radio o-radio--tab'>
                      <input
                        type='radio'
                        id='radio-sort-1'
                        name='radio-sort'
                        checked={sorting === SORTING_ENUM.RECENT}
                        onChange={() => setSorting(SORTING_ENUM.RECENT)}
                      />
                      <label htmlFor='radio-sort-1'>
                        <SvgIcon icon='clock' />
                        <span className='o-radio__label'>{i18n.t('news.recent')}</span>
                      </label>
                    </div>
                    <div className='o-radio o-radio--tab'>
                      <input
                        type='radio'
                        id='radio-sort-2'
                        name='radio-sort'
                        checked={sorting === SORTING_ENUM.TOP}
                        onChange={() => setSorting(SORTING_ENUM.TOP)}
                      />
                      <label htmlFor='radio-sort-2'>
                        <SvgIcon icon='stars' />
                        <span className='o-radio__label'>{i18n.t('news.top')}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='l-grid-news'>
              {news.map((item, index) => (
                <NewsItem key={index} news={item} />
              ))}
            </div>
          </React.Fragment>
        )}
      </div>
    </main>
  );
}

News.propTypes = {
  slugId: PropTypes.string.isRequired,
  newsData: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  newsData: state.news,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ getNews: getNewsAction, clearNewsData: clearNewsDataAction }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(News);

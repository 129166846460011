// QT = QuestionType
// AT = Action Type

export const QuestionTypes = {
  THREE_IMAGE_SINGLE_CHOICE: 'THREE_IMAGE_SINGLE_CHOICE', // type 1 - ok
  ORDERING_CHOICE: 'ORDERING_CHOICE', // type 2
  SINGLE_SLIDER_CHOICE: 'SINGLE_SLIDER_CHOICE', // type 3
  THREE_SLIDER_CHOICE_VALUES: 'THREE_SLIDER_CHOICE_VALUES', // type 4 - ok
  FOUR_IMAGE_SINGLE_CHOICE: 'FOUR_IMAGE_SINGLE_CHOICE', // type 5 - ok
  TWO_IMAGE_SINGLE_CHOICE: 'TWO_IMAGE_SINGLE_CHOICE', // type 6 - ok
  MULTIPLE_IMAGE_MULTIPLE_CHOICE: 'MULTIPLE_IMAGE_MULTIPLE_CHOICE', // type 7
  DESCRIPTION_FACT: 'DESCRIPTION_FACT', // type 10 - n/a
  SINGLE_IMAGE_FACT: 'SINGLE_IMAGE_FACT', // type 11 - n/a
  TWO_IMAGE_FACT: 'TWO_IMAGE_FACT', // type 12 - n/a
  FEEDBACK: 'FEEDBACK', // type 13
  SLIDER_FEEDBACK: 'SLIDER_FEEDBACK', // type 14
  MULTIPLE_IMAGE_OTHER_CHOICE: 'MULTIPLE_IMAGE_OTHER_CHOICE', // type 15 - ok
  DEMOGRAPHICS: 'DEMOGRAPHICS', // type 16
  ANNOTATION_IMAGE: 'ANNOTATION_IMAGE', // type 17 - ok
  ANNOTATION_MAP: 'ANNOTATION_MAP', // type 18 - ok
  QUICK_TEXT: 'QUICK_TEXT', // type 19 - ok
  RATING: 'RATING', // type 20 - ok
  PERSONAL_DATA: 'PERSONAL_DATA',
};

export const QT_0_SINGLE_3_IMAGE = QuestionTypes.THREE_IMAGE_SINGLE_CHOICE;
export const QT_1_REORDER_QUESTION = QuestionTypes.ORDERING_CHOICE;
export const QT_2_SLIDER_QUESTION = QuestionTypes.SINGLE_SLIDER_CHOICE;
export const QT_3_MULTIPLE_SLIDER_QUESTION = QuestionTypes.THREE_SLIDER_CHOICE_VALUES;
export const QT_4_SINGLE_4_IMAGE = QuestionTypes.FOUR_IMAGE_SINGLE_CHOICE;
export const QT_5_SINGLE_2_IMAGE = QuestionTypes.TWO_IMAGE_SINGLE_CHOICE;
export const QT_6_MULTI_IMAGE = QuestionTypes.MULTIPLE_IMAGE_MULTIPLE_CHOICE;

export const QT_10_FACT_TEXT = QuestionTypes.DESCRIPTION_FACT;
export const QT_11_FACT_IMAGE_TEXT = QuestionTypes.SINGLE_IMAGE_FACT;
export const QT_12_FACT_COMPARE = QuestionTypes.TWO_IMAGE_FACT;
export const QT_13_FEEDBACK = QuestionTypes.FEEDBACK;
export const QT_14_SLIDER_FEEDBACK = QuestionTypes.SLIDER_FEEDBACK;
export const QT_15_IMAGES_OTHER = QuestionTypes.MULTIPLE_IMAGE_OTHER_CHOICE;
export const QT_16_DEMOGRAPHIC = QuestionTypes.DEMOGRAPHICS;
// export const QT_16_DEMOGRAPHIC = QuestionTypes.DEMOGRAPHICS;
export const QT_17_ANNOTATION_IMAGE = QuestionTypes.ANNOTATION_IMAGE;
export const QT_18_ANNOTATION_MAP = QuestionTypes.ANNOTATION_MAP;
export const QT_19_QUICK_TEXT = QuestionTypes.QUICK_TEXT;
export const QT_20_RATING = QuestionTypes.RATING;
export const QT_21_PERSONAL_DATA = QuestionTypes.PERSONAL_DATA;

// Qualifying Questions
export const QQ_102_SINGLE_2_IMAGE = 102;
export const QQ_103_SINGLE_3_IMAGE = 103;
export const QQ_104_SINGLE_4_IMAGE = 104;
export const QQ_106_MULTI_IMAGE = 106;

// Internal actions - NOT related to QuestionTypes from API
export const AT_1006_GAMIFICATION = 1006;
export const AT_1007_CHARITY = 1007;
export const AT_1008_SHARE_WITH_COMMUNITY = 1008;
export const AT_1009_SIGNIN_SIGNUP = 1009;

export const AT_1013_KNOW_LOCATION = 1013;
export const AT_1014_END_OF_POLL = 1014;
export const AT_1015_GAMIFICATION_LEVEL1 = 1015;
export const AT_1016_WRONG_LOCATION = 1016;

export const INCLUDE_QQ = 1;
export const EXCLUDE_QQ = 2;
export const INCLUDE_FACTS = 3;
export const EXCLUDE_FACTS = 4;
export const INCLUDE_DEMOGRAPHIC = 5;
export const EXCLUDE_DEMOGRAPHIC = 6;

export function isQualifyingQuestion(questionType) {
  const qq = [
    QQ_102_SINGLE_2_IMAGE,
    QQ_103_SINGLE_3_IMAGE,
    QQ_104_SINGLE_4_IMAGE,
    QQ_106_MULTI_IMAGE,
    QT_21_PERSONAL_DATA,
  ];
  return qq.includes(questionType);
  // return (
  //   QQ_102_SINGLE_2_IMAGE === questionType ||
  //   QQ_103_SINGLE_3_IMAGE === questionType ||
  //   QQ_104_SINGLE_4_IMAGE === questionType ||
  //   QQ_106_MULTI_IMAGE === questionType
  // );
}

// As part of #1871 refactoring, questionTypes were changes to sync with backend
// this caused problems as QQ questions arent unique questionType anymore
// removing QQ logic breaks everything, so this is interim solution,
// second pass of refactoring should refactor QQ question logic and introduce
// a property at location the QQ question is added in poll.js reducer
// example question: {...question, isQQ: true}
export function makeQualifyingQuestion(questionType) {
  switch (questionType) {
    case QuestionTypes.TWO_IMAGE_SINGLE_CHOICE:
      return QQ_102_SINGLE_2_IMAGE;
    case QuestionTypes.THREE_IMAGE_SINGLE_CHOICE:
      return QQ_103_SINGLE_3_IMAGE;
    case QuestionTypes.FOUR_IMAGE_SINGLE_CHOICE:
      return QQ_104_SINGLE_4_IMAGE;
    case QuestionTypes.MULTIPLE_IMAGE_MULTIPLE_CHOICE:
      return QQ_106_MULTI_IMAGE;
    default:
      return AT_1014_END_OF_POLL;
  }
}

export function isFact(questionType) {
  if (
    QT_10_FACT_TEXT === questionType ||
    QT_11_FACT_IMAGE_TEXT === questionType ||
    QT_12_FACT_COMPARE === questionType
  ) {
    return true;
  }

  return false;
}

export function isAnnotateImageQuestion(questionType) {
  return QT_17_ANNOTATION_IMAGE === questionType;
}

export function isAnnotateMapQuestion(questionType) {
  return QT_18_ANNOTATION_MAP === questionType;
}

export function isPersonalDataQuestion(questionType) {
  return QT_21_PERSONAL_DATA === questionType;
}

export function isDemographicQuestion(questionType) {
  return QT_16_DEMOGRAPHIC === questionType;
}

export function isQuestion(questionType, includeQQ, includeFacts, includeDemographic) {
  if (!(includeQQ && includeFacts)) {
    throw new Error('You must specify inclusion criteria INCLUDE_QQ or INCLUDE_QQ AND INCLUDE_FACT or EXCLUDE_FACTS');
  }

  // Match QUESTIONS ONLY
  if (
    QT_0_SINGLE_3_IMAGE === questionType ||
    QT_1_REORDER_QUESTION === questionType ||
    QT_2_SLIDER_QUESTION === questionType ||
    QT_3_MULTIPLE_SLIDER_QUESTION === questionType ||
    QT_4_SINGLE_4_IMAGE === questionType ||
    QT_5_SINGLE_2_IMAGE === questionType ||
    QT_6_MULTI_IMAGE === questionType ||
    QT_13_FEEDBACK === questionType ||
    QT_14_SLIDER_FEEDBACK === questionType ||
    QT_15_IMAGES_OTHER === questionType ||
    QT_17_ANNOTATION_IMAGE === questionType ||
    QT_18_ANNOTATION_MAP === questionType ||
    QT_19_QUICK_TEXT === questionType ||
    QT_20_RATING === questionType
    // ||    QT_21_PERSONAL_DATA === questionType
  ) {
    return true;
  }

  // OPTIONALLY match also FACTS types
  if (includeFacts === INCLUDE_FACTS && isFact(questionType)) {
    return true;
  }

  // OPTIONALLY match also QQ types
  if (includeQQ === INCLUDE_QQ && isQualifyingQuestion(questionType)) {
    return true;
  }

  if (includeDemographic === INCLUDE_DEMOGRAPHIC && isDemographicQuestion(questionType)) {
    return true;
  }

  return false;
}

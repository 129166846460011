import React from 'react';

import { getImageUrl, IMAGE_FORMATS } from './Formats';

export default function SquareL(props) {
  const { imageId, imageType, alt, ...rest } = props;

  return (
    <picture>
      <source media='(max-width: 799px)' srcSet={getImageUrl(imageId, IMAGE_FORMATS.GMV_IMAGE_SQUARE_M_600x600)} />
      <source media='(min-width: 800px)' srcSet={getImageUrl(imageId, IMAGE_FORMATS.GMV_IMAGE_SQUARE_L_1200x1200)} />
      <img src={getImageUrl(imageId, IMAGE_FORMATS.GMV_IMAGE_SQUARE_L_1200x1200)} alt={alt} {...rest} />
    </picture>
  );
}

import React from 'react';
import i18n from '../../../i18n/helper';
import AnswerPolls from './svg/answerPolls-1';
import EarnPoints from './svg/earnPoints-2';
import HelpSocialInitiatives from './svg/helpSocialInitiatives-3';
import BecomeHero from './svg/becomeHero-4';

const CHARITY_EXPLAINER_ITEMS = [
  {
    figure: <AnswerPolls />,
    title: <i18n.Translate value='gamification.intro.step1.title' />,
    description: <i18n.Translate value='gamification.intro.step1.body' />,
  },
  {
    figure: <EarnPoints />,
    title: <i18n.Translate value='gamification.intro.step2.title' />,
    description: <i18n.Translate value='gamification.intro.step2.body' />,
  },
  {
    figure: <HelpSocialInitiatives />,
    title: <i18n.Translate value='gamification.intro.step3.title' />,
    description: <i18n.Translate value='gamification.intro.step3.body' />,
  },
  {
    figure: <BecomeHero />,
    title: <i18n.Translate value='gamification.intro.step4.title' />,
    description: <i18n.Translate value='gamification.intro.step4.body' />,
  },
];

export default CHARITY_EXPLAINER_ITEMS;

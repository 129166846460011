import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { isAbsoluteUrl, prepareRelativeUrl } from './LinkHelper';

export const LINK_TESTID = 'link';
export const ANCHOR_TESTID = 'anchor';

export default function EngageLink(props) {
  const { url = '', children, className, openInNewWindow, onClick, isProjectUrl = true } = props;

  return (
    <React.Fragment>
      {isAbsoluteUrl(url) ? (
        // eslint-disable-next-line
        <a
          href={url}
          onClick={onClick}
          target={openInNewWindow ? '_blank' : undefined}
          className={className}
          rel={openInNewWindow ? 'noopener noreferrer' : ''}
          data-testid={ANCHOR_TESTID}
        >
          {children}
        </a>
      ) : (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          to={prepareRelativeUrl(url, isProjectUrl)}
          onClick={onClick}
          target={openInNewWindow ? '_blank' : undefined}
          className={className}
          rel={openInNewWindow ? 'noopener noreferrer' : ''}
          data-testid={LINK_TESTID}
        >
          {children}
        </Link>
      )}
    </React.Fragment>
  );
}

EngageLink.propTypes = {
  children: PropTypes.array,
  url: PropTypes.string,
  className: PropTypes.string,
  openInNewWindow: PropTypes.bool,
  onClick: PropTypes.func,
  isProjectUrl: PropTypes.bool,
};

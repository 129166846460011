export const showTooltip = (tooltipText, x, y) => (dispatch) =>
  dispatch({
    type: 'SHOW_TOOLTIP',
    tooltipText,
    x,
    y,
  });

export const hideTooltip = () => (dispatch) =>
  dispatch({
    type: 'HIDE_TOOLTIP',
  });

import React from 'react';
import PropTypes from 'prop-types';
import Slugify from 'react-slugify';
import { getDateTimeString } from 'utils/utils';
import Link from '../link/Link';
import { Img, WIDE } from '../images/Img';
import MarkdownField, { MARKDOWN_MIN_LINK } from '../markdown/MarkdownField';
import i18n from '../../i18n/helper';
import PillButton from '../button/PillButton';

export default function NewsWidget(props) {
  const { news, date } = props;
  const { resource, actionUrl, title, description, actionName, newsContent, _id } = news;
  const actionNameTrimmed = actionName ? actionName.trim() : null;
  const actionUrlTrimmed = actionUrl ? actionUrl.trim() : null;
  const newsUrl = `news/${Slugify(title)}/${_id}`;

  return (
    <article className={`c-card-news ${resource ? 'c-card-news--figure' : ''}`}>
      {resource && (
        <figure className='c-card-news__figure'>
          <Img imageType={WIDE} imageId={resource} alt='' />
        </figure>
      )}
      <div className='c-card-news__content'>
        {/* eslint-disable-next-line */}
        <h2 className='c-card-news__title'>{newsContent ? <Link url={newsUrl}>{title}</Link> : title}</h2>
        {date && <p className='c-card-news__meta'>{getDateTimeString(date)}</p>}
        <div className='c-card-news__summary u-rich-text'>
          <p>
            <MarkdownField markdown={description} markdownConfig={MARKDOWN_MIN_LINK} />
          </p>
        </div>
        {newsContent && (
          <div className='c-card-news__more'>
            <PillButton classModifier='c-button--small' url={newsUrl} title={i18n.t('news.read_more')} />
          </div>
        )}
        {actionNameTrimmed && actionUrlTrimmed && !newsContent && (
          <div className='c-card-news__action'>
            <PillButton
              classModifier='c-button--small c-button--alternate'
              url={actionUrlTrimmed}
              title={actionNameTrimmed}
            />
          </div>
        )}
      </div>
    </article>
  );
}

NewsWidget.propTypes = {
  news: PropTypes.object.isRequired,
  date: PropTypes.string,
};

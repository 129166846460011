import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../../SvgIcon';
import DemographicIntroIllustration from '../../../assets-v2/images/illustration/demographic-intro.png';
import i18n from '../../../i18n/helper';

export default function DemographicIntro(props) {
  const { goToNextQuestion, hideButton } = props;
  return (
    <div className='c-demographic__group c-demographic__group--intro'>
      <figure className='c-demographic__media'>
        <img src={DemographicIntroIllustration} alt='' />
      </figure>

      <div className='c-demographic__info u-rich-text'>
        <p>{i18n.t('demographic.intro.info')}</p>
      </div>

      <div className='c-demographic__start'>
        <small>
          {i18n.t('demographic.intro.start')}
          <SvgIcon icon='arrow-down' />
        </small>
        <button
          onClick={goToNextQuestion}
          className='c-button c-button--large c-button--alternate'
          style={{ opacity: hideButton ? 0 : 1 }}
        >
          <span className='c-button__label'>{i18n.t('demographic.intro.answer')}</span>
        </button>
      </div>
    </div>
  );
}

DemographicIntro.propTypes = {
  goToNextQuestion: PropTypes.func.isRequired,
  hideButton: PropTypes.bool.isRequired,
};

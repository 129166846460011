import * as news from '../../services/news/news';

export const clearNewsData = (data) => ({
  type: 'CLEAR_NEWS',
  data,
});
export const getNews = (slug_id, sorting) => (dispatch, getState) => {
  const page = getState().news.page + 1;
  return news
    .getUpdatesFromServer(slug_id, sorting, page)
    .then((res) => {
      dispatch({
        type: 'SUCCESS_ADD_NEWS',
        newsData: res,
        page,
      });
    })
    .catch((res) => {
      dispatch({
        type: 'FAIL_ADD_NEWS',
        failState: res,
      });
    });
};

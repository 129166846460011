import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { feedbackEmailZip as feedbackEmailZipAction, getFeedbakOptions } from '../../actions';

import i18n from '../../i18n/helper';
import SvgIcon from '../SvgIcon';
// import Coin from '../coin/coin';
import {
  TERMS_AND_CONDITIONS_LINK,
  PRIVACY_POLICY_LINK,
  MAX_POSTCODE_LENGTH,
  MAX_FEEDBACK_CHARS,
  CHAR_LIMIT_WARNING,
  MAX_FEEDBACK_SUBJECT_CHARS,
} from '../../constants';
import TextInputWithIconAndEditButton from '../basicComponents/inputs/TextInputWithIconAndEditButton';
import SlimUploader from '../fileUpload/SlimUploader';
import { IMAGE_FORMATS } from '../images/Formats';
import { isEmailValid } from '../../utils/utils';

const FeedbackForm = forwardRef((props, ref) => {
  const {
    slugId,
    feedbackEmailZip,
    userData,
    disableForm,
    onSubmitFeedback,
    actions,
    // showCoin,
    isGeneralFeedback = false,
    setIsSubmitDisabled = () => {},
    allowImageUpload = false,
    isPostCodeOptional = false,
    isEmailOptional = false,
    collectEmailOnly = false,
    customizations,
  } = props;

  const [feedback, setFeedback] = useState(() => (collectEmailOnly ? 'n/a' : ''));
  const [feedbackSubject, setFeedbackSubject] = useState('');
  const [email, setEmail] = useState('');
  const [isEmailDisabled, setIsEmailDisabled] = useState(false);
  const [postCode, setPostCode] = useState('');
  const [isPostCodeDisabled, setIsPostCodeDisabled] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [image, setImage] = useState({});

  const isFeedbackSubjectRequired = customizations?.isFeedbackSubjectRequired || false;

  useEffect(() => {
    actions.getFeedbakOptions(slugId);
  }, [actions, slugId]);

  useEffect(() => {
    if (feedbackEmailZip.email || userData.email) {
      setEmail(feedbackEmailZip.email || userData.email);
      setIsEmailDisabled(true);
    }

    if (feedbackEmailZip.postCode || userData.postCode) {
      setPostCode(feedbackEmailZip.postCode || userData.postCode);
      setIsPostCodeDisabled(true);
    }

    if (feedbackEmailZip.email || userData.email) {
      setAcceptTerms(true);
    }
  }, [userData, feedbackEmailZip]);

  const submitFeedback = () => {
    // update global state before submitting
    actions.feedbackEmailZip({
      email,
      postCode,
    });

    onSubmitFeedback({
      feedbackSubject,
      feedback,
      email,
      postalCode: postCode,
      acceptTerms,
      image,
    });
    setImage({});
    setFeedbackSubject('');
    setFeedback('');
  };

  const charLeft = (max, currentValue) => max - currentValue;

  // Sax - refactored to /utils/utils.js
  // const isEmailValid = (emailText) => {
  //   if (!emailText) {
  //     return false;
  //   }
  //
  //   const emailRegex = /^[\w._-]+[+]?([\w._-]+)?@[\w.-]+\.[a-zA-Z]{1,6}$/;
  //   return emailRegex.test(emailText);
  // };

  const isSubmitDisabled = () => {
    const checkFeedbackSubject = isGeneralFeedback && isFeedbackSubjectRequired;
    const isFeedbackSubjectValid = checkFeedbackSubject ? feedbackSubject : true;

    const isSubmitButtonDisabled =
      disableForm ||
      !isFeedbackSubjectValid ||
      !(
        feedback &&
        (acceptTerms || (isEmailOptional && !collectEmailOnly)) &&
        (postCode || isPostCodeOptional) &&
        ((isEmailValid(email) && email) || (isEmailOptional && !collectEmailOnly))
      );
    setIsSubmitDisabled(isSubmitButtonDisabled);
    return (
      // disabled because submitting
      isSubmitButtonDisabled
    );
  };

  useImperativeHandle(ref, () => ({ submitFeedback, isSubmitDisabled: isSubmitDisabled() }));

  /**
   * Custom strings hotfix for LendLease projects
   * 6023f4a08f352a0004d476b4
   * 602528ffba2ffe00045aa2f3
   */
  const isLendleaseProject = () => {
    const arrayOfIds = [
      '6023f4a08f352a0004d476b4',
      '602528ffba2ffe00045aa2f3',
      '5f1aa18b767884000418e15d',
      '6022903a696d0f00046eb399',
      '6008352dfa358500040740cf',
    ];
    return arrayOfIds.includes(slugId);
  };

  const LendLeaseCheckboxLabel = () => (
    <React.Fragment>
      I agree to{' '}
      <a className='c-link' href={TERMS_AND_CONDITIONS_LINK} target='_blank' rel='noopener noreferrer'>
        Give My View&apos;s
      </a>{' '}
      & Lendlease&apos;s{' '}
      <a className='c-link' href='https://www.highroadwest.london/privacy/' target='_blank' rel='noopener noreferrer'>
        Privacy Policy{' '}
      </a>{' '}
      and{' '}
      <a
        className='c-link'
        href='https://www.highroadwest.london/terms-of-use/'
        target='_blank'
        rel='noopener noreferrer'
      >
        {' '}
        Terms and Conditions{' '}
      </a>
    </React.Fragment>
  );

  const LendLeaseCheckboxLink = () => 'How we will use your data?';

  const LendLeaseAcceptCheckBoxDescription = () =>
    'Lendlease companies and our agents will only contact you regarding your feedback and in accordance with our privacy policy and terms and conditions. We will not share your data with anyone else.';

  return (
    <React.Fragment>
      <div className={`l-form c-fields u-a-children-fade-in ${disableForm ? 'is-disabled' : ''}`}>
        {isGeneralFeedback ? (
          <div className='c-field l-form__item--l'>
            <label className='c-field__label' htmlFor='feedbackSubject'>
              {i18n.t('questions.feedbackForm.subjectLabel')}
            </label>
            <div className='c-field__group'>
              <div className='o-input'>
                <input
                  type='text'
                  placeholder={i18n.t('questions.feedbackForm.subjectLabel')}
                  id='feedbackSubject'
                  value={feedbackSubject}
                  onChange={(e) => {
                    setFeedbackSubject(e.target.value);
                  }}
                  maxLength='100'
                />
              </div>
              <span
                className={`c-field__count ${
                  charLeft(MAX_FEEDBACK_SUBJECT_CHARS, feedbackSubject.length) <= CHAR_LIMIT_WARNING
                    ? 'c-field__count--limit'
                    : ''
                }`}
                title='Characters left'
              >
                {charLeft(MAX_FEEDBACK_SUBJECT_CHARS, feedbackSubject.length)}
              </span>
            </div>
          </div>
        ) : null}

        {!collectEmailOnly ? (
          <div className='c-field l-form__item'>
            <label className='c-field__label' htmlFor='feedback'>
              {i18n.t('questions.feedbackForm.textareaLabel')}
            </label>
            <div className='c-field__group'>
              <div className='o-textarea'>
                <textarea
                  value={feedback}
                  placeholder={i18n.t('questions.feedbackForm.textareaPlaceholder')}
                  id='feedback'
                  name='feedback'
                  onChange={(e) => {
                    setFeedback(e.target.value);
                  }}
                  disabled={disableForm}
                  maxLength={MAX_FEEDBACK_CHARS}
                />
              </div>
              <span
                className={`c-field__count ${
                  charLeft(MAX_FEEDBACK_CHARS, feedback.length) <= CHAR_LIMIT_WARNING ? 'c-field__count--limit' : ''
                }`}
                title='Characters left'
              >
                {charLeft(MAX_FEEDBACK_CHARS, feedback.length)}
              </span>
            </div>
          </div>
        ) : null}

        {!isEmailOptional || collectEmailOnly ? (
          <div
            className={`c-field l-form__item u-force-ltr ${
              isPostCodeOptional || collectEmailOnly ? '' : 'l-form__item--m'
            }`}
          >
            <label className='c-field__label' htmlFor='e-mail'>
              {i18n.t('questions.feedbackForm.emailLabel')} *
            </label>
            <div className='c-field__group'>
              <div className={`o-input o-input--icon ${isEmailDisabled ? 'o-input--edit' : ''}`}>
                <TextInputWithIconAndEditButton
                  value={email}
                  placeholder={i18n.t('questions.feedbackForm.emailPlaceholder')}
                  id='e-mail'
                  name='email'
                  onChange={(e) => {
                    setEmail(e.target.value.replace(/\s+/g, ''));
                  }}
                  disabled={disableForm || isEmailDisabled}
                  icon='envelope'
                  onClick={() => {
                    setIsEmailDisabled(false);
                    setAcceptTerms(false);
                  }}
                  buttonHide={isEmailDisabled}
                  buttonLabel={i18n.t('questions.feedbackForm.edit')}
                />
              </div>
            </div>
          </div>
        ) : null}

        {!isPostCodeOptional && !collectEmailOnly ? (
          <div className={`c-field l-form__item u-force-ltr ${!isEmailOptional ? 'l-form__item--m' : ''}`}>
            <label className='c-field__label' htmlFor='post-code'>
              {i18n.t('questions.feedbackForm.postcodeLabel')} *
            </label>
            <div className='c-field__group'>
              <div className={`o-input o-input--icon ${isPostCodeDisabled ? 'o-input--edit' : ''}`}>
                <TextInputWithIconAndEditButton
                  value={postCode}
                  placeholder={i18n.t('questions.feedbackForm.postcodePlaceholder')}
                  id='post-code'
                  name='postalCode'
                  onChange={(e) => {
                    setPostCode(e.target.value);
                  }}
                  disabled={isPostCodeDisabled || disableForm}
                  icon='map-pin'
                  onClick={() => {
                    setIsPostCodeDisabled(false);
                    setAcceptTerms(false);
                  }}
                  buttonHide={isPostCodeDisabled}
                  buttonLabel={i18n.t('questions.feedbackForm.edit')}
                  maxLength={MAX_POSTCODE_LENGTH}
                />
              </div>
              <span
                className={`c-field__count ${
                  charLeft(MAX_POSTCODE_LENGTH, postCode.length) <= CHAR_LIMIT_WARNING ? 'c-field__count--limit' : ''
                }`}
                title='Characters left'
              >
                {charLeft(MAX_POSTCODE_LENGTH, postCode.length)}
              </span>
            </div>
          </div>
        ) : null}

        {allowImageUpload ? (
          <div className='c-field'>
            {/* eslint-disable-next-line */}
            <label className='c-field__label' htmlFor='question-upload'>
              {i18n.t('questions.feedbackForm.uploadImage')}
            </label>
            <div className='c-field__group'>
              <SlimUploader
                imageFormat={{
                  ratio: 'free',
                  size: { width: 800, height: 800 },
                  imageType: IMAGE_FORMATS.GMV_IMAGE_ORIGINAL,
                }}
                id='question-upload'
                setImage={setImage}
                image={image}
              />
            </div>
          </div>
        ) : null}

        {!isEmailOptional || collectEmailOnly ? (
          <div className='c-field l-form__item'>
            <div className='c-field__group'>
              <div className='o-checkbox'>
                <input
                  onChange={() => {
                    setAcceptTerms(!acceptTerms);
                  }}
                  type='checkbox'
                  id='checkbox-terms'
                  name='checkbox-terms'
                  checked={acceptTerms}
                  disabled={disableForm}
                />
                <label htmlFor='checkbox-terms'>
                  {isLendleaseProject()
                    ? LendLeaseCheckboxLabel()
                    : i18n.t('questions.feedbackForm.acceptCheckboxLabel')}
                  <SvgIcon icon='check' />
                </label>
              </div>
              <details className='c-field__details' open=''>
                <summary>
                  {isLendleaseProject() ? LendLeaseCheckboxLink() : i18n.t('questions.feedbackForm.acceptCheckboxLink')}
                  &nbsp;
                  <SvgIcon icon='angle-down' />
                </summary>
                <p>
                  {isLendleaseProject() ? (
                    LendLeaseAcceptCheckBoxDescription()
                  ) : (
                    <React.Fragment>
                      {i18n.t('questions.feedbackForm.acceptCheckboxDescription')}&nbsp;
                      <a className='c-link' href={TERMS_AND_CONDITIONS_LINK} target='_blank' rel='noopener noreferrer'>
                        {i18n.t('dashboard.settings.terms')}
                      </a>{' '}
                      {i18n.t('dashboard.settings.and')}&nbsp;
                      <a className='c-link' href={PRIVACY_POLICY_LINK} target='_blank' rel='noopener noreferrer'>
                        {i18n.t('dashboard.settings.policy')}
                      </a>
                    </React.Fragment>
                  )}
                </p>
              </details>
            </div>
          </div>
        ) : null}

        {isGeneralFeedback && (
          <div className='c-field l-form__item c-field--action'>
            <div className='c-field__group'>
              <button
                className='c-button c-button--primary c-button--large c-button--block'
                disabled={isSubmitDisabled()}
                onClick={submitFeedback}
              >
                <span className='c-button__label'>{i18n.t('pollsScreen.sendFeedback')}</span>
              </button>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
});

FeedbackForm.propTypes = {
  slugId: PropTypes.string.isRequired,
  onSubmitFeedback: PropTypes.func.isRequired,
  disableForm: PropTypes.bool.isRequired,
  isGeneralFeedback: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  feedbackEmailZip: state.polls.feedbackEmailZip,
  userData: state.feedback.userData,
  customizations: state.customizations,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ feedbackEmailZip: feedbackEmailZipAction, getFeedbakOptions }, dispatch),
});

// export default FeedbackForm;
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(FeedbackForm);

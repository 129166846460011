import { Img, REACTION } from 'components/images/Img';
import React, { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
// import { getReactionImageUrl, IMAGE_FORMATS } from 'components/images/Formats';
import { DRAG_FORMAT, ANNOTATE_STATES } from '../utils';
import { AnnotatePinReactionProps } from './types';

export function AnnotatePinReaction({
  choice,
  firstInteractionOccured,
  setAnnotateEdit,
  isDragAllowed,
  firstReactionDropped,
}: AnnotatePinReactionProps): JSX.Element {
  const { resource, choice: label, _id } = choice;

  const [, drag, preview] = useDrag(
    () => ({
      type: DRAG_FORMAT,
      item: () => {
        if (!firstInteractionOccured) {
          setAnnotateEdit(ANNOTATE_STATES.FIRST_INTERACTION);
        } else if (firstInteractionOccured) {
          setAnnotateEdit(ANNOTATE_STATES.INTERACTION);
        }
        return { resource, choiceId: _id, label };
      },
      end: (_item, monitor) => {
        if (!monitor.didDrop()) {
          // return to default state if reaction is not dropped in required area (so that skip is still possible)
          if (!firstReactionDropped) {
            setAnnotateEdit(ANNOTATE_STATES.INTERACTIVE);
          } else {
            setAnnotateEdit('');
          }
        }
      },
      canDrag: () => isDragAllowed,
    }),
    [firstInteractionOccured, isDragAllowed],
  );

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  return (
    <React.Fragment>
      <div
        className='c-choice-icon'
        ref={drag}
        style={{
          touchAction: 'none',
          userSelect: 'none',
          WebkitUserSelect: 'none',
          MozUserSelect: 'none',
          MozUserFocus: 'none',
          WebkitTouchCallout: 'none',
        }}
      >
        <input type='radio' id={_id} name='annotate-reactions' tabIndex={0} checked={false} onChange={() => {}} />
        <label htmlFor={_id}>
          <span className='c-choice-icon__media'>
            <Img
              imageId={resource}
              imageType={REACTION}
              alt=''
              draggable={false}
              onContextMenu={(e: React.MouseEvent) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onSelect={(e: React.MouseEvent) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </span>
          <span className='c-choice-icon__title'>{label}</span>
        </label>
      </div>
    </React.Fragment>
  );
}

import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import PropTypes from 'prop-types';

import { getQueries, scrollWithOffset } from './LinkHelper';

export default function HashLink(props) {
  const { url = '', hash = '', children, className } = props;

  return (
    <Link
      smooth
      scroll={(el) => scrollWithOffset(el)}
      to={{ pathname: url, hash, search: getQueries() }}
      className={className}
    >
      {children}
    </Link>
  );
}

HashLink.propTypes = {
  children: PropTypes.node,
  url: PropTypes.string,
  hash: PropTypes.string,
  className: PropTypes.string,
};

import * as customizations from '../../services/project/customizations';

export const getCustomizations = (slug_id) => (dispatch) =>
  customizations
    .getCustomizations(slug_id)
    .then((response) => {
      dispatch({
        type: 'SUCCESS_GET_CUSTOMIZATIONS',
        data: response.data,
      });
      return true;
    })
    .catch((response) => {
      dispatch({
        type: 'FAIL_GET_CUSTOMIZATIONS',
        data: response,
      });
      return false;
    });

import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

/**
 * Example string template
 * You have earned [ICON] %{points} points!
 * i18n replacement vars are replaced in parent
 * ICON placeholder places SVG icon within the text
 * @param props (stringId is a key to i18n lookup)
 * @returns {*}
 * @constructor
 */
function LabelWithIcon(props) {
  const { label, icon } = props;
  const parts = label.split('[ICON]');
  return (
    <React.Fragment>
      {parts[0]}
      {icon && <SvgIcon icon={icon} />}
      {parts[1] || ''}
    </React.Fragment>
  );
}

LabelWithIcon.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

export default LabelWithIcon;

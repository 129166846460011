import i18n from 'i18n/helper';
import { AnnotateModalFinishProps } from './types';

export function AnnotateModalFinish({
  onClickFinishConfirm,
  onClickAddMore,
  isActive,
  reactionsLeftToAdd,
}: AnnotateModalFinishProps): JSX.Element {
  return (
    <div className={`c-pseudo-modal c-pseudo-modal--finish-adding-annotations ${isActive ? 'is-active' : ''}`}>
      <header className='c-pseudo-modal__header'>
        <h3 className='c-pseudo-modal__title'>{i18n.t('questions.annotate.finishWarning')}</h3>
      </header>
      <div className='c-pseudo-modal__content'>
        <p>{i18n.t('questions.annotate.finishReminder', { nrOfAnnotations: reactionsLeftToAdd })}</p>
      </div>
      <footer className='c-pseudo-modal__footer'>
        <div className='c-pseudo-modal__action-item c-pseudo-modal__action-item__add-more'>
          <button className='c-button c-button--pill c-button--alternate' onClick={onClickAddMore}>
            <span className='c-button__label'>{i18n.t('questions.annotate.addMore')}</span>
          </button>
        </div>
        <div className='c-pseudo-modal__action-item c-pseudo-modal__action-item__confirm-finish'>
          <button
            className='c-button c-button--primary c-button--pill c-annotate-actions__finish'
            onClick={onClickFinishConfirm}
          >
            <span className='c-button__label'>{i18n.t('questions.annotate.finish')}</span>
          </button>
        </div>
      </footer>
    </div>
  );
}

import { createUseGesture, dragAction, pinchAction, wheelAction, FullGestureState } from '@use-gesture/react';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import clamp from 'lodash.clamp';
import { getNormalizedClient, getZoomModifier, getDoubleTapZoomModifier } from './utils';

export type Transform = {
  x: number;
  y: number;
  scale: number;
};

const useGesture = createUseGesture([dragAction, pinchAction, wheelAction]);

export const usePanZoom = (
  minScale = 0.25,
  maxScale = 1,
): {
  dragRef: RefObject<HTMLDivElement>;
  state: Transform;
  onLoad: () => void;
  onZoom: (isZoomIn: boolean, clientX: number, clientY: number) => void;
  setEnableDragging: (enable: boolean) => void;
} => {
  const [state, setState] = useState<Transform>({ x: 0, y: 0, scale: 1 });
  const [enable, setEnableDragging] = useState<boolean>(true);
  const animationFrameRef = useRef(0);
  const dragRef = useRef<HTMLDivElement | null>(null);

  const updateTransform = useCallback(
    (transform: Transform) => {
      if (dragRef.current) {
        dragRef.current.style.transform = `translate(${transform.x}px, ${transform.y}px) scale(${transform.scale})`;
      }
      animationFrameRef.current = 0;
    },
    [dragRef, animationFrameRef],
  );

  useEffect(() => {
    updateTransform(state);
  }, [state, updateTransform]);

  const getTranslateAfterScale = useCallback(
    (clientX: number, clientY: number, newScale: number) => {
      const clientInContainer = getNormalizedClient(clientX, clientY, dragRef.current?.parentElement);
      const x = clientInContainer.x - ((clientInContainer.x - state.x) * newScale) / state.scale;
      const y = clientInContainer.y - ((clientInContainer.y - state.y) * newScale) / state.scale;
      return { x, y };
    },
    [state],
  );

  const onZoom = useCallback(
    (isZoomIn: boolean, clientX: number, clientY: number) => {
      const zoomModifier = getDoubleTapZoomModifier(isZoomIn);
      const newScale = clamp(state.scale + zoomModifier, minScale, maxScale);
      const newTranslate = getTranslateAfterScale(clientX, clientY, newScale);
      setState({ x: newTranslate.x, y: newTranslate.y, scale: newScale });
    },
    [maxScale, minScale, state, getTranslateAfterScale],
  );

  const onDrag = useCallback(
    // eslint-disable-next-line consistent-return
    ({ movement: [x, y], pinching, cancel }: FullGestureState<'drag'>) => {
      if (pinching || !enable) return cancel();
      if (!animationFrameRef.current) {
        // event.preventDefault();
        const newX = state.x + x;
        const newY = state.y + y;
        animationFrameRef.current = window.requestAnimationFrame(() =>
          updateTransform({ x: newX, y: newY, scale: state.scale }),
        );
      }
    },
    [state, updateTransform, enable],
  );

  const onDragEnd = useCallback(
    // eslint-disable-next-line consistent-return
    ({ pinching, cancel, movement: [x, y] }: FullGestureState<'drag'>) => {
      if (pinching || !enable) return cancel();
      setState((prevState) => {
        const newX = prevState.x + x;
        const newY = prevState.y + y;
        return { x: newX, y: newY, scale: prevState.scale };
      });
      if (animationFrameRef.current) {
        window.cancelAnimationFrame(animationFrameRef.current);
        animationFrameRef.current = 0;
      }
    },
    [enable],
  );

  const onPinch = useCallback(
    ({ offset: [d], origin: [clientX, clientY], lastOffset: [dLast] }: FullGestureState<'pinch'>) => {
      if (!animationFrameRef.current) {
        animationFrameRef.current = window.requestAnimationFrame(() => {
          const newScale = clamp(state.scale + (d - dLast) / 5, minScale, maxScale);
          const newTranslate = getTranslateAfterScale(clientX, clientY, newScale);
          updateTransform({ x: newTranslate.x, y: newTranslate.y, scale: newScale });
        });
      }
    },
    [updateTransform, minScale, maxScale, getTranslateAfterScale, state],
  );

  const onPinchEnd = useCallback(
    ({ offset: [d], origin: [clientX, clientY], lastOffset: [dLast] }: FullGestureState<'pinch'>) => {
      const newScale = clamp(state.scale + (d - dLast) / 5, minScale, maxScale);
      const newTranslate = getTranslateAfterScale(clientX, clientY, newScale);
      setState({ x: newTranslate.x, y: newTranslate.y, scale: newScale });
      if (animationFrameRef.current) {
        window.cancelAnimationFrame(animationFrameRef.current);
        animationFrameRef.current = 0;
      }
    },
    [maxScale, minScale, getTranslateAfterScale, state],
  );

  const onWheel = useCallback(
    ({ delta: [, delta], active, event }: FullGestureState<'wheel'>) => {
      if (delta !== 0 && !animationFrameRef.current && active) {
        const zoomModifier = getZoomModifier(delta < 0);
        const newScale = clamp(state.scale + zoomModifier, minScale, maxScale);
        const newTranslate = getTranslateAfterScale(event.clientX, event.clientY, newScale);
        animationFrameRef.current = window.requestAnimationFrame(() => {
          setState({ x: newTranslate.x, y: newTranslate.y, scale: newScale });
        });
      }
    },
    [getTranslateAfterScale, maxScale, minScale, state.scale],
  );

  const onLoad = useCallback(() => {
    if (dragRef.current && dragRef.current.parentElement) {
      const parentRect = dragRef.current.parentElement?.getBoundingClientRect();
      const dragRect = dragRef.current.getBoundingClientRect();
      const { width: dragWidth, height: dragHeight } = dragRect;
      const { width: parentWidth, height: parentHeight } = parentRect;
      const newScale = clamp(Math.round((parentHeight / dragHeight) * state.scale * 100) / 100, minScale, maxScale);
      const newX = ((-dragWidth * newScale) / state.scale + parentWidth) / 2;
      const newY = ((-dragHeight * newScale) / state.scale + parentHeight) / 2;
      setState({ x: newX, y: newY, scale: newScale });
    }
  }, [state, minScale, maxScale]);

  useGesture(
    {
      onDrag,
      onDragEnd,
      onPinch,
      onPinchEnd,
      onWheel,
    },
    {
      target: dragRef,
      drag: { filterTaps: true, tapsThreshold: 8 },
      pinch: { scaleBounds: { min: 0.04, max: 5.2 } },
    },
  );

  return {
    dragRef,
    state,
    onLoad,
    onZoom,
    setEnableDragging,
  };
};

import React from 'react';
// import { ReactComponent as GMVSymbol } from '../../assets-v2/images/gmv-symbol.svg';
import { ReactComponent as GMVSymbol } from '../../assets-v2/images/gmv-logo--white--normal.svg';

export default function Header() {
  return (
    <header className='l-m-header c-m-header' role='banner'>
      <div className='l-container'>
        <h1 className='c-m-logo'>
          <a className='c-m-logo__link' href='/'>
            <GMVSymbol />
            Give My View
          </a>
        </h1>

        <nav className='c-m-nav-header'>
          <ul className='c-m-nav-header__items'>
            <li className='c-m-nav-header__item'>
              <a className='c-m-nav-header__link' href='https://www.built-id.com/contact'>
                Contact us
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

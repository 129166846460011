import { useEffect, useState } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { Img, REACTION } from 'components/images/Img';
import { REDRAG_FORMAT } from '../utils';
import { AnnotateAreaPinProps } from './types';

export const AnnotateAreaPin = ({
  scale,
  reaction,
  isDragAllowed,
  setEnableDragging,
}: AnnotateAreaPinProps): JSX.Element => {
  const [hideReacion, setHideReaction] = useState(false);
  const [, drag, preview] = useDrag(
    () => ({
      type: REDRAG_FORMAT,
      item: () => {
        setEnableDragging(false);
        setHideReaction(true);
        return { resource: reaction.resource, choiceId: reaction.choiceId, label: reaction.label };
      },
      end: (_item, monitor) => {
        setEnableDragging(true);
        if (!monitor.didDrop()) {
          setHideReaction(false);
        }
      },
      canDrag: () => isDragAllowed,
    }),
    [reaction],
  );

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  return (
    <div
      className='c-annotate-image__pin'
      style={{
        left: `${reaction.left}%`,
        top: `${reaction.top}%`,
        transform: `scale(${1 / scale})`,
        pointerEvents: `${isDragAllowed ? 'all' : 'none'}`,
        opacity: `${hideReacion ? '0' : '100'}`,
        zIndex: 50,
        touchAction: 'none',
        userSelect: 'none',
        MozUserFocus: 'none',
        WebkitUserSelect: 'none',
        WebkitTouchCallout: 'none',
      }}
      ref={drag}
    >
      <Img
        imageId={reaction.resource}
        imageType={REACTION}
        alt={reaction.label}
        draggable={false}
        onContextMenu={(e: React.MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onSelect={(e: React.MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      />
    </div>
  );
};

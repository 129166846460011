import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../../SvgIcon';

export default function DemographicProgress(props) {
  const { circleOffset } = props;

  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    setIsUpdate(true);
    setTimeout(() => setIsUpdate(false), 1000);
  }, [circleOffset]);
  return (
    <div
      className={`c-demographic__progress ${isUpdate ? 'is-updated' : ''} ${circleOffset <= 0 ? 'is-completed' : ''}`}
    >
      <SvgIcon icon='check' />

      <svg viewBox='0 0 32 32' className='c-demographic__progress-icon'>
        <circle
          className='c-demographic__progress-background'
          cx='16'
          cy='16'
          r='13'
          style={{ strokeDasharray: 81.64, strokeDashoffset: 0 }}
        />
        <circle
          className='c-demographic__progress-circle'
          cx='16'
          cy='16'
          r='13'
          style={{ strokeDasharray: 81.64, strokeDashoffset: circleOffset }}
        />
      </svg>
    </div>
  );
}

DemographicProgress.propTypes = {
  circleOffset: PropTypes.number,
};

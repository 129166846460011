import React from 'react';

const earnPointsContent = `  <g id="explainer-step-2">
    <g id="scene" fill-rule="nonzero">
      <path id="scene__background" fill="#eff4fa" d="M161.818 95.609c-.003-29.489-17.839-56.013-45.057-67.001-27.217-10.988-58.345-4.233-78.63 17.065-20.286 21.298-25.653 52.857-13.557 79.721h130.852c4.222-9.355 6.401-19.512 6.392-29.785z"></path>
      <path id="scene__horizon" fill="#4f4a45" d="M160.023 124.494a.9.9 0 010 1.8H19.977a.9.9 0 010-1.8h140.046zm-143.637 0a.9.9 0 010 1.8h-4.488a.9.9 0 010-1.8h4.488zm151.716 0a.9.9 0 010 1.8h-4.488a.9.9 0 010-1.8h4.488z"></path>
    </g>
    <g id="person">
      <ellipse id="person__shadow" cx="90" cy="156.588" fill="#070048" fill-opacity=".15" rx="26.842" ry="3.123"></ellipse>
      <path id="person__hair-back" fill="#724f3d" fill-rule="nonzero" d="M103.34 84.886l-.772-20.309c-.149.017-.3.017-.449 0-1.214 5.765-6.276 9.889-12.137 9.889-5.861 0-10.923-4.124-12.137-9.889-.149.017-.3.017-.449 0l-.754 20.309c-.02.563.189 1.11.579 1.515.389.405.925.633 1.486.633h22.568c.561 0 1.097-.228 1.486-.633.39-.405.599-.952.579-1.515z"></path>
      <path id="person__hair-back1" fill="#191816" fill-rule="nonzero" d="M104.24 84.852l-.772-20.309c-.02-.523-.481-.919-1.002-.86a1.115 1.115 0 01-.245 0c-.461-.052-.887.255-.982.709-1.127 5.349-5.822 9.174-11.257 9.174s-10.13-3.825-11.257-9.174c-.095-.454-.521-.761-.982-.709a1.115 1.115 0 01-.245 0c-.521-.059-.982.337-1.001.861l-.755 20.308c-.028.809.272 1.592.83 2.172.559.582 1.33.911 2.135.91h22.568a2.956 2.956 0 002.135-.91c.558-.58.858-1.363.83-2.169v-.003zm-26.462-20.26l-.393.082-.091.798c.217.024.436.024.653 0l-.1-.878-.069-.002zm12.204 10.774c5.07 0 9.581-2.875 11.821-7.229l.638 16.783c.011.317-.108.627-.328.857-.22.229-.522.357-.837.357H78.707c-.316 0-.618-.128-.838-.357-.22-.23-.339-.54-.328-.86l.623-16.775c2.241 4.351 6.75 7.224 11.818 7.224zm12.206-10.774l-.071.003-.1.877c.217.024.436.024.653 0l-.091-.798-.391-.082z"></path>
      <path id="person__face" fill="#d18660" fill-rule="nonzero" d="M104.453 62.122c-.012-1.218-.875-2.259-2.064-2.491V57.97c0-6.879-5.547-12.455-12.389-12.455S77.611 51.091 77.611 57.97v1.661c-1.242.237-2.12 1.359-2.057 2.629.064 1.269 1.049 2.297 2.309 2.408 1.214 5.765 6.276 9.889 12.137 9.889 5.861 0 10.923-4.124 12.137-9.889 1.31-.121 2.313-1.224 2.316-2.546z"></path>
      <path id="person__face-outline" fill="#191816" fill-rule="nonzero" d="M103.289 57.97c0-7.375-5.949-13.355-13.289-13.355s-13.289 5.98-13.289 13.355v1.003c-1.285.562-2.128 1.875-2.056 3.331.075 1.488 1.085 2.731 2.464 3.135C78.631 71.311 83.913 75.457 90 75.457c6.088 0 11.37-4.147 12.881-10.02 1.438-.425 2.468-1.757 2.472-3.312v-.012c-.013-1.384-.842-2.601-2.064-3.138V57.97zm-1.235 5.801a.9.9 0 00-.797.711c-1.127 5.349-5.822 9.175-11.257 9.175s-10.13-3.826-11.257-9.175a.899.899 0 00-.802-.711c-.811-.071-1.447-.735-1.488-1.556-.041-.822.527-1.547 1.327-1.7a.9.9 0 00.731-.884V57.97c0-6.383 5.145-11.555 11.489-11.555 6.344 0 11.489 5.172 11.489 11.555v1.661c0 .431.305.801.728.884.767.149 1.326.821 1.336 1.611-.005.856-.654 1.567-1.499 1.645z"></path>
      <path id="person__eyes" fill="#191816" fill-rule="nonzero" d="M85.35 57.97c.862 0 1.562.704 1.562 1.571s-.7 1.57-1.562 1.57c-.863 0-1.562-.703-1.562-1.57 0-.867.699-1.571 1.562-1.571zm9.3 0c.413 0 .808.166 1.098.461.291.296.451.695.446 1.11 0 .637-.382 1.211-.968 1.453a1.557 1.557 0 01-1.705-.349c-.445-.453-.574-1.131-.327-1.718.248-.586.823-.964 1.456-.957z"></path>
      <path id="person__cheeks" fill="#bd704a" fill-rule="nonzero" d="M83.285 62.14c.853 0 1.544.695 1.544 1.553 0 .857-.691 1.552-1.544 1.552-.853 0-1.544-.695-1.544-1.552 0-.858.691-1.553 1.544-1.553zm13.43 0c.853 0 1.544.695 1.544 1.553 0 .857-.691 1.552-1.544 1.552-.853 0-1.544-.695-1.544-1.552 0-.858.691-1.553 1.544-1.553z"></path>
      <path id="person__mouth" fill="#191816" fill-rule="nonzero" d="M86.409 68.158a6.4397 6.4397 0 007.2 0c.412-.278.521-.837.244-1.249-.278-.412-.837-.522-1.25-.244a4.642 4.642 0 01-5.189 0c-.412-.278-.971-.168-1.249.244-.277.412-.168.971.244 1.249z"></path>
      <path id="person__hair" fill="#724f3d" fill-rule="nonzero" d="M90 42.41c1.756-.011 3.322.868 4.258 2.229.3-.718.997-1.19 1.775-1.2 2.159-.066 4.24.813 5.704 2.41 1.464 1.596 2.167 3.753 1.926 5.912l-.718 7.329c-.041.367-.271.686-.607.838-.335.152-.726.115-1.027-.098-2.657-1.95-7.181-6.643-7.181-8.142.195-.366.361-.745.499-1.133-3.019 7.986-17.885 10.114-19.747 6.386-.915-1.805-.143-4.657 2.352-4.332.183.019.365-.048.492-.183.126-.134.184-.32.155-.503-.449-5.65 5.224-9.513 12.119-9.513z"></path>
      <path id="Combined-Shape" fill="#a86c4d" fill-rule="nonzero" d="M90 42.41c1.756-.011 3.322.868 4.258 2.229.3-.718.997-1.19 1.775-1.2 2.159-.066 4.24.813 5.704 2.41 1.397 1.524 2.101 3.558 1.953 5.617l-.027.295-.214 2.201a7.5032 7.5032 0 00-1.712-3.113c-1.464-1.597-3.545-2.476-5.704-2.41-.778.01-1.475.482-1.775 1.2-.936-1.361-2.502-2.24-4.258-2.229-6.895 0-12.568 3.863-12.119 9.513a.5986.5986 0 01-.155.503.5913.5913 0 01-.492.183c-.587-.077-1.08.023-1.477.244-.397-.249-.696-.553-.875-.912-.915-1.805-.143-4.657 2.352-4.332.183.019.365-.048.492-.183.126-.134.184-.32.155-.503-.449-5.65 5.224-9.513 12.119-9.513z"></path>
      <path id="person__hair-outline" fill="#191816" fill-rule="nonzero" d="M89.997 41.51c1.634-.01 3.127.636 4.224 1.701.491-.416 1.12-.662 1.792-.672 2.418-.072 4.748.914 6.387 2.702 1.64 1.788 2.427 4.203 2.159 6.607l-.719 7.341a1.9472 1.9472 0 01-1.131 1.559c-.626.283-1.355.214-1.93-.193-2.441-1.791-5.445-4.859-6.81-7.039-1.717 2.187-4.401 3.896-7.697 4.997-5.333 1.78-10.884 1.454-12.192-1.164-1.276-2.515.042-5.765 2.884-5.665-.261-5.911 5.537-10.173 13.033-10.174zm6.347 2.826l-.284.002h-.016c-.292.004-.564.13-.757.337l.029-.026c.41.748.67 1.596.736 2.504.064 1.403-.17 2.719-.66 3.933-.1.259-.211.515-.335.765.361 1.214 4.14 5.311 6.774 7.244.04.028.091.033.136.013.046-.021.078-.065.083-.106l.719-7.341c.212-1.9-.407-3.799-1.695-5.204-1.288-1.404-3.117-2.177-5.014-2.119l.284-.002zm-6.338-1.027H90c-6.695 0-11.607 3.693-11.23 8.474.072.457-.072.923-.389 1.26-.319.339-.778.509-1.263.458-1.434-.186-2.188 1.546-1.431 3.038.757 1.515 5.45 1.791 10.015.266 4.058-1.355 6.988-3.665 8.086-6.577.333-.95.468-1.965.394-2.993a3.917 3.917 0 00-.686-2.04.904.904 0 01-.113-.229c-.783-1.018-2.011-1.665-3.377-1.657z"></path>
      <path id="person__upper-body" fill="#ffb0ca" fill-rule="nonzero" d="M101.365 117.145v-24.37c.033-3.11 2.323-5.727 5.387-6.156 15.566-2.69 17.182-17.24 17.362-21.338a1.056 1.056 0 00-.286-.757 1.0397 1.0397 0 00-.738-.326h-7.182c0 14.442-14.363 14.442-14.363 14.442H78.653s-14.364 0-14.364-14.442h-7.182c-.279.005-.545.122-.737.326-.193.204-.296.477-.287.757.127 4.098 1.796 18.648 17.381 21.338 3.055.443 5.338 3.052 5.386 6.156v24.37h22.515z"></path>
      <path id="person__upper-body-outline" fill="#191816" fill-rule="nonzero" d="M102.265 117.145v-24.37c.028-2.656 1.99-4.898 4.611-5.265 7.262-1.254 12.178-5.044 15.122-10.611 1.995-3.772 2.849-7.803 3.015-11.578.018-.531-.174-1.037-.531-1.415-.359-.38-.855-.599-1.377-.608h-7.197a.9.9 0 00-.9.9c0 5.966-2.491 9.724-6.686 11.833-1.483.745-3.082 1.227-4.682 1.496-.56.093-1.079.154-1.543.187-.272.02-.461.026-.552.026H78.653c-.092 0-.281-.006-.553-.026-.464-.033-.983-.094-1.543-.187-1.6-.269-3.198-.751-4.682-1.496-4.194-2.109-6.686-5.867-6.686-11.833a.9.9 0 00-.9-.9h-7.182c-.537.009-1.033.228-1.392.608-.357.378-.548.884-.531 1.404.112 3.657.986 7.799 2.998 11.59 2.953 5.564 7.887 9.356 15.129 10.606 2.641.383 4.598 2.62 4.639 5.282v24.357a.9.9 0 00.9.9h22.515a.9.9 0 00.9-.9zm-22.515-.9v-23.47c-.054-3.559-2.663-6.54-6.157-7.047-6.65-1.148-11.121-4.584-13.821-9.672-1.866-3.514-2.684-7.397-2.789-10.802a.1499.1499 0 01.041-.112.1375.1375 0 01.098-.044h6.285c.251 6.166 3.095 10.246 7.66 12.541 1.658.834 3.426 1.367 5.193 1.663.618.103 1.193.17 1.711.207.317.023.549.031.682.031h22.892c.133 0 .365-.008.681-.031.519-.037 1.094-.104 1.712-.207 1.766-.296 3.534-.829 5.193-1.663 4.564-2.295 7.409-6.375 7.659-12.541h6.3c.022.001.058.016.084.044.027.029.042.069.041.11-.155 3.521-.957 7.306-2.808 10.805-2.692 5.091-7.145 8.524-13.809 9.675-3.476.486-6.095 3.479-6.133 7.033v23.48H79.75z"></path>
      <path id="person__hands" fill="#d18660" fill-rule="nonzero" d="M60.555 56.959c1.983 0 3.59 1.617 3.59 3.611v3.61h-7.181v-3.61c0-1.994 1.607-3.611 3.591-3.611zm58.89 0c1.984 0 3.591 1.617 3.591 3.611v3.61h-7.181v-3.61c0-1.994 1.607-3.611 3.59-3.611z"></path>
      <path id="person__hands-outline" fill="#191816" fill-rule="nonzero" d="M56.064 60.57c0-2.49 2.009-4.511 4.491-4.511 2.481 0 4.49 2.021 4.49 4.511v3.61c0 .497-.402.9-.9.9h-7.181a.9.9 0 01-.9-.9v-3.61zm7.181 0c0-1.498-1.205-2.711-2.69-2.711-1.486 0-2.691 1.213-2.691 2.711v2.71h5.381v-2.71zm60.691 0v3.61a.9.9 0 01-.9.9h-7.181c-.498 0-.9-.403-.9-.9v-3.61c0-2.49 2.009-4.511 4.49-4.511 2.482 0 4.491 2.021 4.491 4.511zm-1.8 0c0-1.498-1.205-2.711-2.691-2.711-1.485 0-2.69 1.213-2.69 2.711v2.71h5.381v-2.71z"></path>
      <g id="person__medal">
        <g id="medal-strap" fill-rule="nonzero">
          <path fill="#7cdba2" stroke="#34bc6a" stroke-width="1.8" d="M86.894 103.642L78.635 78.73h4.147L90 97.414l7.218-18.684h4.147l-8.259 24.912h-6.212z"></path>
          <path id="medal-strap-outline" fill="#191816" d="M96.378 78.406a.9007.9007 0 01.84-.576h4.147c.614 0 1.048.601.855 1.183l-8.26 24.912a.9.9 0 01-.854.617h-6.212a.9.9 0 01-.854-.617l-8.26-24.912c-.193-.582.241-1.183.855-1.183h4.147c.372 0 .706.229.84.576L90 94.916l6.378-16.51zm-3.922 24.336l7.663-23.112h-2.284L90.84 97.738c-.297.768-1.383.768-1.68 0L82.165 79.63h-2.284l7.663 23.112h4.912z"></path>
        </g>
        <g id="medal-badge">
          <ellipse cx="90" cy="101.566" fill="#ffde62" rx="6.194" ry="6.228"></ellipse>
          <path id="medal-badge-outline" fill="#191816" fill-rule="nonzero" d="M90 108.694c3.919 0 7.094-3.193 7.094-7.128 0-3.936-3.175-7.128-7.094-7.128-3.919 0-7.094 3.192-7.094 7.128 0 3.935 3.175 7.128 7.094 7.128zm0-1.8c-2.923 0-5.294-2.385-5.294-5.328 0-2.944 2.371-5.328 5.294-5.328 2.923 0 5.294 2.384 5.294 5.328 0 2.943-2.371 5.328-5.294 5.328z"></path>
        </g>
      </g>
      <path id="person__collar-side" fill="#ff87af" fill-rule="nonzero" d="M82.818 78.766L90 84.958l-5.656 4.982a2.055 2.055 0 01-1.993.421c-.691-.222-1.216-.793-1.382-1.504l-2.316-10.091h4.165zm18.547 0l-2.334 10.091c-.166.711-.691 1.282-1.382 1.504a2.055 2.055 0 01-1.993-.421L90 84.958l7.182-6.192h4.183z"></path>
      <path id="person__collar-top" fill="#d18660" fill-rule="nonzero" d="M97.218 78.73H82.782L90 84.958l7.218-6.228z"></path>
      <path id="person__collar-outline" fill="#191816" fill-rule="nonzero" d="M97.218 77.83c.091 0 .177.013.257.035l3.89.001c.579 0 1.008.539.877 1.103l-2.334 10.093c-.238 1.018-.991 1.837-1.984 2.156-.995.319-2.083.089-2.863-.602l-5.062-4.459-5.058 4.457c-.782.693-1.87.923-2.865.604-.993-.319-1.746-1.138-1.984-2.159l-2.316-10.091c-.13-.564.298-1.102.877-1.102l3.872-.001a.967.967 0 01.257-.035h14.436zm-14.734 1.836h-2.701l2.062 8.987c.094.403.391.726.781.851.389.125.814.035 1.123-.239l4.881-4.3-6.146-5.299zm17.749 0h-2.717l-6.146 5.299 4.883 4.302c.307.272.732.362 1.121.237.39-.125.687-.448.78-.85l2.079-8.988zm-5.436-.036h-9.594L90 83.769l4.797-4.139z"></path>
      <path id="person__legs" fill="#d18660" fill-rule="nonzero" d="M81.741 137.904l.916 15.688c.066 1.096.971 1.951 2.064 1.949h10.558c1.093.002 1.998-.853 2.064-1.949l.916-15.688H81.741z"></path>
      <path id="person__skirt" fill="#ffb0ca" fill-rule="nonzero" d="M98.259 137.904c1.11.002 2.025-.871 2.083-1.985l1.023-18.774H78.653l1.005 18.774c.058 1.114.973 1.987 2.083 1.985h16.518z"></path>
      <path id="Combined-Shape1" fill="#ff87af" fill-rule="nonzero" d="M101.365 117.145l-.218 4h-22.28l-.214-4h22.712z"></path>
      <path id="person__lower-body-outline" fill="#191816" fill-rule="nonzero" d="M101.365 116.245c.517 0 .927.433.899.949l-1.023 18.771c-.067 1.294-.946 2.362-2.126 2.715l-.873 14.966c-.096 1.572-1.394 2.798-2.963 2.795H84.723c-1.571.003-2.869-1.223-2.965-2.797l-.874-14.964c-1.18-.353-2.058-1.421-2.125-2.713l-1.005-18.774c-.028-.515.383-.948.899-.948h22.712zM89.1 138.804h-6.405l.86 14.733c.038.622.549 1.105 1.166 1.104H89.1v-15.837zm8.205 0H90.9v15.837h4.38c.575.001 1.059-.419 1.151-.98l.014-.122.86-14.735zm3.11-20.759H79.602l.955 17.827c.033.637.554 1.133 1.184 1.132H98.26c.629.001 1.15-.495 1.183-1.134l.972-17.825z"></path>
      <path id="person__feet" fill="#525252" fill-rule="nonzero" stroke="#191816" stroke-width="2" d="M97.182 149.313c2.246.012 4.309 1.25 5.386 3.232.313.634.282 1.385-.081 1.992-.364.607-1.01.985-1.714 1.004H90c0-3.418 2.74-6.198 6.14-6.228h1.042zm-13.376 0c3.421 0 6.194 2.789 6.194 6.228H79.227a2.0638 2.0638 0 01-1.714-1.004 2.0852 2.0852 0 01-.081-1.992c1.08-1.979 3.141-3.216 5.386-3.232h.988z"></path>
      <path id="person__feet-outline" fill="#191816" fill-rule="nonzero" d="M79.203 156.441c-1.012-.027-1.94-.571-2.462-1.441-.52-.869-.564-1.944-.099-2.887 1.237-2.265 3.597-3.681 6.176-3.7h.988c2.662 0 4.981 1.473 6.194 3.652 1.204-2.161 3.497-3.629 6.14-3.652h1.047c2.574.014 4.938 1.432 6.189 3.734.447.909.403 1.984-.117 2.853-.522.87-1.45 1.414-2.462 1.441H79.203zm17.979-6.228h-1.034c-2.6.023-4.751 1.93-5.173 4.428h9.784c.392-.014.752-.226.956-.566.206-.345.223-.772.062-1.101-.92-1.694-2.682-2.75-4.595-2.761zm-13.376 0h-.981c-1.918.014-3.679 1.07-4.586 2.729a1.19 1.19 0 00.046 1.133c.206.343.57.556.942.566h9.798c-.425-2.515-2.601-4.428-5.219-4.428z"></path>
    </g>
    <g id="confetti" fill-rule="nonzero" stroke="#191816" stroke-width="2">
      <path id="confetti-11" fill="#4cf4fc" d="M118.817 38.312c-2.045 3.227-5.55 5.223-9.354 5.325l-3.304-5.885c3.82-.112 7.328-2.147 9.336-5.415l3.322 5.975z"></path>
      <path id="confetti-10" fill="#ff6242" d="M90.144 22.426c1.357 3.572.866 7.588-1.311 10.723l-6.661-.83c2.177-3.135 2.668-7.152 1.31-10.723l6.662.83z"></path>
      <path id="confetti-9" fill="#ff6196" d="M60.106 53.494c-2.466-2.917-3.316-6.882-2.263-10.561l6.554-1.426c-1.014 3.663-.168 7.593 2.262 10.506l-6.553 1.481z"></path>
      <path id="confetti-8" fill="#ff6196" d="M150.776 139.186c-2.466-2.917-3.315-6.881-2.262-10.56l6.553-1.426c-1.033 3.679-.186 7.634 2.263 10.56l-6.554 1.426z"></path>
      <path id="confetti-7" fill="#bf8df2" d="M55.725 128.102c3.441 1.63 5.856 4.869 6.445 8.647l-5.386 4.044c-.583-3.777-3.001-7.013-6.446-8.629l5.387-4.062z"></path>
      <path id="confetti-6" fill="#bf8df2" d="M129.5 100.122c-3.441 1.629-5.856 4.868-6.446 8.647l5.387 4.043c.583-3.777 3-7.013 6.445-8.629l-5.386-4.061z"></path>
      <path id="confetti-5" fill="#4cf4fc" d="M122.767 128.572c3.478 1.544 5.966 4.723 6.643 8.484l-5.296 4.17c-.668-3.765-3.16-6.948-6.644-8.484l5.297-4.17z"></path>
      <path id="confetti-3" fill="#ff8a14" d="M47.448 73.459c-3.175 2.097-7.18 2.469-10.683.993l-.629-6.716c3.532 1.507 7.58 1.127 10.773-1.01l.539 6.733z"></path>
      <path id="confetti-2" fill="#fff48c" d="M139.016 61.184c-3.755.613-7.568-.686-10.18-3.466l2.172-6.391c2.603 2.784 6.412 4.083 10.162 3.466l-2.154 6.391z"></path>
      <path id="confetti-1" fill="#fff48c" d="M44.719 106.584c.802-3.735 3.41-6.818 6.948-8.213l5.117 4.368c-3.535 1.404-6.146 4.482-6.966 8.214l-5.099-4.369z"></path>
    </g>
    <g id="coin-3">
      <circle id="coin-3-background" cx="156" cy="90" r="20" fill="#ffde62"></circle>
      <path id="coin-3-outline" fill="#333" fill-opacity=".2" fill-rule="nonzero" d="M156 70c11.046 0 20 8.954 20 20s-8.954 20-20 20-20-8.954-20-20 8.954-20 20-20zm0 3.75c-8.975 0-16.25 7.275-16.25 16.25s7.275 16.25 16.25 16.25 16.25-7.275 16.25-16.25-7.275-16.25-16.25-16.25zM166 90c0 5.523-4.477 10-10 10s-10-4.477-10-10h20z"></path>
    </g>
    <g id="coin-1">
      <circle id="coin-1-background" cx="34" cy="48" r="10" fill="#ffde62"></circle>
      <path id="coin-1-outline" fill="#333" fill-opacity=".2" fill-rule="nonzero" d="M34 38c5.523 0 10 4.477 10 10s-4.477 10-10 10-10-4.477-10-10 4.477-10 10-10zm0 1.875c-4.487 0-8.125 3.638-8.125 8.125s3.638 8.125 8.125 8.125 8.125-3.638 8.125-8.125-3.638-8.125-8.125-8.125zM39 48c0 2.761-2.239 5-5 5s-5-2.239-5-5h10z"></path>
    </g>
    <g id="coin-2">
      <circle id="coin-2-background" cx="23" cy="119" r="15" fill="#ffde62"></circle>
      <path id="coin-2-outline" fill="#333" fill-opacity=".2" fill-rule="nonzero" d="M23 104c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15zm0 2.813c-6.731 0-12.187 5.456-12.187 12.187 0 6.731 5.456 12.188 12.187 12.188 6.731 0 12.188-5.457 12.188-12.188 0-6.731-5.457-12.187-12.188-12.187zM30.5 119c0 4.142-3.358 7.5-7.5 7.5-4.142 0-7.5-3.358-7.5-7.5h15z"></path>
    </g>
  </g>`;

export default function earnPoints() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
      clipRule='evenodd'
      viewBox='0 0 180 180'
      // eslint-disable-next-line
      dangerouslySetInnerHTML={{ __html: earnPointsContent }}
    />
  );
}

import Slider, { CustomArrowProps } from 'react-slick';
import { QuestionResource } from 'components/organisms/questions';
import { useRef } from 'react';
import { SvgIcon } from 'components/atoms/svgIcon';
import { CarouselItem } from './CarouselItem';

type CarouselImageProps = {
  resource: QuestionResource[];
  altImage: string;
};

type CarouselDotsProps = {
  dots: React.ReactNode;
};

const CarouselDots = ({ dots }: CarouselDotsProps): JSX.Element => (
  <ul className='c-carousel__dots' role='tablist' style={{ display: 'flex' }}>
    {dots}
  </ul>
);

type SliderArrowProps = {
  isRight?: boolean;
} & CustomArrowProps;

const SliderArrow = ({ className, onClick, isRight }: SliderArrowProps): JSX.Element => {
  // remove slick-next and slick-prev classes which are classes for slick arrows
  // as they clash with our custom arrows
  const classNames = className?.replace('slick-next', '').replace('slick-prev', '') ?? '';
  return (
    <button
      className={`${isRight ? 'c-carousel__next' : 'c-carousel__prev'} ${classNames ?? ''}`}
      style={{ display: 'flex' }}
      onClick={onClick}
    >
      <span className='u-screen-reader-text'>{isRight ? 'Next' : 'Previous'}</span>
      {isRight ? <SvgIcon icon='arrow-right' /> : <SvgIcon icon='arrow-left' />}
    </button>
  );
};

export const ImageCarousel = ({ resource, altImage }: CarouselImageProps): JSX.Element => {
  const slider = useRef<Slider | null>(null);
  const sliderSettings = {
    mobileFirst: true,
    infinite: false,
    arrows: true,
    nextArrow: <SliderArrow isRight />,
    prevArrow: <SliderArrow />,
    dots: true,
    dotsClass: 'c-carousel__dots',
    appendDots: (dots: React.ReactNode) => <CarouselDots dots={dots} />,
    adaptiveHeight: false,
  };
  return (
    <div className='c-carousel c-lead-image__carousel'>
      <Slider {...sliderSettings} ref={slider} className='c-carousel__items'>
        {resource.map((image, index) => (
          <CarouselItem resource={image} index={index} key={image._id} altImage={altImage} />
        ))}
      </Slider>
    </div>
  );
};

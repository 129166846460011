import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Team, Gallery, About, Decisions, ProjectProgress, Map } from '../components/home';
import { getHomeData, getAboutData } from '../actions';
import Spinner from '../components/spinner/spinner';
import enums from '../shared/engage-flow-enums';
import { MAP_KEY } from '../constants';
import Panel from '../components/panel/Panel';
import GOOGLE_MAP_ENUMS from '../shared/engage-google-map-enums';

const ENUMS = enums().HomepageSectionFlowTypes;
const ENUMS_ARRAY = enums().homepageSectionFlowTypeList();

const ENUM_OBJECTS = () => {
  const tmp = [];
  ENUMS_ARRAY.forEach((item) => tmp.push({ sectionType: item, visible: true }));
  return tmp;
};

const mapStyle = GOOGLE_MAP_ENUMS().locationMapStyle;

class ProjectHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: null,
      locationExtended: null,
      flag: false,
    };
    // console.log(props.match.path);
    // this.navigateToPolls = this.navigateToPolls.bind(this);
  }

  componentDidMount() {
    this.props.actions.getHomeData(this.props.slug_id);
    this.props.actions.getAboutData(this.props.slug_id).then(() => {
      this.setState({
        location: this.props.location,
        locationExtended: this.props.locationExtended,
        flag: true,
      });
    });
  }

  arrayToRender() {
    return this.props.customizations.homepageSectionFlow && this.props.customizations.homepageSectionFlow.length !== 0
      ? this.props.customizations.homepageSectionFlow
      : ENUM_OBJECTS();
  }

  renderItems(itemName, index) {
    switch (itemName) {
      case ENUMS.INTRO:
        // return <About key={index} about={this.props.homeData.about} clicked={this.navigateToPolls} />;
        return <About key={index} about={this.props.homeData.about} />;
      case ENUMS.TIMELINE:
        return <ProjectProgress key={index} slugId={this.props.slug_id} />;

      case ENUMS.INFLUENCED_DECISIONS:
        return (
          this.props.homeData.influencedDecisions.length > 0 && (
            <Decisions key={index} decisions={this.props.homeData.influencedDecisions} path={this.props.match.path} />
          )
        );
      case ENUMS.MEDIA:
        return this.props.homeData.gallery.length > 0 && <Gallery key={index} gallery={this.props.homeData.gallery} />;

      case ENUMS.LOCATION:
        return this.state.flag ? (
          <Map
            key={index}
            mapKey={MAP_KEY}
            location={this.state.location}
            locationExtended={this.state.locationExtended}
            mapStyle={mapStyle}
          />
        ) : null;
      case ENUMS.TEAM:
        return <Team key={index} team={this.props.homeData.team} />;

      default:
        return null;
    }
  }

  // //clicking on this button will navigate to the polls page
  // navigateToPolls() {
  //   let slug = sessionStorage.getItem('slug');
  //   this.props.history.push(`${slug}/polls`);
  // }
  render() {
    // console.log('HOME DATA', this.props.homeData);
    return (
      <main className='l-section-main c-section-main' id='appContent'>
        {this.props.homeData.isLoading === true ? (
          <Spinner />
        ) : (
          <React.Fragment>
            <div className='l-container'>
              {/* panels */}
              {this.props.homeData.panel && (
                <div className='c-block'>
                  <div className='l-container'>
                    <Panel history={this.props.history} panel={this.props.homeData.panel} />
                  </div>
                </div>
              )}
              {this.arrayToRender().map((item, index) => item.visible && this.renderItems(item.sectionType, index))}
            </div>
          </React.Fragment>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  homeData: state.home,
  location: state.header.location,
  locationExtended: state.header.locationExtended,
  customizations: state.customizations,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ getHomeData, getAboutData }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectHome);

import i18n from 'i18n/helper';
import { AnnotateActionProps } from './types';
import { AnnotateActionsArea } from './AnnotateActionsArea';

export function AnnotateActions({
  onClickZoomIn,
  onClickZoomOut,
  onClickZoomReset,
  annotateModalDelete,
  annotateModalFinish,
  onClickFinish,
  isFinishVisible,
  isAnnotateMap,
}: AnnotateActionProps): JSX.Element {
  return (
    <div className='c-annotate-actions'>
      {!isAnnotateMap ? (
        <AnnotateActionsArea
          onClickZoomIn={onClickZoomIn}
          onClickZoomOut={onClickZoomOut}
          onClickZoomReset={onClickZoomReset}
        />
      ) : null}
      {isFinishVisible ? (
        <button
          className='c-button c-button--primary c-button--pill c-button--large c-annotate-actions__finish--confirm'
          onClick={onClickFinish}
        >
          <span className='c-button__label'>{i18n.t('questions.annotate.finish')}</span>
        </button>
      ) : null}
      <div className='c-annotate-actions__pseudo-modals'>
        {annotateModalDelete}
        {annotateModalFinish}
      </div>
    </div>
  );
}

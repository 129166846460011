import React from 'react';
import SvgIcon from '../SvgIcon';

export default function PreviewNotice(props) {
  const { removeNotice } = props;
  return (
    <div className='c-preview-notice'>
      <p>
        This project is in{' '}
        <em>
          <SvgIcon icon='eye' /> preview mode
        </em>{' '}
        — no votes will be registered.
      </p>
      <button className='c-button c-button--primary' onClick={removeNotice}>
        <span className='c-button__label'>Hide notice</span>
      </button>
    </div>
  );
}

import React, { Fragment } from 'react';
import i18n from 'i18n/helper';

import SvgIcon from '../../../SvgIcon';
import { FIRST_NAME, LAST_NAME, EMAIL, ADDRESS, POSTAL_CODE, PHONE, EXTRA1, EXTRA2 } from './FieldConstants';

// const REQUIRED = i18n.t('general.required');

function getFieldConfiguration(fieldName, field) {
  const FIRST_NAME_LABEL = i18n.t('questions.personalData.firstNameLabel');
  const LAST_NAME_LABEL = i18n.t('questions.personalData.lastNameLabel');
  const EMAIL_LABEL = i18n.t('questions.personalData.emailLabel');
  const ADDRESS_LABEL = i18n.t('questions.personalData.addressLabel');
  const POSTAL_CODE_LABEL = i18n.t('questions.personalData.postalCodeLabel');
  const PHONE_LABEL = i18n.t('questions.personalData.phoneLabel');

  switch (fieldName) {
    case FIRST_NAME:
      return { label: field.label || FIRST_NAME_LABEL };
    case LAST_NAME:
      return { label: field.label || LAST_NAME_LABEL };
    case EMAIL:
      return { type: 'email', label: field.label || EMAIL_LABEL };
    case ADDRESS:
      return { label: field.label || ADDRESS_LABEL };
    case POSTAL_CODE:
      return { label: field.label || POSTAL_CODE_LABEL };
    case PHONE:
      return { type: 'tel', label: field.label || PHONE_LABEL };
    case EXTRA1:
      return { label: field.label || '' };
    case EXTRA2:
      return { label: field.label || '' };
    default:
      return {};
  }
}

export default function PersonalDataField(props) {
  const { field } = props;
  const isSelect = (_field) => _field.lookupValues?.length > 0;
  return <Fragment>{!isSelect(field) ? <TextInput {...props} /> : <SelectInput {...props} />}</Fragment>;
}

function SelectInput(props) {
  const { fieldName, field, personalData, setPersonalData } = props;
  const OPTIONAL = i18n.t('general.optional');

  return (
    <div className='c-field c-field--label-hide'>
      <div className='c-field__group'>
        <div className='o-select'>
          <select
            id={fieldName}
            onChange={(e) => {
              const newPersonalData = { ...personalData };
              newPersonalData[fieldName] = e.target.value;
              setPersonalData(newPersonalData);
            }}
          >
            <option value='' disabled={false}>
              {field?.label || fieldName} {field.required ? '' : `(${OPTIONAL})`}
            </option>
            {field.lookupValues.map((l, idx) => (
              <option key={idx} value={l}>
                {l}
              </option>
            ))}
          </select>
          <SvgIcon icon='angle-down' />
        </div>
      </div>
    </div>
  );
}

function TextInput(props) {
  const { fieldName, field, personalData, setPersonalData } = props;
  const fieldConfig = getFieldConfiguration(fieldName, field);
  const OPTIONAL = i18n.t('general.optional');

  const label = `${fieldConfig.label} ${field.required ? '' : `(${OPTIONAL})`}`;
  return (
    <div className='c-field c-field--label-hide'>
      <label className='c-field__label' htmlFor={fieldName}>
        First name * {field + fieldName}
      </label>

      <div className='c-field__group'>
        <div className='o-input'>
          <input
            type={fieldConfig.type || 'text'}
            placeholder={label}
            id={fieldName}
            value={personalData[fieldName]}
            maxLength={300}
            onChange={(e) => {
              const newPersonalData = { ...personalData };
              newPersonalData[fieldName] = e.target.value;
              setPersonalData(newPersonalData);
            }}
          />
        </div>
      </div>
    </div>
  );
}

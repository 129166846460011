/* eslint-disable */
module.exports = function () {
  const _ = require('lodash');

  const EngageEndOfPollFlowButtonType = {
    DEFAULT_BUTTON: 'defaultButton',
    ADDON_BUTTON: 'addonButton',
    ADDON_SECOND_BUTTON: 'addonSecondButton',
  };

  const HomepageSectionFlowTypes = {
    INTRO: 'INTRO',
    TIMELINE: 'TIMELINE',
    INFLUENCED_DECISIONS: 'INFLUENCED_DECISIONS',
    MEDIA: 'MEDIA',
    LOCATION: 'LOCATION',
    TEAM: 'TEAM',
  };

  return {
    EngageEndOfPollFlowButtonType,
    engageEndOfPollFlowButtonTypeList,
    HomepageSectionFlowTypes,
    homepageSectionFlowTypeList,
  };

  function engageEndOfPollFlowButtonTypeList() {
    return _.map(Object.keys(EngageEndOfPollFlowButtonType), (key) => EngageEndOfPollFlowButtonType[key]);
  }

  function homepageSectionFlowTypeList() {
    return _.map(Object.keys(HomepageSectionFlowTypes), (key) => HomepageSectionFlowTypes[key]);
  }
};

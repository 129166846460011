import { useRef, useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import _ from 'lodash';

import { SUBMIT_DELAY, RECAPTCHA, FB_PIXEL } from '../../../constants';
import i18n from '../../../i18n/helper';

/**
 * Provides delay for coin animation before progressing the form
 * @param delay
 * @returns {Promise<any>}
 */
export function timerPromise(delay) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(true), delay);
  });
}

/**
 * Hooks version of scroll styling handler
 * Much cleaner implementation
 * In caller just define
 * const [isScrollingClass, setScrollingClass] = useState('');
 * and hook it to div like so
 * <div className='c-question__content' onScroll={handleScroll(setScrollingClass)}>
 * @param setScrollingClass - state Hook function
 * @param clazz - optional scrolling class
 * @returns {Function}
 */
export function handleScroll(setScrollingClass, clazz = 'c-question--scroll') {
  return (event) => {
    const scrollingClass = event.currentTarget.scrollTop > 0 ? clazz : '';
    setScrollingClass(scrollingClass);
  };
}

/**
 * Store previous question ID to detect question change
 * @param value
 * @returns {undefined}
 */
export function usePreviousPropsAndState(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

/**
 * Default question form state
 * @type {{communityAnswer: Array, showCoin: boolean, isResult: boolean, disableForm: boolean}}
 */
export const DEFAULT_STATE = {
  isResult: false,
  showCoin: false,
  communityAnswer: [],
  disableForm: false,
};

export async function getCaptchaToken() {
  return new Promise((resolve) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(RECAPTCHA, { action: 'submit_question' })
        .then((token) => resolve(token))
        .catch(() => resolve(undefined));
    });
  });
}

/**
 * Submit question response
 * Executes update against API and handles housekeeping
 * @param updateState - callback to update form state
 * @param slugId
 * @param pollId
 * @param questionId
 * @param answer - user's answer packed - specific to each form
 * @param currentLevelUpStatus
 * @param actions
 */
const executeUpdateFn = async (
  setState,
  slugId,
  pollId,
  questionId,
  answer,
  actions,
  showCoinsInHeader,
  skipResults,
  isQQ,
  questionButtonClicked,
) => {
  // show coin before all else
  const showCoins = showCoinsInHeader(isQQ, answer);
  setState({ ...DEFAULT_STATE, showCoin: showCoins, disableForm: true });
  const delay = showCoins ? SUBMIT_DELAY : SUBMIT_DELAY / 2;

  const token = await getCaptchaToken();
  const answerWithToken = { ...answer, token };
  // Upadate local data from API
  // WTF can't figure out why, but all components do it
  actions.getPolls(slugId);
  actions.getAboutData(slugId);

  // submit answer and in parallel wait for coin animation
  const promises = [
    timerPromise(delay),
    actions.submitPollQuestion(slugId, pollId, questionId, answerWithToken),
    // FOR DEBUG ONLY - alternate with real call above
    // Promise.resolve({ data: { leveledUp: true, communityAnswer: [{ amount: 20 }] } }),
  ];

  // submit answer and in parallel wait for coin animation
  Promise.all(promises)
    .then((data) => {
      // console.log('DONE');
      // console.log('DATA', data);

      // ReactPixel send question submitted
      ReactPixel.trackSingleCustom(FB_PIXEL, 'question_submit', { questionId });

      // REUSING logic for normal and QQ question
      // If QQ jump right to next question after submit
      if (isQQ || skipResults) {
        return questionButtonClicked();
      }

      const communityAnswer = data.length > 1 && data[1].data && data[1].data.communityAnswer;

      // Update form state after I receive confirmation from API, hide coin and show results
      setState({
        isResult: true,
        showCoin: false,
        communityAnswer,
        disableForm: false,
      });
      return true;
    })
    .catch((res) => {
      // console.log('After submit', res);
      if (res.status === 400) {
        alert(i18n.t('pollsFeedback.submitPollQuestion'));
      }
    });
};

const executeUpdateMultipleAnswerQuestionFn = async (
  submitPollQuestion,
  slugId,
  pollId,
  questionId,
  answer,
  trackingQuestionId,
) => {
  const token = await getCaptchaToken();
  const answerWithToken = { ...answer, token };

  submitPollQuestion(slugId, pollId, questionId, answerWithToken)
    .then(() => {
      ReactPixel.trackSingleCustom(FB_PIXEL, 'question_submit', { questionId: trackingQuestionId });
    })
    .catch((res) => {
      // console.log('After submit', res);
      if (res.status === 400) {
        alert(i18n.t('pollsFeedback.submitPollQuestion'));
      }
    });
};

/**
 * resolves double submit in database
 */
export const executeUpdate = _.debounce(executeUpdateFn, SUBMIT_DELAY / 2, {
  leading: true,
  trailing: false,
});

export const executeUpdateMultipleAnswerQuestion = _.debounce(executeUpdateMultipleAnswerQuestionFn, SUBMIT_DELAY / 2, {
  leading: true,
  trailing: false,
});

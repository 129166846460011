import React from 'react';

const coinContent = `
  <g id="points">
    <g id="coin">
      <circle id="coin-circle" cx="64" cy="64" r="16" fill="#ffde62"></circle>
      <path id="coin-outline" fill="#333" fill-opacity=".2" fill-rule="nonzero" d="M64 48c8.837 0 16 7.163 16 16s-7.163 16-16 16-16-7.163-16-16 7.163-16 16-16zm0 3c-7.18 0-13 5.82-13 13s5.82 13 13 13 13-5.82 13-13-5.82-13-13-13zm8 13c0 4.418-3.582 8-8 8s-8-3.582-8-8h16z"></path>
    </g>
    <g id="lines" fill="none" stroke="#ffde62" stroke-width="3">
      <path id="line-6" d="M64 32V8"></path>
      <path id="line-5" d="M91.713 48l20.784-12"></path>
      <path id="line-4" d="M91.713 80l20.784 12"></path>
      <path id="line-3" d="M64 96v24"></path>
      <path id="line-2" d="M36.287 80L15.503 92"></path>
      <path id="line-1" d="M36.287 48L15.503 36"></path>
    </g>
  </g>
`;

export default function coinAimation() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fillRule='evenodd'
      strokeLinecap='round'
      clipRule='evenodd'
      viewBox='0 0 128 128'
      // eslint-disable-next-line
      dangerouslySetInnerHTML={{ __html: coinContent }}
    />
  );
}

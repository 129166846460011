import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import MoreItem from '../components/more/MoreItem';
import { getFaq } from '../actions';
import ITEM_LIST from '../components/more/MoreItemList';

function More(props) {
  const { link, projectId, faqData, actions } = props;

  const [items, setItems] = useState(ITEM_LIST);

  useEffect(() => {
    projectId && actions.getFaq(projectId);
  }, [projectId, actions]);

  useEffect(() => {
    projectId && window.gtag('event', 'moreDetail', { event_category: 'feed', event_label: projectId });
  }, [projectId]);

  useEffect(() => {
    const tempItems = { ...ITEM_LIST };
    if (!faqData || faqData.length === 0) {
      delete tempItems.faq;
    }
    if (link) {
      tempItems.website = { ...ITEM_LIST.website, url: link };
    }
    setItems({ ...tempItems });
  }, [link, faqData]);

  return (
    <main className='l-section-main c-section-main' id='appContent'>
      <div className='l-container'>
        <nav className='c-nav-list c-nav-list--grid'>
          <ul className='c-nav-list__items'>
            {Object.keys(items).map(
              (item, index) =>
                items[item].url !== '' && (
                  <MoreItem
                    key={index}
                    title={items[item].label}
                    url={items[item].url}
                    icon={items[item].icon}
                    isProjectUrl={items[item].isProjectUrl}
                    openInNewWindow={items[item].openInNewWindow}
                  />
                ),
            )}
          </ul>
        </nav>
      </div>
    </main>
  );
}

More.propTypes = {
  link: PropTypes.string,
  projectId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  link: state.header.projectUrl,
  projectId: state.header._id,
  faqData: state.faq.data,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ getFaq }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(More);

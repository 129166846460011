/* eslint-disable */
module.exports = function () {
  const _ = require('lodash');

  const EngageNotificationSourceType = {
    INFLUENCED_DECISION: 'INFLUENCED_DECISION',
    NEWS: 'NEWS',
  };

  const EngageNotificationSortType = {
    RECENT: 'RECENT',
    TOP: 'TOP',
  };

  return {
    EngageNotificationSourceType,
    engageNotificationSourceTypeList,
    EngageNotificationSortType,
  };

  function engageNotificationSourceTypeList() {
    return _.map(Object.keys(EngageNotificationSourceType), (key) => EngageNotificationSourceType[key]);
  }
};

import * as Geolocation from 'services/geolocation/geolocation';

export const isGeolocationVerified = (slugId) => (dispatch) =>
  Geolocation.isVerified(slugId)
    .then((response) => {
      dispatch({
        type: 'SUCCESS_IS_VERIFIED',
        data: response.data,
      });
      // return true;
      return !!response?.data?.isLocationVerified;
    })
    .catch((response) => {
      dispatch({
        type: 'FAIL_IS_VERIFIED',
        data: response.data,
      });
      return false;
    });

export const validateLocation = (slugId, data) => (dispatch) =>
  Geolocation.validateLocation(slugId, data)
    .then((response) => {
      dispatch({
        type: 'SUCCESS_IS_VERIFIED',
        data: response.data,
      });
      // return true;
      return !!response?.data?.isLocationVerified;
    })
    .catch((response) => {
      dispatch({
        type: 'FAIL_IS_VERIFIED',
        data: response.data,
      });
      return false;
    });

import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable as arrayMove } from 'array-move';
import PropTypes from 'prop-types';

import { createAnswer } from 'components/organisms/question';
import Coin from '../../coin/coin';
import i18n from '../../../i18n/helper';
import SvgIcon from '../../SvgIcon';
import ThumbsUp from '../../../assets-v2/images/illustration/thumbs-up.png';
import QuestionUtils from '../QuestionUtils/QuestionUtils';

const SortableItem = SortableElement(({ value }) => (
  <div className='c-choice-reorder'>
    <h3 className='c-choice-reorder__title'>{value}</h3>
    <SvgIcon icon='grab' />
  </div>
));

const SortableList = SortableContainer(({ items }) => (
  <div className='c-reorder__items u-a-reorder-swap-cue'>
    {items.map((value, index) => (
      <SortableItem key={`item-${index}`} index={index} value={value.choice} />
    ))}
  </div>
));

export default class Question01 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.question.choices,
      // result: false,
      // communityAnswer: null,
    };
  }

  componentDidUpdate(prevsProps) {
    if (JSON.stringify(prevsProps.question) !== JSON.stringify(this.props.question)) {
      // eslint-disable-next-line
      this.setState({
        items: this.props.question.choices,
        // result: false,
        // communityAnswer: null,
      });
    }
  }

  handleButton = () => {
    !this.props.isResult
      ? this.props.onSubmitQuestion(createAnswer(this.createChoiceArray()))
      : this.props.questionButtonClicked();
  };

  handleScroll = QuestionUtils.handleScroll(this);

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      items: arrayMove(this.state.items, oldIndex, newIndex),
    });
  };

  createChoiceArray = () => {
    const choiceArray = [];
    this.state.items.forEach((value, index) => {
      choiceArray.push({ choiceId: value._id, order: index + 1 });
    });
    return choiceArray;
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      items: arrayMove(this.state.items, oldIndex, newIndex),
    });
  };

  render() {
    // console.log(this.props.question);
    // console.log(this.state.items);
    // console.log(this.props.communityAnswer);
    return (
      <article
        className={`c-question ${!this.props.isResult ? 'c-question--side-header' : 'c-question--center-header'} ${
          this.state.isScrollingClass || ''
        }`}
      >
        <div className='c-question__header'>
          <h2 className='c-question__title'>
            {!this.props.isResult ? (
              <React.Fragment>{this.props.question.questionText}</React.Fragment>
            ) : (
              <React.Fragment>
                {<img src={ThumbsUp} alt='' />}
                {i18n.t('questions.dragDropQuestionScreen.result')}
              </React.Fragment>
            )}
          </h2>
        </div>
        {!this.props.isResult && (
          <div className='c-question__content' onScroll={this.handleScroll}>
            <div className='c-reorder u-a-children-fade-in'>
              <h3 className='c-reorder__header'>
                <span className='c-reorder__label'>
                  {i18n.t('questions.dragDropQuestionScreen.dragDropQuestionText')}
                </span>
              </h3>
              <span className='c-reorder__label-top'>{i18n.t('questions.dragDropQuestionScreen.favourite')}</span>
              <SortableList
                items={this.state.items}
                onSortEnd={this.onSortEnd}
                helperClass='c-choice-reorder--helper'
              />
              <span className='c-reorder__label-bottom '>
                {i18n.t('questions.dragDropQuestionScreen.leastFavourite')}
              </span>
            </div>
          </div>
        )}
        <div className='c-question__footer'>
          <button
            className={`c-button c-button--primary c-button--large ${this.props.disableForm ? 'is-disabled' : ''}`}
            onClick={this.handleButton}
            disabled={this.props.disableForm}
          >
            <span className='c-button__label'>
              {this.props.isResult === false ? i18n.t('questions.submitButton') : i18n.t('questions.nextQuestion')}
            </span>
            {this.props.showCoin && !this.props.isResult && <Coin bounceFromBottom />}
          </button>
        </div>
      </article>
    );
  }
}

Question01.propTypes = {
  question: PropTypes.object.isRequired,
  onSubmitQuestion: PropTypes.func.isRequired,
  isResult: PropTypes.bool.isRequired,
  showCoin: PropTypes.bool.isRequired,
  questionButtonClicked: PropTypes.func.isRequired,
};

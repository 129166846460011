import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../../SvgIcon';

export default function TextInputWithIcon(props) {
  const { value, placeholder, onChange, disabled, id, name, icon, maxLength } = props;
  return (
    <React.Fragment>
      <input
        value={value}
        type='text'
        placeholder={placeholder}
        id={id}
        name={name}
        onChange={onChange}
        disabled={disabled}
        maxLength={maxLength}
      />
      <SvgIcon icon={icon} />
    </React.Fragment>
  );
}

TextInputWithIcon.propTypes = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import i18n from '../../i18n/helper';
import PillButton from '../button/PillButton';
import MarkdownField, { MARKDOWN_MID } from '../markdown/MarkdownField';
import SvgIcon from '../SvgIcon';

export default function About(props) {
  const { about } = props;

  const [opened, setOpened] = useState(false);
  const useToggle = about.length > 400;

  const addToggleClass = () => {
    let className = '';

    if (useToggle) {
      className = `c-about__description--toggle ${opened ? 'is-opened' : 'is-closed'}`;
    }
    return className;
  };

  return (
    <div className='c-block c-block--light c-block--background-1'>
      <div className='l-container l-container--spacing'>
        <article className='c-about'>
          <div className='c-about__content'>
            <h2 className='c-about__heading'>{i18n.t('body.about')}</h2>
            <div className={`c-about__description u-rich-text ${addToggleClass()}`}>
              <MarkdownField markdown={about} markdownConfig={MARKDOWN_MID} />

              {useToggle && (
                <button
                  className='c-button c-about__toggle'
                  onClick={() => {
                    setOpened(!opened);
                  }}
                >
                  <span className='c-button__label'>{opened ? i18n.t('body.showLess') : i18n.t('body.showMore')}</span>

                  <SvgIcon icon='angle-down' />
                </button>
              )}
            </div>
            <PillButton
              classModifier='c-button--alternate c-button--large'
              title={i18n.t('body.say')}
              url={i18n.t('body.sayUrl') === 'sayUrl' ? '/surveys' : i18n.t('body.sayUrl')}
            />
          </div>
        </article>
      </div>
    </div>
  );
}

About.propTypes = {
  about: PropTypes.string.isRequired,
};

import * as Home from '../../services/home/home';

export const getHomeProjectsData = () => (dispatch) =>
  Home.getDataFromServer()
    .then((response) => {
      dispatch({
        type: 'SUCCESS_HOME_DATA',
        data: response.data,
      });
      return true;
    })
    .catch((response) => {
      dispatch({
        type: 'FAIL_HOME_DATA',
        data: response,
      });
      return false;
    });

import React from 'react';
import PropTypes from 'prop-types';

import MarkdownField, { MARKDOWN_MIN } from '../markdown/MarkdownField';
import Stage from './Stage';

export default function Phase(props) {
  const { phaseNumber, phaseTitle, phaseDescription, stages } = props;

  return (
    <div className='c-timeline__phase'>
      <h2 id={`phase-${phaseNumber}`} className='c-timeline__phase-title'>
        {phaseTitle}
      </h2>
      <p className='c-timeline__phase-description'>
        <MarkdownField markdown={phaseDescription} markdownConfig={MARKDOWN_MIN} />
      </p>
      <div className='c-timeline__stages u-a-children-fade-in'>
        {stages &&
          stages.map((item, index) => (
            <Stage
              key={item._id}
              phaseNumber={phaseNumber}
              stageNumber={index + 1}
              title={item.title}
              description={item.description}
              resource={item.resource[0]}
              date={item.date}
              type={item.type}
            />
          ))}
      </div>
    </div>
  );
}

Phase.propTypes = {
  phaseNumber: PropTypes.number.isRequired,
  phaseTitle: PropTypes.string.isRequired,
  phaseDescription: PropTypes.string.isRequired,
  stages: PropTypes.arrayOf(PropTypes.object).isRequired,
};

import React from 'react';

import { getReactionImageUrl, IMAGE_FORMATS } from './Formats';

export default function Reaction(props) {
  const { imageId, imageType, alt, ...rest } = props;

  return (
    <picture>
      <img src={getReactionImageUrl(imageId, IMAGE_FORMATS.GMV_IMAGE_REACTION)} alt={alt} {...rest} />
    </picture>
  );
}

import { QuestionResource } from 'components/organisms/questions';
import { SvgIcon } from 'components/atoms/svgIcon';
import i18n from 'i18n/helper';
import { Img, WIDE } from 'components/images/Img';
import { useState } from 'react';
import { getImageUrl, IMAGE_FORMATS } from 'components/images/Formats';
import { usePanZoom } from 'components/panZoomHook/usePanZoom';
import { ZOOM_IN, ZOOM_OUT } from 'components/organisms/questions/annotateAdvanced/utils';

type ImageWithZoomProps = {
  resource: QuestionResource;
  altImage: string;
  setIsMediaZoomed(isMediaZoomed: boolean): void;
  zoomEnabled: boolean;
};

export const ImageWithZoom = ({
  resource,
  altImage,
  setIsMediaZoomed,
  zoomEnabled = true,
}: ImageWithZoomProps): JSX.Element => {
  const { url = '' } = resource;

  const { dragRef, onLoad, onZoom } = usePanZoom(0.5, 2);
  const [isActive, setIsActive] = useState(false);

  const onClickZoomOut = () => {
    if (dragRef.current) {
      const containerRect = dragRef.current.getBoundingClientRect();
      // onZoom(ZOOM_OUT);
      onZoom(ZOOM_OUT, containerRect.left + containerRect.width / 2, containerRect.top + containerRect.height / 2);
    }
  };
  const onClickZoomIn = () => {
    if (dragRef.current) {
      const containerRect = dragRef.current.getBoundingClientRect();
      // onZoom(ZOOM_IN);
      onZoom(ZOOM_IN, containerRect.left + containerRect.width / 2, containerRect.top + containerRect.height / 2);
    }
  };
  const onClickZoomReset = () => {
    onLoad();
  };

  const setActiveZoomModal = () => {
    setIsActive(true);
    setIsMediaZoomed(true);
  };

  const disableZoomModal = () => {
    setIsActive(false);
    setIsMediaZoomed(false);
  };

  return (
    <div className='c-lead-image__media'>
      <div className={`c-pan-zoom ${isActive ? 'is-active' : ''}`}>
        <div className='c-pan-zoom__preview'>
          <figure className='c-pan-zoom__preview-media'>
            <button onClick={() => setActiveZoomModal()} disabled={!zoomEnabled}>
              <Img imageType={WIDE} imageId={url} alt={altImage} />
            </button>
          </figure>
          <span className='c-pan-zoom__preview-text'>
            <SvgIcon icon='magnifying-glass' />
            <strong> {i18n.t('image.zoom')}</strong>
          </span>
        </div>
        <div className='c-pan-zoom-area-container'>
          <div className='c-pan-zoom-area u-a-pan-zoom-area-touch-cue'>
            <div className='c-pan-zoom__content'>
              <div
                className='c-pan-zoom-image'
                ref={dragRef}
                onContextMenu={(e) => {
                  e.preventDefault();
                }}
                style={{ touchAction: 'none' }}
              >
                <div
                  className='c-pan-zoom-image__group'
                  style={{ backgroundImage: `url(${getImageUrl(url, IMAGE_FORMATS.GMV_IMAGE_ORIGINAL)})` }}
                >
                  <img
                    className='c-pan-zoom-image__proportion'
                    src={`${getImageUrl(url, IMAGE_FORMATS.GMV_IMAGE_ORIGINAL)}`}
                    alt='zoom enabled'
                    loading='lazy'
                  />
                </div>
              </div>
              <div className='c-pan-zoom-actions__area'>
                <button className='c-button c-pan-zoom-actions__zoom-in' onClick={onClickZoomIn}>
                  <span className='c-button__label'>{i18n.t('questions.annotate.zoomIn')}</span>
                  <SvgIcon icon='plus' />
                </button>
                <button className='c-button c-pan-zoom-actions__zoom-out' onClick={onClickZoomOut}>
                  <span className='c-button__label'>{i18n.t('questions.annotate.zoomOut')}</span>
                  <SvgIcon icon='minus' />
                </button>
                <button className='c-button c-pan-zoom-actions__center' onClick={onClickZoomReset}>
                  <span className='c-button__label'>{i18n.t('questions.annotate.center')}</span>
                  <SvgIcon icon='expand-fit' />
                </button>
              </div>
              <button className='c-button c-pan-zoom__close' onClick={() => disableZoomModal()}>
                <span className='c-button__label'>{i18n.t('close')}</span>

                <SvgIcon icon='times' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const DemographicsTypes = {
  DEMOGRAPHIC_AGE: 'demographic_age', // type 1
  DEMOGRAPHIC_ETHNICITY: 'demographic_ethnicity', // type 2
  DEMOGRAPHIC_GENDER: 'demographic_gender', // type 3
  DEMOGRAPHIC_SEXUALITY: 'demographic_sexuality', // type 4
  DEMOGRAPHIC_RELIGION: 'demographic_religion', // type 5
  DEMOGRAPHIC_HOUSEHOLD_NUMBER: 'demographic_household_number', // type 6
  DEMOGRAPHIC_HOUSEHOLD_INCOME: 'demographic_household_income', // type 7
  DEMOGRAPHIC_ACCOMODATION_TYPE: 'demographic_accommodation_type', // type 8
  DEMOGRAPHIC_ACCOMODATION_OWNERSHIP: 'demographic_accommodation_ownership', // type 9
  DEMOGRAPHIC_EMPLOYEMENT: 'demographic_employment', // type 10
  DEMOGRAPHIC_CUSTOM_TYPE: 'demographic_custom_type', // type 100
};

export const DemographicsChoiceContentTypes = {
  DEFINED: 'DEFINED',
  OTHER: 'OTHER',
  SKIP: 'SKIP',
};

export const CHOICES_BREAK_MODIFIER = 8;

export const CUSTOM_QUESTION_CHOICE_COUNT = 12;

export const DEMOGRAPHIC_PROGRESS_MAX = 81.64;

export const DEMOGRAPHIC_QUESTION_TEXT = 'demographic-question';

export const DEMOGRAPHIC_DELAY = 700;

export const DemographicsTypesIntegerMap = () => {
  const demographicsTypesMap = {};
  demographicsTypesMap[DemographicsTypes.DEMOGRAPHIC_AGE] = 1;
  demographicsTypesMap[DemographicsTypes.DEMOGRAPHIC_ETHNICITY] = 2;
  demographicsTypesMap[DemographicsTypes.DEMOGRAPHIC_GENDER] = 3;
  demographicsTypesMap[DemographicsTypes.DEMOGRAPHIC_SEXUALITY] = 4;
  demographicsTypesMap[DemographicsTypes.DEMOGRAPHIC_RELIGION] = 5;
  demographicsTypesMap[DemographicsTypes.DEMOGRAPHIC_HOUSEHOLD_NUMBER] = 6;
  demographicsTypesMap[DemographicsTypes.DEMOGRAPHIC_HOUSEHOLD_INCOME] = 7;
  demographicsTypesMap[DemographicsTypes.DEMOGRAPHIC_ACCOMODATION_TYPE] = 8;
  demographicsTypesMap[DemographicsTypes.DEMOGRAPHIC_ACCOMODATION_OWNERSHIP] = 9;
  demographicsTypesMap[DemographicsTypes.DEMOGRAPHIC_EMPLOYEMENT] = 10;
  demographicsTypesMap[DemographicsTypes.DEMOGRAPHIC_CUSTOM_TYPE] = 100;
  return demographicsTypesMap;
};

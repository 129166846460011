import React from 'react';

import { getImageUrl, IMAGE_FORMATS } from './Formats';

export default function ImgWide(props) {
  const { imageId, imageType, alt, ...rest } = props;

  return (
    <picture>
      {/* <source media='(max-width: 799px)' srcSet={getImageUrl(imageId, IMAGE_FORMATS.GMV_IMAGE_WIDE_S_800x480)} /> */}
      {/* <source media='(min-width: 800px)' srcSet={getImageUrl(imageId, IMAGE_FORMATS.GMV_IMAGE_WIDE_M_1200x720)} /> */}
      <img src={getImageUrl(imageId, IMAGE_FORMATS.GMV_IMAGE_WIDE_S_800x480)} alt={alt} {...rest} />
    </picture>
  );
}

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

// import auth from './auth';
import polls from './polls';
import poll from './poll';
import news from './news/news';
import customizations from './project/customizations';
import home from './project/home';
import header from './project/header';
import timeline from './timeline/timeline';
import faq from './project/faq';
import feedback from './feedback/feedback';
// import profile from "./profile/profile";
import languages from './languages/languages';
import charities from './project/retrieveCharities';
import homePage from './home/home';
import geolocation from './geolocation/geolocation';
import survey from './survey/survey';

const rootReducer = combineReducers({
  router: connectRouter(createBrowserHistory()),
  // auth,
  polls,
  poll,
  news,
  home,
  header,
  timeline,
  faq,
  feedback,
  // profile,
  languages,
  charities,
  homePage,
  customizations,
  geolocation,
  survey,
});

export default rootReducer;

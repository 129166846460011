import { ReactNode } from 'react';
import i18n from '../../../i18n/helper';
import { CHAR_LIMIT_WARNING } from '../../../constants';

export type BaseFieldProps = {
  id: string;
  label: string;
  description?: string;
  maxLength?: number;
  characterCountWarning?: number;
  isIcon?: boolean;
  value: string;
};

export const BaseField = ({
  id,
  description,
  characterCountWarning = CHAR_LIMIT_WARNING,
  isIcon,
  label,
  maxLength,
  value,
  children,
}: BaseFieldProps & { children: ReactNode }): JSX.Element => {
  const characterCount = (maxLength || 0) - value.length;
  return (
    <div className='c-field'>
      <label className='c-field__label' htmlFor={id}>
        {label}
      </label>
      <div className='c-field__group'>
        <div className={`o-input ${isIcon ? 'o-input--icon' : ''}`}>{children}</div>
        {description && <p className='c-field__description'>{description}</p>}
        {maxLength && (
          <span
            className={`c-field__count ${characterCount < characterCountWarning ? 'c-field__count--limit' : ''}`}
            title={i18n.t('questions.feedbackForm.countTitle')}
          >
            {characterCount}
          </span>
        )}
      </div>
    </div>
  );
};

import React from 'react';
import PropTypes from 'prop-types';

import SliderArrow from '../arrow/SliderArrow';

export default function SliderControls(props) {
  const { setSlide, slide, classModifier = '', items, identifier } = props;
  const size = items.length;

  return (
    <div className={`c-carousel__controls ${classModifier}`}>
      <SliderArrow onClick={() => setSlide((size + slide - 1) % size)} className='slick-arrow' />
      <SliderArrow isRight onClick={() => setSlide((slide + 1) % size)} className='slick-arrow' />
      <ul className='c-carousel__dots' role='tablist' style={{ display: 'flex' }}>
        {items.map((item, index) => (
          <li key={index} role='presentation' className={index === slide ? 'slick-active' : ''}>
            <button
              onClick={() => setSlide(index)}
              type='button'
              role='tab'
              id={`slick-slide-control-${identifier}-${index}`}
              aria-controls={`slick-slide-${identifier}-${index}`}
              aria-label={`${index + 1} of ${size}`}
              tabIndex={index === slide ? '0' : '-1'}
              aria-selected={index === slide}
            >
              {`${index + 1}`}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

SliderControls.propTypes = {
  setSlide: PropTypes.func.isRequired,
  slide: PropTypes.number.isRequired,
  classModifier: PropTypes.string,
  items: PropTypes.array.isRequired,
  identifier: PropTypes.string.isRequired,
};

/* eslint-disable */
module.exports = function () {
  const roadmapOverlayStyle = {
    strokeColor: '#070047',
    strokeOpacity: 0.7,
    strokeWeight: 3,
    fillColor: '#070047',
    fillOpacity: 0.1,
    clickable: true,
    draggable: true,
    editable: true,
    zIndex: 2,
  };

  const satelliteOverlayStyle = {
    strokeColor: '#070047',
    strokeOpacity: 0.7,
    strokeWeight: 3,
    fillColor: '#ffffff',
    fillOpacity: 0.3,
    clickable: true,
    draggable: true,
    editable: true,
    zIndex: 2,
  };

  const markerStyle = {
    icon: '/assets-v2/images/pin-purple.png',
    zIndex: 2,
    draggable: true,
  };

  const locationMapStyle = [
    {
      featureType: 'poi.medical',
      elementType: 'geometry.fill',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.place_of_worship',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.school',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.sports_complex',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.sports_complex',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.sports_complex',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
  ];

  // Shape types MUST be aligned with google map API constants for overlay types
  const shapeTypes = {
    CIRCLE: 'circle', // google.maps.drawing.OverlayType.CIRCLE
    POLYGON: 'polygon', // google.maps.drawing.OverlayType.POLYGON
    RECTANGLE: 'rectangle', // google.maps.drawing.OverlayType.RECTANGLE
    MARKER: 'marker', // google.maps.drawing.OverlayType.MARKER
  };

  const mapOptions = {
    zoom: 14,
    lat: 51.508859,
    lng: -0.111376,
  };

  return {
    roadmapOverlayStyle,
    satelliteOverlayStyle,
    markerStyle,
    locationMapStyle,
    shapeTypes,
    mapOptions,
  };
};

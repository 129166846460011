import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';
import Link from '../link/Link';

export default function MoreItem(props) {
  const { title, icon, url, isProjectUrl, openInNewWindow } = props;
  return (
    <li className='c-nav-list__item'>
      {/* eslint-disable-next-line */}
      <Link url={url} className='c-nav-list__link' isProjectUrl={isProjectUrl} openInNewWindow={openInNewWindow}>
        <span className='c-nav-list__label'>{title}</span>
        <SvgIcon icon={icon} />
      </Link>
    </li>
  );
}

MoreItem.propTypes = {
  title: PropTypes.node,
  icon: PropTypes.string,
  url: PropTypes.string,
  isProjectUrl: PropTypes.bool,
  openInNewWindow: PropTypes.bool,
};

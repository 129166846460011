import {
  isQuestion,
  INCLUDE_QQ,
  EXCLUDE_FACTS,
  makeQualifyingQuestion,
} from '../containers/questions/QuestionConstants';

const initialState = {
  showPoll: false,
};

function getTotalNrQuestions(questions) {
  return questions.filter((item) => isQuestion(item.questionType, INCLUDE_QQ, EXCLUDE_FACTS)).length;
}

function insertQuestion(questions, questionWrapper, isConditionalPoll) {
  if (questionWrapper && !questionWrapper.isSubmitted) {
    switch (questionWrapper.positionInPoll) {
      case 'first':
        return [questionWrapper.question, ...questions];
      case 'last':
        return [...questions, questionWrapper.question];
      case 'middle':
        // inserting in middle would potentially mess up conditional logic
        if (isConditionalPoll) {
          return [questionWrapper.question, ...questions];
        }
        // modify questions to insert new question in the middle
        questions.splice(Math.floor(questions.length / 2), 0, questionWrapper.question);
        return questions;
      // if wrong definition, return at first position
      default:
        return [questionWrapper.question, ...questions];
    }
  }
  // there is no question to insert
  return questions;
}

function getCombinedQuestions(questions, questionsToAdd, isConditionalPoll) {
  const completeQuestions = questionsToAdd.reduce(
    (intermediatyQuestionArray, nextQuestion) =>
      insertQuestion(intermediatyQuestionArray, nextQuestion, isConditionalPoll),
    questions,
  );

  if (isConditionalPoll) {
    return completeQuestions;
  }
  return completeQuestions.filter((question) => !question.isSubmitted);
}

// function getCombinedQuestions(questions, qualifyingQ, demographicQ, isConditionalPoll) {
//   // API returns e.g. 'multiple-image other choice' as questionType for segmentation question
//   // question renderer in QuestionSwitcher expects different questionTypes for segmentation and normal questions
//   // thus, we change segmentation questionType to match the expected value (e.g. QQ_106_MULTI_IMAGE) and not
//   // the value received from the server
//   const qualifyingQestion = qualifyingQ && {
//     ...qualifyingQ,
//     question: {
//       ...qualifyingQ?.question,
//       questionType: makeQualifyingQuestion(qualifyingQ?.question.questionType),
//     },
//   };
//   const questionsWithQualifying = insertQuestion(questions, qualifyingQestion, isConditionalPoll);
//   const completeQuestions = insertQuestion(questionsWithQualifying, demographicQ, isConditionalPoll);
//   if (isConditionalPoll) {
//     return completeQuestions;
//   }
//   return completeQuestions.filter((question) => !question.isSubmitted);
// }

export default (state = initialState, action) => {
  // API returns e.g. 'multiple-image other choice' as questionType for segmentation question
  // question renderer in QuestionSwitcher expects different questionTypes for segmentation and normal questions
  // thus, we change segmentation questionType to match the expected value (e.g. QQ_106_MULTI_IMAGE) and not
  // the value received from the server

  switch (action.type) {
    case 'SUCCESS_RETRIEVE_POLL': {
      // this call returns
      // https://github.com/sdesregistry/IH-Engage/blob/fix/conditional-usecases/src/utils/hooks/useConditionalQuestion/conditional-usecases.md#new-questions-schema
      const qualifyingQuestion = action.data.data.segmentationQuestion && {
        ...action.data.data.segmentationQuestion,
        question: {
          ...action.data.data.segmentationQuestion?.question,
          questionType: makeQualifyingQuestion(action.data.data.segmentationQuestion?.question.questionType),
        },
      };
      return {
        ...state,
        ...action.data.data,
        questions: getCombinedQuestions(
          action.data.data.questions,
          [qualifyingQuestion, action.data.data.demographicQuestion, action.data.data.personalData],
          action.data.data.isConditionalPoll,
        ),
        totalQuestions: getTotalNrQuestions(action.data.data.questions),
      };
    }

    default:
      return state;
  }
};

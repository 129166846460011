import React from 'react';
import PropTypes from 'prop-types';

import { DemographicsChoiceContentTypes } from '../DemographicConstants';
import SvgIcon from '../../../../SvgIcon';

export default function DemographicTemplateChoiceDefault(props) {
  const { choiceKey, choiceLabel, questionType, choiceContentType, handleSelect, choiceId } = props;
  return (
    <div
      className={`c-choice-list ${
        choiceContentType === DemographicsChoiceContentTypes.SKIP ? 'c-choice-list--skip' : ''
      }`}
    >
      <input type='radio' id={`${choiceKey}_${choiceId}`} name={questionType} onChange={() => handleSelect(choiceId)} />
      <label htmlFor={`${choiceKey}_${choiceId}`}>
        <span className='c-choice-list__title'>{choiceLabel}</span>
        {choiceContentType === DemographicsChoiceContentTypes.SKIP && <SvgIcon icon='forward' />}
        <SvgIcon icon='check' />
      </label>
    </div>
  );
}

DemographicTemplateChoiceDefault.propTypes = {
  choiceKey: PropTypes.string,
  choiceLabel: PropTypes.string.isRequired,
  questionType: PropTypes.string.isRequired,
  choiceContentType: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
  choiceId: PropTypes.string.isRequired,
};

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import i18n from 'i18n/helper';

import Slim from './slimFileUpload/slim.react';
import SvgIcon from '../SvgIcon';
import { uploadImage } from '../../actions';
// import { getImageUrl } from '../images/Formats';

function SlimUploader(props) {
  const {
    imageFormat,
    id,
    tip,
    image = { id: '', fileName: 'untitled' },
    modifierClass,
    setImage,
    actions,
    isAnnotate = false,
  } = props;
  let slimComponent = null;

  // const [uploadedImageId, setUploadedImageId] = useState(imageId);

  const slimUpload = (formdata, progress, success) => {
    actions
      .uploadImage(formdata[0], (progressEvent) => {
        // handleUpload(formdata[0], progressEvent => {
        // console.log(progressEvent);
        progress(progressEvent.loaded, progressEvent.total);
      })
      .then((res) => {
        // console.log('UPLOAD FINISHED', res);
        // setUploadedImageId(res.imageId);
        setImage(res);
        success();
      });
  };
  // const slimUpload = (formdata, progress, success, failure, slim) => {
  //   handleUpload(formdata[0], progressEvent => {
  //     console.log(progressEvent);
  //     progress(progressEvent.loaded, progressEvent.total);
  //   });
  // };

  /**
   * Need this transformation to add white background to transparent PNG images
   * No problem (not needed) on any non-transparent image
   * refs.
   * solution https://codecanyon.net/comments/17060275
   * API ref. https://pqina.nl/slim/ data-did-transform
   * ### IMPORTANT ###
   * between the answer and new version there is a difference that new version
   * does NOT receive ready callback, but instead data is returned directly
   */
  const didTransform = (data /* API change , ready */) => {
    // get the drawing context and size of the output image
    const ctx = data.output.image.getContext('2d');
    const { width } = data.output;
    const { height } = data.output;

    // draw behind current image
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = '#fff'; // use white background color
    ctx.fillRect(0, 0, width, height);

    // restore to default composite operation (is draw over current image)
    ctx.globalCompositeOperation = 'source-over';

    // continue saving the data
    return data;
    // API changed - original uses ready callback
    // ready(data);
  };

  // register Slim component so I can remove image after successful upload
  // Needed for ie. gallery where I should get empty Slim after upload
  const didInit = (data, slim) => {
    slimComponent = slim;
  };
  // Clear image from Slim after upload finished
  const didUpload = () => {
    // console.log('didUpload');
    slimComponent && slimComponent.remove();
  };

  const getFilenameLong = () =>
    image.filename.length > 20 ? `${image.filename.substring(0, 10)}...` : image.filename || 'Untitled';

  return (
    <React.Fragment>
      {
        /* Case when image present */
        image.id && (
          <React.Fragment>
            <div className={`c-upload ${modifierClass || ''} ${imageFormat.previewModifier || ''}`}>
              <div className='c-upload__preview'>
                {/* <img src={getImageUrl(imageUrl || uploadedImageId, imageFormat.imageType)} alt='' /> */}
                {/* <img src={getImageUrl(imageUrl || uploadedImageId, { name: '' })} alt='' className='c-upload__image' /> */}
                <img
                  src={`https://s3.us-west-2.amazonaws.com/gmv-done.image.givemyview.com/user-content/image/${image.id}`}
                  alt=''
                  className='c-upload__image'
                />
                <span className='c-upload__image-label'>{getFilenameLong()}</span>
                <button className='c-upload__remove' type='button' onClick={() => setImage({})}>
                  <SvgIcon icon='times' />
                  <span className='u-screen-reader-text'>{i18n.t('questions.feedbackForm.removeImage')}</span>
                </button>
              </div>
            </div>
            {tip && <p className='c-field__description'>{tip}</p>}
          </React.Fragment>
        )
      }

      {/* NO IMAGE */}
      {!image.id && (
        <Fragment>
          <div className='c-upload'>
            <div className='c-upload__select'>
              <Slim
                {...imageFormat}
                jpegCompression={50}
                forceType='jpg'
                service={slimUpload}
                serviceFormat='file'
                edit={false}
                push
                maxFileSize={7}
                didUpload={didUpload}
                didInit={didInit}
                label='Some label'
                popoverClassName={imageFormat.popupModifier}
                didTransform={didTransform}
              >
                <input type='file' name='question-image-upload' id={id} accept='image/jpeg, image/png, image/gif' />
              </Slim>

              <div className='c-upload__placeholder'>
                <SvgIcon icon='camera' />
                {isAnnotate ? (
                  <React.Fragment>
                    <span className='o-label o-label--short'>{i18n.t('questions.annotate.photo')}</span>
                    <span className='o-label o-label--long'>{i18n.t('questions.feedbackForm.addImage')}</span>
                  </React.Fragment>
                ) : (
                  <span className='o-label'>{i18n.t('questions.feedbackForm.addImage')}</span>
                )}
              </div>
            </div>
          </div>
          {tip && <p className='c-field__description'>{tip}</p>}
        </Fragment>
      )}
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      uploadImage,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(SlimUploader);

SlimUploader.propTypes = {
  imageFormat: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  tip: PropTypes.string,
  imageUrl: PropTypes.string,
  modifierClass: PropTypes.string,
  image: PropTypes.objectOf(PropTypes.string, PropTypes.string),
  setImage: PropTypes.func.isRequired,
  isAnnotate: PropTypes.bool,
};

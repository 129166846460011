import React from 'react';

import ProjectCard from '../projectCard/ProjectCard';

export default function Projects({ projects }) {
  // console.log("PROJECTS", projects);
  return (
    <React.Fragment>
      {projects && projects.map((project, idx) => <ProjectCard key={idx} project={project} classModifier='' />)}
    </React.Fragment>
  );
}

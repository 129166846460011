import React from 'react';
import i18n from '../../i18n/helper';

import { PRIVACY_POLICY_LINK, TERMS_AND_CONDITIONS_LINK } from '../../constants';

const ITEM_LIST = {
  share: {
    label: <i18n.Translate value='Share' />,
    icon: 'share',
    url: '/share',
  },
  feedback: {
    label: <i18n.Translate value='Feedback' />,
    icon: 'comment-alt-dots',
    url: '/feedback',
  },
  website: {
    label: <i18n.Translate value='website' />,
    icon: 'globe',
    url: '',
  },
  faq: {
    label: <i18n.Translate value='FAQS' />,
    icon: 'arrow-right',
    url: '/faq',
  },
  toc: {
    label: <i18n.Translate value='dashboard.settings.terms' />,
    icon: 'arrow-right',
    url: TERMS_AND_CONDITIONS_LINK,
    isProjectUrl: false,
    openInNewWindow: true,
  },
  pp: {
    label: <i18n.Translate value='dashboard.settings.policy' />,
    icon: 'arrow-right',
    url: PRIVACY_POLICY_LINK,
    isProjectUrl: false,
    openInNewWindow: true,
  },
};

export default ITEM_LIST;

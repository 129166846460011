import { useRef } from 'react';
import { SvgIcon } from 'components/atoms/svgIcon';
import { useDrop, XYCoord } from 'react-dnd';
import { getImageUrl, IMAGE_FORMATS } from 'components/images/Formats';
import i18n from 'i18n/helper';
// import usePanZoom from 'components/panZoomHook/usePanZoom';
import { AnnotateAreaProps, DropReaction } from './types';
import { DRAG_FORMAT, REDRAG_FORMAT } from '../utils';
import { AnnotateAreaPin } from './AnnotateAreaPin';

export function AnnotateArea({
  resource,
  placedReactions,
  addReaction,
  dragRef,
  state,
  onLoad,
  setPlacedReactions,
  removeLastReaction,
  isDragAllowed,
  setEnableDragging,
}: AnnotateAreaProps): JSX.Element {
  const imageRef = useRef<HTMLImageElement | null>(null);

  const getReactionLocation = (location: XYCoord) => {
    if (dragRef.current) {
      const { left: dragLeft, top: dragTop, width, height } = dragRef.current.getBoundingClientRect();
      const left = ((location.x - dragLeft) / width) * 100;
      const top = ((location.y - dragTop) / height) * 100;
      return { top, left };
    }
    return { top: 50, left: 50 };
  };

  const [, drop] = useDrop(
    () => ({
      accept: [DRAG_FORMAT, REDRAG_FORMAT],
      drop: (item: DropReaction, monitor) => {
        const location = monitor.getClientOffset() || { x: 0, y: 0 };
        const { top, left } = getReactionLocation(location);
        if (monitor.getItemType() === REDRAG_FORMAT) {
          removeLastReaction();
        }
        addReaction(item, left, top);
      },
    }),
    [addReaction],
  );

  const onImageLoad = () =>
    setTimeout(() => {
      onLoad();
      if (dragRef.current) {
        // eslint-disable-next-line no-param-reassign
        dragRef.current.style.visibility = 'visible';
      }
    }, 100);

  return (
    <div className='c-annotate-area u-a-annotation-area-touch-cue'>
      <p className='c-annotate-area__note'>
        <SvgIcon icon='swipe-indicator' />
        {i18n.t('questions.annotate.map.explainer')}
      </p>
      <div className='c-annotate-area__content' dir='ltr'>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          ref={dragRef}
          className='c-annotate-image'
          onContextMenu={(e) => {
            e.preventDefault();
          }}
          style={{ touchAction: 'none', visibility: 'hidden' }}
        >
          <div
            className='c-annotate-image__group'
            style={{
              backgroundImage: `url(${getImageUrl(resource, IMAGE_FORMATS.GMV_IMAGE_ORIGINAL)})`,
            }}
            ref={drop}
          >
            <img
              ref={imageRef}
              onLoad={onImageLoad}
              className='c-annotate-image__proportion'
              src={`${getImageUrl(resource, IMAGE_FORMATS.GMV_IMAGE_ORIGINAL)}`}
              alt=''
            />
            {placedReactions.map((reaction, index) => (
              <AnnotateAreaPin
                setPlacedReactions={setPlacedReactions}
                reaction={reaction}
                scale={state.scale}
                key={index}
                isDragAllowed={isDragAllowed && index === placedReactions.length - 1}
                setEnableDragging={setEnableDragging}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

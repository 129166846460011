import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import i18n from '../i18n/helper';
import PillButtonHashLink from '../components/button/PillButtonHashLink';
import { getNoOfPhases as getNoOfPhasesAction } from '../actions';
import Phase from '../components/timeline/Phase';
import Panel from '../components/panel/Panel';

function Timeline(props) {
  const { timeline, actions, slugId, history } = props;
  const { getNoOfPhases } = actions;

  const [phases, setPhases] = useState([]);

  useEffect(() => {
    getNoOfPhases(slugId);
  }, [getNoOfPhases, slugId]);

  useEffect(() => {
    timeline.phases.phases ? setPhases(timeline.phases.phases) : setPhases([]);
  }, [timeline]);

  return (
    <main className='l-section-main c-section-main' id='appContent'>
      <div className='l-container'>
        {timeline.phases.panel && <Panel history={history} panel={timeline.phases.panel} />}
        <div className='c-section-intro'>
          <h1 className='c-section-intro__title'>{i18n.t('header.timeline')}</h1>
          {phases.length > 1 && (
            <div className='c-section-intro__action'>
              <p className='u-screen-reader-text-only-mobile'>{i18n.t('body.phases')}</p>
              {phases.map((item) => (
                <PillButtonHashLink
                  key={item._id}
                  classModifier='c-button--small c-button--number'
                  url='timeline'
                  hash={`#phase-${item.phaseId}`}
                  screenReaderTitle={i18n.t('body.phase')}
                  title={`${item.phaseId}`}
                  icon={false}
                />
              ))}
            </div>
          )}
        </div>
        <div className='c-timeline'>
          {phases.map((item) => (
            <Phase
              key={item._id}
              phaseTitle={item.phaseTitle}
              phaseDescription={item.phaseDescription}
              phaseNumber={item.phaseId}
              stages={item.updates}
            />
          ))}
        </div>
      </div>
    </main>
  );
}

const mapStateToProps = (state) => ({
  timeline: state.timeline,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getNoOfPhases: getNoOfPhasesAction,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Timeline);

Timeline.propTypes = {
  timeline: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  slugId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};

const initialState = {
  isLoading: true,
  feedbackEmailZip: { email: null, postCode: null },
};
export default (state = initialState, action) => {
  switch (action.type) {
    case 'SUCCESS_ADD_POLLS':
      return { ...state, ...action.data, isLoading: false };
    case 'FAIL_ADD_POLLS':
      return { ...state, ...action.data, isLoading: false };
    case 'FEEDBACK_EMAIL_ZIP':
      return { ...state, feedbackEmailZip: action.value };
    default:
      return state;
  }
};

import React from 'react';
import Spinner from './components/spinner/spinner';

class RouteNotFound extends React.Component {
  componentDidMount() {
    this.props.redirectTo();
  }

  render() {
    return <Spinner />;
  }
}

export default RouteNotFound;

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Header from '../components/mainHome/Header';
import Footer from '../components/mainHome/Footer';
import TermsAndConditions from '../components/termsAndConditions/Terms';
import PrivacyPolicy from '../components/termsAndConditions/PrivacyPolicy';

export default function TermsContainer() {
  // console.log('TERMS', props);
  return (
    <React.Fragment>
      <div className='l-marketing'>
        <Header />
        <div className='l-m-section-main c-m-section-main'>
          <Switch>
            <Route exact path='/terms-and-conditions' component={TermsAndConditions} />
            <Route exact path='/privacy-policy' component={PrivacyPolicy} />
          </Switch>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

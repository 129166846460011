import React from 'react';
import PropTypes from 'prop-types';
// import thumbup from '../../assets/images/Icons/thumbup.svg';
import { Img, WIDE } from '../images/Img';
import MarkdownField, { MARKDOWN_MIN_LINK } from '../markdown/MarkdownField';

const InfluenceDecision = (props) => {
  const { resource, title, description, type, index, identifier } = props;
  return (
    <div
      className={`c-influenced__item c-carousel__item ${resource ? '' : 'c-influenced__item--no-figure'}`}
      role='tabpanel'
      aria-describedby={`slick-slide-control-${identifier}-${index}`}
      id={`slick-slide-${identifier}-${index}`}
    >
      {resource && (
        <figure className='c-influenced__figure'>
          {type === 1 && resource && <Img imageType={WIDE} imageId={resource} alt='media' />}
        </figure>
      )}
      {(title || description) && (
        <div className='c-influenced__group'>
          {title && <h3 className='c-influenced__title'>{title}</h3>}
          {description && (
            <p className='c-influenced__summary u-rich-text'>
              <MarkdownField markdown={description} markdownConfig={MARKDOWN_MIN_LINK} />
            </p>
          )}
        </div>
      )}
    </div>
  );
};

InfluenceDecision.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  resource: PropTypes.string,
  type: PropTypes.number.isRequired,
  index: PropTypes.number,
  identifier: PropTypes.string,
};

export default InfluenceDecision;

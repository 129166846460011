import * as header from '../../services/project/header';

export const getAboutData = (slug_id) => (dispatch) =>
  header
    .getDataFromServer(slug_id)
    .then((response) => {
      dispatch({
        type: 'SUCCESS_ADD_ABOUT_DATA',
        data: response.data,
      });
      return true;
    })
    .catch((response) => {
      dispatch({
        type: 'FAIL_ADD_ABOUT_DATA',
        data: response,
      });
      return false;
    });

import axios from 'axios';
import * as url from '../constants.js';
import { getBidTokenWithExpiry } from '../utils/utils';

export { SERVER_V2 } from '../constants';

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      return null;
    }
    if ([502].includes(error.response.status)) {
      return Promise.reject(error);
    }
    if (error.response.status === 401) {
      // window.location.reload(false);
      return Promise.reject(error);
    }
    if ([400, 403].includes(error.response.status)) {
      return Promise.reject(error);
    }
    return error;
  },
);

const authHeaders = () => {
  const token = localStorage.getItem('token') !== null ? `Bearer ${localStorage.getItem('token')} ` : null;
  const bidToken = getBidTokenWithExpiry() !== undefined ? getBidTokenWithExpiry() : null;
  const location = localStorage.getItem('Geo-Position') !== null ? localStorage.getItem('Geo-Position') : null;
  const lang = localStorage.getItem('lang') !== null ? localStorage.getItem('lang') : null;
  const isPreview = sessionStorage.getItem('isPreview') === 'true' ? 'true' : undefined;
  const auth = {};
  if (token) {
    auth.Authorization = token;
  }
  if (bidToken) {
    auth['BID-Authorization'] = bidToken;
  }
  if (location) {
    auth['Geo-Position'] = location;
  }
  if (lang) {
    auth['Accept-Language'] = lang;
  }
  const headers = {
    ...auth,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (isPreview) {
    headers.isPreview = isPreview;
  }

  return headers;
};

export const all = (fn, data) => {
  const promisArray = data.map((item) => fn(item.endpoint, item.data).catch(() => false));
  return axios.all(promisArray);
};

export const get = (endpoint, config, server = url.SERVER_V1) =>
  axios({
    url: server + endpoint,
    method: 'get',
    headers: authHeaders(),
    ...config,
  })
    .then((response) => response)
    .catch((error) => Promise.reject(error.response));

export const post = (endpoint, data, server = url.SERVER_V1) =>
  axios({
    url: server + endpoint,
    method: 'post',
    headers: authHeaders(),
    data,
  })
    .then((response) => response)
    .catch((error) => Promise.reject(error.response));

export const patch = (endpoint, data, server = url.SERVER_V1) =>
  axios({
    url: server + endpoint,
    method: 'patch',
    headers: authHeaders(),
    responseType: 'json',
    data,
  })
    .then((response) => response)
    .catch((error) => Promise.reject(error.response));

export const remove = (endpoint, data, server = url.SERVER_V1) =>
  axios({
    url: server + endpoint,
    method: 'delete',
    headers: authHeaders(),
    responseType: 'json',
    data,
  })
    .then((response) => response)
    .catch((error) => Promise.reject(error.response));

/**
 * Prepare POST request for upload
 * @param url
 * @param type
 * @param file
 * @returns {*}
 */
export async function uploadImage(file, progress) {
  // first get a signed upload URL from S3
  // const signed = await axios({
  //   url: 'http://localhost:9000/gmv-backend-api/v1/projects/image-upload-url',
  //   method: 'get',
  //   headers:{
  //     'Accept-Language': 'en',
  //     Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1NTExZGE1YmMyMzI1OTAzMDBlOTI0ZGYiLCJpYXQiOjE2MjE1MDU5NDAsImV4cCI6MTYyNDA5Nzk0MH0.3fnW3Dq_ugl0PqhUf8Cbktgi82En_cxNhdReYcxJGnY'
  //   },
  // })
  // .then(response => response)
  // .catch((error) => Promise.reject(error.response));

  const signed = await get('/projects/user-content-upload-url/images', null, url.SERVER_V2);
  const { signedUrl, imageId, pureId } = signed.data;

  await axios.put(signedUrl, file, {
    headers: { 'Content-Type': 'image/jpeg' },
    onUploadProgress: progress,
  });
  // await getRequests().s3upload(signedUrl, file, {
  //   headers: { 'Content-Type': 'image/jpeg' },
  //   onUploadProgress: progress,
  // });

  const image = {
    id: imageId,
    _id: pureId,
    filename: file.name,
    contentType: file.type,
    length: file.size,
    mediaType: 'image',
    metadata: { type: 'GMV' },
  };

  return image;
  // return axios({
  //   url: `https://localhost:9000/gmv-backend-api/v1/${url}`,
  //   method: 'post',
  //   headers: {
  //     'Accept-Language': 'en',
  //     Authorization:
  //       'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1NTExZGE1YmMyMzI1OTAzMDBlOTI0ZGYiLCJpYXQiOjE2MjE1MDU5NDAsImV4cCI6MTYyNDA5Nzk0MH0.3fnW3Dq_ugl0PqhUf8Cbktgi82En_cxNhdReYcxJGnY',
  //   },
  //   data: image,
  // })
  //   .then(response => {return {response, imageId}})
  //   .catch(error => Promise.reject(error.response));
}

import * as api from './api';

const PROJECTS = '/projects/';
const USERTOKEN = '/users/me/token';

// V2 API

export const retrievePollFromServer = (slug_id, poll_id) =>
  api.get(`${PROJECTS}${slug_id}/polls/${poll_id}`, undefined, api.SERVER_V2);

export const pollQuestionSeen = (slug_id, poll_id, question_id) =>
  api.post(`${PROJECTS}${slug_id}/polls/${poll_id}/question/${question_id}/seen`, undefined, api.SERVER_V2);

export const pollQuestionSkip = (slug_id, poll_id, question_id) =>
  api.post(`${PROJECTS}${slug_id}/polls/${poll_id}/question/${question_id}/skip`, undefined, api.SERVER_V2);

export const pollFinished = (slugId, pollId) =>
  api.post(`${PROJECTS}${slugId}/polls/${pollId}/completed`, undefined, api.SERVER_V2);

// V1 API

export const getPollsFromServer = (slug_id) => api.get(`${PROJECTS}${slug_id}/polls`);

export const temporaryAuthToken = () => api.get(USERTOKEN);

export const submitPollQues = (slug_id, poll_id, question_id, data) =>
  api.post(`${PROJECTS}${slug_id}/polls/${poll_id}/question/${question_id}`, data);

export const submitCharityDonation = (slug_id, poll_id, charity_id, data) =>
  api.post(`${PROJECTS}${slug_id}/polls/${poll_id}/donate/${charity_id}`, data);

export const submitSocialShare = (slug_id) => api.post(`${PROJECTS}${slug_id}/share`);

export const uploadImage = (file, progressHandler) => api.uploadImage(file, progressHandler);

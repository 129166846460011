import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import propTypes from 'prop-types';

import { GeneralModal } from 'components/molecules/generalModal';
import i18n from '../../i18n/helper';
import { sendFeedbackData } from '../../actions';
import FeedbackForm from '../../components/feedbackForm/FeedbackForm';

function Feedback(props) {
  const { slugId, actions } = props;

  const [disableForm, setDisableForm] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const sendFeedback = (formData) => {
    setDisableForm(true);
    // e.stopPropagation();
    const data = {
      projectId: slugId,
      // will not work without feedbackId,
      // since there is no longer an option to select the type of feedback, 3 is hardcoded
      feedbackId: 3,
      subject: formData.feedbackSubject,
      description: formData.feedback,
      email: formData.email,
      postCode: formData.postalCode,
    };

    actions.sendFeedbackData(data).then((res) => {
      setDisableForm(false);
      if (res.status === 201) {
        setShowModal(true);
      } else {
        alert(i18n.t('feedBackScreen.cantSubmit'));
      }
    });
  };

  return (
    <main className='l-section-main c-section-main' id='appContent'>
      <div className='l-container'>
        <div className='c-section-intro'>
          <h1 className='c-section-intro__title'>{i18n.t('Feedback')}</h1>
        </div>
        <FeedbackForm
          disableForm={disableForm}
          onSubmitFeedback={sendFeedback}
          showCoin={false}
          isGeneralFeedback
          slugId={slugId}
        />
        {showModal ? (
          <GeneralModal
            description={i18n.t('feedBackScreen.submit')}
            closeCallback={() => {
              setShowModal(false);
            }}
          />
        ) : null}
      </div>
    </main>
  );
}

Feedback.propTypes = {
  slugId: propTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  userData: state.feedback.userData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ sendFeedbackData }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Hero from '../components/mainHome/Hero';
import Header from '../components/mainHome/Header';
import Projects from '../components/mainHome/Projects';
import { getHomeProjectsData } from '../actions';
import { Version } from '../components/atoms/version';
import Footer from '../components/mainHome/Footer';
import CookieNotice from '../components/cookieNotice/CookieNotice';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [{}, {}, {}, {}],
      showCookieControl: false,
      showCookieNotice: !sessionStorage.getItem('isPreview') && localStorage.getItem('CookieAccepted') !== 'YES',
    };
  }

  componentDidMount() {
    this.props.actions.getHomeProjectsData().then(() => {
      this.setState({ projects: this.props.projects });
    });
  }

  manageCookies = () => {
    this.setState({ showCookieControl: true, showCookieNotice: false });
  };

  render() {
    return (
      <React.Fragment>
        <div className='l-marketing'>
          <Header />
          <div className='l-m-section-main c-m-section-main'>
            <div className='c-m-block c-m-block--light c-m-block--hero'>
              <div className='l-container'>
                <Hero />
              </div>
            </div>

            <div className='c-m-block'>
              <div className='l-container'>
                <div className='l-grid-cards'>
                  <Projects projects={this.state.projects} />
                </div>
              </div>
            </div>

            <div className='c-m-block c-m-block--dark c-m-block--cta'>
              <div className='l-container'>
                <h2 className='c-m-block__title'>
                  Empower your community with the Give My View digital engagement platform
                </h2>

                <div className='c-m-block__action'>
                  <a
                    href='https://www.built-id.com/contact'
                    className='c-button c-button--alternate c-button--large c-button--pill'
                  >
                    <span className='c-button__label'>Contact us</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <Footer openCookieControl={this.state.showCookieControl} />
        </div>

        <Version />

        {this.state.showCookieNotice && <CookieNotice onManageCookies={this.manageCookies} />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({ projects: state.homePage.projects });

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ getHomeProjectsData }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { submitPollQuestion, getAboutData, getPolls } from '../../../actions';
import { executeUpdate, DEFAULT_STATE } from '../QuestionUtils/QuestionFormUtils';
import ImageQuestion from '../imageQuestion/ImageQuestion';
import MultipleChoice from '../06_MultipleChoiceQuestion/06_Question';
import { TWO_IMAGE_CHOICE, THREE_IMAGE_CHOICE, FOUR_IMAGE_CHOICE } from './QQUtils';
import {
  QQ_103_SINGLE_3_IMAGE,
  QQ_104_SINGLE_4_IMAGE,
  QQ_106_MULTI_IMAGE,
} from '../../../containers/questions/QuestionConstants';

function ImageQualifyingQuestions(props) {
  const { question, slugId, pollId, questionButtonClicked, actions, showCoinsInHeader, skipResults, isQQ, qType } =
    props;
  const [state, setState] = useState(DEFAULT_STATE);

  const onSubmitQuestion = (answer) => {
    // console.log('POSTing answer', answer);
    executeUpdate(
      setState,
      slugId,
      pollId,
      question._id,
      answer,
      actions,
      showCoinsInHeader,
      skipResults,
      isQQ,
      questionButtonClicked,
    );
  };

  const getType = (qqType) => {
    if (qqType === QQ_104_SINGLE_4_IMAGE) {
      return FOUR_IMAGE_CHOICE;
    }
    if (qqType === QQ_103_SINGLE_3_IMAGE) {
      return THREE_IMAGE_CHOICE;
    }
    return TWO_IMAGE_CHOICE;
  };

  const qqType = getType(qType);
  const isQQMultiImage = qType === QQ_106_MULTI_IMAGE;
  return (
    <div className='c-modal-poll__content'>
      {isQQMultiImage ? (
        <MultipleChoice
          onSubmitQuestion={onSubmitQuestion}
          isResult={false}
          question={question}
          communityAnswer={[]}
          showCoin={state.showCoin}
          questionButtonClicked={questionButtonClicked}
          disableForm={state.disableForm}
        />
      ) : (
        <ImageQuestion
          articleClassModifier={qqType.articleClassModifier}
          imageType={qqType.imageType}
          imageGridClassModifier={qqType.imageGridClassModifier}
          media={qqType.media}
          question={question}
          onSubmitQuestion={onSubmitQuestion}
          isResult={false}
          showCoin={state.showCoin}
          questionButtonClicked={questionButtonClicked}
          communityAnswer={[]}
        />
      )}
    </div>
  );
}

ImageQualifyingQuestions.propTypes = {
  qType: PropTypes.number.isRequired,
  question: PropTypes.object.isRequired,
  slugId: PropTypes.string.isRequired,
  pollId: PropTypes.string.isRequired,
  questionButtonClicked: PropTypes.func.isRequired,
  showCoinsInHeader: PropTypes.func.isRequired,
  skipResults: PropTypes.bool.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      submitPollQuestion,
      getAboutData,
      getPolls,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageQualifyingQuestions);

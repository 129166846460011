import React from 'react';
import PropTypes from 'prop-types';
import ReactCompareImage from 'react-compare-image';
import i18n from '../../../i18n/helper';
import { getImageUrl, IMAGE_FORMATS } from '../../images/Formats';
import FactIllustration from '../../../assets-v2/images/illustration/fact-book.png';
import MarkdownField, { MARKDOWN_MIN_PLUS } from '../../markdown/MarkdownField';

export default function FactCompare12(props) {
  const { question, questionButtonClicked } = props;
  const leftImageId = question.resource[0] ? question.resource[0].url : '';
  const rightImageId = question.resource[1] ? question.resource[1].url : '';
  return (
    <div className='c-modal-poll__content'>
      <article className='c-question'>
        <div className='c-question__header' />
        <div className='c-question__content'>
          <div className='c-fact c-fact--compare u-a-children-fade-in'>
            <div className='c-fact__header'>
              <figure className='c-fact__illustration'>
                <img src={FactIllustration} alt='' />
              </figure>
              <h3 className='c-fact__title'>{i18n.t('questions.quickfact')}</h3>
            </div>
            <div className='c-fact__content u-rich-text'>
              <MarkdownField markdown={question.questionText} markdownConfig={MARKDOWN_MIN_PLUS} />
            </div>
            <div className='c-fact__media'>
              <div className='fact_drag'>
                <ReactCompareImage
                  leftImage={getImageUrl(leftImageId, IMAGE_FORMATS.GMV_IMAGE_SQUARE_M_600x600)}
                  rightImage={getImageUrl(rightImageId, IMAGE_FORMATS.GMV_IMAGE_SQUARE_M_600x600)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='c-question__footer'>
          <button className='c-button c-button--alternate c-button--large' onClick={questionButtonClicked}>
            <span className='c-button__label'>{i18n.t('questions.factType3.buttonText')}</span>
          </button>
        </div>
      </article>
    </div>
  );
}

FactCompare12.propTypes = {
  question: PropTypes.object.isRequired,
  questionButtonClicked: PropTypes.func.isRequired,
};

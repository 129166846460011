import React from 'react';
import PropTypes from 'prop-types';
import { Img } from '../images/Img';
import Coin from '../coin/coin';

export default function SelectImage(props) {
  const { resource, choiceName, communityAnswer, imageType, isFixedImgURL, showCoin } = props;
  return (
    <React.Fragment>
      <span className='c-choice-image__media'>
        {showCoin && <Coin />}
        {isFixedImgURL ? (
          <img src={resource} alt={choiceName} />
        ) : (
          <Img imageId={resource} alt={choiceName} imageType={imageType} />
        )}
        <span className='c-choice-image__state'>
          <span className='c-choice-image__value'>{`${communityAnswer}%`}</span>
          <span className='c-choice-image__bar'>
            <span className='c-choice-image__bar-full' style={{ height: `${communityAnswer}%` }} />
          </span>
        </span>
      </span>
    </React.Fragment>
  );
}

SelectImage.propTypes = {
  isFixedImgURL: PropTypes.bool,
  resource: PropTypes.string.isRequired,
  choiceName: PropTypes.string,
  communityAnswer: PropTypes.number,
  imageType: PropTypes.string.isRequired,
  showCoin: PropTypes.bool,
};

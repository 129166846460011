import React from 'react';
import PropTypes from 'prop-types';

import { Img, WIDE } from '../images/Img';
import MarkdownField, { MARKDOWN_MIN_LINK } from '../markdown/MarkdownField';
import i18n from '../../i18n/helper';

export default function InfluencedDecisionWidget(props) {
  const { news } = props;
  const { resource, title, description } = news;
  return (
    <article className={`c-card-influenced ${resource ? 'c-card-influenced--figure' : ''}`}>
      {resource && (
        <figure className='c-card-influenced__figure'>
          <Img imageType={WIDE} imageId={resource} alt='resource' />
        </figure>
      )}
      <div className='c-card-influenced__content'>
        <p className='c-card-influenced__meta'>{i18n.t('body.decision_title')}</p>
        {title && <h2 className='c-card-influenced__title'>{title}</h2>}
        {description && (
          <div className='c-card-influenced__summary'>
            <p className='u-rich-text'>
              <MarkdownField markdown={description} markdownConfig={MARKDOWN_MIN_LINK} />
            </p>
          </div>
        )}
      </div>
    </article>
  );
}

InfluencedDecisionWidget.propTypes = {
  news: PropTypes.object.isRequired,
};

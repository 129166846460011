import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

import { createAnswer } from 'components/organisms/question';
import Coin from '../../coin/coin';
import { SUBMIT_DELAY } from '../../../constants';
import thumbup from '../../../assets-v2/images/illustration/thumbs-up.png';
import i18n from '../../../i18n/helper';
import InputRange from '../../inputRange/InputRange';

const makeSliderObject = (array) => {
  const obj = {};
  array.forEach((item, index) => {
    obj[`slider_${index}`] = 0;
  });
  return obj;
};

export default class Question03 extends React.Component {
  delayRemoveClass = debounce(() => this.setState({ distributeTotalClass: '' }), SUBMIT_DELAY);

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      allSlidersValues: makeSliderObject(this.props.question.choices),
      distributeTotalClass: '',
    };
  }

  handleSlider = (event) => {
    this.setSliderValue(event.target.name, +event.target.value);
  };

  createChoiceArray = () => {
    const choiceArray = [];
    this.props.question.choices.forEach((value, index) => {
      choiceArray.push({
        choiceId: value._id,
        order: value.order,
        amount: this.state.allSlidersValues[`slider_${index}`],
      });
    });
    return choiceArray;
  };

  setSliderValue = (key, value) => {
    const allSlidersValues = { ...this.state.allSlidersValues };
    allSlidersValues[key] = value;
    let totalValue = 0;
    let otherSlidersValue = 0;
    Object.keys(allSlidersValues).forEach((sliderKey) => {
      if (sliderKey !== key) {
        otherSlidersValue += allSlidersValues[sliderKey];
      }
      totalValue += allSlidersValues[sliderKey];
    });
    if (totalValue >= this.props.question.maxValue) {
      this.setState({ distributeTotalClass: 'u-a-warning-nudge' });
      this.delayRemoveClass();
      allSlidersValues[key] = this.props.question.maxValue - otherSlidersValue;
    }
    this.setState({
      allSlidersValues,
    });
  };

  handleButton = () => {
    !this.props.isResult
      ? this.props.onSubmitQuestion(createAnswer(this.createChoiceArray()))
      : this.props.questionButtonClicked();
  };

  render() {
    return (
      <article className={`c-question c-question--side-header ${this.state.isScrollingClass || ''}`}>
        <div className='c-question__header'>
          <h2 className='c-question__title'>
            {this.props.isResult ? (
              <React.Fragment>
                <img src={thumbup} alt='thumbimage' />
                {i18n.t('questions.MultipleSliderQuestion.resultText')}
              </React.Fragment>
            ) : (
              this.props.question.questionText
            )}
          </h2>
        </div>
        <div className='c-question__content' onScroll={this.handleScroll}>
          <div className='c-distribute u-a-children-fade-in'>
            <h3 className='c-distribute__header'>
              <span className='c-distribute__label'>
                {i18n.t('questions.MultipleSliderQuestion.dragMultisliderText')}
              </span>
              <span className={`c-distribute__total ${this.state.distributeTotalClass}`}>
                {this.props.question.label}
              </span>
            </h3>
            <div className='c-distribute__items u-a-children-fade-in'>
              {this.props.question.choices.map((data, idx) => (
                <div
                  key={`slider_${idx}`}
                  className={`c-slider c-slider--distribute ${idx === 0 ? ' u-a-slider-move-cue ' : ''} ${
                    this.props.isResult ? ' is-result is-disabled ' : ''
                  }`}
                >
                  <label className='c-slider__label' htmlFor={`distribute-${idx}`}>
                    {data.choice}
                  </label>
                  <div className='c-slider__group'>
                    <div className='c-slider__control'>
                      <InputRange
                        name={`slider_${idx}`}
                        min='0'
                        max={this.props.question.maxValue}
                        step='1'
                        value={this.state.allSlidersValues[`slider_${idx}`]}
                        id={`distribute-${idx}`}
                        onChange={(event) => {
                          this.handleSlider(event);
                        }}
                        onInput={(event) => {
                          this.handleSlider(event);
                        }}
                      />
                      <div className='c-slider__indicator'>
                        {/* eslint-disable-next-line */}
                        <label
                          aria-hidden='true'
                          htmlFor={`distribute-${idx}`}
                          style={{
                            width: `${
                              (this.state.allSlidersValues[`slider_${idx}`] / this.props.question.maxValue) * 100
                            }%`,
                          }}
                        >
                          <span className='c-slider__handle' />
                        </label>
                        {this.props.isResult && (
                          <div
                            className='c-slider__community'
                            style={{
                              width: `${
                                ((this.props.communityAnswer && this.props.communityAnswer.length > 0
                                  ? this.props.communityAnswer[idx].amount
                                  : this.state.allSlidersValues[`slider_${idx}`]) /
                                  this.props.question.maxValue) *
                                100
                              }%`,
                            }}
                            data-title={i18n.t('questions.emojiSliderQuestion.rating')}
                          >
                            <span className='c-slider__community-handle' />
                          </div>
                        )}
                      </div>
                    </div>
                    <span className='c-slider__value'>{this.state.allSlidersValues[`slider_${idx}`]}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className='c-question__footer'>
          <button
            className={`c-button c-button--primary c-button--large ${this.props.disableForm ? 'is-disabled' : ''}`}
            onClick={this.handleButton}
            disabled={this.props.disableForm}
          >
            <span className='c-button__label'>
              {this.props.isResult === false ? i18n.t('questions.submitButton') : i18n.t('questions.nextQuestion')}
            </span>
            {this.props.showCoin && !this.props.isResult && <Coin bounceFromBottom />}
          </button>
        </div>
      </article>
    );
  }
}
Question03.propTypes = {
  question: PropTypes.object.isRequired,
  communityAnswer: PropTypes.array.isRequired,
  onSubmitQuestion: PropTypes.func.isRequired,
  isResult: PropTypes.bool.isRequired,
  showCoin: PropTypes.bool.isRequired,
  questionButtonClicked: PropTypes.func.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

import FeedbackFormQuestion from '../FeedbackFormQuestion';

function SliderFeedback14(props) {
  // const { question, sliderValue } = props;

  // Show appropriate message corresponding to the selected value
  const getQuestionText = (value, labels) => {
    if (value <= 25) {
      return labels[3].label;
    }
    if (value > 25 && value <= 50) {
      return labels[2].label;
    }
    if (value > 50 && value <= 75) {
      return labels[1].label;
    }
    if (value > 75) {
      return labels[0].label;
    }
    return 'Undefined Question';
  };

  return (
    <FeedbackFormQuestion questionText={getQuestionText(props.sliderValue, props.question.resourceLabels)} {...props} />
  );
}

SliderFeedback14.propTypes = {
  sliderValue: PropTypes.number.isRequired,
  question: PropTypes.object.isRequired,
  onSubmitFeedback: PropTypes.func.isRequired,
  showCoin: PropTypes.bool.isRequired,
};

export default SliderFeedback14;

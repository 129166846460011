import { useCallback, useState, useEffect } from 'react';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { QuestionComponentProps } from 'components/organisms/questions';
import { QuestionTextHeader } from 'components/atoms/questionTextHeader';
import { Attachment, createAnswer } from 'components/organisms/question';
import { usePanZoom } from 'components/panZoomHook/usePanZoom';
import { connect, ConnectedProps } from 'react-redux';
import QuestionFooter from 'components/question/questionComponents/QuestionFooter';
import i18n from 'i18n/helper';
import clamp from 'lodash.clamp';

import { getFeedbakOptions } from '../../../../actions';
import { AnnotateArea } from './AnnotateArea/AnnotateArea';
import { AnnotatePin } from './AnnotatePin/AnnotatePin';
import { AnnotateActions } from './AnnotateActions/AnnotateActions';
import { PlacedReactions, DropReaction } from './AnnotateArea/types';
import { ANNOTATE_STATES, ZOOM_IN, ZOOM_OUT } from './utils';
import { AnnotateModalDelete } from './AnnotateActions/AnnotateModalDelete';
import { AnnotateModalFinish } from './AnnotateActions/AnnotateModalFinish';
import { AnnotateMenu } from './AnnotatePin/AnnotateMenu';
import { FeedbackState } from './types';

function AnnotateAdvanced17Fn({
  question,
  setAnnotateEdit,
  questionButtonClicked,
  onSubmitMultipleQuestion,
  userData,
  feedbackEmailZip,
  slugId,
  actions,
  showCoinsInHeader,
}: PropsFromRedux & QuestionComponentProps): JSX.Element {
  const { questionText, choices, multiChoicesSelectableBound, resource } = question;
  const areaImage = resource[0] && resource[0].url;

  const { dragRef, state, onLoad, onZoom, setEnableDragging } = usePanZoom();

  const [feedback, setFeedback] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showFinishConfirm, setShowFinishConfirm] = useState(false);
  const [placedReactions, setPlacedReactions] = useState<PlacedReactions[]>([]);
  const [image, setImage] = useState<Attachment>({ id: '', fileName: 'untitled' });
  const [firstInteractionOccured, setfirstInteractionOccured] = useState(false);
  const [showCoin, setShowCoin] = useState(false);
  const [isResult, setIsResult] = useState(false);
  const [allowRedrag, setAllowRedrag] = useState(false);

  const allowComments = question.questionConfiguration.allowComments;
  const allowImageUpload = question.questionConfiguration.allowUserContentUpload.image;

  useEffect(() => {
    actions.getFeedbakOptions(slugId);
  }, [actions, slugId]);

  const addReaction = useCallback(
    (reactionToDrop: DropReaction, left: number, top: number) => {
      setAllowRedrag(true);
      const newReaction: PlacedReactions = {
        resource: reactionToDrop.resource,
        feedback: '',
        label: reactionToDrop.label,
        // left,
        // top,
        left: clamp(left, 0, 100),
        top: clamp(top, 0, 100),
        choiceId: reactionToDrop.choiceId,
      };
      setPlacedReactions((prevPlacedReactions) => [...prevPlacedReactions, newReaction]);
      setAnnotateEdit(ANNOTATE_STATES.EDIT);
    },
    [setAnnotateEdit],
  );

  const removeLastReaction = useCallback(() => {
    setPlacedReactions((prevPlacedReactions) => [...prevPlacedReactions.slice(0, -1)]);
  }, []);

  const onFinish = () => {
    const showCoins = showCoinsInHeader(false);
    setIsResult(true);
    setShowCoin(showCoins);
    setAnnotateEdit(ANNOTATE_STATES.DONE);
    setShowFinishConfirm(false);
    // questionButtonClicked();
  };

  const onClickZoomOut = () => {
    if (dragRef.current) {
      const containerRect = dragRef.current.getBoundingClientRect();
      // onZoom(ZOOM_OUT);
      onZoom(ZOOM_OUT, containerRect.left + containerRect.width / 2, containerRect.top + containerRect.height / 2);
    }
  };
  const onClickZoomIn = () => {
    if (dragRef.current) {
      const containerRect = dragRef.current.getBoundingClientRect();
      // onZoom(ZOOM_IN);
      onZoom(ZOOM_IN, containerRect.left + containerRect.width / 2, containerRect.top + containerRect.height / 2);
    }
  };
  const onClickZoomReset = () => {
    onLoad();
  };

  const onClickSubmit = () => {
    setfirstInteractionOccured(true);
    setAllowRedrag(false);
    const reaction = placedReactions[placedReactions.length - 1];
    if (multiChoicesSelectableBound === placedReactions.length) {
      if (multiChoicesSelectableBound === 1) {
        onFinish();
      } else {
        setAnnotateEdit(ANNOTATE_STATES.FINISH);
      }
    } else {
      setAnnotateEdit(ANNOTATE_STATES.INTERACTIVE);
    }

    onSubmitMultipleQuestion(
      createAnswer([
        {
          choiceId: reaction?.choiceId ?? '',
          feedback,
          email: feedbackEmailZip.email || userData.email || '',
          postalCode: feedbackEmailZip.postCode || userData.postCode || '',
          attachment: image.id ? [image] : undefined,
          imagePin: {
            x: reaction?.left ?? 0,
            y: reaction?.top ?? 0,
          },
        },
      ]),
    );
    setFeedback('');
    setImage({ id: '', fileName: 'untitled' });
  };

  const onClickDeleteConfirm = () => {
    setAllowRedrag(false);
    setPlacedReactions((prevPlacedReactions) => [...prevPlacedReactions.slice(0, -1)]);
    setFeedback('');
    setImage({ id: '', fileName: 'untitled' });
    setShowDeleteConfirm(false);
    if (placedReactions.length - 1 > 0) {
      setAnnotateEdit(ANNOTATE_STATES.INTERACTIVE);
    } else {
      setAnnotateEdit('');
    }
  };

  const onClickAddMore = () => {
    setShowFinishConfirm(false);
  };

  const onClickFinish = () => {
    if (multiChoicesSelectableBound - placedReactions.length <= 0) {
      onFinish();
    } else {
      setShowFinishConfirm(true);
    }
  };

  const getTooltipText = (): string => {
    const currentLanguageDefault: string | undefined = i18n.t('questions.annotate.image.tooltip');
    return question?.questionConfiguration?.tooltip || currentLanguageDefault || '';
  };

  return (
    <article className='c-question c-question--narrow'>
      <QuestionTextHeader
        questionText={questionText}
        isResult={isResult}
        resultText={i18n.t('questions.annotate.result')}
        hasTooltip
        tooltipText={getTooltipText()}
      />
      <div
        className='c-question__content'
        style={{ userSelect: 'none', MozUserSelect: 'none', WebkitUserSelect: 'none' }}
      >
        <div className='c-annotate c-annotate-advanced'>
          <AnnotateArea
            setEnableDragging={setEnableDragging}
            resource={areaImage}
            placedReactions={placedReactions}
            addReaction={addReaction}
            dragRef={dragRef}
            state={state}
            onLoad={onLoad}
            setPlacedReactions={setPlacedReactions}
            removeLastReaction={removeLastReaction}
            isDragAllowed={allowRedrag}
          />
          <AnnotatePin
            choices={choices}
            multiChoicesSelectableBound={multiChoicesSelectableBound}
            reactionsLeft={multiChoicesSelectableBound - placedReactions.length}
            isDragAllowed={placedReactions.length < multiChoicesSelectableBound}
            firstInteractionOccured={firstInteractionOccured}
            setAnnotateEdit={setAnnotateEdit}
            annotateMenu={
              <AnnotateMenu
                onClickDelete={() => setShowDeleteConfirm(true)}
                feedback={feedback}
                setFeedback={setFeedback}
                onClickSubmit={onClickSubmit}
                allowComments={allowComments}
                allowImageUpload={allowImageUpload}
                image={image}
                setImage={setImage}
              />
            }
          />
          <AnnotateActions
            onClickZoomOut={onClickZoomOut}
            onClickZoomReset={onClickZoomReset}
            onClickZoomIn={onClickZoomIn}
            onClickFinish={onClickFinish}
            isFinishVisible={placedReactions.length > 0 && !showFinishConfirm}
            annotateModalDelete={
              <AnnotateModalDelete
                onClickCancel={() => setShowDeleteConfirm(false)}
                onClickConfirm={onClickDeleteConfirm}
                isActive={showDeleteConfirm}
              />
            }
            annotateModalFinish={
              <AnnotateModalFinish
                onClickFinishConfirm={onFinish}
                onClickAddMore={onClickAddMore}
                isActive={showFinishConfirm}
                reactionsLeftToAdd={multiChoicesSelectableBound - placedReactions.length}
              />
            }
          />
        </div>
      </div>
      <QuestionFooter
        onClick={() => questionButtonClicked()}
        buttonClassModifier=' c-button--primary c-button--large'
        buttonText={i18n.t('questions.nextQuestion')}
        showCoin={showCoin}
      />
    </article>
  );
}

const mapStateToProps = (state: FeedbackState) => ({
  userData: state.feedback.userData,
  feedbackEmailZip: state.polls.feedbackEmailZip,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators(
    {
      getFeedbakOptions,
    },
    dispatch,
  ),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export const AnnotateAdvanced17 = connector(AnnotateAdvanced17Fn);

type PropsFromRedux = ConnectedProps<typeof connector>;

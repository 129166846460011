import React from 'react';

// import LogoRibbon from './LogoRibbon';
import Illustration3DHome from '../../assets-v2/images/marketing/illustration-hero.png';
import SvgIcon from '../SvgIcon';

export default function Hero() {
  return (
    <div className='c-m-hero'>
      <h1 className='c-m-hero__title'>Changemaking Made Easy</h1>
      <div className='c-m-hero__summary'>
        <div className='c-m-hero__illustration'>
          <img src={Illustration3DHome} alt='' />
        </div>
      </div>
      <div className='c-m-hero__action'>
        <a
          href='https://www.built-id.com/give-my-view'
          className='c-button c-button--primary c-button--large c-button--pill'
        >
          <span className='c-button__label'>Learn more</span>
          <SvgIcon icon='arrow-right' />
        </a>
      </div>
      {/* issue #2195 but I'll keep it here in case we need it later (ie. updated client list) */}
      {/* <LogoRibbon /> */}
    </div>
  );
}

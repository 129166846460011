import React from 'react';

/* eslint-disable react/no-unescaped-entities */
export default function TermsAndConditions() {
  return (
    <div className='c-m-block'>
      <div className='l-container'>
        <div className='c-m-text'>
          <h1>Terms & Conditions</h1>

          <h2>1. About these Terms</h2>

          <p>
            1.1. These terms and conditions ("<strong>Terms</strong>") explain how you may use our website
            givemyview.com and all associated webpages (the "<strong>Site</strong>"), which we provide free of charge.
            Please read these Terms carefully before using the Site. We draw your attention in particular to the
            "Limitation of Liability" section.
          </p>
          <p>1.2. These Terms apply to you as an individual when you:</p>

          <p className='u-indent-1'>1.2.1. access, browse or use our Site or any materials available on the Site; or</p>
          <p className='u-indent-1'>
            1.2.2. participate in any digital community consultancy activities (such as answering to a survey or giving
            your feedback) via the Site.
          </p>

          <p>
            1.3. By accessing or using the Site or otherwise indicating your acceptance, you confirm that you accept
            these Terms and the documents referred to in them and agree to comply with them. If you do not agree to
            these Terms, you should stop using the Site immediately.
          </p>
          <p>
            1.4. These Terms incorporate our Privacy and Cookie Policy (available at
            https://www.givemyview.com/privacy-policy). Our Privacy Policy sets out the terms on which we collect,
            process, share and store any personal data we collect from you, or that you provide to us and your rights in
            relation to your personal data. Our Cookie Policy sets out information about the cookies on our Site. We
            will only use your personal data as set out in our Privacy and Cookie Policy. For questions about our data
            practices, please contact our Data Protection Officer at <a href='security@land.tech'>security@land.tech</a>
            .
          </p>
          <p>
            1.5. These Terms are dated February 2024. We may update these Terms from time to time in order to reflect
            changes to the Site or for legal, regulatory, security or business reasons. Our updated Terms will be
            displayed on the Site and by continuing to use and access the Site following such updates, you agree to be
            bound by the updated Terms. You have the responsibility to check these Terms from time to time to verify
            such updates.
          </p>
          <p>1.6. These Terms are only available in the English language.</p>

          <h2>2. About us</h2>

          <p>
            2.1. We are Built-ID Limited, a company registered in England and Wales (registration number 10311228) and
            with a registered address of 9 Appold Street, London, England, EC2A 2AP ("<strong>we</strong>", "
            <strong>us</strong>", "<strong>our</strong>"). Built-ID Limited is a wholly owned subsidiary of Land
            Technologies Limited (registration number 08845300) .
          </p>
          <p>
            2.2. If you have any questions, complaints or comments about the Site or these Terms, then please contact us
            at <a href='mailto:info@givemyview.com'>info@givemyview.com</a> or call 020 3086 7855.
          </p>

          <h2>3. Your use of the Site</h2>

          <p>
            3.1. We permit you to use the Site only for your general information or participation in the digital
            community consultancy activities. You are responsible for making all arrangements necessary for you to
            access the Site.
          </p>

          <p>3.2. As a condition for your use of the Site, you agree not to use the Site:</p>

          <p className='u-indent-1'>
            3.2.1. for any purpose that is unlawful or in breach of applicable law or prohibited by these Terms;
          </p>
          <p className='u-indent-1'>
            3.2.2. use the Site in any way that interrupts, damages, impairs or renders the Site less efficient;
          </p>
          <p className='u-indent-1'>3.2.3. impersonate any other person (living or dead);</p>
          <p className='u-indent-1'>
            3.2.4. attempt to, or authorise, encourage or assist any other person to copy, modify, reverse-engineer,
            decompile, disassemble, alter or otherwise tamper with any software (including source code), databases and
            other technology that forms part of the Site; or
          </p>
          <p className='u-indent-1'>
            3.2.5. access or attempt to gain unauthorised access to information provided by other users or to penetrate
            or attempt to penetrate the Site’s security measures.
          </p>
          <p className='u-indent-1'>3.2.6. to commit any act of fraud;</p>
          <p className='u-indent-1'>3.2.7. to distribute viruses or malware or other similar malicious programs;</p>
          <p className='u-indent-1'>3.2.8. for purposes of promoting unsolicited advertising or sending spam;</p>
          <p className='u-indent-1'>
            3.2.9. to simulate communications from us or another service or entity in order to collect identifying
            information, authentication credentials, or other information (phishing);
          </p>
          <p className='u-indent-1'>3.2.10. to promote any unlawful activity;</p>
          <p className='u-indent-1'>
            3.2.11. to represent or suggest that we endorse any other business, product or service unless we have
            separately agreed to do so in writing; or
          </p>
          <p className='u-indent-1'>3.2.12. to attempt to circumvent password or user authentication methods.</p>

          <p>
            3.3. We do not guarantee that our Site will always be available, bugs-free or uninterrupted. Furthermore, we
            cannot promise that the Site will be fit or suitable for any purpose. We may suspend, withdraw, discontinue
            or change all or any part of the Site as we see fit without notice. We will not be liable to you if, for any
            reason, our Site is unavailable at any time or for any period.
          </p>
          <p>3.4. Intellectual property rights</p>

          <p className='u-indent-1'>
            3.4.1. The Site and all intellectual property rights in it, including but not limited to any Site Content
            (as defined below) are owned by us, our licensors or both (as applicable). Intellectual property rights
            means rights such as copyright, trademarks, trade names, logos, domain names, design rights, database
            rights, patents and all other intellectual property rights of any kind, whether or not they are registered
            or unregistered (anywhere in the world). We and our licensors reserve all of our rights in any intellectual
            property in connection with the Site.
          </p>
          <p className='u-indent-1'>
            3.4.2. Your use of our intellectual property right is strictly prohibited unless you have our prior written
            permission.
          </p>
          <p className='u-indent-1'>
            3.4.3. Nothing in these Terms grants you any legal rights in the Site other than as necessary to enable you
            to access the Site. Your use of the Site grants you no rights to you in relation to our or our licensor’s
            intellectual property rights. You agree not to adjust or try to circumvent or delete any notices contained
            on the Site (including any intellectual property notices) and in particular in any digital rights or other
            security technology embedded or contained within the Site.
          </p>

          <h2>4. User Content</h2>

          <p>
            4.1. By submitting information, details, data, text, photos, graphics or any other content whatsoever to us
            in connection with your use of the Site ("
            <strong>User Content</strong>"), you grant us a non-exclusive, royalty-free, sublicensable, perpetual and
            worldwide license to use that User Content at our own discretion.
          </p>
          <p>
            4.2. You promise us that any User Content you submit to us via the Site will not infringe the IPR or any
            rights of any third parties.
          </p>
          <p>
            4.3. You agree that you will not submit any User Content that is abusive, threatening, defamatory, obscene,
            vulgar or otherwise offensive or in violation of our Terms. You also agree not to submit any User Content
            that is false and misleading or uses the Site in a manner that is fraudulent or deceptive. We will not be
            responsible or liable to any third party for User Content or the accuracy of any materials submitted by you
            or any other users of the Site.
          </p>
          <p>
            4.4. You agree that we can edit or take down from the Site any User Content you submit to us without notice
            if we believe it is in breach of these Terms. You are responsible for keeping copies of your User Content,
            and we will not make any backup copies or return any User Content to you when you cease to use the Site for
            any reason (including where you choose to and also where your use or access has been suspended, disabled or
            terminated).
          </p>
          <p>
            4.5. You acknowledge and agree that, although we use reasonable efforts to alert users of the Site that they
            may not misuse your User Content, we cannot guarantee this and accept no liability to you if it is misused
            by third parties.
          </p>

          <h2>5. Suspension and termination</h2>

          <p>
            5.1. Without prejudice to other provisions of these Terms, we may immediately terminate or suspend your
            access to the Site without notice if you breach any of your obligations or restrictions under these Terms.
          </p>

          <h2>6. No reliance on information</h2>

          <p>
            6.1. Any text, images, video, audio or other multimedia content, software or other information or material
            available on the Site ("<strong>Site Content</strong>") is provided on an "as-is" basis and for your general
            information only. The Site Content does not constitute technical, financial or legal advice or any other
            type of advice on which you should rely for any purposes. You must obtain professional or specialist advice
            before taking or refraining from any action on the basis of the Site Content.
          </p>
          <p>
            6.2. We have no duty to pre-screen content posted on the Site by users, including User Content, whether
            directly contributed by a user or contributed by a third party on behalf of a user (including, without
            limitation, participation in any digital community consultancy activities).
          </p>
          <p>
            6.3. Although we make reasonable efforts to update Site Content, we make no representations, warranties or
            guarantees, whether expressed or implied, that the Site Content is accurate, complete or up-to-date.
          </p>
          <p>
            6.4. We make no representations, warranties or guarantees about the suitability or quality of any user or
            Site Content, and the inclusion of any of them on the Site does not constitute any endorsement or
            recommendation of them by us.
          </p>
          <p>
            6.5. Where the Site contains links or references to other websites and resources provided by third parties,
            these links or references are provided for your information only. We have no control over the contents of
            those websites or resources. We accept no legal responsibility for any content, material or information
            contained in them. The display of any links and references to third-party websites or resources does not
            mean that we endorse that third party’s websites or resources. Your use of a third-party website may be
            governed by the terms and conditions of that third-party website.
          </p>

          <h2>7. Limitation of liability</h2>

          <p>
            7.1. Except for any legal responsibility that we cannot exclude in law (such as for death or personal injury
            caused by our negligence) or arising under applicable laws relating to the protection of your personal
            information, we are not legally responsible under these Terms for any:
          </p>
          <p className='u-indent-1'>7.1.1. losses that:</p>

          <p className='u-indent-2'>7.1.1.1. were not foreseeable to you and us when these Terms were formed; or</p>
          <p className='u-indent-2'>7.1.1.2. that were not caused by any breach on our part;</p>

          <p className='u-indent-1'>7.1.2. business losses; and</p>
          <p className='u-indent-1'>7.1.3. losses to non-consumers.</p>

          <p>
            7.2. While we may take reasonable precautions to prevent the existence of computer viruses or malware, or
            other malicious programs on the Site, we accept no liability for them.
          </p>
          <p>
            7.3. We accept no responsibility or liability for and shall have no involvement with any contact,
            relationship, dealing or agreement you may have or enter into with any other user of the Site. Any contact
            or relationship (commercial or otherwise) that you have with any other user is strictly between you and that
            user. We accept no responsibility or liability for and have no involvement with any such contact or
            relationship.
          </p>
          <p>
            7.4. We shall have no liability to you for any breach of these Terms caused by any event or circumstance
            beyond our reasonable control, including strikes, lock-outs or other industrial disputes; breakdown of
            systems or network access; or flood, fire, explosion or accident.
          </p>

          <h2>8. General</h2>

          <p>8.1. You agree that we may provide notices about these Terms to you:</p>

          <p className='u-indent-1'>8.1.1. via a banner notice via the Site;</p>
          <p className='u-indent-1'>8.1.2. by email sent to your registered email address; or</p>
          <p className='u-indent-1'>8.1.3. through other means, including mobile number, telephone, or mail.</p>

          <p>
            8.2. We may transfer our rights or obligations (including as part of any sale, licensing arrangement,
            acquisition, joint venture, merger or re-organisation of all or any part of our business) or sub-contract
            our obligations under these Terms to another legal entity. These Terms are personal to you. You may not
            transfer your rights or obligations under these Terms to anyone else.
          </p>
          <p>
            8.3. If we delay exercising or fail to exercise or enforce any right available to us under these Terms, this
            does not constitute a waiver of that right or any other rights under these Terms.
          </p>
          <p>
            8.4. If any part of these Terms is disallowed or found to be ineffective by any court or regulator, the
            other provisions shall continue to apply.
          </p>
          <p>
            8.5. These Terms are not intended to give rights to anyone to enforce any of these Terms except you and us.
          </p>
          <p>
            8.6. We will do our best to resolve any disputes over these Terms. If you are unhappy with us, please
            contact us as soon as possible. If you wish to take court proceedings against us, the Courts of England and
            Wales will have exclusive jurisdiction in relation to these Terms. English law will apply to these Terms.
          </p>
        </div>
      </div>
    </div>
  );
}

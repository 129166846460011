import React from 'react';
import PropTypes from 'prop-types';

const PollButton = (props) => {
  const { onClick, disabled } = props;
  return (
    <button className='c-button c-button--primary c-button--large' onClick={onClick} disabled={disabled} type='button'>
      {props.text}
    </button>
  );
};

PollButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};
export default PollButton;

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import LanguageItem from './LanguageItem';
import Spinner from '../spinner/spinner';

export default function Languages(props) {
  const { languageCode, getLanguages, slugId, showLanguages, languages, changeLocale, closeDropDown } = props;
  const [language, setLanguage] = useState(languageCode);
  const languageRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (languageRef.current && !languageRef.current.contains(event.target)) {
        closeDropDown();
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [languageRef, closeDropDown]);

  useEffect(() => {
    getLanguages(slugId);
  }, [slugId, getLanguages]);

  const selectLanguage = (lang) => {
    changeLocale(lang);
    setLanguage(lang);
    closeDropDown();
  };

  return (
    <React.Fragment>
      <div
        className='c-change-language__dropdown'
        tabIndex='-1'
        id='language-dropdown'
        ref={languageRef}
        aria-hidden={!showLanguages}
      >
        <nav className='c-nav-lang'>
          <ul className='c-nav-lang__items'>
            {languages.length > 0 ? (
              <React.Fragment>
                {languages.map((item, index) => (
                  <LanguageItem
                    key={index}
                    languageTitle={item.languageTitle}
                    languageCode={item.languageCode}
                    onLanguageSelect={selectLanguage}
                    selectedLanguage={language}
                  />
                ))}
              </React.Fragment>
            ) : (
              <Spinner />
            )}
          </ul>
        </nav>
      </div>
    </React.Fragment>
  );
}

Languages.propTypes = {
  getLanguages: PropTypes.func,
  slugId: PropTypes.string,
  languages: PropTypes.array,
  changeLocale: PropTypes.func,
  closeDropDown: PropTypes.func,
  languageCode: PropTypes.string,
  showLanguages: PropTypes.bool,
};

import { useEffect, useState } from 'react';

export const useShowExplainer = (): [boolean, (setShow: boolean) => void] => {
  const [showExplainer, setShowExplainer] = useState(false);

  useEffect(() => {
    const element = document.getElementsByClassName('l-app')[0];
    if (showExplainer) {
      element.classList.add('is-explainer-opened');
      element.classList.remove('is-explainer-closed');
    } else {
      element.classList.remove('is-explainer-opened');
      element.classList.add('is-explainer-closed');
    }
    return () => {
      element.classList.remove('is-explainer-opened');
      element.classList.remove('is-explainer-closed');
    };
  }, [showExplainer]);

  return [showExplainer, setShowExplainer];
};

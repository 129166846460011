import React, { useRef, useState, useEffect, useCallback } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

import GalleryItem from './GalleryItem';
import i18n from '../../i18n/helper';
import SliderControls from './SliderControls';

export default function Gallery(props) {
  const { gallery } = props;
  const slider = useRef(null);
  const [slide, setSlide] = useState(0);

  const IDENTIFIER = 'gallery';

  useEffect(() => {
    slider.current.slickGoTo(slide);
  }, [slide]);

  // there is an issue with afterChange: https://github.com/akiran/react-slick/issues/1262
  // without debounce it can get stuck in infinite loop when clicking quickly on arrows
  // useCallback is necessary for debounce to work
  const speed = 500;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounced = useCallback(debounce((next) => setSlide(next), speed, { leading: true }));
  const sliderSettings = {
    mobileFirst: true,
    arrows: false,
    infinite: true,
    beforeChange: (current, next) => debounced(next),
    adaptiveHeight: true,
  };

  return (
    <div className='c-block'>
      <div className='l-container l-container--spacing'>
        <div className='c-media'>
          <h2 className='c-media__heading'>{i18n.t('body.gallery_title')}</h2>
          <div className='c-media__carousel c-carousel'>
            <Slider {...sliderSettings} ref={slider} className='c-media__items c-carousel__items'>
              {gallery.map((item, index) => (
                <GalleryItem
                  key={index}
                  resource={item.resource}
                  mediaType={item.mediaType}
                  slider={slider}
                  identifier={IDENTIFIER}
                  index={index}
                  description={item.caption}
                />
              ))}
            </Slider>
            {gallery.length > 1 && (
              <SliderControls
                setSlide={setSlide}
                classModifier='c-carousel__controls--green'
                slide={slide}
                items={gallery}
                identifier={IDENTIFIER}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

Gallery.propTypes = {
  gallery: PropTypes.arrayOf(
    PropTypes.shape({
      order: PropTypes.number,
      mediaType: PropTypes.string,
      _id: PropTypes.string,
      resource: PropTypes.string.isRequired,
    }),
  ),
};

import * as faq from '../../services/project/faq';

export const getFaq = (slug_id) => (dispatch) =>
  faq
    .getFaqFromServer(slug_id)
    .then((res) => {
      dispatch({
        type: 'SUCCESS_ADD_FAQS',
        data: res,
      });
      return res;
    })
    .catch((res) => {
      dispatch({
        type: 'FAIL_ADD_FAQS',
        failState: res,
      });
    });

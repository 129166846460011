import React from 'react';
import { Img, SQUARE } from '../images/Img';
import { getImageUrl, IMAGE_FORMATS } from '../images/Formats';

export default function ProjectCard({ project, classModifier }) {
  return (
    <article className={`c-card-project  ${classModifier}`}>
      <a href={`/${project.slug}`}>
        {project.projectHeader ? (
          <figure className='c-card-project__background'>
            <img
              src={getImageUrl(project.projectHeader, IMAGE_FORMATS.GMV_IMAGE_ULTRA_WIDE_S_800x200)}
              alt={project.projectName}
            />
          </figure>
        ) : (
          <div className='c-card-project__background' />
        )}

        <div className='c-card-project__content'>
          {project.projectLogo && (
            <figure className='c-card-project__logo'>
              <Img imageType={SQUARE} imageId={project.projectLogo} alt={project.profileName} />
            </figure>
          )}

          {project.projectName && <h2 className='c-card-project__title'>{project.projectName}</h2>}
          {project.architects && <p className='c-card-project__tagline'>{project.architects}</p>}
        </div>
      </a>
    </article>
  );
}

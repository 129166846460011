import React from 'react';

function SvgIcon({ icon }) {
  const iconClass = `o-icon o-icon-${icon}`;
  const url = `/assets-v2/images/symbol-defs.svg#o-icon-${icon}`;
  return (
    <svg className={iconClass} focusable='false'>
      <use xlinkHref={url} />
    </svg>
  );
}

export default SvgIcon;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { DemographicsChoiceContentTypes } from '../DemographicConstants';
import DemographicTemplateChoiceDefault from './DemographicTemplateChoiceDefault';
import SvgIcon from '../../../../SvgIcon';

export default function DemographicHouseholdNumberChoice(props) {
  const { choices, handleSelect, questionType } = props;

  const [activeOrder, setActiveOrder] = useState(-1);

  const handleSelectAndHighlight = (choiceId, order) => {
    setActiveOrder(order);
    handleSelect(choiceId);
  };

  const handleSelectAndRemoveHighlight = (choiceId) => {
    setActiveOrder(-1);
    handleSelect(choiceId);
  };

  const DefinedChoice = (choiceProps) => {
    const {
      choiceKey,
      questionType: questionTypeChoice,
      handleSelectAndHighlight: handleSelectAndHighlightChoice,
      choiceId,
      choiceLabel,
      isChecked,
      order,
    } = choiceProps;
    return (
      <div className={`c-choice-list c-choice-list--number ${isChecked ? 'is-visually-checked' : ''}`}>
        <input
          type='radio'
          id={choiceKey}
          name={questionTypeChoice}
          onChange={() => handleSelectAndHighlightChoice(choiceId, order)}
        />
        <label htmlFor={choiceKey}>
          <span className='c-choice-list__title'>{choiceLabel}</span>
          <SvgIcon icon='user-filled' />
          <SvgIcon icon='user' />
          <SvgIcon icon='check' />
        </label>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className='l-choice-grid__group'>
        {choices
          .filter((item) => item.choiceContentType === DemographicsChoiceContentTypes.DEFINED)
          .map((choice) => (
            <DefinedChoice
              key={choice._id}
              choiceKey={choice.choiceKey}
              questionType={questionType}
              handleSelectAndHighlight={handleSelectAndHighlight}
              choiceId={choice._id}
              choiceLabel={choice.choice}
              order={choice.order}
              isChecked={choice.order <= activeOrder}
            />
          ))}
      </div>
      {choices
        .filter((item) => item.choiceContentType === DemographicsChoiceContentTypes.SKIP)
        .map((choice) => (
          <DemographicTemplateChoiceDefault
            key={choice._id}
            choiceKey={choice.choiceKey}
            questionType={questionType}
            handleSelect={handleSelectAndRemoveHighlight}
            choiceId={choice._id}
            choiceLabel={choice.choice}
            choiceContentType={choice.choiceContentType}
          />
        ))}
    </React.Fragment>
  );
}

DemographicHouseholdNumberChoice.propTypes = {
  questionType: PropTypes.string.isRequired,
  choices: PropTypes.array.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

import engageLanguages from 'shared/engage-languages';

const ENGAGE_LANGUAGES = engageLanguages().engageLanguages();
const filterLanguages = (language) => {
  const currentLanguage = ENGAGE_LANGUAGES.find((lng) => language.languageCode === lng.languageCode);
  return currentLanguage.available;
};

const initialState = {
  languages: [],
};
export default (state = initialState, action) => {
  switch (action.type) {
    case 'SUCCESS_ADD_LANGUAGES':
      return {
        ...state,
        languages: action.data.filter(filterLanguages),
      };
    case 'FAIL_ADD_LANGUAGES':
      return {
        ...state,
        failState: action.failState,
      };
    default:
      return state;
  }
};

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Confetti from 'react-dom-confetti';
import ReactPixel from 'react-facebook-pixel';

import SvgIcon from '../SvgIcon';
import EndOfPollCharityIllustration from '../../assets-v2/images/illustration/charity.png';
import i18n from '../../i18n/helper';
import CharityItem from './CharityItem';
import { retrieveCharities, getAboutData } from '../../actions';
import { handleScroll } from '../question/QuestionUtils/QuestionFormUtils';
import QuestionUtils from '../question/QuestionUtils/QuestionUtils';
import { SUBMIT_DELAY, FB_PIXEL } from '../../constants';

function EndOfPollCharity(props) {
  const { closePoll, charities, actions, slugId, onSubmitCharity } = props;
  const [charityId, setCharityId] = useState('');
  const [confetti, setConfetti] = useState(false);
  const [isScrollingClass, setScrollingClass] = useState('');

  useEffect(() => {
    actions.retrieveCharities(slugId);
    actions.getAboutData(slugId);
  }, [actions, slugId]);

  useEffect(() => {
    if (charities.filter((charity) => charity.isActive).length === 1) {
      setCharityId(charities[0]._id);
    }
  }, [charities]);

  const onSubmit = (charityIdSubmit) => {
    setConfetti(true);
    QuestionUtils.timerPromise(SUBMIT_DELAY).then(() => {
      onSubmitCharity(charityIdSubmit);
    });
    // ReactPixel send question submitted
    ReactPixel.trackSingle(FB_PIXEL, 'Donate');
  };

  const config = {
    angle: 90,
    spread: 45,
    startVelocity: 45,
    elementCount: 80,
    dragFriction: 0.1,
    duration: 3000,
    stagger: 0,
    width: '10px',
    height: '10px',
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
  };

  return (
    <React.Fragment>
      <div className='c-modal-poll__header'>
        <button className='c-button c-modal-poll__close' onClick={closePoll}>
          <span className='c-button__label'>{i18n.t('close')}</span>
          <SvgIcon icon='times' />
        </button>
      </div>
      <div className='c-modal-poll__content'>
        <div className={`c-question c-question--wide ${isScrollingClass}`}>
          <div className='c-question__header' />
          <div className='c-question__content' onScroll={handleScroll(setScrollingClass)}>
            <div className='c-poll-charities'>
              <figure className='c-poll-charities__illustration'>
                <img src={EndOfPollCharityIllustration} alt='' />
              </figure>
              <h2 className='c-poll-charities__title'>{i18n.t('charity.supportCharity')}</h2>
              <div className='c-poll-charities__list'>
                {charities
                  .filter((charity) => charity.isActive)
                  .map((charity, index) => (
                    <CharityItem
                      key={index}
                      icon={charity.resource}
                      title={charity.title}
                      description={charity.description}
                      id={charity._id}
                      setCharityId={setCharityId}
                      charityId={charityId}
                    />
                  ))}
              </div>
            </div>
          </div>
          <div className='c-question__footer'>
            <button
              className='c-button c-button--primary c-button--large'
              onClick={() => onSubmit(charityId)}
              disabled={!charityId}
            >
              <Confetti className='u-confetti' active={confetti} config={config} />
              <span className='c-button__label'>{i18n.t('charity.donatePoints')}</span>
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

EndOfPollCharity.propTypes = {
  closePoll: PropTypes.func.isRequired,
  charities: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  slugId: PropTypes.string.isRequired,
  onSubmitCharity: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  charities: state.charities.data,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      retrieveCharities,
      getAboutData,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(EndOfPollCharity);

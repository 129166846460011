import React, { useState } from 'react';
import PropTypes from 'prop-types';

// import Slider from '../../slider/Slider';
import { ImageWithZoomOrCarousel } from 'components/molecules/imageWithZoomOrCarousel/ImageWithZoomOrCarousel';
import i18n from '../../../i18n/helper';
import { handleScroll } from '../QuestionUtils/QuestionFormUtils';
import QuestionUtils from '../QuestionUtils/QuestionUtils';
import Coin from '../../coin/coin';
import InputRange from '../../inputRange/InputRange';

function SliderQuestion14(props) {
  const { question, sliderValue, sliderChange, onSubmitQuestion, showCoin, disableForm, setIsMediaZoomed } = props;
  // const sliderClass = QuestionUtils.getSliderClass(sliderValue);

  const [isScrollingClass, setScrollingClass] = useState('');

  return (
    <article className={`c-question c-question--side-header u-a-children-fade-in ${isScrollingClass || ''}`}>
      <div className='c-question__header'>
        <h2 className='c-question__title'>{question.questionText}</h2>
      </div>
      <div className='c-question__content' onScroll={handleScroll(setScrollingClass)}>
        {question?.resource.length > 0 && (
          <ImageWithZoomOrCarousel
            resource={question?.resource}
            altImage='slider image'
            leadImageType={question?.questionConfiguration?.leadImageType}
            setIsMediaZoomed={setIsMediaZoomed}
            zoomEnabled={!disableForm}
          />
        )}
        <div className='c-slider c-slider--emoji u-a-slider-move-cue'>
          <label className='c-slider__label' htmlFor='emoji-1'>
            {i18n.t('questions.emojiSliderQuestion.sliderInfoText')}
          </label>
          <div className='c-slider__group'>
            <div className='c-slider__control'>
              <InputRange
                min={question.choices[0].range[0]}
                max={question.choices[0].range[1]}
                step='1'
                value={sliderValue}
                id='emoji-1'
                onChange={(event) => {
                  sliderChange(event);
                }}
                onInput={(event) => {
                  sliderChange(event);
                }}
                disabled={disableForm}
              />
              <div className='c-slider__indicator'>
                {/* eslint-disable-next-line */}
                <label aria-hidden htmlFor='emoji-1' style={{ width: `${sliderValue}%` }}>
                  <span
                    className={`c-slider__handle c-slider__handle--emoji-${QuestionUtils.getCommunityEmoji(
                      sliderValue,
                    )}`}
                  />
                </label>
              </div>
            </div>
            <span className='c-slider__value'>{`${sliderValue}`}</span>
          </div>
        </div>
      </div>
      <div className='c-question__footer'>
        <button
          className={`c-button c-button--primary c-button--large ${disableForm ? 'is-disabled' : ''}`}
          onClick={onSubmitQuestion}
          disabled={disableForm}
        >
          <span className='c-button__label'>{i18n.t('questions.submitButton')}</span>
          {showCoin && <Coin bounceFromBottom />}
        </button>
      </div>
    </article>
  );
}

SliderQuestion14.propTypes = {
  question: PropTypes.object.isRequired,
  sliderValue: PropTypes.number.isRequired,
  sliderChange: PropTypes.func.isRequired,
  onSubmitQuestion: PropTypes.func.isRequired,
  showCoin: PropTypes.bool.isRequired,
  disableForm: PropTypes.bool.isRequired,
};

export default SliderQuestion14;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ImageOrVideo from '../imageOrVideo/ImageOrVideo';
import MarkdownField, { MARKDOWN_MIN_LINK } from '../markdown/MarkdownField';

export default function GalleryItem(props) {
  const { mediaType, resource, description, index, identifier } = props;
  return (
    <div
      className={`c-media__item c-carousel__item ${resource ? '' : 'c-influenced__item--no-figure'}`}
      role='tabpanel'
      aria-describedby={`slick-slide-control-${identifier}-${index}`}
      id={`slick-slide-${identifier}-${index}`}
    >
      {resource &&
        mediaType &&
        (mediaType === 'video' ? (
          <ImageOrVideo resource={{ mediaType, url: resource }} imageAlt='galleryItem' />
        ) : (
          <Fragment>
            <figure className={`c-media__figure ${description ? 'c-media__figure--caption' : ''}`}>
              <ImageOrVideo resource={{ mediaType, url: resource }} imageAlt='galleryItem' />
              {description && (
                <figcaption className='c-media__caption'>
                  <MarkdownField markdown={description || ''} markdownConfig={MARKDOWN_MIN_LINK} />
                </figcaption>
              )}
            </figure>
          </Fragment>
        ))}
    </div>
  );
}

GalleryItem.propTypes = {
  resource: PropTypes.string,
  mediaType: PropTypes.string,
  description: PropTypes.string,
  index: PropTypes.number,
  identifier: PropTypes.string,
};

import clamp from 'lodash/clamp';

const ZOOM_FACTOR = 0.1;
const ZOOM_FACTOR_BOUBLE_TAP = 1.5 * ZOOM_FACTOR;
export const ZOOM_IN = true;
export const ZOOM_OUT = false;

export const getNormalizedClient = (clientX, clientY, containerRect) => {
  const containerBoundingRect = containerRect.getBoundingClientRect();
  return { x: clientX - containerBoundingRect.left, y: clientY - containerBoundingRect.top };
};

export const transformString = (boundedTransform) =>
  `translate(${boundedTransform.translateX}px, ${boundedTransform.translateY}px) scale(${boundedTransform.scale})`;

export const getBoundedTransform = (translateX, translateY, scale, bounds) => {
  const boundTranslateX = clamp(translateX, bounds.minX, bounds.maxX);
  const boundTranslateY = clamp(translateY, bounds.minY, bounds.maxY);
  const boundScale = clamp(scale, bounds.minScale, bounds.maxScale);
  return { translateX: boundTranslateX, translateY: boundTranslateY, scale: boundScale };
};

export const getZoomModifier = (isZoomIn) => (isZoomIn ? ZOOM_FACTOR : -ZOOM_FACTOR);

export const getDoubleTapZoomModifier = (isZoomIn) => (isZoomIn ? ZOOM_FACTOR_BOUBLE_TAP : -ZOOM_FACTOR_BOUBLE_TAP);

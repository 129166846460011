import React from 'react';
import PropTypes from 'prop-types';

export default function SpanTextCount(props) {
  const { classModifier, maxLength, currentCount, title, charLimitWarning = 0 } = props;
  const getCharactersLeft = () => maxLength - currentCount;
  return (
    <span
      className={`${classModifier} ${getCharactersLeft() <= charLimitWarning ? 'c-field__count--limit' : ''}`}
      title={title}
    >
      {getCharactersLeft()}
    </span>
  );
}

SpanTextCount.propTypes = {
  classModifier: PropTypes.string,
  maxLength: PropTypes.number.isRequired,
  currentCount: PropTypes.number.isRequired,
  title: PropTypes.string,
  charLimitWarning: PropTypes.number,
};

export const DRAG_FORMAT = 'application/annotate';
export const REDRAG_FORMAT = 'application/reannotate';
export const ANNOTATE_STATES = {
  INTERACTIVE: 'c-modal-poll--annotate-advanced-interactive',
  EDIT: 'c-modal-poll--annotate-advanced-edit',
  FIRST_INTERACTION: 'c-modal-poll--annotate-advanced-first-interaction',
  INTERACTION: 'c-modal-poll--annotate-advanced-interaction',
  FINISH: 'c-modal-poll--annotate-advanced-finish',
  DONE: 'c-modal-poll--annotate-done',
};

export const ZOOM_IN = true;
export const ZOOM_OUT = false;

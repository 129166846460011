import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { getLanguages as getLanguagesAction } from '../../actions';
import Languages from './Languages';
import i18n from '../../i18n/helper';
import SvgIcon from '../SvgIcon';
import { getCurrentLangCode } from './LanguageHelper';

function LanguageComponent(props) {
  // languages are filtered by available flag defined by (src/shared/engage-languages.js)
  // directly in redux in src/services/languages/languages.js
  const { forceUpdateHandler, slugId, getLanguages, languages } = props;

  const [buttonName, setButtonName] = useState('English');
  const [lang, setLang] = useState('en');
  const [showLanguages, setShowLanguages] = useState(false);

  const getButtonName = () => {
    const code = getCurrentLangCode();
    if (code === null || code === 'en') return i18n.t('language.English');
    if (code === 'ar') return i18n.t('language.Arabic');
    if (code === 'bn') return i18n.t('language.Bengali');
    if (code === 'es') return i18n.t('language.Spanish');
    if (code === 'cy') return i18n.t('language.Welsh');
    if (code === 'gu') return i18n.t('language.Gujarati');
    if (code === 'pa') return i18n.t('language.Punjabi');
    if (code === 'pl') return i18n.t('language.Polish');
    if (code === 'ur') return i18n.t('language.Urdu');
    if (code === 'de') return i18n.t('language.German');
    if (code === 'hi') return i18n.t('language.Hindi');
    if (code === 'lt') return i18n.t('language.Lithuanian');
    if (code === 'fa') return i18n.t('language.Farsi');
    if (code === 'so') return i18n.t('language.Somali');
    if (code === 'tr') return i18n.t('language.Turkish');
    if (code === 'hr') return i18n.t('language.Croatian');
    if (code === 'ta') return i18n.t('language.Tamil');
    if (code === 'fr') return i18n.t('language.French');
    return i18n.t('language.English');
  };

  const changeLocale = useCallback(
    (language) => {
      localStorage.setItem('lang', language);
      i18n.setLocale(language);
      forceUpdateHandler();
      if (language === 'ur' || language === 'ar' || language === 'fa') {
        document.getElementsByTagName('body')[0].classList.add('_rtl');
      } else {
        document.getElementsByTagName('body')[0].classList.remove('_rtl');
      }
      if (language === 'es') {
        document.getElementsByTagName('body')[0].classList.add('spanish_language');
      } else {
        document.getElementsByTagName('body')[0].classList.remove('spanish_language');
      }
      setButtonName(getButtonName());
      setLang(language);
    },
    [forceUpdateHandler],
  );

  useEffect(() => {
    getLanguages(slugId).then((res) => {
      const code = getCurrentLangCode();
      const existsInLanguages = res.data ? res.data.find((lng) => lng.languageCode === code) : false;

      // if language exists on the project than set it as current language
      // otherwise fall back to English
      changeLocale(existsInLanguages ? code : 'en');
    });
  }, [changeLocale, getLanguages, slugId]);

  // Show language selector only if more than one language is available
  // https://github.com/sdesregistry/IH-Engage/issues/360
  const showLangSelector = () => (languages ? languages.length > 1 : false);

  return (
    <React.Fragment>
      {showLangSelector() && (
        <div className={`c-change-language ${showLanguages ? 'is-opened' : 'is-closed'}`}>
          <button
            className='c-change-language__toggle'
            type='button'
            aria-controls='language-dropdown'
            aria-expanded={showLanguages}
            onClick={() => setShowLanguages(!showLanguages)}
          >
            <span className='o-label'>
              <span className='u-screen-reader-text'>{i18n.t('language.heading')}</span>
              {buttonName}
            </span>
            <SvgIcon icon='angle-down' />
          </button>
          {showLanguages && (
            <Languages
              getLanguages={getLanguages}
              slugId={slugId}
              languages={languages}
              changeLocale={changeLocale}
              closeDropDown={() => setShowLanguages(false)}
              languageCode={lang}
              showLanguages={showLanguages}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
}

LanguageComponent.propTypes = {
  forceUpdateHandler: PropTypes.func.isRequired,
  slugId: PropTypes.string.isRequired,
  getLanguages: PropTypes.func.isRequired,
  languages: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  languages: state.languages.languages,
});

const mapDispatchToProps = (dispatch) => ({
  getLanguages: bindActionCreators(getLanguagesAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageComponent);

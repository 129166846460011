import { useCallback, useEffect, useRef, useState } from 'react';
import { useDeepCompareEffectForMaps } from './utils';

type useMapProps = {
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
} & google.maps.MapOptions;

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace google.maps {
    interface Polygon {
      getBounds(): google.maps.LatLngBounds | null;
    }
  }
}

google.maps.Polygon.prototype.getBounds = function getBounds() {
  const bounds = new google.maps.LatLngBounds();
  this.getPath().forEach((coord) => {
    bounds.extend(coord);
  });
  return bounds;
};

export const useMap = ({
  onClick,
  onIdle,
  ...options
}: useMapProps): { setRef: (node: HTMLDivElement | null) => void; map: google.maps.Map | undefined } => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [map, setMap] = useState<google.maps.Map>();

  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  const setRef = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      setMap(new window.google.maps.Map(node, options));
    }

    // Save a reference to the node
    ref.current = node;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (map) {
      ['click', 'idle'].forEach((eventName) => google.maps.event.clearListeners(map, eventName));

      if (onClick) {
        map.addListener('click', onClick);
      }

      if (onIdle) {
        map.addListener('idle', () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return { setRef, map };
};

import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';
import { getQueries } from '../link/LinkHelper';

export default function NavItem(props) {
  const { to, label, icon, exact, classNameModifier = '' } = props;

  return (
    <li className={`c-nav-header__item ${classNameModifier}`}>
      <NavLink
        to={{ pathname: to, search: getQueries() }}
        className='c-nav-header__link'
        exact={exact}
        activeClassName='is-active'
      >
        <SvgIcon icon={icon} />
        <span className='c-nav-header__label'>{label}</span>
      </NavLink>
    </li>
  );
}

NavItem.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  classNameModifier: PropTypes.string,
};

export const TWO_IMAGE_CHOICE = {
  articleClassModifier: '',
  imageType: 'WIDE',
  imageGridClassModifier: 'l-choice-grid--wide-two',
  media: false,
};

export const FOUR_IMAGE_CHOICE = {
  articleClassModifier: ' c-question-wide ',
  imageType: 'SQUARE',
  imageGridClassModifier: 'l-choice-grid--square-four',
  media: false,
};

export const THREE_IMAGE_CHOICE = {
  articleClassModifier: ' c-question--side-media ',
  imageType: 'SQUARE_L',
  imageGridClassModifier: 'l-choice-grid--square-only-three',
  media: true,
};

import { BaseField, BaseFieldProps } from '../baseField/BaseField';

export type TextAreaProps = {
  setValue: (value: string) => void;
  placeholder: string;
  disabled?: boolean;
} & BaseFieldProps;

export const TextArea = ({ id, maxLength, value, setValue, placeholder, ...rest }: TextAreaProps): JSX.Element => (
  <BaseField {...rest} id={id} value={value} maxLength={maxLength}>
    <textarea
      value={value}
      maxLength={maxLength}
      placeholder={placeholder}
      {...rest}
      onChange={(event) => {
        setValue(event.target.value);
      }}
      id={id}
    />
  </BaseField>
);

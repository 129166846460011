import * as api from '../api';

const PROJECTS = '/projects/';

export const getNoOfPhasesFromServer = (slug_id) => api.get(`${PROJECTS}${slug_id}/timeline`);

export const getCurrentPhaseDataFromServer = (slug_id, currentPhase) =>
  api.get(`${PROJECTS}${slug_id}/timeline/phase/${currentPhase}`);

export const getMorePhaseInfoFromServer = (slug_id, currentPhase, block_id) =>
  api.get(`${PROJECTS}${slug_id}/timeline/phase/${currentPhase}/update/${block_id}`);

const FIRST_NAME = 'firstName';
const LAST_NAME = 'lastName';
const EMAIL = 'email';
const ADDRESS = 'address';
const POSTAL_CODE = 'postalCode';
const PHONE = 'phone';
const EXTRA1 = 'extra1';
const EXTRA2 = 'extra2';
const allFields = [FIRST_NAME, LAST_NAME, EMAIL, ADDRESS, POSTAL_CODE, PHONE, EXTRA1, EXTRA2] as const;

function getInitialState(): Record<string, string> {
  // output
  // {
  //   firstName: '',
  //   lastName: '',
  //   email: '',
  //   address: '',
  //   postalCode: '',
  //   phone: '',
  //   extra1: '',
  //   extra2: '',
  // }
  // const fields: { [k: string]: any } = {};
  const fields: Record<string, string> = {};
  allFields.forEach((f) => {
    fields[f] = '';
  });
  return fields;
}

export { FIRST_NAME, LAST_NAME, EMAIL, ADDRESS, POSTAL_CODE, PHONE, EXTRA1, EXTRA2, allFields, getInitialState };

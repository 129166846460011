import React from 'react';

import { getImageUrl, IMAGE_FORMATS } from './Formats';

export default function Square(props) {
  const { imageId, imageType, alt, ...rest } = props;

  return (
    <picture>
      <img src={getImageUrl(imageId, IMAGE_FORMATS.GMV_IMAGE_SQUARE_S_300x300)} alt={alt} {...rest} />
    </picture>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import TrackVisibility from 'react-on-screen';
import TeamMember from './TeamMember';
import i18n from '../../i18n/helper';

export default function Team(props) {
  const { team } = props;
  return (
    <div className='c-block c-block--background-3'>
      <div className='l-container l-container--spacing'>
        <TrackVisibility className='c-team c-team--list' once partialVisibility>
          {({ isVisible }) =>
            isVisible && (
              <React.Fragment>
                <h2 className='c-team__heading'>{i18n.t('body.team')}</h2>
                <div className='c-team__items u-a-children-fade-in'>
                  {team.map((item, index) => (
                    <TeamMember
                      key={index}
                      resource={item.resource}
                      title={item.title}
                      role={item.role}
                      link={item.url}
                      teamId={item._id}
                    />
                  ))}
                </div>
              </React.Fragment>
            )
          }
        </TrackVisibility>
      </div>
    </div>
  );
}

Team.propTypes = {
  team: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      title: PropTypes.string,
      role: PropTypes.string,
      resource: PropTypes.string,
      link: PropTypes.string,
    }),
  ).isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { createAnswer } from 'components/organisms/question';
import { ImageWithZoomOrCarousel } from 'components/molecules/imageWithZoomOrCarousel/ImageWithZoomOrCarousel';
import Coin from '../../coin/coin';
import QuestionUtils from '../QuestionUtils/QuestionUtils';
import thumbup from '../../../assets-v2/images/illustration/thumbs-up.png';
import i18n from '../../../i18n/helper';
import InputRange from '../../inputRange/InputRange';

export default class Question02 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: 50,
    };
  }

  handleSlider = (event) => {
    this.setState({ selected: event.target.value });
    // this.props.onSubmitQuestion(event.target.value);
  };

  handleScroll = QuestionUtils.handleScroll(this);

  createChoiceArray = () => [{ choiceId: this.props.question.choices[0]._id, amount: this.state.selected }];

  handleButton = () => {
    !this.props.isResult
      ? this.props.onSubmitQuestion(createAnswer(this.createChoiceArray()))
      : this.props.questionButtonClicked();
  };

  render() {
    return (
      <article
        className={`c-question c-question--side-header u-a-children-fade-in ${this.state.isScrollingClass || ''}`}
      >
        <div className='c-question__header'>
          <h2 className='c-question__title'>
            {this.props.isResult ? (
              <React.Fragment>
                <img src={thumbup} alt='thumbimage' />
                {i18n.t('questions.emojiSliderQuestion.result')}
              </React.Fragment>
            ) : (
              this.props.question.questionText
            )}
          </h2>
        </div>
        <div className='c-question__content' onScroll={this.handleScroll}>
          {this.props.question?.resource?.length !== 0 ? (
            <ImageWithZoomOrCarousel
              resource={this.props.question.resource}
              altImage='quick text image'
              leadImageType={this.props.question?.questionConfiguration?.leadImageType}
              setIsMediaZoomed={this.props.setIsMediaZoomed}
              zoomEnabled={!this.props.disableForm}
            />
          ) : null}
          <div
            className={`c-slider c-slider--emoji u-a-slider-move-cue ${
              this.props.isResult ? ' is-result is-disabled ' : ''
            }`}
          >
            <label className='c-slider__label' htmlFor='emoji-1'>
              {i18n.t('questions.emojiSliderQuestion.sliderInfoText')}
            </label>
            <div className='c-slider__group'>
              <div className='c-slider__control'>
                <InputRange
                  min='0'
                  max='100'
                  step='1'
                  value={this.state.selected}
                  id='emoji-1'
                  onChange={(event) => {
                    this.handleSlider(event);
                  }}
                  onInput={(event) => {
                    this.handleSlider(event);
                  }}
                />
                <div className='c-slider__indicator'>
                  {/* eslint-disable-next-line */}
                  <label aria-hidden htmlFor='emoji-1' style={{ width: `${this.state.selected}%` }}>
                    <span
                      className={`c-slider__handle c-slider__handle--emoji-${QuestionUtils.getCommunityEmoji(
                        this.state.selected,
                      )}`}
                    />
                  </label>
                  {this.props.isResult && (
                    <div
                      className='c-slider__community'
                      style={{
                        width: `${
                          this.props.communityAnswer && this.props.communityAnswer.length > 0
                            ? this.props.communityAnswer[0].amount
                            : this.state.selected
                        }%`,
                      }}
                      data-title={i18n.t('questions.emojiSliderQuestion.rating')}
                    >
                      <span className='c-slider__community-handle' />
                    </div>
                  )}
                </div>
              </div>
              <span className='c-slider__value'>{`${this.state.selected}`}</span>
            </div>
          </div>
        </div>
        <div className='c-question__footer'>
          <button
            className={`c-button c-button--primary c-button--large ${this.props.disableForm ? 'is-disabled' : ''}`}
            onClick={this.handleButton}
            disabled={this.props.disableForm}
          >
            <span className='c-button__label'>
              {this.props.isResult === false ? i18n.t('questions.submitButton') : i18n.t('questions.nextQuestion')}
            </span>
            {this.props.showCoin && !this.props.isResult && <Coin bounceFromBottom />}
          </button>
        </div>
      </article>
    );
  }
}

Question02.propTypes = {
  question: PropTypes.object.isRequired,
  communityAnswer: PropTypes.array.isRequired,
  onSubmitQuestion: PropTypes.func.isRequired,
  isResult: PropTypes.bool.isRequired,
  showCoin: PropTypes.bool.isRequired,
  questionButtonClicked: PropTypes.func.isRequired,
  disableForm: PropTypes.bool.isRequired,
};

import { BASE_IMAGE_URL, REACTION_BASE_IMAGE_URL } from '../../constants';

/**
 * Image formatting constants
 * This is taken from Lambda definitions
 */
const DQ = 'DUMMY';
export const IMAGE_FORMATS = {
  /**
   * GMV formats
   */
  GMV_IMAGE_SQUARE_S_300x300: {
    w: 300,
    h: 300,
    name: '-gmv-square-s_300x300.jpeg',
    crop: true,
    q: DQ,
    isGMV: true,
  },
  GMV_IMAGE_SQUARE_M_600x600: {
    w: 600,
    h: 600,
    name: '-gmv-square-m_600x600.jpeg',
    crop: true,
    q: DQ,
    isGMV: true,
  },
  GMV_IMAGE_SQUARE_L_1200x1200: {
    w: 1200,
    h: 1200,
    name: '-gmv-square-l_1200x1200.jpeg',
    crop: true,
    q: DQ,
    isGMV: true,
  },
  GMV_IMAGE_WIDE_S_800x480: {
    w: 800,
    h: 480,
    name: '-gmv-wide-s_800x480.jpeg',
    crop: true,
    q: DQ,
    isGMV: true,
  },
  GMV_IMAGE_WIDE_M_1200x720: {
    w: 1200,
    h: 720,
    name: '-gmv-wide-m_1200x720.jpeg',
    crop: true,
    q: DQ,
    isGMV: true,
  },
  GMV_IMAGE_WIDE_L_1600x960: {
    w: 1600,
    h: 960,
    name: '-gmv-wide-l_1600x960.jpeg',
    crop: true,
    q: DQ,
    isGMV: true,
  },
  GMV_IMAGE_ULTRA_WIDE_S_800x200: {
    w: 800,
    h: 200,
    name: '-gmv-ultra-wide-s_800x200.jpeg',
    crop: true,
    q: DQ,
    isGMV: true,
  },
  GMV_IMAGE_ULTRA_WIDE_M_1200x300: {
    w: 1200,
    h: 300,
    name: '-gmv-ultra-wide-m_1200x300.jpeg',
    crop: true,
    q: DQ,
    isGMV: true,
  },
  GMV_IMAGE_ULTRA_WIDE_L_1600x400: {
    w: 1600,
    h: 400,
    name: '-gmv-ultra-wide-l_1600x400.jpeg',
    crop: true,
    q: DQ,
    isGMV: true,
  },
  GMV_IMAGE_ULTRA_WIDE_XL_2000x500: {
    w: 2000,
    h: 500,
    name: '-gmv-ultra-wide-xl_2000x500.jpeg',
    crop: true,
    q: DQ,
    isGMV: true,
  },

  GMV_IMAGE_ORIGINAL: { name: '-original.jpeg' },
  GMV_IMAGE_REACTION: { name: '-gmv-emoji-l_144x144.png' },
  GMV_IMAGE_SPRITE: { name: '_sprite.png' },
};

/**
 * Providing image URL from imageID and format
 * @param imageId
 * @param format
 * @returns {string}
 */
export function getImageUrl(imageId, format) {
  const baseUrl = `${BASE_IMAGE_URL}/${imageId}`;
  return `${baseUrl}${format.name}`;
}

export function getReactionImageUrl(imageId, format) {
  const baseUrl = `${REACTION_BASE_IMAGE_URL}/${imageId}`;
  return `${baseUrl}${format.name}`;
}

/// /////////////////////////////////////////////////////////////////////////////////
/// /////////////////////////////////////////////////////////////////////////////////
/// /////////////////////////////////////////////////////////////////////////////////
/**
 * Here only for reference to Lambda version
 * I only need GMV* formats
 * ##### DO NOT USE #####
 */
// export const ORIGINAL_FORM_LAMBDA_IMAGE_FORMAT = {
//
//   /**
//    * GMV formats
//    */
//   GMV_IMAGE_SQUARE_S_300x300: {w: 300, h: 300, name: '-gmv-square-s_300x300.jpeg', crop: true, q: DQ, isGMV: true},
//   GMV_IMAGE_SQUARE_M_600x600: {w: 600, h: 600, name: '-gmv-square-m_600x600.jpeg', crop: true, q: DQ, isGMV: true},
//   GMV_IMAGE_SQUARE_L_1200x1200: {w: 1200, h: 1200, name: '-gmv-square-l_1200x1200.jpeg', crop: true, q: DQ, isGMV: true},
//   GMV_IMAGE_WIDE_S_800x480: {w: 800, h: 480, name: '-gmv-wide-s_800x480.jpeg', crop: true, q: DQ, isGMV: true},
//   GMV_IMAGE_WIDE_M_1200x720: {w: 1200, h: 720, name: '-gmv-wide-m_1200x720.jpeg', crop: true, q: DQ, isGMV: true},
//   GMV_IMAGE_WIDE_L_1600x960: {w: 1600, h: 960, name: '-gmv-wide-l_1600x960.jpeg', crop: true, q: DQ, isGMV: true},
//   GMV_IMAGE_ULTRA_WIDE_S_800x200: {w: 800, h: 200, name: '-gmv-ultra-wide-s_800x200.jpeg', crop: true, q: DQ, isGMV: true},
//   GMV_IMAGE_ULTRA_WIDE_M_1200x300: {w: 1200, h: 300, name: '-gmv-ultra-wide-m_1200x300.jpeg', crop: true, q: DQ, isGMV: true},
//   GMV_IMAGE_ULTRA_WIDE_L_1600x400: {w: 1600, h: 400, name: '-gmv-ultra-wide-l_1600x400.jpeg', crop: true, q: DQ, isGMV: true},
//   GMV_IMAGE_ULTRA_WIDE_XL_2000x500: {w: 2000, h: 500, name: '-gmv-ultra-wide-xl_2000x500.jpeg', crop: true, q: DQ, isGMV: true},
//
//   /*
//     #### LEGACY BID formats
//    */
//   IMAGE_FULL: {w: 800, h: 800, name: '-full.jpeg', q: DQ, fill: false},
//   IMAGE_FULL_600x600x80: {w: 600, h: 600, name: FULL_IMAGE_SUFIX_600x600x80, q: DQ, fill: false},
//   IMAGE_SMALL: {w: 280, h: 170, name: '-small.jpeg', crop: true, q: DQ, fill: false, transformation: 'IMAGE_SMALL'},
//   IMAGE_PANEL: {w: 749, h: 455, name: '-panel.jpeg', crop: true, q: DQ, fill: false, transformation: 'IMAGE_PANEL'},
//   IMAGE_SQUARE: {w: 80, h: 80, name: '-square.jpeg', crop: true, q: DQ, fill: false},
//   IMAGE_LOGO: {w: 150, h: 150, name: '-logo.jpeg', q: DQ, fill: false},
//   IMAGE_AVATAR: {w: 150, h: 150, name: '-avatar.jpeg', q: DQ, fill: false},
//
//   IMAGE_FULL_2X: {w: 1600, h: 1600, name: '-full_2x.jpeg', q: DQ, fill: false},
//   IMAGE_FULL_600x600x80_2X: {w: 1200, h: 1200, name: FULL_IMAGE_SUFIX_600x600x80_2X, q: DQ, fill: false},
//   IMAGE_SMALL_2X: {w: 560, h: 340, name: '-small_2x.jpeg', crop: true, q: DQ, fill: false, transformation: 'IMAGE_SMALL'},
//   IMAGE_PANEL_2X: {w: 1498, h: 910, name: '-panel_2x.jpeg', crop: true, q: DQ, fill: false, transformation: 'IMAGE_PANEL'},
//   IMAGE_SQUARE_2X: {w: 160, h: 160, name: '-square_2x.jpeg', crop: true, q: DQ, fill: false},
//   IMAGE_LOGO_2X: {w: 300, h: 300, name: '-logo_2x.jpeg', q: DQ, fill: false},
//   IMAGE_AVATAR_2X: {w: 300, h: 300, name: '-avatar_2x.jpeg', q: DQ, fill: false},
//
//
//   // PROFILE BACKGROUND
//   IMAGE_BG_L: {w: 2000, h: 800, name: '-bg-l.jpeg', crop: true, q: DQ, fill: true},
//   IMAGE_BG_M: {w: 1200, h: 600, name: '-bg-m.jpeg', crop: true, q: DQ, fill: true},
//   IMAGE_BG_S: {w: 800, h: 600, name: '-bg-s.jpeg', crop: true, q: DQ, fill: true},
//
//   // IMAGE_BG_L_2X: {w: 4000, h: 1600, name: '-bg-l_2x.jpeg', crop: true, q: DQ, fill: true},
//   // IMAGE_BG_M_2X: {w: 2400, h:  1200, name: '-bg-m_2x.jpeg', crop: true, q: DQ, fill: true},
//   // IMAGE_BG_S_2X: {w: 1600, h: 1200, name: '-bg-s_2x.jpeg', crop: true, q: DQ, fill: true},
//
//
//   // Banners
//   BANNER_TOP: {w: 728, h: 90, name: '-banner-top.jpeg', crop: true, q: DQ, fill: false},
//   BANNER_SQUARE: {w: 300, h: 250, name: '-banner-square.jpeg', crop: true, q: DQ, fill: false},
//   BANNER_INLINE: {w: 468, h: 60, name: '-banner-inline.jpeg', crop: true, q: DQ, fill: false},
//
//   // BANNER_TOP_2X: {w: 1456, h: 180, name: '-banner-top_2x.jpeg', crop: true, q: DQ, fill: false},
//   // BANNER_SQUARE_2X: {w: 600, h: 500, name: '-banner-square_2x.jpeg', crop: true, q: DQ, fill: false},
//   // BANNER_INLINE_2X: {w: 936, h: 120, name: '-banner-inline_2x.jpeg', crop: true, q: DQ, fill: false}
//
//   // ORIGINAL
//   IMAGE_ORIGINAL: {name: '-original.jpeg', isOriginal: true, q: 100, fill: false},
// };

import React from 'react';
import i18n from '../../i18n/helper';

const stageTypes = {
  IN_PROGRESS: 'in progress',
  DISRUPTIVE: 'disruptive',
  FUTURE: 'future',
  DONE: 'done',
};

const stageStates = {
  // IN_PROGRESS: { icon: 'dot-circle', text: i18n.t('timeline.moreInfo.inProgress'), classModifier: 'active' },
  IN_PROGRESS: {
    icon: 'dot-circle',
    text: <i18n.Translate value='timeline.moreInfo.inProgress' />,
    classModifier: 'active',
  },
  DISRUPTIVE: {
    icon: 'triangle',
    text: <i18n.Translate value='timeline.moreInfo.Disruptive' />,
    classModifier: 'disruptive',
  },
  FUTURE: {
    icon: 'circle',
    text: <i18n.Translate value='timeline.moreInfo.Upcoming' />,
    classModifier: 'upcoming',
  },
  DONE: {
    icon: 'check-circle',
    text: <i18n.Translate value='timeline.moreInfo.Completed' />,
    classModifier: 'completed',
  },
};

const getType = (type) => {
  switch (type) {
    case stageTypes.IN_PROGRESS:
      return stageStates.IN_PROGRESS;
    case stageTypes.DISRUPTIVE:
      return stageStates.DISRUPTIVE;
    case stageTypes.FUTURE:
      return stageStates.FUTURE;
    case stageTypes.DONE:
      return stageStates.DONE;
    default:
      return { icon: '', text: '', classModifier: 'active' };
  }
};

export { getType, stageTypes };

import i18n from 'i18n/helper';
import { SvgIcon } from 'components/atoms/svgIcon';
import type { AnnotateActionsAreaProps } from './types';

export function AnnotateActionsArea({
  onClickZoomOut,
  onClickZoomIn,
  onClickZoomReset,
}: AnnotateActionsAreaProps): JSX.Element {
  return (
    <div className='c-annotate-actions__area'>
      <button className='c-button c-annotate-actions__zoom-in' onClick={onClickZoomIn}>
        <span className='c-button__label'>{i18n.t('questions.annotate.zoomIn')}</span>
        <SvgIcon icon='plus' />
      </button>
      <button className='c-button c-annotate-actions__zoom-out' onClick={onClickZoomOut}>
        <span className='c-button__label'>{i18n.t('questions.annotate.zoomOut')}</span>
        <SvgIcon icon='minus' />
      </button>
      <button className='c-button c-annotate-actions__center' onClick={onClickZoomReset}>
        <span className='c-button__label'>{i18n.t('questions.annotate.center')}</span>
        <SvgIcon icon='expand-fit' />
      </button>
    </div>
  );
}

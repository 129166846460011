import * as home from '../../services/project/home';

export const getHomeData = (slug_id) => (dispatch) =>
  home
    .getDataForHome(slug_id)
    .then((res) => {
      if (res[0] === false || res[1] === false || res[2] === false) {
        Promise.reject(res);
      } else {
        dispatch({
          type: 'SUCCESSFULL_ADD_INFO',
          data: res,
        });
      }
    })
    .catch((res) => {
      dispatch({
        type: 'FAILED_ADD_INFO',
        data: res,
      });
    });

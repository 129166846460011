import React from 'react';
import PropTypes from 'prop-types';
import { DemographicsChoiceContentTypes } from '../DemographicConstants';
import DemographicTemplateChoiceDefault from './DemographicTemplateChoiceDefault';

import DemographicTemplateChoiceOther from './DemographicTemplateChoiceOther';

export default function DemographicTemplateChoice(props) {
  const { choiceContentType } = props;

  const getDemographicChoice = () => {
    switch (choiceContentType) {
      case DemographicsChoiceContentTypes.OTHER:
        return <DemographicTemplateChoiceOther {...props} />;
      case DemographicsChoiceContentTypes.DEFINED:
      case DemographicsChoiceContentTypes.SKIP:
      default:
        return <DemographicTemplateChoiceDefault {...props} />;
    }
  };

  return <React.Fragment>{getDemographicChoice()}</React.Fragment>;
}

DemographicTemplateChoice.propTypes = {
  choiceKey: PropTypes.string,
  questionType: PropTypes.string.isRequired,
  choiceLabel: PropTypes.string.isRequired,
  choiceContentType: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
  choiceId: PropTypes.string.isRequired,
};

import { getCurrentLangCode } from '../language/LanguageHelper';

const getForeignLangQuery = (languageCode) => {
  const lang = languageCode || getCurrentLangCode();
  const query = !lang || lang === 'en' ? '' : `lang=${lang}`;
  return query;
};

const getPreviewQuery = () => {
  const isPreview = sessionStorage.getItem('isPreview');
  return isPreview ? 'isPreview=true' : '';
};

const getQueries = (languageCode) => {
  const params = {
    lang: getForeignLangQuery(languageCode),
    preview: getPreviewQuery(),
  };

  const query = Object.keys(params)
    .map((item) => (item ? params[item] : ''))
    .filter((item) => item !== '')
    .join('&');
  return query ? `?${query}` : '';
};

const isAbsoluteUrl = (url) => url.indexOf('http') >= 0;

const prepareRelativeUrl = (url, isProjectUrl) => {
  const { pathname } = window.location;
  const projectSlug = isProjectUrl ? `/${pathname.split('/')[1]}` : '';

  // maintain backwards compatibility with /timeline that should go to projectSlug/timeline, not /timeline
  const relativeUrl = url[0] === '/' ? url.slice(1) : url;

  return `${projectSlug}/${relativeUrl}${getQueries()}`;
};

const scrollWithOffset = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = document.getElementById('appNav').offsetHeight;
  window.scrollTo({ top: yCoordinate - yOffset, behavior: 'smooth' });
};

export { isAbsoluteUrl, prepareRelativeUrl, getQueries, scrollWithOffset };

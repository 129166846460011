const initialState = {
  options: [],
  userData: {
    email: '',
    postCode: '',
    userConsent: false,
  },
};
export default (state = initialState, action) => {
  switch (action.type) {
    case 'SUCCESS_ADD_FEEDBACK_OPTIONS':
      return {
        ...state,
        ...action.data.data,
      };

    case 'FAIL_ADD_FEEDBACK_OPTIONS':
      return {
        ...state,
        failState: action.data,
      };

    default:
      return state;
  }
};

import * as charities from '../../services/project/retrieveCharities';

export const retrieveCharities = (slug_id) => (dispatch) =>
  charities.getretrieveCharities(slug_id).then((response) => {
    dispatch({
      type: 'RETRIEVE_CHARITIES',
      data: response.data,
    });
    return response;
  });

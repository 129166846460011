import React from 'react';
import PropTypes from 'prop-types';

import Link from '../link/Link';
import SvgIcon from '../SvgIcon';

export default function PillButton(props) {
  const { classModifier = '', url, screenReaderTitle, title, icon = true } = props;

  // on production Link does not work with Absolute urls
  return (
    // eslint-disable-next-line
    <Link className={`c-button c-button--pill ${classModifier}`} url={url}>
      <span className='c-button__label'>
        {screenReaderTitle && <span className='u-screen-reader-text'>{screenReaderTitle}</span>}
        {title}{' '}
      </span>
      {icon && <SvgIcon icon='arrow-right' />}
    </Link>
  );
}

PillButton.propTypes = {
  classModifier: PropTypes.string,
  url: PropTypes.string,
  screenReaderTitle: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.bool,
};

import React from 'react';
import PropTypes from 'prop-types';

export default function CharityExplainerItem(props) {
  const { figure, title, description, actionTitle, onClick } = props;
  return (
    <div className='c-explainer__item c-carousel__item'>
      <div className='c-explainer__group'>
        {figure && <figure className='c-explainer__figure'>{figure}</figure>}
        <h2 className='c-explainer__title'>{title}</h2>
        <p className='c-explainer__description'>{description}</p>
        {actionTitle && onClick && (
          <div className='c-explainer__actions'>
            <button
              className='c-button c-button--alternate c-button--large c-button--pill'
              onClick={() => onClick(false)}
            >
              <span className='c-button__label'>{actionTitle}</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

CharityExplainerItem.propTypes = {
  figure: PropTypes.node,
  title: PropTypes.node,
  description: PropTypes.node,
  actionTitle: PropTypes.node,
  onClick: PropTypes.func,
};

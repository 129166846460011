import React from 'react';
import PropTypes from 'prop-types';

import { Img, WIDE } from '../../../images/Img';
import DemographicQuestionChoices from './DemographicTemplateChoices/DemographicQuestionChoices';
import { getQuestionIllustration, getQuestionClass } from './DemographicQuestionUtils';
import { CHOICES_BREAK_MODIFIER, DEMOGRAPHIC_QUESTION_TEXT } from './DemographicConstants';

export default function DemographicTemplate(props) {
  const { questionText, questionType, choices, onSubmitQuestion, questionId, order, resource } = props;

  const gridListModifier = choices.length > CHOICES_BREAK_MODIFIER ? 'l-choice-grid--list-two' : 'l-choice-grid--list';

  const handleSelect = (choiceId, feedback) => {
    onSubmitQuestion(questionId, choiceId, feedback, order);
  };

  return (
    <div
      className={`c-demographic__group ${getQuestionClass(questionType)}`}
      id={`#${DEMOGRAPHIC_QUESTION_TEXT}-${order}`}
    >
      <div className='c-demographic__header'>
        <h2 className='c-demographic__title'>{questionText}</h2>
      </div>
      {resource.length > 0 ? (
        <figure className='c-demographic__media'>
          <Img imageId={resource[0].url} imageType={WIDE} />
        </figure>
      ) : (
        getQuestionIllustration(questionType) && (
          <figure className='c-demographic__media'>{getQuestionIllustration(questionType)}</figure>
        )
      )}
      <div className='c-demographic__choices'>
        <div className={`l-choice-grid ${gridListModifier}`}>
          <DemographicQuestionChoices
            choices={choices}
            questionId={questionId}
            handleSelect={handleSelect}
            questionType={questionType}
          />
        </div>
      </div>
    </div>
  );
}

DemographicTemplate.propTypes = {
  questionText: PropTypes.string.isRequired,
  questionType: PropTypes.string.isRequired,
  choices: PropTypes.array.isRequired,
  onSubmitQuestion: PropTypes.func.isRequired,
  questionId: PropTypes.string.isRequired,
  order: PropTypes.number.isRequired,
  resource: PropTypes.array.isRequired,
};

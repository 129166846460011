import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

import SliderControls from '../../home/SliderControls';
import CHARITY_EXPLAINER_ITEMS from './ExplainerItems';
import CharityExplainerItem from './ExplainerItem';
import i18n from '../../../i18n/helper';
import CharityExplainerCharityItem from './ExplainerCharityItems';

export default function CharityExplainer(props) {
  const { showExplainer, setShowExplainer, slugId } = props;
  const slider = useRef(null);
  const [slide, setSlide] = useState(0);

  const IDENTIFIER = 'explainer';

  useEffect(() => {
    slider.current.slickGoTo(slide);
  }, [slide]);

  const sliderSettings = {
    mobileFirst: true,
    arrows: false,
    afterChange: (current) => setSlide(current),
    adaptiveHeight: true,
  };

  const sliderItems = CHARITY_EXPLAINER_ITEMS.map((item, index) => (
    <CharityExplainerItem key={index} figure={item.figure} title={item.title} description={item.description} />
  ))
    .concat(<CharityExplainerCharityItem key={15} slugId={slugId} />)
    .concat(
      <CharityExplainerItem
        key={16}
        title={i18n.t('gamification.intro.step5.title')}
        description={i18n.t('gamification.intro.step5.body')}
        actionTitle={i18n.t('body.say')}
        onClick={setShowExplainer}
      />,
    );

  return (
    <div className='c-explainer' id='explainer' aria-hidden={!showExplainer}>
      <div className='c-carousel c-explainer__carousel'>
        <Slider {...sliderSettings} ref={slider} className='c-carousel__items'>
          {sliderItems}
        </Slider>
        <SliderControls
          setSlide={setSlide}
          classModifier=''
          slide={slide}
          items={sliderItems}
          identifier={IDENTIFIER}
        />
      </div>
    </div>
  );
}

CharityExplainer.propTypes = {
  showExplainer: PropTypes.bool,
  setShowExplainer: PropTypes.func,
};

import React from 'react';
import PropTypes from 'prop-types';

const PollProgress = (props) => {
  const { questionCount, currentStep } = props;
  const total = questionCount >= 1 ? questionCount : 1;
  // const current = props.question_count - props.incomplete_questions || props.total_coins_earned;
  const current = currentStep >= 0 ? currentStep : 0;

  // ##### I have a bug in the counter
  // max to 100%, but still need to fix the counter
  const percentageCompleted = Math.min((100 * current) / total, 100);
  // console.log('PROGRESS', total, current, props.currentStep, percentageCompleted);
  return (
    <React.Fragment>
      <div
        className='c-modal-poll__progress'
        role='progressbar'
        aria-valuenow={Math.floor(percentageCompleted)}
        aria-valuemin='0'
        aria-valuemax='100'
      >
        <span className='c-modal-poll__progress-filled' style={{ width: `${percentageCompleted}%` }} />
      </div>
    </React.Fragment>
  );
};

PollProgress.propTypes = {
  questionCount: PropTypes.number,
  currentStep: PropTypes.number,
};

export default PollProgress;

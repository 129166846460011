import { useState } from 'react';
import { bindActionCreators, AnyAction, Dispatch } from 'redux';
import { connect } from 'react-redux';

import {
  executeUpdate,
  DEFAULT_STATE,
  executeUpdateMultipleAnswerQuestion,
} from '../../question/QuestionUtils/QuestionFormUtils';
import { submitPollQuestion, getAboutData, getPolls } from '../../../actions';
import { QuestionProps, Answer } from './types';

const QuestionFn = ({
  question,
  slugId,
  pollId,
  questionButtonClicked,
  actions,
  showCoinsInHeader,
  skipResults,
  QuestionComponent,
  setHideSkipAfterAnswer,
  setAnnotateEdit,
  isQQ,
  setIsMediaZoomed,
  isMediaZoomed,
}: QuestionProps): JSX.Element => {
  const { _id } = question;

  const [state, setState] = useState(DEFAULT_STATE);

  const onSubmitQuestion = (answer: Answer, skipResultsOnIntermediate = true) => {
    // console.log(answer);
    // eslint-disable-next-line
    executeUpdate(
      setState,
      slugId,
      pollId,
      _id,
      answer,
      actions,
      showCoinsInHeader,
      skipResults && skipResultsOnIntermediate,
      isQQ,
      questionButtonClicked,
    );
  };

  const onSubmitMultipleQuestion = (answer: Answer) => {
    // eslint-disable-next-line
    executeUpdateMultipleAnswerQuestion(actions.submitPollQuestion, slugId, pollId, _id, answer, question._id);
  };

  return (
    <div className='c-modal-poll__content'>
      <QuestionComponent
        showCoinsInHeader={showCoinsInHeader}
        question={question}
        communityAnswer={state.communityAnswer}
        onSubmitQuestion={onSubmitQuestion}
        onSubmitMultipleQuestion={onSubmitMultipleQuestion}
        isResult={state.isResult}
        showCoin={state.showCoin}
        questionButtonClicked={questionButtonClicked}
        slugId={slugId}
        disableForm={state.disableForm}
        setHideSkipAfterAnswer={setHideSkipAfterAnswer}
        setAnnotateEdit={setAnnotateEdit}
        skipResults={skipResults}
        setIsMediaZoomed={setIsMediaZoomed}
        isMediaZoomed={isMediaZoomed}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators(
    {
      submitPollQuestion,
      getAboutData,
      getPolls,
    },
    dispatch,
  ),
});

export const Question = connect(null, mapDispatchToProps)(QuestionFn);

import { useState } from 'react';
import { SvgIcon } from 'components/atoms/svgIcon';
import i18n from 'i18n/helper';
import { AnnotateMenuProps } from './types';
import SlimUploader from '../../../../fileUpload/SlimUploader';
import { MAX_FEEDBACK_CHARS } from '../../../../../constants';
import { IMAGE_FORMATS } from '../../../../images/Formats';

export function AnnotateMenu({
  feedback,
  setFeedback,
  onClickSubmit,
  onClickDelete,
  allowComments,
  allowImageUpload,
  image,
  setImage,
}: AnnotateMenuProps): JSX.Element {
  const [feedbackOpened, setFeedbackOpened] = useState(false);

  return (
    <div className='c-annotate-menu'>
      <div className={`c-annotate-menu__user-feedback options--${Number(allowComments) + Number(allowImageUpload)}`}>
        {allowComments ? (
          <div className='c-annotate-menu__item c-annotate-menu__item--feedback'>
            <button
              className='c-annotate-menu__action c-annotate-menu__action--feedback'
              onClick={() => setFeedbackOpened(true)}
            >
              <span className='c-annotate-menu__icon'>
                <SvgIcon icon='comment-alt-dots' />
              </span>
              <span className='o-label o-label--short'>{i18n.t('questions.annotate.feedback')}</span>
              <span className='o-label o-label--long'>
                {feedback.length > 0
                  ? i18n.t('questions.annotate.editFeedback')
                  : i18n.t('questions.annotate.leaveFeedback')}
              </span>
            </button>
          </div>
        ) : null}
        {allowImageUpload ? (
          <div className='c-annotate-menu__item c-annotate-menu__item--media'>
            <SlimUploader
              imageFormat={{
                ratio: 'free',
                size: { width: 800, height: 800 },
                imageType: IMAGE_FORMATS.GMV_IMAGE_ORIGINAL,
              }}
              // For testing scenario where image already exists
              // imageId='60af6a7106ec6174730747f7'
              // For testing scenario where no image is uploaded yet
              // imageId='60af6a7106ec6174730747f7'
              id='question-upload'
              image={image}
              setImage={setImage}
              isAnnotate
            />
          </div>
        ) : null}
      </div>
      <div className='c-annotate-menu__user-final-actions'>
        <div className='c-annotate-menu__item c-annotate-menu__item--delete'>
          <button
            className='c-button c-button--attention'
            onClick={() => {
              onClickDelete();
              setFeedbackOpened(false);
            }}
          >
            <span className='u-screen-reader-text'>{i18n.t('questions.annotate.remove')}</span>
            <SvgIcon icon='trash' />
          </button>
        </div>
        <div className='c-annotate-menu__item c-annotate-menu__item--submit'>
          <button
            className='c-button c-button--alternate c-button--large  c-button--block'
            onClick={() => {
              onClickSubmit();
              setFeedbackOpened(false);
            }}
          >
            <span className='c-button__label'>{i18n.t('questions.submitButton')}</span>
          </button>
        </div>
      </div>
      <div className={`c-annotate-advanced__feedback ${feedbackOpened ? 'is-opened' : 'is-closed'}`}>
        <button className='c-button c-annotate-advanced__toggle' onClick={() => setFeedbackOpened(false)}>
          <span className='c-button__label'>{i18n.t('questions.annotate.toggle')}</span>
          <SvgIcon icon='angle-down' />
        </button>
        <div className='c-field c-field--label-hide c-annotate-advanced__field'>
          <label className='c-field__label' htmlFor='question-feedback'>
            {i18n.t('questions.annotate.feedback')}
          </label>
          <div className='c-field__group'>
            <div className='o-textarea'>
              <textarea
                placeholder={i18n.t('questions.feedbackForm.textareaPlaceholder')}
                id='question-feedback'
                onChange={(ev) => setFeedback(ev.target.value)}
                value={feedback}
                maxLength={MAX_FEEDBACK_CHARS}
              />
            </div>
            <span className='c-field__count' title={i18n.t('questions.feedbackForm.countTitle')}>
              {MAX_FEEDBACK_CHARS - feedback.length}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

/**
 * With upgrade to react-markdown 8.0.7, the allowedElements notation has changed.
 * from docs - node (Element from hast) — element to check
 * https://github.com/syntax-tree/hast#element
 * Below commented the old notation
 */
export const MARKDOWN_MIN = {
  renderConfig: ['strong', 'em', 'text'],
};

export const MARKDOWN_MIN_LINK = {
  renderConfig: ['strong', 'em', 'a', 'text'],
};

export const MARKDOWN_MIN_PLUS = {
  renderConfig: ['strong', 'p', 'em', 'text'],
};

export const MARKDOWN_MID = {
  renderConfig: ['strong', 'p', 'em', 'a', 'text'],
};

export const MARKDOWN_MAX = {
  renderConfig: ['strong', 'p', 'em', 'a', 'text', 'ul', 'ol', 'li'],
};

// export const MARKDOWN_MIN = {
//   renderConfig: ['strong', 'emphasis', 'text'],
// };

// export const MARKDOWN_MIN_LINK = {
//   renderConfig: ['strong', 'emphasis', 'link', 'text'],
// };

// export const MARKDOWN_MIN_PLUS = {
//   renderConfig: ['strong', 'paragraph', 'emphasis', 'text'],
// };

// export const MARKDOWN_MID = {
//   renderConfig: ['strong', 'paragraph', 'emphasis', 'link', 'text'],
// };

// export const MARKDOWN_MAX = {
//   renderConfig: ['strong', 'paragraph', 'emphasis', 'link', 'text', 'list', 'listItem'],
// };

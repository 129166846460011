import * as polls from '../services/polls';

// export const openPoll = () => {
//   return {
//     type: 'SHOW_POLL',
//     value: true,
//   };
// };
//
// export const closePoll = () => {
//   return {
//     type: 'CLOSE_POLL',
//     value: false,
//   };
// };

export const getPolls = (slug_id) => (dispatch) =>
  polls
    .getPollsFromServer(slug_id)
    .then((res) => {
      dispatch({
        type: 'SUCCESS_ADD_POLLS',
        data: res.data,
      });
      const { data } = res;
      data.isSuccessfull = true;
      return data;
    })
    .catch((res) => {
      dispatch({
        type: 'FAIL_ADD_POLLS',
        data: res,
      });
      const data = res.data ? res.data : {};
      data.isSuccessfull = false;
      return data;
    });

export const retrievePoll = (slug_id, poll_id) => (dispatch) =>
  polls
    .retrievePollFromServer(slug_id, poll_id)
    .then((res) => {
      dispatch({
        type: 'SUCCESS_RETRIEVE_POLL',
        data: res,
      });
      return res;
    })
    .catch((res) => {
      dispatch({
        type: 'FAIL_RETRIEVE_POLL',
        data: res.data,
      });
      // let data = res.data;
      return Promise.reject(res);
    });

export const tempAuthToken = () => (dispatch) =>
  polls
    .temporaryAuthToken()
    .then((response) => {
      dispatch({
        type: 'TEMP_AUTH_TOKEN_SUCCESS',
        data: response,
      });
      return response;
    })
    .catch((response) => {
      dispatch({
        type: 'TEMP_AUTH_TOKEN_FAILED',
        data: response,
      });
      return response;
    });

export const submitPollQuestion = (slug_id, poll_id, question_id, data) => (dispatch) =>
  polls
    .submitPollQues(slug_id, poll_id, question_id, data)
    .then((response) => {
      dispatch({
        type: 'SUBMIT_POLL_QUESTION_SUCCESS',
        data: response,
      });
      return response;
    })
    .catch((response) => {
      dispatch({
        type: 'SUBMIT_POLL_QUESTION_FAILED',
        data: response,
      });
      return response;
    });

export const pollQuestionSeen = (slug_id, poll_id, question_id) => (dispatch) =>
  polls
    .pollQuestionSeen(slug_id, poll_id, question_id)
    .then((response) => {
      dispatch({
        type: 'SEEN_POLL_QUESTION_SUCCESS',
        data: response,
      });
      return response;
    })
    .catch((response) => {
      dispatch({
        type: 'SEEN_POLL_QUESTION_FAILED',
        data: response,
      });
      return response;
    });

export const pollQuestionSkip = (slug_id, poll_id, question_id) => (dispatch) =>
  polls
    .pollQuestionSkip(slug_id, poll_id, question_id)
    .then((response) => {
      dispatch({
        type: 'SKIP_POLL_QUESTION_SUCCESS',
        data: response,
      });
      return response;
    })
    .catch((response) => {
      dispatch({
        type: 'SKIP_POLL_QUESTION_FAILED',
        data: response,
      });
      return response;
    });

export const charityDonation = (slug_id, poll_id, charity_id, data) => (dispatch) =>
  polls
    .submitCharityDonation(slug_id, poll_id, charity_id, data)
    .then((response) => {
      dispatch({
        type: 'SUBMIT_CHARITY_DONATION_SUCCESS',
        data: response,
      });
      return response;
    })
    .catch((response) => {
      dispatch({
        type: 'SUBMIT_CHARITY_DONATION_FAILED',
        data: response,
      });
      return response;
    });

export const socialShare = (slug_id) => (dispatch) =>
  polls
    .submitSocialShare(slug_id)
    .then((response) => {
      dispatch({
        type: 'SUBMIT_SOCIAL_SHARE_SUCCESS',
        data: response,
      });
      return response;
    })
    .catch((response) => {
      dispatch({
        type: 'SUBMIT_SOCIAL_SHARE_FAILED',
        data: response,
      });
      return response;
    });

export const feedbackEmailZip = (data) => ({
  type: 'FEEDBACK_EMAIL_ZIP',
  value: data,
});

export const uploadImage =
  (file, progressHandler) =>
  (
    dispatch, // TODO -- temporary for Image upload on Client, this might be permanent
  ) =>
    polls
      .uploadImage(file, progressHandler)
      .then((response) => {
        dispatch({
          type: 'IMAGE_UPLOAD_SUCCESS',
          data: response,
        });
        return response;
      })
      .catch((response) => {
        dispatch({
          type: 'IMAGE_UPLOAD_FAILED',
          data: response,
        });
        return response;
      });

import React from 'react';
import PropTypes from 'prop-types';

import Coin from '../../coin/coin';

export default function QuestionFooter(props) {
  const { onClick, buttonText, buttonClassModifier, showCoin, disabled = false } = props;
  return (
    <div className='c-question__footer'>
      {showCoin && <Coin bounceFromBottom />}
      <button
        className={`c-button ${buttonClassModifier} ${disabled ? 'disabled' : ''}`}
        onClick={onClick}
        disabled={disabled}
      >
        <span className='c-button__label'>{buttonText}</span>
      </button>
    </div>
  );
}

QuestionFooter.propTypes = {
  showCoin: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
  buttonClassModifier: PropTypes.string,
};

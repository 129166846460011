import { get, all } from '../api';

const PROJECTS = '/projects/';

export const getDataForHome = (slug_id) => {
  const confingData = [
    {
      endpoint: `${PROJECTS}${slug_id}/feed`,
      data: null,
    },
    {
      endpoint: `${PROJECTS}${slug_id}/gallery`,
      data: null,
    },
    {
      endpoint: `${PROJECTS}${slug_id}/phases`,
      data: null,
    },
  ];
  return all(get, confingData);
};

import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../i18n/helper';
import { Img, WIDE } from '../images/Img';
import SvgIcon from '../SvgIcon';
import { SECONDS_PER_QUESTION } from '../../constants';

export default function SinglePoll(props) {
  const { isFinished, onClick, resource, name, coins, questionCount, completedQuestions, classModifier } = props;
  const getPollClassModifier = () => {
    const ifFinished = isFinished ? 'c-card-poll--completed' : '';
    const ifResource = resource ? ' c-card-poll--figure' : '';
    const ifContinue = completedQuestions > 0 ? 'c-card-poll--continue' : '';
    const classMod = `${ifFinished} ${ifResource} ${ifContinue} ${classModifier}`;
    return classMod;
  };
  const getMinutes = () => {
    const pollDuration = (questionCount * SECONDS_PER_QUESTION) / 60;
    return pollDuration < 1
      ? i18n.t('pollsScreen.underAMinute')
      : i18n.t('pollsScreen.underMinutes', { time: Math.ceil(pollDuration) });
  };

  const getPollCompleted = () => (
    <React.Fragment>
      {coins ? (
        <React.Fragment>
          <SvgIcon icon='coin' />{' '}
          {isFinished
            ? `${i18n.t('pollsScreen.donated', { x: coins })}`
            : `${coins} ${i18n.t('pollsScreen.points')} — ${getMinutes()}`}
        </React.Fragment>
      ) : (
        <React.Fragment>{isFinished ? `${i18n.t('pollsScreen.completed')}` : `${getMinutes()}`}</React.Fragment>
      )}
    </React.Fragment>
  );

  return (
    <article className={`c-card-poll ${getPollClassModifier()}`} onClick={isFinished ? null : onClick}>
      {resource && (
        <figure className='c-card-poll__figure'>
          <Img imageType={WIDE} imageId={resource} alt='' />
        </figure>
      )}
      <div className='c-card-poll__content'>
        <h2 className='c-card-poll__title'>{name}</h2>
        <p className='c-card-poll__meta'>{getPollCompleted()}</p>
        {isFinished ? (
          <div className='c-card-poll__completed'>
            <SvgIcon icon='check' />
          </div>
        ) : (
          <button className='c-button c-button--primary c-button--small c-button--pill c-card-poll__action'>
            {completedQuestions > 0 ? i18n.t('pollsScreen.continue') : i18n.t('pollsScreen.answerPoll')}
            <SvgIcon icon='arrow-right' />
          </button>
        )}
      </div>
    </article>
  );
}

SinglePoll.propTypes = {
  name: PropTypes.string,
  questionCount: PropTypes.number,
  isFinished: PropTypes.bool,
  completedQuestions: PropTypes.number,
  coins: PropTypes.number,
  resource: PropTypes.string,
  onClick: PropTypes.func,
  classModifier: PropTypes.string,
};

import React from 'react';
import PropTypes from 'prop-types';

import { Img, SQUARE } from '../images/Img';

const TeamMemberFn = (props) => {
  const { title, role, resource } = props;
  return (
    <React.Fragment>
      {resource && (
        <figure className='c-team__logo'>
          <Img imageType={SQUARE} imageId={resource} alt='member' />
        </figure>
      )}
      <div className='c-team__group'>
        <h3 className='c-team__title'>{title}</h3>
        <span className='c-team__meta'>{role}</span>
      </div>
    </React.Fragment>
  );
};

export default function TeamMember(props) {
  const { title, role, resource, link, teamId } = props;
  return (
    <div className='c-team__item'>
      {link ? (
        <a
          href={link}
          onClick={() => window.gtag(`'event', ‘teamMember', {'event_category': 'feed', 'event_label': ${teamId}}`)}
          target='_blank'
          rel='noopener noreferrer'
        >
          <TeamMemberFn title={title} role={role} resource={resource} />
        </a>
      ) : (
        <TeamMemberFn title={title} role={role} resource={resource} />
      )}
    </div>
  );
}

TeamMember.propTypes = {
  resource: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  link: PropTypes.string,
  teamId: PropTypes.string.isRequired,
};

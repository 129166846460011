import React from 'react';
import PropTypes from 'prop-types';

import HashLink from '../link/HashLink';
import SvgIcon from '../SvgIcon';

export default function PillButtonHashLink(props) {
  const { classModifier = '', url, hash, screenReaderTitle, title, icon = true } = props;

  // on production Link does not work with Absolute urls
  return (
    <HashLink className={`c-button c-button--pill ${classModifier}`} url={url} hash={hash}>
      <span className='c-button__label'>
        {screenReaderTitle && <span className='u-screen-reader-text'>{screenReaderTitle}</span>}
        {title}{' '}
      </span>
      {icon && <SvgIcon icon='arrow-right' />}
    </HashLink>
  );
}

PillButtonHashLink.propTypes = {
  classModifier: PropTypes.string,
  url: PropTypes.string,
  hash: PropTypes.string,
  screenReaderTitle: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.bool,
};

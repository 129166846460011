import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import i18n from '../../../i18n/helper';
import DemographicIntro from './DemographicIntro';
import DemographicProgress from './DemographicProgress';
import DemographicTemplate from './questions/DemographicTemplate';
import { handleScroll, executeUpdateMultipleAnswerQuestion } from '../QuestionUtils/QuestionFormUtils';
import {
  DEMOGRAPHIC_PROGRESS_MAX,
  DEMOGRAPHIC_QUESTION_TEXT,
  DEMOGRAPHIC_DELAY,
} from './questions/DemographicConstants';
import { submitPollQuestion } from '../../../actions';

function DemographicQuestion(props) {
  const { question, questionButtonClicked, slugId, pollId, actions, setHideSkipDemographic } = props;
  const { questions } = question;

  const [isScrollingClass, setIsScrollingClass] = useState('');
  const [circleOffset, setCircleOffset] = useState(DEMOGRAPHIC_PROGRESS_MAX);
  const [questionsAnswered, setQuestionsAnswered] = useState(new Set());
  const [nextQuestion, setNextQuestion] = useState('');

  useEffect(() => {
    setCircleOffset(DEMOGRAPHIC_PROGRESS_MAX - (DEMOGRAPHIC_PROGRESS_MAX / questions.length) * questionsAnswered.size);
  }, [questionsAnswered, questions.length]);

  const scrollToElementInModal = (elementOrder) => {
    const el = document.getElementById(`#${DEMOGRAPHIC_QUESTION_TEXT}-${elementOrder}`);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    setHideSkipDemographic(!!isScrollingClass);
  }, [isScrollingClass, setHideSkipDemographic]);

  const onSubmitQuestion = (demographicQuestionId, choiceId, feedback, questionNumber) => {
    setTimeout(() => {
      setNextQuestion(questionNumber > questions.length ? questions.length : questionNumber + 1);
      scrollToElementInModal(questionNumber > questions.length ? questions.length : questionNumber + 1);
    }, DEMOGRAPHIC_DELAY);
    setQuestionsAnswered(new Set(questionsAnswered.add(demographicQuestionId)));
    const choiceArray = [
      {
        choiceId,
        order: null,
        amount: null,
        feedback: feedback || null,
      },
    ];
    const answer = { questionId: demographicQuestionId, choice: choiceArray };
    // console.log('POSTing answer', answer);
    executeUpdateMultipleAnswerQuestion(
      actions.submitPollQuestion,
      slugId,
      pollId,
      question._id,
      answer,
      demographicQuestionId,
    );
  };

  return (
    <div className='c-modal-poll__content'>
      <div
        className={`c-demographic ${isScrollingClass}`}
        onScroll={handleScroll(setIsScrollingClass, 'c-demographic--scroll')}
      >
        {isScrollingClass && <DemographicProgress circleOffset={circleOffset} />}
        <DemographicIntro
          goToNextQuestion={() => {
            scrollToElementInModal(nextQuestion || 0);
          }}
          hideButton={!!isScrollingClass}
        />
        {questions.map((DemographicQuestionItem) => (
          <DemographicTemplate
            key={DemographicQuestionItem._id}
            questionType={DemographicQuestionItem.questionType}
            questionText={DemographicQuestionItem.questionText}
            choices={DemographicQuestionItem.choices}
            onSubmitQuestion={onSubmitQuestion}
            questionId={DemographicQuestionItem._id}
            order={DemographicQuestionItem.order}
            resource={DemographicQuestionItem.resource}
          />
        ))}
        <footer className='c-demographic__footer'>
          <button
            className='c-button c-button--primary c-button--large'
            id={`#${DEMOGRAPHIC_QUESTION_TEXT}-${questions.length}`}
            onClick={questionButtonClicked}
          >
            <span className='c-button__label'>{i18n.t('questions.nextQuestion')}</span>
          </button>
        </footer>
      </div>
    </div>
  );
}

DemographicQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  questionButtonClicked: PropTypes.func.isRequired,
  slugId: PropTypes.string.isRequired,
  pollId: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      submitPollQuestion,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(DemographicQuestion);

import React from 'react';
import { connect } from 'react-redux';
// import ReactPixel from 'react-facebook-pixel';
import { bindActionCreators } from 'redux';
import { NavLink } from 'react-router-dom';
import { getAboutData, getCustomizations, tempAuthToken } from '../../actions';
import LanguageComponent from '../language/LanguageComponent';
import { Img, U_WIDE, SQUARE } from '../images/Img';
import { getBidTokenWithExpiryAndApplyEnum, setBidTokenWithExpiry } from '../../utils/utils';

class Header extends React.Component {
  /**
   * ##### Probably inappropriate usage of componentDidMount
   * I'm basically using it as application init
   * as the first "mounted" and fully wired component that gets loaded after
   * we know the project.The only thing "above" is App.js,but that one is not
   * fully wired ie. I don't have actions and redux.
   */
  componentDidMount() {
    // Create user token as early as possible (if one does not exist)
    // load customizations to get Token Expiration enum
    this.props.actions.getCustomizations(this.props.slug_id).then(() => {
      if (!getBidTokenWithExpiryAndApplyEnum(this.props.expireToken)) {
        this.props.actions.tempAuthToken().then((res) => {
          setBidTokenWithExpiry(res.data.token, this.props.expireToken);
        });
      }
    });

    this.props.actions.getAboutData(this.props.slug_id).then(() => {
      this.setState(
        {
          // isLoading: false,
        },
        () => {
          window.latitude = this.props.aboutProject.location ? this.props.aboutProject.location.latitude : 0;
          window.longitude = this.props.aboutProject.location ? this.props.aboutProject.location.longitude : 0;
        },
      );
    });
  }

  render() {
    const slug = sessionStorage.getItem('slug');
    return (
      <React.Fragment>
        <header className='l-section-header c-section-header' id='appHeader' role='banner'>
          <div className='l-container'>
            <div className='c-masthead'>
              <figure className='c-masthead__background'>
                {this.props.aboutProject.projectHeader && (
                  <Img imageType={U_WIDE} imageId={this.props.aboutProject.projectHeader} alt='banner' />
                )}
              </figure>

              {this.props.aboutProject.projectLogo && (
                <figure className='c-masthead__logo'>
                  <NavLink to={`/${slug}`} exact>
                    <Img imageType={SQUARE} imageId={this.props.aboutProject.projectLogo} alt='logo' />
                  </NavLink>
                </figure>
              )}

              <h1 className='c-masthead__title'>
                {this.props.aboutProject.projectName ? this.props.aboutProject.projectName : ''}
              </h1>

              <p className='c-masthead__tagline'>
                {this.props.aboutProject.architects ? this.props.aboutProject.architects : ''}
              </p>
            </div>
            <LanguageComponent slugId={this.props.slug_id} forceUpdateHandler={this.props.forceUpdateHandler} />
          </div>
        </header>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  expireToken: state.customizations.forceExpireToken,
  aboutProject: state.header,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ getAboutData, getCustomizations, tempAuthToken }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);

const ExpirationTypes = {
  D1: 'D1', // 1 day
  D2: 'D2', // 2 days
  D3: 'D3',
  D4: 'D4',
  D5: 'D5',
  D6: 'D6',
  D7: 'D7',
  W1: 'W1', // end of week
  M1: 'M1', // end of month
  MON: 'MON', // monday
  TUE: 'TUE',
  WED: 'WED',
  THU: 'THU',
  FRI: 'FRI',
  SAT: 'SAT',
  SUN: 'SUN',
};

function ExpirationTypeList() {
  return Object.keys(ExpirationTypes).map((key) => ExpirationTypes[key]);
}

module.exports = {
  ExpirationTypes,
  ExpirationTypeList,
};

import { SvgIcon } from 'components/atoms/svgIcon';
import i18n from 'i18n/helper';
import { AnnotateModalDeleteProps } from './types';

export function AnnotateModalDelete({
  onClickCancel,
  onClickConfirm,
  isActive,
}: AnnotateModalDeleteProps): JSX.Element {
  return (
    <div className={`c-pseudo-modal c-pseudo-modal--delete-annotation ${isActive ? 'is-active' : ''}`}>
      <header className='c-pseudo-modal__header'>
        <h3 className='c-pseudo-modal__title'>{i18n.t('questions.annotate.deleteWarning')}</h3>
      </header>
      <div className='c-pseudo-modal__content'>
        <p>{i18n.t('questions.annotate.deleteReminder')}</p>
      </div>
      <footer className='c-pseudo-modal__footer'>
        <div className='c-pseudo-modal__action-item c-pseudo-modal__action-item__cancel'>
          <button className='c-button' onClick={onClickCancel}>
            <span className='c-button__label u-screen-reader-text'>{i18n.t('questions.annotate.cancel')}</span>
            <SvgIcon icon='times' />
          </button>
        </div>
        <div className='c-pseudo-modal__action-item c-pseudo-modal__action-item__confirm'>
          <button className='c-button c-button--alternate' onClick={onClickConfirm}>
            <span className='c-button__label u-screen-reader-text'>{i18n.t('questions.annotate.confirm')}</span>
            <SvgIcon icon='check' />
          </button>
        </div>
      </footer>
    </div>
  );
}

import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { useGetNextQuestion } from 'utils/hooks/useGetNextQuestion/useGetNextQuestion';
// action
import {
  retrieveCharities,
  charityDonation,
  getPolls,
  getAboutData,
  getFeedbakOptions,
  pollQuestionSeen,
  pollQuestionSkip,
} from '../../actions';
// helper
import QuestionHelper from './QuestionHelper';
import { getActiveCharities } from '../../utils/utils';

// components
import QuestionHeader from '../../components/question/QuestionHeader';
import PollProgress from '../../components/question/PollProgress';

// Non-question forms
import EndOfPollAnonymous from '../../components/gamefication/EndOfPollAnonymous';
import AccessDenied from '../../components/location/AccessDenied';
import WrongLocation from '../../components/location/WrongLocation';
import EndOfPollCharity from '../../components/gamefication/EndOfPollCharity';

import {
  isQuestion,
  isQualifyingQuestion,
  isFact,
  isDemographicQuestion,
  INCLUDE_QQ,
  INCLUDE_FACTS,
  EXCLUDE_FACTS,
  AT_1007_CHARITY,
  AT_1014_END_OF_POLL,
  AT_1013_KNOW_LOCATION,
  AT_1016_WRONG_LOCATION,
  INCLUDE_DEMOGRAPHIC,
  isAnnotateImageQuestion,
  isAnnotateMapQuestion,
  isPersonalDataQuestion,
} from './QuestionConstants';
import QuestionUtils from '../../components/question/QuestionUtils/QuestionUtils';
import { SUBMIT_DELAY } from '../../constants';

const IMAGE_ZOOM_CLASS = 'pan-zoom-modal-active';

function Questions(props) {
  // props from container (Polls.js)
  const { slugId, initialFormType, initialQuestion, closePoll, nextPoll } = props;
  // props from global state (via stateToProps)
  const { charities, poll, header, polls } = props;
  // props from dispatch (via dispatchToProps)
  const { actions, customizations } = props;

  const questions = poll.questions || [];

  // STATE definition
  // const [answers, setAnswers] = useState([]);
  const answers = useRef([]);
  const [currentQuestionIndex, currentForm, getNextQuestion] = useGetNextQuestion(
    questions,
    poll.lastQuestionAnswered,
    poll.isConditionalPoll,
    initialQuestion,
    initialFormType,
    getActiveCharities(charities).length > 0,
    poll.isCharityChosen,
    poll._id,
    answers,
  );

  const [ariaState, setAriaState] = useState(false);
  const [showCoins, setShowCoins] = useState(false);
  const [hideSkipDemographic, setHideSkipDemographic] = useState(false);
  const [annotateEdit, setAnnotateEdit] = useState('');
  const questionId = questions[currentQuestionIndex] ? questions[currentQuestionIndex]._id : 0;
  const [hideSkipAfterAnswer, setHideSkipAfterAnswer] = useState(false);
  // Added additional state to hide components because of z-index issue:
  // https://github.com/sdesregistry/IH-Engage/issues/1926#issuecomment-1267281701
  const [isMediaZoomed, setIsMediaZoomed] = useState(false);

  // console.log('FORM TYPE', index, formType, initialFormType, initialQuestion);
  // console.log('Questions', poll.questions);

  useEffect(() => {
    actions.getFeedbakOptions(slugId);
  }, [slugId, actions]);

  /**
   * ESC key handler for closing poll, wrapped in useCallback
   * https://reactjs.org/docs/hooks-reference.html#usecallback
   */
  const onOverlayKeyDown = useCallback(
    (event) => {
      if (event.which === 27) {
        closePoll();
      }
    },
    [closePoll],
  );

  useEffect(() => {
    // console.log('MOUNT');
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('is-modal-opened');
    setAriaState(false);

    // returned function will be called on component unmount
    return () => {
      // console.log('UN-MOUNT');
      body.classList.remove('is-modal-opened');
      setAriaState(true);
    };
  }, [setAriaState]);

  useEffect(() => {
    actions.retrieveCharities(slugId);
  }, [actions, slugId]);

  useEffect(() => {
    setShowCoins(false);
    if (questionId) {
      actions.pollQuestionSeen(slugId, poll._id, questionId);
    }
  }, [questionId, poll._id, slugId, actions]);

  /**
   * Returns current question
   * Uses question index from state
   * @returns {*}
   */
  function getCurrentQuestion() {
    return questions[currentQuestionIndex];
  }

  /**
   * Update polls status after answer
   */
  function getUpdatedPolls() {
    actions.getPolls(slugId);
  }

  const showCoinsInHeader = (isQQ, answer) => {
    answers.current = [...answers.current, answer];
    setHideSkipAfterAnswer(true);
    // send GA event the first time someone answer a qustion on a poll
    const currentPoll = polls.polls.find((item) => item._id === poll._id);
    if (!currentPoll.completedQuestions && currentQuestionIndex === 0) {
      window.gtag('event', 'firstSubmit', { event_category: 'polls', event_label: poll._id });
    }

    let showCoinsIfCharity = false;
    if (charities.filter((charity) => charity.isActive).length > 0 && !isQQ) {
      setShowCoins(true);
      QuestionUtils.timerPromise(2 * SUBMIT_DELAY).then(() => {
        setShowCoins(false);
      });
      showCoinsIfCharity = true;
    }
    return showCoinsIfCharity;
  };

  /**
   * Submit charity pick
   * @param charityId
   */
  function onCharityClick(charityId) {
    const obj = { charityId };

    actions.charityDonation(slugId, poll._id, charityId, obj).then(() => {
      actions.getPolls(slugId);
      getNextQuestion();
    });
  }

  /**
   * After the question is submitted go to next step (question or Charity or EndOfPoll)
   */
  function onEndQuestion() {
    setHideSkipAfterAnswer(false);
    setAnnotateEdit('');
    // update poll state upon question answer
    // no need to update if fact
    if (!isFact(currentForm)) {
      getUpdatedPolls();
      // event.stopPropagation();
      actions.getAboutData(slugId);
    }
    getNextQuestion();
  }

  function onSkipQuestion() {
    actions.pollQuestionSkip(slugId, poll._id, questionId);
    onEndQuestion();
  }

  /**
   * calculate the amount of points earned per question answered
   */
  function getPoints() {
    const currentPoll = polls.polls.find((item) => item._id === poll._id);
    const pointsPerQuestion = currentPoll ? currentPoll.coins / currentPoll.questionCount : 0;
    const pointsEarned = currentPoll ? currentPoll.completedQuestions * pointsPerQuestion + pointsPerQuestion : 0;
    return {
      pointsPerQuestion,
      pointsEarned,
    };
  }

  function isConditionalLogicQuestion() {
    return questions[currentQuestionIndex]?.questionConfiguration?.conditionalExecution?.rules.length > 0;
  }

  /**
   * Displays form based on formType parameter
   * @returns {null|*}
   */
  function questionSwitcher() {
    /**
     * QUESTIONS (question, fact, QQ) handling
     */
    const id = getCurrentQuestion() ? getCurrentQuestion()._id : 0;
    const params = {
      // Need key to trigger re-render on question change if repeated question type
      key: id,
      question: getCurrentQuestion(),
      slugId,
      pollId: poll._id,
      getUpdatedPolls,
      questionButtonClicked: onEndQuestion,
      showCoin: showCoins,
      // QQ specific
      // qType is needed because we re-use ImageQualifyingQuestions component for 3 question types
      qType: currentForm,
      isQQ: isQualifyingQuestion(currentForm),
      showCoinsInHeader,
      setHideSkipDemographic,
      skipResults: poll.skipCommunityResponse,
      setAnnotateEdit,
      setHideSkipAfterAnswer,
      setIsMediaZoomed,
      isMediaZoomed,
    };

    // Render questions
    if (isQuestion(currentForm, INCLUDE_QQ, INCLUDE_FACTS, INCLUDE_DEMOGRAPHIC)) {
      const questionFormType = QuestionHelper.questionSwitcher(currentForm, params);
      if (questionFormType) {
        return questionFormType;
      }
      getNextQuestion();
    }

    /**
     * Handling remaining non-question actions
     */
    switch (currentForm) {
      case AT_1007_CHARITY:
        return <EndOfPollCharity slugId={slugId} closePoll={closePoll} onSubmitCharity={onCharityClick} />;

      case AT_1014_END_OF_POLL:
        return <EndOfPollAnonymous pollId={poll._id} nextPollAction={nextPoll} closePoll={closePoll} slugId={slugId} />;

      case AT_1013_KNOW_LOCATION:
        return <AccessDenied closePoll={closePoll} />;

      case AT_1016_WRONG_LOCATION:
        return <WrongLocation closePoll={closePoll} location={header.locationName} />;

      default:
        return (
          <EndOfPollAnonymous
            pollId={poll._id}
            nextPollAction={nextPoll}
            closePoll={closePoll}
            slugId={slugId}
            // hasOneAnswer={answers.length > 0 || poll.lastResponse}
          />
        );
    }
  }

  const getQuestionSpecificModalClassName = () => {
    if (isAnnotateImageQuestion(currentForm) || isAnnotateMapQuestion(currentForm))
      return 'c-modal-poll--annotate c-modal-poll--annotate-advanced';
    if (isPersonalDataQuestion(currentForm)) {
      return 'c-modal-poll--pdc-form';
    }
    return '';
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className='c-modal-poll-container' onKeyDown={onOverlayKeyDown} aria-hidden={ariaState}>
      <div
        className={`c-modal-poll ${
          isDemographicQuestion(currentForm) ? 'c-modal-poll--demographic' : ''
        } ${getQuestionSpecificModalClassName()} ${annotateEdit} ${isMediaZoomed ? IMAGE_ZOOM_CLASS : ''}`}
        role='dialog'
        tabIndex='-1'
        aria-labelledby='modal-poll-title'
      >
        {QuestionHelper.doShowPollHeader(currentForm) ? (
          <React.Fragment>
            <QuestionHeader
              currentQuestion={QuestionHelper.getCurrentQuestionNumber(poll, currentQuestionIndex)}
              questionCount={poll.totalQuestions}
              onSkipQuestion={onSkipQuestion}
              onClosePoll={closePoll}
              isQuestion={isQuestion(currentForm, INCLUDE_QQ, EXCLUDE_FACTS, INCLUDE_DEMOGRAPHIC)}
              isQualifyingQuestion={isQualifyingQuestion(currentForm)}
              isDemographicQuestion={isDemographicQuestion(currentForm)}
              points={getPoints()}
              showCoins={showCoins}
              hideSkipDemographic={hideSkipDemographic || hideSkipAfterAnswer || isConditionalLogicQuestion()}
              isConditionalPoll={poll.isConditionalPoll}
              hideClosePollButton={customizations.hideClosePollButton}
            />
            <PollProgress
              questionCount={QuestionHelper.getProgressQuestionsTotal(poll, currentQuestionIndex)}
              currentStep={QuestionHelper.getCurrentStepNumber(poll, currentQuestionIndex)}
            />
          </React.Fragment>
        ) : null}
        {getCurrentQuestion() !== null ? questionSwitcher() : null}
      </div>
    </div>
  );
}

Questions.propTypes = {
  initialFormType: PropTypes.number,
  initialQuestion: PropTypes.string,
  slugId: PropTypes.string.isRequired,
  closePoll: PropTypes.func.isRequired,
  nextPoll: PropTypes.func.isRequired,
  charities: PropTypes.array,
  poll: PropTypes.object.isRequired,
  polls: PropTypes.object.isRequired,
  header: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  charities: state.charities.data,
  poll: state.poll,
  header: state.header,
  polls: state.polls,
  customizations: state.customizations,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      retrieveCharities,
      charityDonation,
      getPolls,
      getAboutData,
      getFeedbakOptions,
      pollQuestionSeen,
      pollQuestionSkip,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Questions);

import React from 'react';

import Square from './Square';
import SquareL from './SquareL';
import ImgWide from './ImgWide';
import ImgUWide from './ImgUWide';
import Reaction from './Reaction';

export const SQUARE = 'SQUARE';
export const SQUARE_L = 'SQUARE_L';
export const WIDE = 'WIDE';
export const U_WIDE = 'U_WIDE';
export const REACTION = 'REACTION';

export function Img(props) {
  const { imageType } = props;

  const ImageSwitcher = () => {
    switch (imageType) {
      case SQUARE:
        return <Square {...props} />;
      case SQUARE_L:
        return <SquareL {...props} />;
      case WIDE:
        return <ImgWide {...props} />;
      case U_WIDE:
        return <ImgUWide {...props} />;
      case REACTION:
        return <Reaction {...props} />;
      default:
        return null;
    }
  };

  return <React.Fragment>{ImageSwitcher()}</React.Fragment>;
}

/**
 * ORIGINAL FORM LUCIJAN
 */
// <!-- Square -->
// <!-- Used for: four image choices, three image choices, multiple image choice -->
// <picture>
//   <img src="image-name--square-mobile.jpg" alt="Image alternative description" class="image-class-if-needed"> <!-- 300x300 -->
// </picture>
//
// <!-- Square large -->
// <!-- Used for: fact compare -->
// <picture>
//   <source media="(max-width: 599px)" srcset="image-name--square-large-mobile.jpg"> <!-- 600x600 -->
//     <source media="(min-width: 600px)" srcset="image-name--square-large-tablet.jpg">  <!-- 1200x1200 -->
//       <img src="image-name--square-tablet.jpg" alt="Image alternative description" class="image-class-if-needed"> <!-- 600x600 -->
// </picture>
//
// <!-- Wide -->
// <!-- Used for: facts, two image choice, other -->
// <picture>
//   <source media="(max-width: 799px)" srcset="image-name--wide-mobile.jpg"> <!-- 800x480 -->
//     <source media="(min-width: 800px)" srcset="image-name--wide-tablet.jpg">  <!-- 1200x720 -->
//       <img src="image-name--wide-mobile.jpg" alt="Image alternative description" class="image-class-if-needed"> <!-- 800x480 -->
// </picture>
//
// <!-- Ultra vide -->
// <!-- Used for: project background -->
// <picture>
//   <source media="(max-width: 799px)" srcset="image-name--ultrawide-mobile.jpg"> <!-- 800x200 -->
//     <source media="(min-width: 800px) and (max-width: 1199px)" srcset="image-name--ultrawide-tablet.jpg"> <!-- 1200x300 -->
//       <source media="(min-width: 1200px) and (max-width: 1599px)" srcset="image-name--ultrawide-desktop.jpg">  <!-- 1600x400 -->
//         <source media="(min-width: 1600px)" srcset="image-name--ultrawide-maximum.jpg">  <!-- 2000x500 -->
//           <img src="image-name--ultrawide-desktop.jpg" alt="Image alternative description" class="image-class-if-needed">
// </picture>

const initialState = {
  phases: {
    showPhases: false,
  },
  phaseData: {
    showPhaseData: false,
  },
  morePhaseData: {
    phaseInfoLoading: true,
  },
};
export default (state = initialState, action) => {
  switch (action.type) {
    case 'SUCCESS_ADD_TIMLINE_NO_OF_PHASE':
      return {
        ...state,
        phases: {
          ...action.data,
          showPhases: true,
        },
      };

    case 'FAIL_ADD_TIMELINE_NO_OF_PHASE':
      return { ...state, failState: action.failState };

    case 'SUCCESS_ADD_TIMELINE_PHASE_DATA':
      return { ...state, phaseData: action.data };

    case 'FAIL_ADD_TIMELINE_PHASE_DATA':
      return { ...state, failState: action.failState };

    case 'SUCCESS_ADD_TIMELINE_MORE_PHASE_DATA':
      return { ...state, morePhaseData: action.data };

    case 'FAIL_ADD_TIMELINE_MORE_PHASE_DATA':
      return {
        ...state,
        morePhaseData: {
          ...action.data,
          phaseInfoLoading: false,
        },
      };

    default:
      return state;
  }
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { createAnswer } from 'components/organisms/question';
import { ImageWithZoomOrCarousel } from 'components/molecules/imageWithZoomOrCarousel/ImageWithZoomOrCarousel';
import i18n from '../../../i18n/helper';
import { handleScroll } from '../QuestionUtils/QuestionFormUtils';
import SelectImage from '../SelectImage';
import ThumbsUp from '../../../assets-v2/images/illustration/thumbs-up.png';

export default function ImageQuestion(props) {
  const {
    onSubmitQuestion,
    articleClassModifier,
    isResult,
    media,
    question,
    agreementText,
    communityAnswer,
    imageGridClassModifier,
    showCoin,
    questionButtonClicked,
    imageType,
    setIsMediaZoomed,
    isMediaZoomed,
    disableForm,
  } = props;

  const [selected, setSelected] = useState('');
  const [isScrollingClass, setIsScrollingClass] = useState('');

  const handleSelect = (choice) => {
    setSelected(choice);
    onSubmitQuestion(createAnswer([{ choiceId: choice._id }]));
  };

  const getAgreementText = () => {
    const communityAgreementText =
      selected._id === communityAnswer.reduce((prev, current) => (prev.amount > current.amount ? prev : current))._id
        ? i18n.t('questions.ImageChoiceQuestion.communityAgreement')
        : i18n.t('questions.ImageChoiceQuestion.communityNotInAgreement');
    return agreementText
      ? i18n.t('questions.pollType.aggrement', {
          percent: communityAnswer.find((item) => item._id === selected._id).amount,
        })
      : communityAgreementText;
  };

  return (
    <article className={`c-question ${articleClassModifier} ${isScrollingClass}`}>
      {media && !isResult && question?.resource?.length > 0 && (
        <div className='c-question__media'>
          <ImageWithZoomOrCarousel
            resource={question.resource}
            altImage='leading image'
            leadImageType={question?.questionConfiguration?.leadImageType}
            setIsMediaZoomed={setIsMediaZoomed}
            zoomEnabled={!disableForm}
          />
        </div>
      )}
      <div className='c-question__header' style={{ display: `${isMediaZoomed ? 'none' : ''}` }}>
        <h2 className='c-question__title'>
          {isResult ? (
            <>
              {<img src={ThumbsUp} alt='' />}
              {getAgreementText()}
            </>
          ) : (
            question.questionText
          )}
        </h2>
      </div>
      <div
        className='c-question__content'
        onScroll={handleScroll(setIsScrollingClass)}
        style={{ display: `${isMediaZoomed ? 'none' : ''}` }}
      >
        <div className={`l-choice-grid ${imageGridClassModifier} u-a-children-fade-in`}>
          {question.choices.map((choice, idx) => {
            const communityAnswerForChoice = communityAnswer.find((item) => item._id === choice._id);
            return (
              <div
                key={idx}
                className={`c-choice-image c-choice-image--bar ${isResult ? ' is-result is-disabled ' : ''} ${
                  selected && selected._id === choice._id ? ' is-highlight ' : ''
                }`}
              >
                <input
                  type='radio'
                  id={`choice-name-${idx}`}
                  name={`choice-name-radio-${question._id}`}
                  checked={selected === choice}
                  disabled={selected}
                  onChange={() => handleSelect(choice)}
                />

                <label htmlFor={`choice-name-${idx}`}>
                  <SelectImage
                    resource={choice.resource}
                    choiceName={choice.choice}
                    communityAnswer={communityAnswerForChoice && communityAnswerForChoice.amount}
                    imageType={`${imageType}`}
                    showCoin={showCoin && selected._id === choice._id}
                  />
                  <span className='c-choice-image__title'>{choice.choice}</span>
                </label>
              </div>
            );
          })}
        </div>
      </div>
      {isResult && (
        <div className='c-question__footer'>
          <button
            className='c-button c-button--primary c-button--large'
            style={{ display: 'block' }}
            onClick={questionButtonClicked}
          >
            <span className='c-button__label'>{i18n.t('questions.nextQuestion')}</span>
          </button>
        </div>
      )}
    </article>
  );
}

ImageQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  communityAnswer: PropTypes.array.isRequired,
  onSubmitQuestion: PropTypes.func.isRequired,
  isResult: PropTypes.bool.isRequired,
  showCoin: PropTypes.bool.isRequired,
  questionButtonClicked: PropTypes.func.isRequired,
  articleClassModifier: PropTypes.string,
  imageType: PropTypes.string,
  imageGridClassModifier: PropTypes.string,
  agreementText: PropTypes.bool,
  media: PropTypes.bool,
  disableForm: PropTypes.bool.isRequired,
};

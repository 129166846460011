import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { Img, WIDE } from '../images/Img';

function getVideoConfigDefault() {
  return {
    config: {
      youtube: {
        playerVars: {
          showinfo: 1,
        },
      },
    },
    className: 'react-player',
    controls: true,
    width: '100%',
    height: '100%',
    // from https://github.com/CookPete/react-player#responsive-player
    style: { top: 0, left: 0, position: 'absolute' },
    allowFullScreen: 'allowFullScreen',
    mozallowfullscreen: 'mozallowfullscreen',
    msallowfullscreen: 'msallowfullscreen',
    oallowfullscreen: 'oallowfullscreen',
    webkitallowfullscreen: 'webkitallowfullscreen',
    // light: !!sessionStorage.getItem('isPreview'),
    light: true,
  };
}

export default function ImageOrVideo(props) {
  const { resource, imageAlt, autoStart } = props;
  const { mediaType, url = '' } = resource;
  const isImage = mediaType === 'image';
  const isVideo = mediaType === 'video';

  const videoUrl = url.indexOf('https://') > -1 ? url : `https://www.youtube.com/watch?v=${url}`;

  const videoConfig = getVideoConfigDefault();

  // Enable autoplay
  if (autoStart) {
    videoConfig.playing = true;
  }

  return (
    <React.Fragment>
      {/* SAX! {isImage && <img src={url} alt={imageAlt} />} */}
      {isImage && <Img imageType={WIDE} imageId={url} alt={imageAlt} />}
      {isVideo && (
        <div className='o-video'>
          <ReactPlayer url={videoUrl} {...videoConfig} />
        </div>
      )}
    </React.Fragment>
  );
}

ImageOrVideo.propTypes = {
  resource: PropTypes.object.isRequired,
  imageAlt: PropTypes.string,
  autostart: PropTypes.bool,
};

import React, { useRef } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SvgIcon from 'components/SvgIcon';
import { bindActionCreators, AnyAction, Dispatch } from 'redux';
import { showTooltip } from 'actions';
import i18n from 'i18n/helper';
import { QuestionTooltipProps } from './types';

const QuestionTooltipFn = ({ tooltipText, actions }: QuestionTooltipProps): JSX.Element => {
  const tooltipElement = useRef<React.RefObject<HTMLElement | null> | null>(null);

  function mouseClickHandler(event: React.MouseEvent<HTMLSpanElement>): void {
    actions?.showTooltip(tooltipText, event.pageX, event.pageY);
  }

  return (
    <span className='o-tooltip o-tooltip--button' onClick={mouseClickHandler} role='button' tabIndex={0}>
      <span className='o-tooltip__action' ref={tooltipElement as React.RefObject<HTMLSpanElement>}>
        {` ${i18n.t('questions.tooltip.howToUse')} `}
        <SvgIcon icon='question-circle' />
      </span>
    </span>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators(
    {
      showTooltip,
    },
    dispatch,
  ),
});
export const QuestionTooltip = connect(null, mapDispatchToProps)(QuestionTooltipFn);

import React from 'react';
import i18n from '../../../i18n/helper';

export default function NextFooter(props) {
  const { onNext } = props;
  return (
    <div className='c-question__footer c-question__footer--alt'>
      <button className='c-button c-button--primary c-button--large' onClick={onNext}>
        <span className='c-button__label'>{i18n.t('questions.nextQuestion')}</span>
      </button>
    </div>
  );
}

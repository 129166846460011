import { useEffect, useRef, useState } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import ReactDOM from 'react-dom';
import { mapOptions, mapStyle } from 'components/atoms/map/utils';
import { MapMarker } from 'components/atoms/mapMarker/MapMarker';
import { getReactionImageUrl, IMAGE_FORMATS } from 'components/images/Formats';
import { useMap } from 'utils/hooks/useMap/useMap';
import AnnotateMapCustomControls from 'components/question/annotateQuestion/AnnotateMapCustomControls';
import throttle from 'lodash.throttle';
import engageLanguages from 'shared/engage-languages';
import { QuestionMapSchema } from '../../types';
import { DropReaction, PlacedMapReactions } from '../AnnotateArea/types';
import { DRAG_FORMAT, REDRAG_FORMAT } from '../utils';
import {
  getMapTypeId,
  getPointFromGlobalLocation,
  isLocationInShape,
  MapsObject,
  setMapShape,
  useSyncRefs,
} from './utils';
import { getCurrentLangCode } from '../../../../language/LanguageHelper';

const ENGAGE_LANGUAGES = engageLanguages().engageLanguages();

type AnnotateMapAreaProps = {
  questionMap: QuestionMapSchema;
  placedReactions: PlacedMapReactions[];
  addReaction: (item: DropReaction, lat: number, lng: number) => void;
  updateLastReaction: (position: google.maps.LatLngLiteral) => void;
  allowRedrag: boolean;
};

export const AnnotateMapArea = ({
  questionMap,
  placedReactions,
  addReaction,
  updateLastReaction,
  allowRedrag,
}: AnnotateMapAreaProps): JSX.Element => {
  const [shape, setShape] = useState<MapsObject>();
  const { setRef, map } = useMap({
    center: { lat: mapOptions.lat, lng: mapOptions.lng },
    zoom: mapOptions.zoom,
    mapTypeId: getMapTypeId(questionMap.mapTypeId),
    disableDefaultUI: true,
    gestureHandling: 'greedy',
    styles: mapStyle,
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const throttledIsLocationInShape = useRef(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    throttle(
      (_shape, latLngLocation, definedShape) =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        isLocationInShape(_shape, { lat: latLngLocation?.lat() ?? 0, lng: latLngLocation?.lng() ?? 0 }, definedShape),
      20,
    ),
  ).current;

  const [, drop] = useDrop(
    () => ({
      accept: [DRAG_FORMAT, REDRAG_FORMAT],
      drop: (item: DropReaction, monitor) => {
        const location = monitor.getClientOffset() || { x: 0, y: 0 };
        const latLngLocation = map?.getProjection()?.fromPointToLatLng(getPointFromGlobalLocation(map, location));
        addReaction(item, latLngLocation?.lat() ?? 181, latLngLocation?.lng() ?? 181);
      },
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
      canDrop: (_item: DropReaction, monitor: DropTargetMonitor<DropReaction, unknown>): boolean => {
        const location = monitor.getClientOffset() || { x: 0, y: 0 };
        const latLngLocation = map?.getProjection()?.fromPointToLatLng(getPointFromGlobalLocation(map, location));
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
        return throttledIsLocationInShape(shape, latLngLocation, questionMap.shape) ?? false;
        // return isLocationInShape(
        //   shape,
        //   { lat: latLngLocation?.lat() ?? 0, lng: latLngLocation?.lng() ?? 0 },
        //   questionMap.shape,
        // );
      },
    }),
    [map, addReaction, shape],
  );

  const combinedRefs = useSyncRefs(drop, setRef);

  useEffect(() => {
    if (map) {
      const tempShape = setMapShape(questionMap, map);
      setShape(tempShape);
      map.fitBounds(tempShape?.getBounds() ?? new google.maps.LatLngBounds());
      const centerControlDiv = document.createElement('div');
      ReactDOM.render(
        <AnnotateMapCustomControls map={map} areaBounds={tempShape?.getBounds() ?? new google.maps.LatLngBounds()} />,
        centerControlDiv,
      );

      const currentLanguageCode = getCurrentLangCode();
      const currentLanguage = ENGAGE_LANGUAGES.find((lng) => currentLanguageCode === lng.languageCode);
      if (currentLanguage?.rightToLeft) {
        map.controls[google.maps.ControlPosition.LEFT_TOP].push(centerControlDiv);
      } else {
        map.controls[google.maps.ControlPosition.RIGHT_TOP].push(centerControlDiv);
      }

      if (questionMap?.customLayer) {
        const customMapURL = `${questionMap.customLayer}&ver=${new Date().getTime()}`;
        const customMapLayer = new google.maps.KmlLayer({
          url: customMapURL,
          map,
        });
        customMapLayer.setMap(map);

        google.maps.event.addListener(customMapLayer, 'defaultviewport_changed', () => {
          const newLayerBounds = customMapLayer.getDefaultViewport();
          const drawnShapeBounds = tempShape?.getBounds() ?? new google.maps.LatLngBounds();
          if (newLayerBounds && drawnShapeBounds) {
            const newBounds = newLayerBounds.union(drawnShapeBounds);
            map.fitBounds(newBounds);
          }
        });
      }
    }
  }, [map, questionMap]);

  return (
    <div className='c-annotate-area__content'>
      <div className='c-annotate-map' ref={combinedRefs}>
        {placedReactions.map((placedReaction, index) => (
          <MapMarker
            map={map}
            position={{ lat: placedReaction.lat, lng: placedReaction.lng }}
            key={index}
            icon={{
              url: getReactionImageUrl(placedReaction.resource, IMAGE_FORMATS.GMV_IMAGE_REACTION),
              scaledSize: new google.maps.Size(64, 64),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(32, 32),
            }}
            draggable={allowRedrag && index === placedReactions.length - 1}
            updateReactionPosition={updateLastReaction}
            isLocationInShape={(latLng) => isLocationInShape(shape, latLng, questionMap.shape)}
            // fix issue #1939 with redragging markers after zoom or canceling delete action
            // on mobile, this prevent markers under these invisible divs from redragging
            zIndex={google.maps.Marker.MAX_ZINDEX + 1}
          />
        ))}
      </div>
    </div>
  );
};

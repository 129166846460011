import { Answer, lastQuestionAnswered } from 'components/organisms/question';
import { FormTypes, Question } from 'components/organisms/questions';
import { AT_1014_END_OF_POLL, AT_1007_CHARITY } from 'containers/questions/QuestionConstants';
import { getBooleanFromConditions } from './conditionaLogic';

export const getIndexFromQuestionId = (
  jumpTo: string | -1 | null,
  questions: Question[],
  lastAnsweredQuestionIndex?: number,
): number => {
  if ((jumpTo === null || jumpTo === undefined) && lastAnsweredQuestionIndex !== undefined) {
    return lastAnsweredQuestionIndex + 1;
  }
  if (jumpTo === -1) {
    return questions.length;
  }
  return questions.findIndex((question) => question._id === jumpTo);
};

/**
 * Function to handle conditional logic
 * It must handle 2 cases:
 * - no rules -> jump to questionId in else;
 * - if rules are present -> deduce if conditions are true
 * and jump to index of questionId in jumpTo
 */
export const getQuestionIdFromConditional = (question: Question, answersArray: Answer[]): string | -1 | null => {
  const conditional = question?.questionConfiguration?.conditionalExecution;
  if (!conditional) {
    return null;
  }
  // no rules
  if (conditional.rules.length === 0) {
    return conditional.else;
  }
  for (let i = 0; i < conditional.rules.length; i += 1) {
    if (getBooleanFromConditions(conditional.rules[i].condition, answersArray)) {
      return conditional.rules[i].jumpTo;
    }
  }
  return conditional.else;
};

/**
 *
 */
export const getNextQuestionIndex = (
  currentQuestionIndex: number,
  isConditionalPoll: boolean,
  answers: Answer[],
  currentQuestion: Question,
  questions: Question[],
): number => {
  if (!isConditionalPoll) {
    return currentQuestionIndex + 1;
  }
  const nextQuestionId = getQuestionIdFromConditional(currentQuestion, answers);
  return getIndexFromQuestionId(nextQuestionId, questions, currentQuestionIndex);
};

export const getNextForm = (
  questions: Question[],
  nextQuestionIndex: number,
  currentFormType: FormTypes,
  hasActiveCharities: boolean,
  isCharityChosen: boolean,
  pollId: string,
): FormTypes => {
  if (!questions[nextQuestionIndex]) {
    // last question submitted, lets signal to GA
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'endedPoll', { event_category: 'polls', event_label: pollId });
    }
    if (currentFormType === AT_1007_CHARITY || isCharityChosen || !hasActiveCharities) {
      return AT_1014_END_OF_POLL;
    }
    return AT_1007_CHARITY;
  }
  return questions[nextQuestionIndex].questionType;
};

/**
 * Function to get initial question Id
 * It must handle 4 cases:
 * - ?question={id} in query params for preview
 * - resume when non-conditional poll
 * - resume when conditional poll
 * - poll never opened
 */
export const getInitialQuestionId = (
  questions: Question[],
  lastResponse: lastQuestionAnswered | null,
  isConditionalPoll: boolean,
  initialQuestionId: string | undefined,
): number => {
  // handle case when ?question={id} in params (preview)
  if (initialQuestionId) {
    const idx = getIndexFromQuestionId(initialQuestionId, questions, 0);
    if (idx >= 0) {
      return idx;
    }
  }
  if (!isConditionalPoll) {
    return 0;
  }
  // handle resume cases for conditional and non-conditional polls
  if (lastResponse) {
    return getNextQuestionIndex(
      lastResponse.lastQuestion.order,
      isConditionalPoll,
      [{ choice: lastResponse.lastAnswer }],
      lastResponse.lastQuestion,
      questions,
    );
  }
  // poll never opened
  return 0;
};

export const getInitialFormType = (
  questions: Question[],
  currentQuestionIndex: number,
  hasActiveCharities: boolean,
  initialFormType: FormTypes | undefined,
): FormTypes => {
  if (initialFormType) {
    return initialFormType;
  }
  if (questions[currentQuestionIndex]?.questionType) {
    return questions[currentQuestionIndex]?.questionType;
  }
  if (hasActiveCharities) {
    return AT_1007_CHARITY;
  }
  return AT_1014_END_OF_POLL;
};

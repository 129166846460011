import { useState, useEffect, useCallback } from 'react';
import { MapQuestionComponentProps } from 'components/organisms/questions';
import { QuestionTextHeader } from 'components/atoms/questionTextHeader';
import { Attachment, createAnswer } from 'components/organisms/question';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import QuestionFooter from 'components/question/questionComponents/QuestionFooter';
import i18n from 'i18n/helper';

import { AnnotateMapArea } from './AnnotateMapArea/AnnotateMapArea';
import { getFeedbakOptions } from '../../../../actions';
import { AnnotatePin } from './AnnotatePin/AnnotatePin';
import { AnnotateActions } from './AnnotateActions/AnnotateActions';
import { DropReaction, PlacedMapReactions } from './AnnotateArea/types';
import { ANNOTATE_STATES } from './utils';
import { AnnotateModalDelete } from './AnnotateActions/AnnotateModalDelete';
import { AnnotateModalFinish } from './AnnotateActions/AnnotateModalFinish';
import { AnnotateMenu } from './AnnotatePin/AnnotateMenu';
import { FeedbackState } from './types';

function AnnotateAdvanced18Fn({
  question,
  setAnnotateEdit,
  questionButtonClicked,
  onSubmitMultipleQuestion,
  userData,
  feedbackEmailZip,
  slugId,
  actions,
  showCoinsInHeader,
}: PropsFromRedux & MapQuestionComponentProps): JSX.Element {
  const { questionText, choices, multiChoicesSelectableBound } = question;

  const [feedback, setFeedback] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showFinishConfirm, setShowFinishConfirm] = useState(false);
  const [placedReactions, setPlacedReactions] = useState<PlacedMapReactions[]>([]);
  const [image, setImage] = useState<Attachment>({ id: '', fileName: 'untitled' });
  const [firstInteractionOccured, setfirstInteractionOccured] = useState(false);
  const [showCoin, setShowCoin] = useState(false);
  const [isResult, setIsResult] = useState(false);
  const [allowRedrag, setAllowRedrag] = useState(false);

  const allowComments = question.questionConfiguration.allowComments;
  const allowImageUpload = question.questionConfiguration.allowUserContentUpload.image;

  useEffect(() => {
    actions.getFeedbakOptions(slugId);
  }, [actions, slugId]);

  const addReaction = useCallback(
    (reactionToDrop: DropReaction, lat: number, lng: number) => {
      setAllowRedrag(true);
      const newReaction: PlacedMapReactions = {
        resource: reactionToDrop.resource,
        feedback: '',
        label: reactionToDrop.label,
        lat,
        lng,
        choiceId: reactionToDrop.choiceId,
      };
      setPlacedReactions((prevPlacedReactions) => [...prevPlacedReactions, newReaction]);
      setAnnotateEdit(ANNOTATE_STATES.EDIT);
    },
    [setAnnotateEdit],
  );

  const onFinish = () => {
    const showCoins = showCoinsInHeader(false);
    setIsResult(true);
    setShowCoin(showCoins);
    setAnnotateEdit(ANNOTATE_STATES.DONE);
    setShowFinishConfirm(false);
    // questionButtonClicked();
  };

  const onClickSubmit = () => {
    setfirstInteractionOccured(true);
    setAllowRedrag(false);
    const reaction = placedReactions[placedReactions.length - 1];
    if (multiChoicesSelectableBound === placedReactions.length) {
      if (multiChoicesSelectableBound === 1) {
        onFinish();
      } else {
        setAnnotateEdit(ANNOTATE_STATES.FINISH);
      }
    } else {
      setAnnotateEdit(ANNOTATE_STATES.INTERACTIVE);
    }

    onSubmitMultipleQuestion(
      createAnswer([
        {
          choiceId: reaction?.choiceId ?? '',
          feedback,
          email: feedbackEmailZip.email || userData.email || '',
          postalCode: feedbackEmailZip.postCode || userData.postCode || '',
          attachment: image.id ? [image] : undefined,
          mapPin: {
            lat: reaction.lat ?? 0,
            lng: reaction.lng ?? 0,
          },
        },
      ]),
    );
    setFeedback('');
    setImage({ id: '', fileName: 'untitled' });
  };

  const onClickDeleteConfirm = () => {
    setAllowRedrag(false);
    setPlacedReactions((prevPlacedReactions) => [...prevPlacedReactions.slice(0, -1)]);
    setFeedback('');
    setImage({ id: '', fileName: 'untitled' });
    setShowDeleteConfirm(false);
    if (placedReactions.length - 1 > 0) {
      setAnnotateEdit(ANNOTATE_STATES.INTERACTIVE);
    } else {
      setAnnotateEdit('');
    }
  };

  const onClickAddMore = () => {
    setShowFinishConfirm(false);
  };

  const onClickFinish = () => {
    if (multiChoicesSelectableBound - placedReactions.length <= 0) {
      onFinish();
    } else {
      setShowFinishConfirm(true);
    }
  };

  const updateLastReaction = (position: google.maps.LatLngLiteral) => {
    const lastReaction = placedReactions[placedReactions.length - 1];
    lastReaction.lat = position.lat;
    lastReaction.lng = position.lng;
    setPlacedReactions((prevPlacedReactions) => [...prevPlacedReactions.slice(0, -1), lastReaction]);
  };

  const getTooltipText = (): string => {
    const currentLanguageDefault: string | undefined = i18n.t('questions.annotate.map.tooltip');
    return question?.questionConfiguration?.tooltip || currentLanguageDefault || '';
  };

  return (
    <article className='c-question c-question--narrow'>
      <QuestionTextHeader
        questionText={questionText}
        isResult={isResult}
        resultText={i18n.t('questions.annotate.result')}
        hasTooltip
        tooltipText={getTooltipText()}
      />
      <div className='c-question__content'>
        <div className='c-annotate c-annotate-advanced'>
          <AnnotateMapArea
            questionMap={question.questionMap}
            placedReactions={placedReactions}
            addReaction={addReaction}
            updateLastReaction={updateLastReaction}
            allowRedrag={allowRedrag}
          />
          <AnnotatePin
            choices={choices}
            multiChoicesSelectableBound={multiChoicesSelectableBound}
            reactionsLeft={multiChoicesSelectableBound - placedReactions.length}
            isDragAllowed={placedReactions.length < multiChoicesSelectableBound}
            firstInteractionOccured={firstInteractionOccured}
            setAnnotateEdit={setAnnotateEdit}
            annotateMenu={
              <AnnotateMenu
                onClickDelete={() => setShowDeleteConfirm(true)}
                feedback={feedback}
                setFeedback={setFeedback}
                onClickSubmit={onClickSubmit}
                allowComments={allowComments}
                allowImageUpload={allowImageUpload}
                image={image}
                setImage={setImage}
              />
            }
          />
          <AnnotateActions
            isAnnotateMap
            onClickFinish={onClickFinish}
            isFinishVisible={placedReactions.length > 0 && !showFinishConfirm}
            annotateModalDelete={
              <AnnotateModalDelete
                onClickCancel={() => setShowDeleteConfirm(false)}
                onClickConfirm={onClickDeleteConfirm}
                isActive={showDeleteConfirm}
              />
            }
            annotateModalFinish={
              <AnnotateModalFinish
                onClickFinishConfirm={onFinish}
                onClickAddMore={onClickAddMore}
                isActive={showFinishConfirm}
                reactionsLeftToAdd={multiChoicesSelectableBound - placedReactions.length}
              />
            }
          />
        </div>
      </div>
      <QuestionFooter
        onClick={() => questionButtonClicked()}
        buttonClassModifier=' c-button--primary c-button--large'
        buttonText={i18n.t('questions.nextQuestion')}
        showCoin={showCoin}
      />
    </article>
  );
}

const mapStateToProps = (state: FeedbackState) => ({
  userData: state.feedback.userData,
  feedbackEmailZip: state.polls.feedbackEmailZip,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators(
    {
      getFeedbakOptions,
    },
    dispatch,
  ),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export const AnnotateAdvancedMap18 = connector(AnnotateAdvanced18Fn);

type PropsFromRedux = ConnectedProps<typeof connector>;

import React, { useState, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { feedbackEmailZip } from '../../actions';

import { handleScroll } from './QuestionUtils/QuestionFormUtils';
import FeedbackForm from '../feedbackForm/FeedbackForm';
import QuestionFooter from './questionComponents/QuestionFooter';
import i18n from '../../i18n/helper';

function FeedbackFormQuestion(props) {
  const {
    questionText,
    disableForm,
    onSubmitFeedback,
    showCoin,
    slugId,
    allowImageUpload,
    isPostCodeOptional,
    isEmailOptional,
    collectEmailOnly,
  } = props;
  const [isScrollingClass, setIsScrollingClass] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const buttonRef = useRef(null);

  return (
    <article className={`c-question c-question--narrow ${isScrollingClass}`}>
      <div className='c-question__header'>
        <h2 className='c-question__title'>{questionText}</h2>
      </div>

      <div className='c-question__content' onScroll={handleScroll(setIsScrollingClass)}>
        <FeedbackForm
          disableForm={disableForm}
          onSubmitFeedback={onSubmitFeedback}
          showCoin={showCoin}
          slugId={slugId}
          ref={buttonRef}
          setIsSubmitDisabled={setIsSubmitDisabled}
          allowImageUpload={allowImageUpload}
          isPostCodeOptional={isPostCodeOptional}
          isEmailOptional={isEmailOptional}
          collectEmailOnly={collectEmailOnly}
        />
      </div>
      {buttonRef.current && (
        <QuestionFooter
          buttonClassModifier={`c-button--primary c-button--large ${isSubmitDisabled ? 'is-disabled' : ''}`}
          buttonText={i18n.t('questions.submitButton')}
          disabled={isSubmitDisabled}
          onClick={() => buttonRef.current.submitFeedback()}
          showCoin={showCoin}
        />
      )}
    </article>
  );
}

FeedbackFormQuestion.propTypes = {
  questionText: PropTypes.string.isRequired,
  onSubmitFeedback: PropTypes.func.isRequired,
  showCoin: PropTypes.bool.isRequired,
  disableForm: PropTypes.bool.isRequired,
  slugId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  feedbackEmailZip: state.polls.feedbackEmailZip,
  userData: state.feedback.userData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ feedbackEmailZip }, dispatch),
});

// export default FeedbackForm;
export default connect(mapStateToProps, mapDispatchToProps)(FeedbackFormQuestion);

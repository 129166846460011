import React from 'react';
import PropTypes from 'prop-types';
import ImageQuestion from '../imageQuestion/ImageQuestion';

export default function Question04(props) {
  return (
    <ImageQuestion
      articleClassModifier='c-question--wide'
      imageType='SQUARE'
      imageGridClassModifier='l-choice-grid--square-four'
      agreementText
      media={false}
      {...props}
    />
  );
}

Question04.propTypes = {
  question: PropTypes.object.isRequired,
  communityAnswer: PropTypes.array.isRequired,
  onSubmitQuestion: PropTypes.func.isRequired,
  isResult: PropTypes.bool.isRequired,
  showCoin: PropTypes.bool.isRequired,
  questionButtonClicked: PropTypes.func.isRequired,
};

import { useState } from 'react';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import i18n from 'i18n/helper';
import { getFeedbakOptions } from 'actions';
import QuestionFooter from 'components/question/questionComponents/QuestionFooter';
import { PersonalDataComponentProps } from '../types';
import { FeedbackState } from '../annotateAdvanced/types';
import PersonalDataField from './PersonalDataField';
import SvgIcon from '../../../SvgIcon';
import { TERMS_AND_CONDITIONS_LINK } from '../../../../constants';
import { isEmailValid } from '../../../../utils/utils';

// const FIRST_NAME = 'firstName';
// const LAST_NAME = 'lastName';
// const EMAIL = 'email';
// const ADDRESS = 'address';
// const POSTAL_CODE = 'postalCode';
// const PHONE = 'phone';
// const EXTRA1 = 'extra1';
// const EXTRA2 = 'extra2';
// const allFields = [FIRST_NAME, LAST_NAME, EMAIL, ADDRESS, POSTAL_CODE, PHONE, EXTRA1, EXTRA2] as const;
import { allFields, getInitialState } from './FieldConstants';

const PersonalDataQuestionFn = ({
  question,
  onSubmitQuestion,
  showCoin,
}: PropsFromRedux & PersonalDataComponentProps): JSX.Element => {
  const { questionText, questionSubtext } = question;
  // console.log(question);

  const [isTermsAccepted, setTermsAccepted] = useState(false);

  // Initial state
  // {
  //   firstName: '',
  //   lastName: '',
  //   email: '',
  //   address: '',
  //   postalCode: '',
  //   phone: '',
  //   extra1: '',
  //   extra2: '',
  // }
  const [personalData, setPersonalData] = useState(getInitialState());

  const fields = allFields.filter((f) => question?.fieldConfiguration[f]?.visible === true);
  // console.log(fields);
  const onHandleButton = () => {
    const res = allFields.map((fieldName) => {
      const r = { choiceId: fieldName, feedback: personalData[fieldName] };
      return r;
    });
    onSubmitQuestion({ choice: res });
  };

  const isRequiredFieldsOK = () =>
    // check that all visible AND required fields have values
    allFields
      .filter((f) => question.fieldConfiguration[f]?.visible)
      .every((f) => !question.fieldConfiguration[f]?.required || personalData[f]);

  const isSubmitDisabled = () => {
    const isEmailOk = !personalData.email || isEmailValid(personalData.email);
    return !(isRequiredFieldsOK() && isTermsAccepted && isEmailOk);
  };

  return (
    <article className='c-question c-question--narrow'>
      <div className='c-question__header'>
        <h2 className='c-question__title'>{questionText}</h2>
        <p className='c-question__description'>{questionSubtext}</p>
      </div>

      <div className='c-question__content'>
        <div className='l-form c-fields u-a-children-fade-in'>
          {fields.map((fieldName, idx) => (
            <PersonalDataField
              key={idx}
              fieldName={fieldName}
              field={question.fieldConfiguration[fieldName]}
              personalData={personalData}
              setPersonalData={setPersonalData}
            />
          ))}

          <div className='c-field'>
            <div className='c-field__group'>
              <div className='o-checkbox '>
                <input
                  type='checkbox'
                  id='personal-data--terms-1'
                  onChange={() => {
                    setTermsAccepted(!isTermsAccepted);
                  }}
                  name='personal-data--terms-1'
                  checked={isTermsAccepted}
                />
                <label htmlFor='personal-data--terms-1'>
                  {i18n.t('questions.feedbackForm.acceptCheckboxLabel')}&nbsp;
                  <a className='c-link' href={TERMS_AND_CONDITIONS_LINK} target='_blank' rel='noopener noreferrer'>
                    <SvgIcon icon='share-square' />
                  </a>
                  <SvgIcon icon='check' />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{JSON.stringify(personalData)}</pre> */}

      <QuestionFooter
        showCoin={showCoin}
        buttonText={i18n.t('questions.submitButton')}
        onClick={onHandleButton}
        disabled={isSubmitDisabled()}
        buttonClassModifier='c-button--primary c-button--large'
      />
    </article>
  );
};

const mapStateToProps = (state: FeedbackState) => ({
  userData: state.feedback.userData,
  feedbackEmailZip: state.polls.feedbackEmailZip,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators(
    {
      getFeedbakOptions,
    },
    dispatch,
  ),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export const PersonalDataQuestion21 = connector(PersonalDataQuestionFn);

type PropsFromRedux = ConnectedProps<typeof connector>;

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { DndProvider } from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { AnnotateDragPreview } from 'components/organisms/questions/annotateAdvanced/AnnotatePin/AnnotateDragPreview';
// import { unregister } from './registerServiceWorker';
import App from './App';
import { getParsedTokenOrMakeWithExpiry } from './utils/utils';

import './assets-v2/stylesheets/style.scss';

import store, { history } from './store';

// handle tokens without expiry date (backwards compatibility)
getParsedTokenOrMakeWithExpiry();

const router = (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <DndProvider options={HTML5toTouch}>
        <AnnotateDragPreview />
        <App />
      </DndProvider>
    </ConnectedRouter>
  </Provider>
);

render(router, document.getElementById('root'));

// unregister();

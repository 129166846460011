import React, { useState } from 'react';
import PropTypes from 'prop-types';

import LocationIllustration from '../../assets-v2/images/geolocation.svg';
import i18n from '../../i18n/helper';
import SvgIcon from '../SvgIcon';

export default function AccessDenied(props) {
  const { closePoll } = props;
  const [showNext, setShowNext] = useState(false);

  const getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }

    return 'unknown';
  };
  // this function will give the respective screen if user denied the location
  const getSteps = () => {
    const nAgt = navigator.userAgent;
    let steps = [];
    const phone = getMobileOperatingSystem();
    if (phone === 'Android') {
      steps = [
        i18n.t('location.accedDenied.androidSettings.step1'),
        i18n.t('location.accedDenied.androidSettings.step2'),
        i18n.t('location.accedDenied.androidSettings.step3'),
        i18n.t('location.accedDenied.androidSettings.step4'),
        i18n.t('location.accedDenied.androidSettings.step5'),
      ];
      return steps;
    }
    if (phone === 'iOS') {
      steps = [
        i18n.t('location.accedDenied.iosSettings.step1'),
        i18n.t('location.accedDenied.iosSettings.step2'),
        i18n.t('location.accedDenied.iosSettings.step3'),
        i18n.t('location.accedDenied.iosSettings.step4'),
        i18n.t('location.accedDenied.iosSettings.step5'),
        i18n.t('location.accedDenied.iosSettings.step6'),
        i18n.t('location.accedDenied.iosSettings.step7'),
      ];
      return steps;
    }
    if (nAgt.indexOf('MSIE') !== -1) {
      steps = [
        i18n.t('location.accedDenied.IeSettings.step1'),
        i18n.t('location.accedDenied.IeSettings.step2'),
        i18n.t('location.accedDenied.IeSettings.step3'),
        i18n.t('location.accedDenied.IeSettings.step4'),
        i18n.t('location.accedDenied.IeSettings.step5'),
      ];
      return steps;
    }
    if (nAgt.indexOf('Chrome') !== -1) {
      steps = [
        i18n.t('location.accedDenied.chromeSettings.step1'),
        i18n.t('location.accedDenied.chromeSettings.step2'),
        i18n.t('location.accedDenied.chromeSettings.step3'),
        i18n.t('location.accedDenied.chromeSettings.step4'),
      ];
      return steps;
    }
    if (nAgt.indexOf('Safari') !== -1) {
      steps = [
        i18n.t('location.accedDenied.safariSettings.step1'),
        i18n.t('location.accedDenied.safariSettings.step2'),
        i18n.t('location.accedDenied.safariSettings.step3'),
      ];
      return steps;
    }
    if (nAgt.indexOf('Firefox') !== -1) {
      steps = [
        i18n.t('location.accedDenied.firefoxSettings.step1'),
        i18n.t('location.accedDenied.firefoxSettings.step2'),
        i18n.t('location.accedDenied.firefoxSettings.step3'),
        i18n.t('location.accedDenied.firefoxSettings.step4'),
        i18n.t('location.accedDenied.firefoxSettings.step5'),
      ];
      return steps;
    }
    if (nAgt.indexOf('Edge') !== -1) {
      steps = [
        i18n.t('location.accedDenied.edgeSettings.step1'),
        i18n.t('location.accedDenied.edgeSettings.step1'),
        i18n.t('location.accedDenied.edgeSettings.step1'),
        i18n.t('location.accedDenied.edgeSettings.step1'),
      ];
      return steps;
    }
    steps = [i18n.t('location.accedDenied.noneSettings.step1'), i18n.t('location.accedDenied.noneSettings.step2')];
    return steps;
  };

  return (
    <div className='c-modal-poll' tabIndex='-1' role='dialog'>
      <div className='c-modal-poll__header'>
        <button className='c-button c-modal-poll__close' onClick={closePoll}>
          <span className='c-button__label'>Close</span>
          <SvgIcon icon='times' />
        </button>
        <h1 className='c-modal-poll__label u-screen-reader-text' id='modalGeolocation'>
          {i18n.t('location.accedDenied.title')}
        </h1>
      </div>
      <div className='c-modal-poll__content'>
        <div className='c-question'>
          <div className='c-question__header' />
          <div className='c-question__content'>
            <div className='c-poll-geolocation'>
              {showNext ? (
                <div className='c-poll-geolocation__content u-rich-text'>
                  <p>
                    <strong>{i18n.t('location.accedDenied.subHeading')}</strong>
                  </p>
                  <ol>
                    {getSteps().map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ol>
                </div>
              ) : (
                <React.Fragment>
                  <figure className='c-poll-geolocation__illustration'>
                    <img src={LocationIllustration} alt='close' />
                  </figure>
                  <h2 className='c-poll-geolocation__title'>{i18n.t('location.accedDenied.text')}</h2>
                  <p className='c-poll-geolocation__description'>{i18n.t('location.accedDenied.subText')}</p>
                </React.Fragment>
              )}
            </div>
          </div>
          {showNext ? (
            <div className='c-question__footer'>
              <button className='c-button c-button--alternate c-button--large' onClick={closePoll}>
                <span className='c-button__label'>{i18n.t('location.accedDenied.okay')}</span>
              </button>
            </div>
          ) : (
            <div className='c-question__footer'>
              <button className='c-button c-button--alternate c-button--large' onClick={() => setShowNext(true)}>
                <span className='c-button__label'>{i18n.t('location.accedDenied.buttonText')}</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

AccessDenied.propTypes = {
  closePoll: PropTypes.func.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import GeolocationError from '../../assets-v2/images/geolocation-error.svg';
import i18n from '../../i18n/helper';
import SvgIcon from '../SvgIcon';

export default function WrongLocation(props) {
  const { closePoll, location } = props;
  return (
    <div className='c-modal-poll' tabIndex='-1' role='dialog'>
      <div className='c-modal-poll__header'>
        <button className='c-button c-modal-poll__close' onClick={closePoll}>
          <span className='c-button__label'>{i18n.t('close')}</span>
          <SvgIcon icon='times' />
        </button>
        <h1 className='c-modal-poll__label u-screen-reader-text' id='modalGeolocation'>
          {i18n.t('location.accedDenied.title')}
        </h1>
      </div>
      <div className='c-modal-poll__content'>
        <div className='c-question'>
          <div className='c-question__header' />
          <div className='c-question__content'>
            <div className='c-poll-geolocation'>
              <img src={GeolocationError} alt='close' />
              <h2 className='c-poll-geolocation__title'>{i18n.t('location.wrongLocation.heading')}</h2>
              <p className='c-poll-geolocation__description'>
                {i18n.t('location.wrongLocation.subHeading', { location })}
              </p>
            </div>
          </div>
          <div className='c-question__footer'>
            <button className='c-button c-button--alternate c-button--large' onClick={closePoll}>
              <span className='c-button__label'>{i18n.t('location.accedDenied.okay')}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

WrongLocation.propTypes = {
  closePoll: PropTypes.func.isRequired,
  location: PropTypes.string,
};

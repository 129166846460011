/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useEffect, useRef } from 'react';
import { createCustomEqual } from 'fast-equals';
import GOOGLE_MAP_ENUMS from '../../../shared/engage-google-map-enums';

// taken from https://github.com/googlemaps/js-samples/pull/952/files
// because of https://github.com/googlemaps/js-samples/issues/946
function isLatLngLiteral(obj: any): obj is google.maps.LatLngLiteral {
  return typeof obj === 'object' && Number.isFinite(obj.lat) && Number.isFinite(obj.lng);
}

export const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a: any, b: any) => {
  if (isLatLngLiteral(a) || a instanceof google.maps.LatLng || isLatLngLiteral(b) || b instanceof google.maps.LatLng) {
    return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
  }
  return deepEqual(a, b);
});

export const useDeepCompareMemoize = (value: any) => {
  const ref = useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useDeepCompareEffectForMaps(callback: React.EffectCallback, dependencies: any[]) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

const overlayStyles = {
  draggable: false,
  editable: false,
};

export const roadmapOverlayStyle = {
  ...GOOGLE_MAP_ENUMS().roadmapOverlayStyle,
  ...overlayStyles,
};

export const satelliteOverlayStyle = {
  ...GOOGLE_MAP_ENUMS().satelliteOverlayStyle,
  ...overlayStyles,
};

export const mapOptions = GOOGLE_MAP_ENUMS().mapOptions;
export const mapStyle = GOOGLE_MAP_ENUMS().locationMapStyle;

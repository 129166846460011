import React from 'react';

const helpSocialContent = `  <g id="explainer-step-3">
    <g id="scene" fill-rule="nonzero">
      <path id="scene__background" fill="#eff4fa" d="M161.818 96.781c-.003-29.314-17.839-55.681-45.057-66.605-27.217-10.923-58.345-4.207-78.63 16.964-20.286 21.172-25.653 52.545-13.557 79.25h130.852a71.473 71.473 0 006.392-29.609z"></path>
      <path id="scene__horizon" fill="#4f4a45" d="M160.023 125.49a.9.9 0 010 1.8H19.977a.9.9 0 010-1.8h140.046zm-143.637 0a.9.9 0 010 1.8h-4.488a.9.9 0 010-1.8h4.488zm151.716 0a.9.9 0 010 1.8h-4.488a.9.9 0 010-1.8h4.488z"></path>
    </g>
    <g id="person">
      <ellipse id="person__shadow" cx="63.14" cy="152.895" fill="#070048" fill-opacity=".15" rx="20.648" ry="3.105"></ellipse>
      <g id="person__briefcase" fill-rule="nonzero">
        <path id="briefcase" fill="#525252" d="M45.58 124.739a7.5546 7.5546 0 00-4.057 6.138v11.162c-.012 1.406 1.09 2.57 2.495 2.638h3.286c1.398-.077 2.49-1.239 2.478-2.638v-11.162a7.5212 7.5212 0 00-4.202-6.138z"></path>
        <path id="briefcase-outline" fill="#191816" d="M45.58 120.017c.498 0 .9.403.9.9v3.281c2.39 1.372 3.969 3.84 4.202 6.679v11.154c.015 1.881-1.45 3.441-3.378 3.546l-3.329-.001c-1.888-.091-3.367-1.655-3.352-3.537l.001-10.447a.8977.8977 0 01.022-.759l-.021.049v-.073c.207-2.735 1.726-5.187 4.055-6.596v-3.296a.9.9 0 01.9-.9zm3.305 12.637l-2.405 1.202v9.921l.775.001c.867-.048 1.554-.731 1.621-1.587l.006-.152.003-9.385zm-6.462.073v9.319c-.008.923.716 1.687 1.595 1.731l.662-.001v-9.921l-2.257-1.128zm3.17-6.963c-1.822 1.11-3.01 3.031-3.17 5.113l.015-.155 3.142 1.571 3.274-1.635a6.6214 6.6214 0 00-3.261-4.894z"></path>
      </g>
      <path id="person__pants" fill="#7abbeb" fill-rule="nonzero" d="M49.71 110.599v39.21h9.947l1.131-25.59c0-1.298 1.053-2.351 2.352-2.351s2.352 1.053 2.352 2.351l1.167 25.59h9.929v-39.21H49.71z"></path>
      <path id="person__pants-outline" fill="#191816" fill-rule="nonzero" d="M48.81 110.599v39.21a.9.9 0 00.9.9h9.947c.481 0 .878-.379.899-.86l1.131-25.59c.001-.841.651-1.491 1.453-1.491s1.452.65 1.452 1.451l1.168 25.631c.022.481.418.859.899.859h9.929a.9.9 0 00.9-.9v-39.21a.9.9 0 00-.9-.9H49.71a.9.9 0 00-.9.9zm1.8.9h25.078v37.41h-8.169l-1.128-24.731c.001-1.754-1.455-3.21-3.251-3.21-1.796 0-3.252 1.456-3.252 3.251l-1.092 24.69H50.61v-37.41z"></path>
      <path id="person__upper-body" fill="#7cdba2" fill-rule="nonzero" d="M63.068 74.475c-11.383.049-20.598 9.26-20.648 20.637v19.614h41.296V95.112c-.049-11.377-9.265-20.588-20.648-20.637z"></path>
      <path id="Combined-Shape" fill="#a3e6be" fill-rule="nonzero" d="M83.716 100.112c-.049-11.377-9.265-20.588-20.648-20.637-11.383.049-20.598 9.26-20.648 20.637v-5c.05-11.377 9.265-20.588 20.648-20.637 11.383.049 20.599 9.26 20.648 20.637v5z"></path>
      <path id="person__upper-body-outline" fill="#191816" fill-rule="nonzero" d="M42.42 115.626a.9.9 0 01-.9-.9V95.108c.052-11.871 9.667-21.482 21.544-21.533 11.885.051 21.501 9.662 21.552 21.537v19.614a.9.9 0 01-.9.9H42.42zm20.648-40.251c-10.887.049-19.701 8.859-19.748 19.737v18.714h5.489l.001-23.864a.9.9 0 01.9-.9c.459 0 .837.343.893.787l.007.113-.001 23.864h25.042l.001-23.936a.9.9 0 01.9-.9c.459 0 .837.343.893.787l.007.113-.001 23.936h5.365v-18.71c-.047-10.882-8.861-19.692-19.748-19.741z"></path>
      <path id="person__feet" fill="#947746" fill-rule="nonzero" d="M54.414 145.682a6.1915 6.1915 0 015.386 3.194c.321.632.295 1.385-.069 1.994-.365.608-1.017.987-1.726 1.003h-8.223a2.0671 2.0671 0 01-1.726-1.003 2.0646 2.0646 0 01-.07-1.994c1.085-1.961 3.145-3.182 5.387-3.194h1.041zm18.475 0c2.242.012 4.302 1.233 5.387 3.194.332.631.31 1.39-.056 2.002-.367.612-1.026.989-1.74.995h-8.205a2.0458 2.0458 0 01-1.739-.995 2.0432 2.0432 0 01-.056-2.002 6.1746 6.1746 0 015.386-3.194h1.023z"></path>
      <path id="person__feet-outline" fill="#191816" fill-rule="nonzero" d="M54.419 144.782c2.567.014 4.926 1.413 6.184 3.686.461.909.423 1.99-.1 2.864-.524.874-1.459 1.418-2.498 1.441h-8.223l-.02-.001a2.9635 2.9635 0 01-2.478-1.44c-.523-.874-.561-1.955-.085-2.892 1.243-2.245 3.602-3.644 6.174-3.658h1.046zm18.475 0c2.567.014 4.926 1.413 6.178 3.675.478.91.448 2.002-.08 2.883a2.9477 2.9477 0 01-2.504 1.433h-8.22c-1.028-.009-1.977-.551-2.504-1.433-.528-.881-.559-1.973-.073-2.899 1.24-2.249 3.602-3.65 6.175-3.659h1.028zm-18.48 1.799h-1.036c-1.916.011-3.677 1.055-4.589 2.702-.181.357-.166.781.039 1.124.204.341.568.554.965.566h8.192c.4-.009.768-.223.973-.566.206-.343.221-.767.055-1.095-.927-1.676-2.688-2.72-4.599-2.731zm18.475.001h-1.02c-1.916.007-3.677 1.051-4.593 2.712-.186.354-.174.779.032 1.121.205.343.574.554.967.558h8.201c.399-.005.767-.216.972-.558.205-.342.217-.767.04-1.103-.927-1.676-2.688-2.72-4.599-2.73z"></path>
      <path id="person__hands" fill="#ffcebf" fill-rule="nonzero" d="M49.674 114.726v3.589c0 1.982-1.608 3.589-3.591 3.589s-3.591-1.607-3.591-3.589v-3.589h7.182zm34.06 0v3.589c0 1.982-1.608 3.589-3.591 3.589s-3.591-1.607-3.591-3.589v-3.589h7.182z"></path>
      <path id="person__hands-outline" fill="#191816" fill-rule="nonzero" d="M49.674 113.826a.9.9 0 01.9.9v3.589c0 2.479-2.011 4.489-4.491 4.489-2.48 0-4.491-2.01-4.491-4.489v-3.589a.9.9 0 01.9-.9h7.182zm34.06 0a.9.9 0 01.9.9v3.589c0 2.479-2.011 4.489-4.491 4.489-2.48 0-4.491-2.01-4.491-4.489v-3.589a.9.9 0 01.9-.9h7.182zm-34.96 1.8h-5.382v2.689c0 1.485 1.205 2.689 2.691 2.689 1.486 0 2.691-1.204 2.691-2.689v-2.689zm34.06 0h-5.382v2.689c0 1.485 1.205 2.689 2.691 2.689 1.486 0 2.691-1.204 2.691-2.689v-2.689z"></path>
      <path id="person__face" fill="#ffcebf" fill-rule="nonzero" stroke="#34bc6a" stroke-width="1.8" d="M77.593 57.983c-.005-1.213-.871-2.252-2.064-2.476v-1.651c0-6.839-5.547-12.382-12.389-12.382s-12.389 5.543-12.389 12.382v1.651c-1.266.203-2.177 1.326-2.112 2.607.064 1.28 1.083 2.307 2.364 2.382 1.196 5.742 6.26 9.857 12.128 9.857s10.932-4.115 12.128-9.857c1.314-.103 2.329-1.196 2.334-2.513z"></path>
      <path id="person__face-outline" fill="#191816" fill-rule="nonzero" d="M76.429 54.847v-.991c0-7.336-5.95-13.282-13.289-13.282-7.339 0-13.289 5.946-13.289 13.282v.975c-1.312.538-2.185 1.857-2.111 3.328.075 1.503 1.118 2.748 2.522 3.124 1.503 5.842 6.782 9.97 12.869 9.97 6.091 0 11.373-4.133 12.872-9.981 1.442-.409 2.484-1.732 2.49-3.285a3.4311 3.4311 0 00-2.064-3.14zm-1.24 4.751a.9013.9013 0 00-.811.714c-1.109 5.325-5.805 9.141-11.247 9.141s-10.137-3.816-11.247-9.141a.901.901 0 00-.829-.715c-.822-.048-1.476-.707-1.518-1.528-.041-.822.543-1.543 1.357-1.673.436-.07.757-.447.757-.889v-1.651c0-6.341 5.144-11.482 11.489-11.482 6.345 0 11.489 5.141 11.489 11.482v1.651c0 .433.308.805.734.884.768.144 1.325.812 1.33 1.592-.005.847-.658 1.549-1.504 1.615z"></path>
      <path id="person__eyes" fill="#191816" fill-rule="nonzero" d="M58.49 53.856c.411-.005.806.156 1.097.447.291.29.452.685.447 1.096 0 .853-.691 1.543-1.544 1.543-.853 0-1.544-.69-1.544-1.543 0-.852.691-1.543 1.544-1.543zm9.3 0c.853 0 1.544.691 1.544 1.543 0 .853-.691 1.543-1.544 1.543-.853 0-1.544-.69-1.544-1.543 0-.852.691-1.543 1.544-1.543z"></path>
      <path id="person__cheeks" fill="#ffb59e" fill-rule="nonzero" d="M56.425 57.983c.853 0 1.544.691 1.544 1.544 0 .852-.691 1.543-1.544 1.543-.853 0-1.544-.691-1.544-1.543 0-.853.691-1.544 1.544-1.544zm13.412 0c.853 0 1.544.691 1.544 1.544 0 .852-.691 1.543-1.544 1.543-.853 0-1.544-.691-1.544-1.543 0-.853.691-1.544 1.544-1.544z"></path>
      <path id="person__mouth" fill="#191816" fill-rule="nonzero" d="M59.533 63.971c2.177 1.457 5.019 1.457 7.196 0a.8988.8988 0 00.247-1.248c-.276-.413-.835-.524-1.248-.248a4.6685 4.6685 0 01-5.194 0c-.413-.276-.972-.165-1.248.248a.8988.8988 0 00.247 1.248z"></path>
      <path id="person__hair" fill="#a86c4d" fill-rule="nonzero" d="M57.969 36.305c0 1.14.925 2.064 2.065 2.064h9.3c4.562 0 8.259 3.696 8.259 8.255v7.214c0 .575-.466 1.041-1.041 1.041h-.592a1.024 1.024 0 01-.737-.305l-5.889-5.886h-10.18a4.4513 4.4513 0 002.693 3.732c.096.034.15.135.126.234a.2146.2146 0 01-.198.161H59.01c-1.595-.186-3-1.033-3.912-2.28l-4.023 4.039c-.196.195-.46.305-.737.305h-.61c-.275 0-.538-.11-.73-.306a1.0206 1.0206 0 01-.293-.735V46.66c.005-.73.114-1.455.323-2.154l-.011.038a6.1685 6.1685 0 01-.337-2.389c.181-3.288 2.906-5.858 6.201-5.85h3.088z"></path>
      <path id="Combined-Shape1" fill="#de926a" fill-rule="nonzero" d="M48.703 48.142c.05.485.157.954.314 1.402l-.085.314a7.6496 7.6496 0 00-.227 1.802l-.002-3.518zm9.266-11.837c0 1.091.846 1.983 1.917 2.059l.148.005h9.3c4.477 0 8.122 3.56 8.256 8.003l.003.252v5l-.003-.252c-.134-4.443-3.779-8.003-8.256-8.003h-9.3l-.148-.005c-1.071-.076-1.917-.968-1.917-2.059h-3.088l-.229.004c-3.091.104-5.631 2.478-5.949 5.546l.002-.195c.004-.608.08-1.213.227-1.802l.085-.314a6.1685 6.1685 0 01-.337-2.389c.177-3.211 2.781-5.738 5.972-5.846l.229-.004h3.088z"></path>
      <path id="person__hair-outline" fill="#191816" fill-rule="nonzero" d="M57.969 35.405a.9.9 0 01.9.9c0 .643.521 1.164 1.165 1.164h9.3c5.059 0 9.159 4.099 9.159 9.155v7.214c0 1.072-.869 1.941-1.941 1.941h-.586c-.52.003-1.019-.204-1.379-.569l-5.625-5.622H60.27c.336.888 1.015 1.615 1.878 1.984.532.189.834.749.697 1.305-.122.473-.536.812-1.07.838H59.01l-.104-.006a6.5476 6.5476 0 01-3.854-1.853l-3.341 3.354c-.364.364-.858.569-1.373.569h-.61c-.516 0-1.01-.207-1.372-.575-.362-.368-.56-.866-.551-1.366v-7.185a8.624 8.624 0 01.36-2.404l-.088.316c-.237-.776-.343-1.605-.296-2.46.208-3.766 3.329-6.71 7.1-6.7h3.088zm-.761 1.8h-2.329c-2.817-.007-5.146 2.19-5.301 5-.035.64.045 1.262.224 1.843.031.051.057.108.078.17.286.845.773 1.586 1.399 2.172a5.276 5.276 0 003.016 1.367c.307.033.57.22.706.487.022.042.043.086.06.132.208.564.487 1.099.829 1.594a.885.885 0 01.13.281c.753.901 1.832 1.51 3.045 1.664h.558a5.3435 5.3435 0 01-1.366-3.154c-.043-.524.371-.973.897-.973h10.18c.239 0 .468.094.637.263l5.893 5.891c.024.024.056.037.096.037h.592c.078 0 .141-.063.141-.141v-7.214c0-4.062-3.294-7.355-7.359-7.355h-9.3c-1.324 0-2.445-.867-2.826-2.064zm-7.604 10.05v6.599c0 .033.012.064.036.088.023.023.055.037.088.037h.61c.038 0 .074-.015.099-.04l3.512-3.528c-.17-.303-.317-.621-.439-.953l.012.032a7.1076 7.1076 0 01-3.508-1.819 7.3132 7.3132 0 01-.41-.416z"></path>
      <path id="person__collar" fill="#53d085" fill-rule="nonzero" d="M63.068 74.475c-4.01.084-7.919 1.277-11.293 3.445l3.321 4.092c.409.51 1.035.796 1.688.772a2.1394 2.1394 0 001.634-.916l4.65-7.393 4.65 7.393c.366.545.969.883 1.625.911.655.028 1.285-.256 1.697-.767l3.304-4.092a21.682 21.682 0 00-11.276-3.445z"></path>
      <path id="person__collar-outline" fill="#191816" fill-rule="nonzero" d="M66.957 82.348c.54.804 1.406 1.289 2.347 1.33.941.041 1.846-.368 2.437-1.101l3.303-4.091c.336-.417.237-1.033-.213-1.322a22.5884 22.5884 0 00-11.744-3.589h-.038c-4.176.088-8.247 1.33-11.761 3.588-.451.29-.55.908-.212 1.325l3.322 4.091c.583.728 1.482 1.139 2.419 1.104.951-.054 1.808-.534 2.34-1.302l3.911-6.216 3.889 6.183zm-2.192-6.866l-.935-1.486c-.353-.561-1.171-.561-1.524 0l-.934 1.486c.562-.059 1.128-.094 1.696-.107.568.013 1.134.049 1.697.107zm8.233 2.672l-2.658 3.293c-.233.288-.588.449-.958.433a1.167 1.167 0 01-.917-.514l-3.7-5.884c2.899.303 5.705 1.212 8.233 2.672zm-15.342 3.235c-.194.279-.543.474-.923.496-.351.013-.704-.149-.935-.436l-2.675-3.296c2.534-1.46 5.345-2.37 8.249-2.671l-3.716 5.907z"></path>
    </g>
    <g id="building">
      <path id="building__facade" fill="#7abbeb" fill-rule="nonzero" d="M90 126.39V96.781c0-.991.804-1.795 1.795-1.795l14.364-.001v-9.24c0-5.947 4.823-10.768 10.773-10.768 5.949 0 10.773 4.821 10.773 10.768l-.001 9.24 14.364.001c.942 0 1.715.725 1.79 1.648l.006.147v29.609H90z"></path>
      <path id="building__facade-outline" fill="#191816" fill-rule="nonzero" d="M90 127.29a.9.9 0 01-.9-.9V96.781c0-1.488 1.207-2.695 2.695-2.695l13.464-.001v-8.34c0-6.444 5.226-11.668 11.673-11.668 6.446 0 11.673 5.224 11.673 11.668l-.001 8.34 13.464.001c1.431 0 2.602 1.116 2.69 2.524l.006.171v29.609a.9.9 0 01-.9.9H90zm15.259-31.405l-13.464.001c-.494 0-.895.401-.895.895v28.709h14.359V95.885zm21.545 0h-19.745v29.605h19.746l-.001-29.605zm15.264.001l-13.464-.001v29.605h14.36V96.781c0-.494-.401-.895-.896-.895zm-25.136-20.009c-5.453 0-9.873 4.418-9.873 9.868v8.34h19.745l.001-8.34c0-5.45-4.421-9.868-9.873-9.868z"></path>
      <g id="building__window-1">
        <path id="window" fill="#eff4fa" d="M101.67 102.17c0-.994-.806-1.8-1.8-1.8h-3.581c-.994 0-1.8.806-1.8 1.8v3.578c0 .993.806 1.8 1.8 1.8h3.581c.994 0 1.8-.807 1.8-1.8v-3.578z"></path>
        <path id="window-outline" fill="#191816" fill-rule="nonzero" d="M96.289 99.47h3.581c1.492 0 2.7 1.209 2.7 2.7v3.578c0 1.491-1.208 2.7-2.7 2.7h-3.581c-1.492 0-2.7-1.209-2.7-2.7v-3.578c0-1.491 1.208-2.7 2.7-2.7zm0 1.8a.9.9 0 00-.9.9v3.578a.9.9 0 00.9.9h3.581c.498 0 .9-.403.9-.9v-3.578c0-.497-.402-.9-.9-.9h-3.581z"></path>
      </g>
      <g id="building__window-2">
        <path id="window1" fill="#eff4fa" d="M101.67 115.629c0-.994-.806-1.8-1.8-1.8h-3.581c-.994 0-1.8.806-1.8 1.8v3.578c0 .993.806 1.8 1.8 1.8h3.581c.994 0 1.8-.807 1.8-1.8v-3.578z"></path>
        <path id="window-outline1" fill="#191816" fill-rule="nonzero" d="M96.289 112.929h3.581c1.492 0 2.7 1.209 2.7 2.7v3.578c0 1.491-1.208 2.7-2.7 2.7h-3.581c-1.492 0-2.7-1.209-2.7-2.7v-3.578c0-1.491 1.208-2.7 2.7-2.7zm0 1.8a.9.9 0 00-.9.9v3.578a.9.9 0 00.9.9h3.581c.498 0 .9-.403.9-.9v-3.578c0-.497-.402-.9-.9-.9h-3.581z"></path>
      </g>
      <g id="building__window-3">
        <path id="window2" fill="#eff4fa" d="M139.375 102.17c0-.994-.807-1.8-1.8-1.8h-3.582c-.993 0-1.8.806-1.8 1.8v3.578c0 .993.807 1.8 1.8 1.8h3.582c.993 0 1.8-.807 1.8-1.8v-3.578z"></path>
        <path id="window-outline2" fill="#191816" fill-rule="nonzero" d="M133.993 99.47h3.582c1.491 0 2.7 1.209 2.7 2.7v3.578c0 1.491-1.209 2.7-2.7 2.7h-3.582c-1.491 0-2.7-1.209-2.7-2.7v-3.578c0-1.491 1.209-2.7 2.7-2.7zm0 1.8a.9.9 0 00-.9.9v3.578a.9.9 0 00.9.9h3.582a.9.9 0 00.9-.9v-3.578a.9.9 0 00-.9-.9h-3.582z"></path>
      </g>
      <g id="building__window-4">
        <path id="window3" fill="#eff4fa" d="M139.375 115.629c0-.994-.807-1.8-1.8-1.8h-3.582c-.993 0-1.8.806-1.8 1.8v3.578c0 .993.807 1.8 1.8 1.8h3.582c.993 0 1.8-.807 1.8-1.8v-3.578z"></path>
        <path id="window-outline3" fill="#191816" fill-rule="nonzero" d="M133.993 112.929h3.582c1.491 0 2.7 1.209 2.7 2.7v3.578c0 1.491-1.209 2.7-2.7 2.7h-3.582c-1.491 0-2.7-1.209-2.7-2.7v-3.578c0-1.491 1.209-2.7 2.7-2.7zm0 1.8a.9.9 0 00-.9.9v3.578a.9.9 0 00.9.9h3.582a.9.9 0 00.9-.9v-3.578a.9.9 0 00-.9-.9h-3.582z"></path>
      </g>
      <g id="building__clock">
        <ellipse cx="116.932" cy="86.014" fill="#fff" stroke="#34bc6a" stroke-width="1.8" rx="6.284" ry="6.281"></ellipse>
        <path id="building__clock-outline" fill="#191816" fill-rule="nonzero" d="M116.932 93.195c3.967 0 7.184-3.215 7.184-7.181s-3.217-7.181-7.184-7.181c-3.968 0-7.184 3.215-7.184 7.181s3.216 7.181 7.184 7.181zm0-1.8c-2.974 0-5.384-2.41-5.384-5.381 0-2.972 2.41-5.381 5.384-5.381 2.973 0 5.384 2.409 5.384 5.381 0 2.971-2.411 5.381-5.384 5.381z"></path>
        <path id="building__clock-pointer" fill="none" stroke="#191816" stroke-width="1.8" d="M116.932 82.963v3.248l2.154 2.154"></path>
      </g>
      <g id="building__large-window">
        <path id="building__window" fill="#eff4fa" d="M106.159 98.575h21.545v6.317h-21.545z"></path>
        <path id="building__window-highlight" fill="#fff" fill-rule="nonzero" d="M119.751 98.575h-13.592v6.317h7.272l6.32-6.317z"></path>
        <path id="building__window-outline" fill="#191816" fill-rule="nonzero" d="M106.159 105.792a.9.9 0 01-.9-.9v-6.317a.9.9 0 01.9-.9h21.546a.9.9 0 01.9.9v6.317a.9.9 0 01-.9.9h-21.546zm5.384-6.318l-4.484.001v4.517l4.484-.001v-4.517zm8.977.001h-7.177v4.517h7.177v-4.517zm6.285 0l-4.485-.001v4.517l4.485.001v-4.517z"></path>
      </g>
      <g id="building__flag" fill-rule="nonzero">
        <path id="building__flag-pole" fill="#191816" d="M117.832 74.959V59.634a.9.9 0 00-1.8 0v15.325c0 .498.403.9.9.9s.9-.402.9-.9z"></path>
        <path id="building__flag-fill" fill="#fb4328" d="M116.932 68.266l8.636-4.325-8.636-4.307v8.632z"></path>
        <path id="building__flag-outline" fill="#191816" d="M117.335 69.071l8.636-4.325c.663-.332.662-1.279-.001-1.61l-8.637-4.307c-.598-.299-1.301.137-1.301.805v8.632c0 .669.704 1.104 1.303.805zm.497-7.982l5.723 2.854-5.723 2.866v-5.72z"></path>
      </g>
      <g id="building__door" fill-rule="nonzero">
        <path fill="#eff4fa" d="M116.932 112.357c-2.975 0-5.387 2.411-5.387 5.384v8.649h10.773v-8.631a5.3817 5.3817 0 00-1.571-3.819 5.3913 5.3913 0 00-3.815-1.583z"></path>
        <path id="building__door-highlight" fill="#fff" d="M121.169 114.403a5.3895 5.3895 0 00-4.237-2.046c-2.975 0-5.387 2.411-5.387 5.384v6.263l9.624-9.601z"></path>
        <path id="building__door-outline" fill="#191816" d="M116.932 111.457c-3.472 0-6.287 2.813-6.287 6.284v8.649a.9.9 0 00.9.9h10.773a.9.9 0 00.9-.9v-8.631a6.2778 6.2778 0 00-1.834-4.454 6.2853 6.2853 0 00-4.452-1.848zm-4.487 14.033v-7.749c0-2.476 2.009-4.484 4.487-4.484 1.192 0 2.336.475 3.178 1.319a4.484 4.484 0 011.308 3.18v7.734h-8.973z"></path>
      </g>
    </g>
  </g>`;

export default function helpSocialInitiatives() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
      clipRule='evenodd'
      viewBox='0 0 180 180'
      // eslint-disable-next-line
      dangerouslySetInnerHTML={{ __html: helpSocialContent }}
    />
  );
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TrackVisibility from 'react-on-screen';

import i18n from '../../i18n/helper';
import MarkdownField, { MARKDOWN_MID } from '../markdown/MarkdownField';
import { getNoOfPhases as getNoOfPhasesAction } from '../../actions';
import { getType, stageTypes } from '../timeline/StageUtils';
import PillButton from '../button/PillButton';
import SvgIcon from '../SvgIcon';

function TimelineTeaser(props) {
  const { icon, classModifier } = props;
  return (
    <div className={`c-timeline-teaser__stage c-timeline-teaser__stage--${classModifier}`}>
      <div className='c-timeline-teaser__indicator'>
        <SvgIcon icon={icon} />
      </div>
    </div>
  );
}

function ProjectProgress(props) {
  const { timeline, actions, slugId } = props;
  const { getNoOfPhases } = actions;

  const MAX_ITEMS = 7;
  const HALF_ITEMS = Math.floor(MAX_ITEMS / 2);

  const [phases, setPhases] = useState([]);
  const [phase, setPhase] = useState(null);
  const [stages, setStages] = useState([]);
  const [stage, setStage] = useState({ date: '', title: '', description: '', type: '' });
  const [phaseTitle, setPhaseTitle] = useState('');

  useEffect(() => {
    getNoOfPhases(slugId);
  }, [getNoOfPhases, slugId]);

  useEffect(() => {
    timeline.phases.phases && setPhases(timeline.phases.phases);
  }, [timeline]);

  useEffect(() => {
    if (phases.length > 0) {
      const index = phases.findIndex((item) => item.phasePercentage < 100);
      if (index !== -1) {
        setPhase(phases[index]);
      } else {
        setPhase(phases[phases.findIndex((item) => item.isCurrent)]);
      }
    }
  }, [phases]);

  // ################# IMPORTANT #################
  // should look into refactoring this
  // #############################################
  useEffect(() => {
    if (phase) {
      setPhaseTitle(phase.phaseTitle);
      // if there are more than MAX_ITEMS stages, find the "in_progress" one and take 3 left and right stages
      if (phase.updates.length > MAX_ITEMS) {
        // ugly math to get MAX_ITEMS stages even if "in_progress" does not have 3 stages left and right
        const indexOfInProgress = phase.updates.findIndex((item) => item.type === stageTypes.IN_PROGRESS);
        if (indexOfInProgress !== -1) {
          if (indexOfInProgress >= phase.updates.length - HALF_ITEMS) {
            setStages(phase.updates.slice(phase.updates.length - MAX_ITEMS, phase.updates.length));
          } else if (indexOfInProgress < HALF_ITEMS) {
            setStages(phase.updates.slice(0, MAX_ITEMS));
          } else {
            setStages(phase.updates.slice(indexOfInProgress - HALF_ITEMS, indexOfInProgress + HALF_ITEMS + 1));
          }
        } else {
          // take the last 7 items on timeline home if no "in_progress" stage
          setStages(phase.updates.slice(Math.max(phase.updates.length - MAX_ITEMS, 0)));
        }
        // otherwise just take all of them
      } else {
        setStages(phase.updates);
      }
    }
  }, [phase, HALF_ITEMS]);

  useEffect(() => {
    const indexOfInProgress = stages.findIndex((item) => item.type === stageTypes.IN_PROGRESS);
    if (indexOfInProgress !== -1) {
      setStage(stages[indexOfInProgress]);
    } else {
      stages.length > 0 && setStage(stages[stages.length - 1]);
    }
  }, [stages]);

  return (
    <div className='c-block'>
      <div className='l-container l-container--spacing'>
        <TrackVisibility once className='c-timeline-teaser'>
          {({ isVisible }) =>
            isVisible && (
              <React.Fragment>
                <h2 className='c-timeline-teaser__heading'>{`${i18n.t('header.timeline')} — ${phaseTitle}`}</h2>

                <div className={`c-timeline-teaser__visual ${isVisible ? 'u-a-children-scale-in' : ''}`}>
                  {stages.map((item, index) => (
                    <TimelineTeaser key={index} {...getType(item.type)} />
                  ))}
                </div>

                <div className='c-timeline-teaser__details'>
                  <p className='c-timeline-teaser__meta'>
                    {stage.date} <span className='c-timeline-teaser__tag'>{getType(stage.type).text}</span>
                  </p>
                  <h3 className='c-timeline-teaser__title'>{stage.title}</h3>
                  <div className='c-timeline-teaser__description u-rich-text'>
                    <MarkdownField markdown={stage.description} markdownConfig={MARKDOWN_MID} />
                  </div>
                  <PillButton
                    classModifier='c-button--outline c-button--small c-timeline-teaser__action'
                    url='timeline'
                    title={i18n.t('body.viewTimeline')}
                  />
                </div>
              </React.Fragment>
            )
          }
        </TrackVisibility>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  timeline: state.timeline,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getNoOfPhases: getNoOfPhasesAction,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectProgress);

ProjectProgress.propTypes = {
  timeline: PropTypes.object,
  actions: PropTypes.object,
  slugId: PropTypes.string,
  isVisible: PropTypes.bool,
};

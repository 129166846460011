// 06_MultiChoiceQuestion.js
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { createAnswer } from 'components/organisms/question';
import ChoiceImage from '../ChoiceImage';
import SubmitFooter from './SubmitFooter';
import NextFooter from './NextFooter';
import { handleScroll } from '../QuestionUtils/QuestionFormUtils';
import i18n from '../../../i18n/helper';

function Question06(props) {
  const { onSubmitQuestion, isResult, question, communityAnswer, showCoin, questionButtonClicked, disableForm } = props;
  const [selected, setSelected] = useState(new Set());
  const [isScrollingClass, setIsScrollingClass] = useState('');

  useEffect(() => {
    setIsScrollingClass(question.choices && question.choices.length <= 4 ? 'c-question--scroll' : '');
  }, [question.choices]);

  // Fix class for grid with only 6 items
  const getGridClass = (choices) => {
    if (choices) {
      switch (choices.length) {
        case 4:
        case 8:
          return 'l-choice-grid--square-four';
        case 6:
          return 'l-choice-grid--square-three';
        case 10:
          return 'l-choice-grid--square-five';
        default:
          return '';
      }
    }
    return '';
  };

  const submitQuestionAndDisableChoices = () => {
    const answer = createAnswer(
      Array.from(selected).map((choiceId) => ({
        choiceId,
      })),
    );
    onSubmitQuestion(answer);
  };

  const getWideClass = (choices) => {
    if (choices) {
      return choices.length !== 6 ? 'c-question--wide' : '';
    }
    return '';
  };

  const isDisabled = (choice) => {
    const limitReached = selected.size === question.multiChoicesSelectableBound;
    return (limitReached && !selected.has(choice._id)) || isResult || disableForm;
  };

  const getChoiceClass = (choice) => {
    let clazz = '';
    const isSelected = selected.has(choice._id);

    if (isResult) {
      clazz += ' is-result ';
    }
    if (isSelected) {
      clazz += ' is-checked ';
    }
    if (isSelected && isResult) {
      clazz += ' is-highlight ';
    }
    if (isDisabled(choice)) {
      clazz += ' is-disabled ';
    }

    return clazz;
  };

  const handleSelect = (e, choice) => {
    const tempSelected = new Set(selected);
    if (e.target.checked) {
      // Ignore select if max allowed choices number reached
      if (tempSelected.size < question.multiChoicesSelectableBound) {
        tempSelected.add(choice._id);
      }
    } else {
      tempSelected.delete(choice._id);
    }
    setSelected(tempSelected);
  };

  return (
    <article
      className={`c-question ${getWideClass(question.choices)} ${isScrollingClass}`}
      onScroll={handleScroll(setIsScrollingClass)}
    >
      <div className='c-question__header'>
        <h2 className='c-question__title'>
          {isResult ? i18n.t('questions.multiChoice.question.result') : question.questionText}
        </h2>
      </div>

      <div className='c-question__content'>
        <div className={`l-choice-grid u-a-children-fade-in ${getGridClass(question.choices)}`}>
          {question.choices.map((choice, idx) => {
            const communityAnswerFiltered =
              communityAnswer && communityAnswer.filter((item) => item._id === choice._id);
            return (
              <div key={idx} className={`c-choice-image c-choice-image--pie ${getChoiceClass(choice, idx)}`}>
                <input
                  type='checkbox'
                  id={`choice-name-${idx}`}
                  name={`choice-name-${idx}`}
                  onChange={(e) => handleSelect(e, choice)}
                  disabled={isDisabled(choice)}
                  checked={selected.has(choice._id)}
                />
                <label htmlFor={`choice-name-${idx}`}>
                  <ChoiceImage
                    isResult={isResult}
                    resource={choice.resource}
                    choiceName={choice.choice}
                    communityAnswer={
                      communityAnswerFiltered && communityAnswerFiltered[0] && communityAnswerFiltered[0].amount
                    }
                  />
                  <span className='c-choice-image__title'>{choice.choice}</span>
                </label>
              </div>
            );
          })}
        </div>
      </div>

      {!isResult && (
        <SubmitFooter
          isDisabled={selected.size === 0 || disableForm}
          onSubmitQuestion={submitQuestionAndDisableChoices}
          nrChoices={question.multiChoicesSelectableBound}
          showCoin={showCoin}
        />
      )}
      {isResult && <NextFooter onNext={questionButtonClicked} />}
    </article>
  );
}

Question06.propTypes = {
  question: PropTypes.object.isRequired,
  onSubmitQuestion: PropTypes.func.isRequired,
  isResult: PropTypes.bool.isRequired,
  showCoin: PropTypes.bool.isRequired,
  questionButtonClicked: PropTypes.func.isRequired,
  communityAnswer: PropTypes.array.isRequired,
};
export default Question06;

import { createStore, applyMiddleware, compose } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
// sax import createHistory from 'history/createBrowserHistory';
import { createBrowserHistory } from 'history';
import thunkMiddleware from 'redux-thunk';
import combineReducers from './reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// sax export const history = createHistory();
export const history = createBrowserHistory();

const store = createStore(
  connectRouter(history)(combineReducers),
  composeEnhancers(applyMiddleware(routerMiddleware(history), thunkMiddleware)),
);

export default store;

import React from 'react';
import PropTypes from 'prop-types';
import ImageQuestion from '../imageQuestion/ImageQuestion';

export default function Question00(props) {
  return (
    <ImageQuestion
      articleClassModifier={`${
        props.isResult ? ' c-question--side-header c-question--center-header' : ' c-question--side-media'
      }`}
      imageType='SQUARE_L'
      imageGridClassModifier='l-choice-grid--square-only-three'
      agreementText={false}
      media
      {...props}
    />
  );
}

Question00.propTypes = {
  question: PropTypes.object.isRequired,
  communityAnswer: PropTypes.array.isRequired,
  onSubmitQuestion: PropTypes.func.isRequired,
  isResult: PropTypes.bool.isRequired,
  showCoin: PropTypes.bool.isRequired,
  questionButtonClicked: PropTypes.func.isRequired,
};

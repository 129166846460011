import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { retrieveCharities } from '../../../actions';
import ExplainerCharityItem from './ExplainerCharityItem';

function CharityExplainerCharityItem(props) {
  const { charities, slugId, actions } = props;
  const [isOpen, setIsOpen] = useState(null);

  useEffect(() => {
    actions.retrieveCharities(slugId);
  }, [actions, slugId]);

  return (
    <div className='c-explainer__item c-carousel__item'>
      <div className='c-explainer__group'>
        <div className='c-explainer__charities'>
          {charities
            .filter((charity) => charity.isActive)
            .map((charity) => (
              <ExplainerCharityItem
                key={charity._id}
                id={charity._id}
                title={charity.title}
                description={charity.description}
                icon={charity.resource}
                coins={charity.coins}
                isOpen={isOpen === charity._id}
                setIsOpen={setIsOpen}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  charities: state.charities.data,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      retrieveCharities,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CharityExplainerCharityItem);

CharityExplainerCharityItem.propTypes = {
  charities: PropTypes.array,
  slugId: PropTypes.string,
};

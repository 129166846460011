import React from 'react';
import ReactMarkdown from 'react-markdown';

import { MARKDOWN_MIN, MARKDOWN_MIN_LINK, MARKDOWN_MIN_PLUS, MARKDOWN_MID, MARKDOWN_MAX } from './MarkdownConstants';

function MarkdownField(props) {
  const { markdown, markdownConfig = MARKDOWN_MID } = props;
  // WTF linter
  // return (<ReactMarkdown children={markdown} unwrapDisallowed allowedElements={markdownConfig.renderConfig}>
  return (
    <ReactMarkdown unwrapDisallowed allowedElements={markdownConfig.renderConfig}>
      {markdown}
    </ReactMarkdown>
  );
}

export { MARKDOWN_MIN, MARKDOWN_MIN_LINK, MARKDOWN_MIN_PLUS, MARKDOWN_MID, MARKDOWN_MAX };
export default MarkdownField;
